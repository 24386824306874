import { useSelector, useDispatch } from "react-redux";
import React, { useEffect, useMemo, useRef } from "react";
import { addUserListener } from "../store/data";
import { dataUsersSelector } from "../store/data";

export function useUsersLazily(uids: string[], listen = true) {
  const dispatch = useDispatch();
  const users = useSelector(dataUsersSelector);

  useEffect(() => {
    const handlerFn = async () => {
      for (let i = 0; i < uids.length; i++) {
        dispatch(addUserListener(uids[i]));
      }
    };
    handlerFn();
  }, [uids]);

  return useMemo(
    () => uids.filter((uid) => users[uid]).map((uid) => users[uid]),
    [users, uids]
  );
}
