import axios from "axios";

import { getDatabase } from "firebase/database";
import { getAuth } from "firebase/auth";

import { logDebug } from "../../util/log.util";

type JoinChannelPayload = {
  channelId: string;
  groupId: string;
  userId?: string;
};

export async function joinChannel({
  channelId,
  groupId,
  userId,
}: JoinChannelPayload) {
  if (!channelId || !groupId) {
    logDebug("[joinChannel] Channel or Group ID isn't provided", {
      channelId,
      groupId,
    });

    return;
  }

  const realtimeDb = getDatabase();
  const authSession = getAuth();

  const sessionUserId = authSession.currentUser?.uid;

  if (!sessionUserId) {
    logDebug("[joinChannel] Cannot retrieve current user ID");

    return;
  }

  const idToken = await authSession.currentUser?.getIdToken();

  if (!idToken) {
    logDebug("[joinChannel] Cannot retrieve an ID token of current user");

    return;
  }

  const projectId = realtimeDb.app.options.projectId;

  if (!projectId) {
    logDebug("[joinChannel] Cannot retrieve Firebase project ID");

    return;
  }

  const config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
      "Content-Type": "application/json",
    },
  };

  return axios.post(
    `https://us-central1-${projectId}.cloudfunctions.net/joinChannel`,
    {
      userUid: userId || sessionUserId,
      channelUid: channelId,
      groupUid: groupId,
      isMain: false,
      addedBy: false,
    },
    config
  );
}
