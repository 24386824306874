import { colorPalette, newColorPalette, default as Theme } from "./Theme";

// Layer 1 (translates initial colors to second layer)
const oldColorBotToNew = {
  "#147efb": "Blue Marble",
  "#0168FF": "Blue Marble",
  "#313599": "Blue Marble",
  "#9C6969": "Earth",
  "#31FB68": "Moss",
  "#97CA64": "Moss",
  "#016930": "Moss",
  "#FFC633": "Dandelion",
  "#FF3335": "Heat",
  "#C63366": "Rosa",
  "#FFCA02": "Dandelion",
  "#FF9768": "Bubblegum",
  "#9E3233": "Rosa",
  "#9B99CC": "Pluto",
  "#9797FD": "Pluto",
  "#69369C": "Bubblegum",
  "#616161": "Glass",
};

export const basePalette = {
  blue: "#007AFF",
  brown: "#A2845E",
  cyan: "#32ADE6",
  green: "#34C759",
  purple: "#AF52DE",
  orange: "#FF9500",
  pink: "#FF2D55",
  red: "#FF3B30",
  mint: "#00C7BE",
  yellow: "#FFC700",
};

// Colors data for Layer 2
export const groupColorsByName = {
  "Blue Marble": {
    colorTop: "#00B2FF",
    colorBot: "#0038FF",
    colorMid: "#015FFF",
    name: "Blue Marble",
  },
  Heat: {
    colorTop: "#FF8616",
    colorBot: "#FF3333",
    colorMid: "#E46813",
    name: "Heat",
  },
  Pluto: {
    colorTop: "#A139F3",
    colorBot: "#4A21F0",
    colorMid: "#8331F3",
    name: "Pluto",
  },
  Rosa: {
    colorTop: "#F31D6A",
    colorBot: "#FF2929",
    colorMid: "#FF3346",
    name: "Rosa",
  },
  Bubblegum: {
    colorTop: "#E16EDD",
    colorBot: "#A53EF5",
    colorMid: "#B44BEF",
    name: "Bubblegum",
  },
  Moss: {
    colorTop: "#05DA3A",
    colorBot: "#00AEB9",
    colorMid: "#04A195",
    name: "Moss",
  },
  Dandelion: {
    colorTop: "#FFCC00",
    colorBot: "#FF9933",
    colorMid: "#DC8323",
    name: "Dandelion",
  },
  Earth: {
    colorTop: "#B05A3E",
    colorBot: "#7E2B19",
    colorMid: "#8B3823",
    name: "Earth",
  },
  Glass: {
    colorTop: "#C5C5C5",
    colorBot: "#727272",
    colorMid: "#535353",
    name: "Glass",
  },
};

// Layer 2 (translating colorBot to colorMid)
const colorMidFromColorBot = {
  "#0038FF": "#015FFF",
  "#FF3333": "#E46813",
  "#4A21F0": "#8331F3",
  "#FF2929": "#FF3346",
  "#A53EF5": "#B44BEF",
  "#00AEB9": "#04A195",
  "#FF9933": "#DC8323",
  "#7E2B19": "#8B3823",
  "#727272": "#535353",
};

// Layer 3 (color palette unification - translates colorMid from Layer 2 to new color of Layer 3)
const newPaletteColorFromColorMid = {
  "#015FFF": Theme.blue,
  "#8B3823": Theme.brown,
  "#04A195": Theme.green,
  "#8331F3": Theme.purple,
  "#B44BEF": Theme.purple,
  "#E46813": Theme.orange,
  "#FF3346": Theme.red,
  "#DC8323": Theme.yellow,
  "#535353": Theme.lightGray,
};

export function getGroupColor(group) {
  if (!group) return Theme.blue;

  let groupColor = null;

  if (
    group.color &&
    (Object.values(colorPalette).includes(group.color) ||
      Object.values(newColorPalette).includes(group.color))
  ) {
    // Handles layer 3, where group has one color property
    groupColor = group.color;
  } else if (
    group.colorBot &&
    Object.values(colorPalette).includes(group.colorBot)
  ) {
    // Handles miscellaneous layer
    groupColor = group.colorBot;
  } else if (group.colorTop && group.colorBot in colorMidFromColorBot) {
    // Handles layer 2
    const colorMid = colorMidFromColorBot[group.colorBot];
    groupColor = newPaletteColorFromColorMid[colorMid];
  } else if (group.colorBot && group.colorBot in oldColorBotToNew) {
    // Handles layer 1
    const colorMid =
      groupColorsByName[oldColorBotToNew[group.colorBot]]?.colorMid;
    groupColor = newPaletteColorFromColorMid[colorMid];
  }

  return groupColor || Theme.blue;
}

export default Object.values(colorPalette);
