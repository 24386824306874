import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

import {
  getAuth,
  browserLocalPersistence,
  setPersistence,
} from "firebase/auth";

const prodConfig = {
  apiKey: "AIzaSyAEHvAB5Vtpg5W8uQfLe1jNTQsOZ9HAyd4",
  authDomain: "dive-67320.firebaseapp.com",
  databaseURL: "https://dive-67320.firebaseio.com",
  projectId: "dive-67320",
  storageBucket: "dive-67320.appspot.com",
  messagingSenderId: "453021838278",
  appId: "1:453021838278:web:0eb7d44bd6b07d89",
  measurementId: "G-H43GB9HRD4",
};

const devConfig = {
  apiKey: "AIzaSyC6-oz5YQnec4d5EHBvllQLI1p-DSMgtXk",
  authDomain: "dive-chat-dev.firebaseapp.com",
  databaseURL: "https://dive-chat-dev.firebaseio.com",
  projectId: "dive-chat-dev",
  storageBucket: "dive-chat-dev.appspot.com",
  messagingSenderId: "931200302136",
  appId: "1:931200302136:web:1403cd7e3c416fac1a7acd",
  measurementId: "G-0K11RLWP8M",
};

const app = initializeApp(prodConfig);

export const auth = getAuth(app);
export const database = getDatabase(app);

setPersistence(auth, browserLocalPersistence);
