import { createAsyncThunk } from "@reduxjs/toolkit";

import { listenForCustomEmojiMap } from "dive/firebase/group";

import { setCustomEmojiMap, unsetCustomEmojiMap } from "../data.slice";

export const addCustomEmojiMapListener = createAsyncThunk(
  "data/ADD_CUSTOM_EMOJI_MAP_LISTENER",
  (groupId: string, { getState, dispatch }) => {
    const stateSnapshot = getState();

    if (!groupId) {
      return Promise.reject("The ID of the group is not defined");
    }

    // We should generate custom ID, because {listenerMap} may already have
    //   an element with {groupId}, because we also have group data listener.
    const listenerMapId = `customEmojiMap-${groupId}`;

    // @ts-expect-error
    if (stateSnapshot?.data?.listenerMap?.[listenerMapId]) {
      return Promise.reject("The listener already exists");
    }

    const removeListener = listenForCustomEmojiMap({
      groupId,
      onValue: (customEmojiMap) => {
        if (!customEmojiMap) {
          dispatch(unsetCustomEmojiMap({ groupId }));

          return;
        }

        dispatch(setCustomEmojiMap({ groupId, customEmojiMap }));
      },
    });

    // TODO: Save the "removeListener" to cache/Redux so
    //   we can access this function later to remove group listener.

    return Promise.resolve();
  }
);
