import React, { FC, useEffect, useRef } from "react";

import { logDebug } from "../../util/log.util";

import { AnimatedCounterBlockContainer } from "./AnimatedCounter.style";

type AnimatedCounterBlockProps = {
  isLast: boolean;
  count: number;
  xPosPercent: number;
  yPosPercent: number;
  rotateDeg: number;
  textColor: string;
  onHide: VoidFunction;
};

export const AnimatedCounterBlock: FC<AnimatedCounterBlockProps> = ({
  isLast,
  count,
  xPosPercent,
  yPosPercent,
  rotateDeg,
  textColor,
  onHide,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const showAnimExecutorRef = useRef<Animation>();
  const hideAnimExecutorRef = useRef<Animation>();

  const ANIMATION_DURATION_MS = 300;

  useEffect(() => {
    if (!containerRef.current) {
      logDebug(
        "[AnimatedCounterBlock] Cannot find element's 'ref' to start animation"
      );

      return;
    }

    showAnimExecutorRef.current = containerRef.current.animate(
      {
        opacity: [0, 1],
        transform: [
          `rotate(${rotateDeg}deg) scale(0)`,
          `rotate(${rotateDeg}deg) scale(1)`,
        ],
      },
      { duration: ANIMATION_DURATION_MS, iterations: 1 }
    );

    const onShowAnimationFinish = () => {
      if (!containerRef.current) {
        return;
      }

      containerRef.current.style.transform = `rotate(${rotateDeg}deg) scale(1)`;
    };

    showAnimExecutorRef.current.addEventListener(
      "finish",
      onShowAnimationFinish
    );

    return () => {
      showAnimExecutorRef.current?.removeEventListener(
        "finish",
        onShowAnimationFinish
      );
    };
  }, []);

  useEffect(() => {
    // When a new multiplier number appears, rather than making the existing
    //   multiplier number disappear immediately, there should be a 200 ms delay.
    // But under all circumstances the original entry animation should complete first

    const hideAnimationKeyframes: PropertyIndexedKeyframes = {
      opacity: [1, 0],
      transform: [
        `rotate(${rotateDeg}deg) scale(1)`,
        `rotate(${rotateDeg}deg) scale(0)`,
      ],
    };

    const onHideAnimationFinish = () => {
      if (containerRef.current) {
        containerRef.current.style.transform = `rotate(${rotateDeg}deg) scale(0)`;
      }

      onHide();
    };

    const startExitAnimation = () => {
      hideAnimExecutorRef.current = containerRef.current?.animate(
        hideAnimationKeyframes,
        { duration: ANIMATION_DURATION_MS, delay: 2000, iterations: 1 }
      );

      hideAnimExecutorRef.current?.addEventListener(
        "finish",
        onHideAnimationFinish
      );
    };

    if (hideAnimExecutorRef.current || !isLast) {
      hideAnimExecutorRef.current = containerRef.current?.animate(
        hideAnimationKeyframes,
        { duration: ANIMATION_DURATION_MS, delay: 200, iterations: 1 }
      );

      hideAnimExecutorRef.current?.addEventListener(
        "finish",
        onHideAnimationFinish
      );
    } else {
      showAnimExecutorRef.current?.addEventListener(
        "finish",
        startExitAnimation
      );
    }

    return () => {
      showAnimExecutorRef.current?.removeEventListener(
        "finish",
        startExitAnimation
      );

      hideAnimExecutorRef.current?.cancel();

      hideAnimExecutorRef.current?.removeEventListener(
        "finish",
        onHideAnimationFinish
      );
    };
  }, [isLast]);

  const contentText = `× ${count}`;

  return (
    <AnimatedCounterBlockContainer
      ref={containerRef}
      zIndex={count}
      topPercent={yPosPercent}
      leftPercent={xPosPercent}
      color={textColor}
      content={contentText}
    >
      {contentText}
    </AnimatedCounterBlockContainer>
  );
};
