import { useCallback } from "react";

import { logDebug } from "../util/log.util";
import { dataMessageMapByChannelSelector, setMessage } from "../store/data";
import { store, useDispatch } from "../store";
import { Message } from "../type/data.type";

type MessageSentPayload = {
  messageId: string;
  channelId: string;
};

// Used to update cached message data to remove local fields
export function useOnMessageSent() {
  const dispatch = useDispatch();

  return useCallback(
    ({ messageId, channelId }: MessageSentPayload) => {
      if (!messageId) {
        logDebug(`[useOnMessageSent] Message data doesn't exist`);

        return;
      }

      const messageByChannelMap = dataMessageMapByChannelSelector(
        // @ts-expect-error
        store.getState(),
        channelId
      );

      const currentMessageSnapshot = messageByChannelMap?.[
        messageId
      ] as Message;

      if (!currentMessageSnapshot) {
        logDebug(
          `[useOnMessageSent] Message snapshot doesn't exist in Redux store`
        );

        return;
      }

      const { localMessage, showNotSent, ...nextMessageSnapshot } =
        currentMessageSnapshot;

      dispatch(
        setMessage({
          channelUid: channelId,
          messageObj: nextMessageSnapshot,
        })
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
}
