import React, { FC, PropsWithChildren, useEffect } from "react";
import { AnimatePresence } from "framer-motion";
import { RecognizedBrowser } from "sniffr";

import { logDebug, logWarning } from "../../util/log.util";
import { useIsBrowser } from "../../hook/useIsBrowser";
import { registerDesktopPushToken } from "../../firebase/user";
import { NotificationPriming } from "../../components/NotificationPriming";

import { useInitialPayloadEffect } from "./useInitialPayloadEffect";
import { useOpenNotificationSource } from "./useOpenNotificationSource";
import { useBadgeCountEffect } from "./useBadgeCountEffect";
import { useNotificationPrimingModalState } from "./useNotificationPrimingModalState";

export const DesktopNotificationProvider: FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  const isBrowser = useIsBrowser();

  const { isPrimingModalVisible } = useNotificationPrimingModalState();

  const openNotificationSource = useOpenNotificationSource();

  useInitialPayloadEffect();

  useBadgeCountEffect();

  useEffect(
    () => {
      // The Desktop Push token should only be retrieved in desktop app
      if (isBrowser) {
        return;
      }

      if (!window.diveNativeDesktopApi) {
        logDebug(`[DesktopNotificationProvider] Desktop API is not available`);

        return;
      }

      const clearEffectList: (() => void)[] = [];

      const removeNotificationClickEventListener =
        window.diveNativeDesktopApi.setNotificationClickEventHandler(
          (event, notificationMeta) => {
            logDebug(
              `[DesktopNotificationProvider] Notification click`,
              notificationMeta
            );

            const channelId = notificationMeta.channelId;
            const groupId = notificationMeta.groupId;
            const eventId = notificationMeta.eventId;

            openNotificationSource({
              channelId,
              groupId,
              eventId,
            });
          }
        );

      clearEffectList.push(removeNotificationClickEventListener);

      if (RecognizedBrowser.os.name === "macos") {
        window.diveNativeDesktopApi
          .registerForApns()
          .then((apnsToken) => {
            logDebug(`[DesktopNotificationProvider] Setting APNS token`, {
              apnsToken,
            });

            registerDesktopPushToken({ token: apnsToken, isApns: true });
          })
          .catch((ex) => {
            logWarning(
              `[DesktopNotificationProvider] Cannot register for APNS`,
              ex
            );
          });
      } else {
        const removePushServiceStartedEventListener =
          window.diveNativeDesktopApi.setNotificationServiceStartedEventHandler(
            (event, token) => {
              logDebug(`[DesktopNotificationProvider] Setting FCM token`, {
                event,
                token,
              });

              registerDesktopPushToken({ token });
            }
          );

        const removePushTokenUpdatedEventListener =
          window.diveNativeDesktopApi.setPushTokenUpdatedEventHandler(
            (event: Record<string, unknown>, token: string) => {
              logDebug(`[DesktopNotificationProvider] Setting new FCM token`, {
                event,
                token,
              });

              registerDesktopPushToken({ token });
            }
          );

        window.diveNativeDesktopApi.setAppReady();

        clearEffectList.push(removePushServiceStartedEventListener);
        clearEffectList.push(removePushTokenUpdatedEventListener);
      }

      return () => {
        clearEffectList.forEach((clearEffect) => {
          clearEffect();
        });
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isBrowser]
  );

  return (
    <>
      {children}
      <AnimatePresence>
        {isPrimingModalVisible ? (
          <NotificationPriming key="desktop-notification-priming-modal" />
        ) : null}
      </AnimatePresence>
    </>
  );
};
