import { useContext } from "react";

import { ProfileCardProviderContext } from "./ProfileCardProvider.context";

export function useProfileCard() {
  const { showProfileCard, hideProfileCard } = useContext(
    ProfileCardProviderContext
  );

  return {
    showProfileCard,
    hideProfileCard,
  };
}
