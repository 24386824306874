import React, { ChangeEvent, FC, useCallback, useEffect, useMemo } from "react";
import {
  add,
  differenceInMinutes,
  endOfDay,
  isSameDay,
  startOfDay,
} from "date-fns";

// @ts-expect-error
import { ReactComponent as LocationSvg } from "dive/assets/locationIcon.svg";

import {
  NewEventModalInfoBlock,
  NewEventModalInfoDateBlock,
  NewEventModalInfoDateSection,
  NewEventModalInfoDateSectionPicker,
  NewEventModalInfoDateSectionSeparator,
  NewEventModalInfoDateSectionText,
  NewEventModalInfoLabel,
  NewEventModalInfoLocation,
  NewEventModalInfoLocationSection,
  NewEventModalInfoSection,
  NewEventModalInfoTextarea,
  NewEventModalInfoTitle,
} from "./NewEventModalInfo.style";

type NewEventModalInfoProps = {
  title: string;
  startTime: number;
  endTime: number;
  location: string;
  description: string;
  onTitleChange: (title: string) => void;
  onStartTimeChange: (startTime: number) => void;
  onEndTimeChange: (endTime: number) => void;
  onLocationChange: (emoji: string) => void;
  onDescriptionChange: (description: string) => void;
};

export const NewEventModalInfo: FC<NewEventModalInfoProps> = ({
  title,
  startTime,
  endTime,
  description,
  location,
  onTitleChange,
  onStartTimeChange,
  onEndTimeChange,
  onDescriptionChange,
  onLocationChange,
}) => {
  const textareaPlaceholder = `Share anything your attendees should know including...

  🌎 Links and forms
  💃 What to wear
  🗺️ How to get there
  `;

  useEffect(() => {}, [startTime]);

  const {
    startMinDate,
    startMinTime,
    startMaxTime,
    endMinDate,
    endMinTime,
    endMaxTime,
  } = useMemo(() => {
    const currentTime = Date.now();

    return {
      startMinDate: currentTime,
      startMinTime: isSameDay(currentTime, startTime)
        ? currentTime
        : startOfDay(startTime),
      startMaxTime: endOfDay(currentTime),
      endMinDate: startTime,
      endMinTime: isSameDay(startTime, endTime)
        ? startTime
        : startOfDay(endTime),
      endMaxTime: endOfDay(startTime),
    };
  }, [startTime, endTime]);

  const onTitleTextChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onTitleChange(event.target.value);
    },
    [onTitleChange]
  );

  const onStartTimeValueChange = useCallback(
    (nextDate: Date) => {
      const minDiff = differenceInMinutes(endTime, startTime);

      let targetEndTime = add(nextDate.getTime(), {
        minutes: minDiff,
      });

      onStartTimeChange(nextDate.getTime());

      if (targetEndTime.getTime() > endTime) {
        onEndTimeChange(targetEndTime.getTime());
      }
    },
    [endTime, startTime, onStartTimeChange, onEndTimeChange]
  );

  const onLocationTextChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onLocationChange(event.target.value);
    },
    [onLocationChange]
  );

  const onDescriptionTextChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      onDescriptionChange(event.target.value);
    },
    [onDescriptionChange]
  );

  return (
    <NewEventModalInfoBlock>
      <NewEventModalInfoSection>
        <NewEventModalInfoTitle
          type="text"
          defaultValue=""
          placeholder="Event Name..."
          maxLength={40}
          onChange={onTitleTextChange}
        />
      </NewEventModalInfoSection>
      <NewEventModalInfoDateBlock>
        <NewEventModalInfoDateSection>
          <NewEventModalInfoDateSectionText>
            Starts
          </NewEventModalInfoDateSectionText>
          <NewEventModalInfoDateSectionPicker
            $width="108px"
            $margin="0 12px 0 0"
            selected={new Date(startTime)}
            minDate={startMinDate}
            dateFormat="MMM d, yyyy"
            onChange={onStartTimeValueChange}
          />
          <NewEventModalInfoDateSectionPicker
            $width="76px"
            showTimeSelect
            showTimeSelectOnly
            selected={new Date(startTime)}
            timeCaption="Time"
            timeIntervals={5}
            dateFormat="h:mm aa"
            minTime={startMinTime}
            maxTime={startMaxTime}
            onChange={onStartTimeValueChange}
          />
        </NewEventModalInfoDateSection>
        <NewEventModalInfoDateSectionSeparator />
        <NewEventModalInfoDateSection>
          <NewEventModalInfoDateSectionText>
            Ends
          </NewEventModalInfoDateSectionText>
          <NewEventModalInfoDateSectionPicker
            $width="108px"
            $margin="0 12px 0 0"
            selected={new Date(endTime)}
            dateFormat="MMM d, yyyy"
            minDate={endMinDate}
            onChange={(date: Date) => onEndTimeChange(date.getTime())}
          />
          <NewEventModalInfoDateSectionPicker
            $width="76px"
            showTimeSelect
            showTimeSelectOnly
            selected={new Date(endTime)}
            timeIntervals={5}
            timeCaption="Time"
            dateFormat="h:mm aa"
            minTime={endMinTime}
            maxTime={endMaxTime}
            onChange={(date: Date) => onEndTimeChange(date.getTime())}
          />
        </NewEventModalInfoDateSection>
      </NewEventModalInfoDateBlock>
      <NewEventModalInfoLocationSection>
        <LocationSvg />
        <NewEventModalInfoLocation
          type="text"
          defaultValue=""
          placeholder="Add Location"
          maxLength={150}
          onChange={onLocationTextChange}
        />
      </NewEventModalInfoLocationSection>
      <NewEventModalInfoSection>
        <NewEventModalInfoLabel>Description</NewEventModalInfoLabel>
        <NewEventModalInfoTextarea
          defaultVale=""
          placeholder={textareaPlaceholder}
          onChange={onDescriptionTextChange}
        />
      </NewEventModalInfoSection>
    </NewEventModalInfoBlock>
  );
};
