// PUSH NOTIFICATION TYPES
export const REACTION_NOTIFICATION_TYPE = "reaction";
export const EVENT_VIEW_MS_NOTIFICATION_TYPE = "eventViewMilestone";
export const REGULAR_MESSAGE_NOTIFICATION_TYPE = "regularMessage";
export const COMMENT_MESSAGE_NOTIFICATION_TYPE = "comment";
export const NEW_CHAT_ADDED_MESSAGE_NOTIFICATION_TYPE = "addedToNewChat";
export const NEW_CHAT_CREATED_MESSAGE_NOTIFICATION_TYPE = "newChatCreated";
export const ENCOURAGE_CREATE_EVENT_NOTIFICATION_TYPE = "encourageCreateEvent";
export const ENCOURAGE_CREATE_GROUP_NOTIFICATION_TYPE = "encourageCreateGroup";

// DESKTOP NOTIFICATION PERMISSION STATE
export const ALLOWED_NOTIFICATION_STATE = "allowed-notification-state";
export const REJECTED_NOTIFICATION_STATE = "rejected-notification-state";
export const INITIAL_NOTIFICATION_STATE = "initial-notification-state";

// DESKTOP NOTIFICATION MODE
export const ALL_NOTIFICATION_MODE = "ALL";
export const NONE_NOTIFICATION_MODE = "NONE";
