import React, { FC, useCallback } from "react";

import { useNewEventModalFormData } from "../useNewEventModalFormData";

import { NewEventModalInfo } from "./NewEventModalInfo";

type NewEventModalInfoContainerProps = {};

export const NewEventModalInfoContainer: FC<
  NewEventModalInfoContainerProps
> = ({}) => {
  const {
    data,
    setData,
    meta: { initialData },
  } = useNewEventModalFormData();

  const onTitleChange = useCallback(
    (title: string) => {
      setData({
        title,
      });
    },
    [setData]
  );

  const onStartTimeChange = useCallback(
    (startTime: number) => {
      setData({
        startTime,
      });
    },
    [setData]
  );

  const onEndTimeChange = useCallback(
    (endTime: number) => {
      setData({
        endTime,
      });
    },
    [setData]
  );

  const onLocationChange = useCallback(
    (location: string) => {
      setData({
        location,
      });
    },
    [setData]
  );

  const onDescriptionChange = useCallback(
    (description: string) => {
      setData({
        description,
      });
    },
    [setData]
  );

  return (
    <NewEventModalInfo
      title={data?.title || initialData.title}
      startTime={data?.startTime || initialData.startTime}
      endTime={data?.endTime || initialData.endTime}
      location={data?.location || initialData.location}
      description={data?.description || initialData.description}
      onTitleChange={onTitleChange}
      onStartTimeChange={onStartTimeChange}
      onEndTimeChange={onEndTimeChange}
      onLocationChange={onLocationChange}
      onDescriptionChange={onDescriptionChange}
    />
  );
};
