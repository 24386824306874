import { createAsyncThunk } from "@reduxjs/toolkit";

import { getGroupData } from "../../../firebase/group";

export const retrieveGroupData = createAsyncThunk(
  "data/RETRIEVE_GROUP_DATA",
  (groupId: string) => {
    return getGroupData(groupId);
  }
);
