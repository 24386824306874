import { FootnoteRegular } from "../../constants/DesignSystem";
import styled from "styled-components";

export const UpdateText = styled(FootnoteRegular)`
  color: #8e8e93;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-self: center;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-weight: ${(props) => (props.semi ? "600" : "400")};
`;

export const AccentText = styled(UpdateText)`
  color: ${(props) => props.color};
  font-weight: ${(props) => (props.semi ? "600" : "400")};
  cursor: pointer;
`;
