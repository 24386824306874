import React, { ChangeEvent, FC, useCallback } from "react";
import { Props as ContentEditableInputProps } from "react-contenteditable";

import { TemplateInputContainer } from "./TemplateInput.style";

type TemplateInputProps = ContentEditableInputProps & {
  value: string;
  placeholder: string;
  // Converts string into HTML markup (text)
  renderValue: (textValue: string) => string;
  onTextChange: (value: string) => void;
};

export const TemplateInput: FC<TemplateInputProps> = ({
  innerRef,
  disabled,
  value,
  placeholder,
  renderValue,
  onTextChange,
  onFocus,
  onBlur,
  ...inputProps
}) => {
  const onChange = useCallback(
    (event: ChangeEvent<HTMLDivElement>) => {
      if (!event.target) {
        return;
      }

      // Content of the editable element, including HTML markup
      // @ts-expect-error
      const content = event.target.value || "";

      const contentDoc = new DOMParser().parseFromString(content, "text/html");

      // The text representation of the entered content
      const value = contentDoc.body.textContent || "";

      onTextChange(value);
    },
    [onTextChange]
  );

  return (
    <TemplateInputContainer
      {...inputProps}
      innerRef={innerRef}
      tabIndex={0}
      role="textbox"
      placeholder={placeholder}
      contentEditable={!disabled}
      html={renderValue(value)}
      onChange={onChange}
    />
  );
};
