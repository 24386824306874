import axios from "axios";
import { getDatabase } from "firebase/database";
import { getAuth } from "firebase/auth";

import { ChannelRecord } from "dive/type/channel.type";
import { Group } from "dive/type/group.type";
import { logDebug } from "dive/util/log.util";

type CreateGroupPayload = {
  data: Group;
  channelList: ChannelRecord[];
};

export async function createGroup({ data, channelList }: CreateGroupPayload) {
  const realtimeDb = getDatabase();
  const authSession = getAuth();

  const projectId = realtimeDb.app.options.projectId;

  if (!projectId) {
    logDebug("[addToGroup] Cannot retrieve Firebase project ID");

    return;
  }

  const idToken = await authSession.currentUser?.getIdToken();
  const myId = authSession.currentUser?.uid;

  if (!idToken || !myId) {
    logDebug("[addToGroup] Cannot retrieve an ID token of current user");

    return;
  }

  const config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
      "Content-Type": "application/json",
    },
  };

  return axios
    .post(
      `https://us-central1-${projectId}.cloudfunctions.net/createGroupAuth`,
      {
        userUid: myId,
        groupObj: data,
        listChannels: channelList,
      },
      config
    )
    .then((response) => {
      return response.data;
    });
}
