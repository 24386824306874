import { format, isSameDay, sub, endOfDay, differenceInDays } from "date-fns";
import styled from "styled-components";
import { ReactComponent as DiveReactionSvg } from "../assets/diveReaction.svg";
import { mmhCustomEmoji } from "../components/EmojiPicker/EmojiPicker.constant";
import { store } from "dive/store";
import { dataCustomEmojiByIdSelector } from "dive/store/data";
import { parseEmojiIdFromReactionId } from "./emoji.util";
import { dateDiffInDays, dateDiffInYears } from "./date.util";

//formats a timestamp into a readable time string for display on list item preview
export const formatTime = (timestamp) => {
  if (timestamp === 0) return "";
  const end = new Date();
  end.setHours(23, 59, 59, 999);
  const messageDay = new Date(timestamp);

  try {
    let timeDiff = parseInt((end - messageDay) / (1000 * 60 * 60 * 24), 10);
    let dayToDisplay;
    if (timeDiff === 0) dayToDisplay = format(messageDay, "h:mm a");
    else if (timeDiff === 1) dayToDisplay = "Yesterday";
    else if (timeDiff <= 7) dayToDisplay = format(messageDay, "eeee");
    else dayToDisplay = format(messageDay, "MM/d/yy");

    return dayToDisplay;
  } catch (err) {}
};

//given an array of messages, returns the one closest to the present
export const mostRecentMessage = (messages) => {
  if (!messages || messages.length === 0) {
    return null;
  }
  return messages.reduce((prev, curr) => {
    if (prev.createdAt > curr.createdAt) {
      return prev;
    } else {
      return curr;
    }
  });
};

//remove a substring from a string
export const removeSubstring = (string, substring) => {
  return string.replace(substring, "");
};

//given your UID and the UID of a dm channel, returns the UID of the other person
export const getOtherPersonUid = (myUid, channelUid) => {
  if (!myUid || !channelUid) return "";
  if (typeof channelUid !== "string") return "";
  return removeSubstring(channelUid, myUid);
};

//truncate string and add ellipses if necessary
export const truncateStringForListItem = (string) => {
  if (string.length <= 20) {
    return string;
  }
  return string.substring(0, 18) + "...";
};

export const getInitialsGroup = (groupObj) => {
  if (!groupObj) {
    console.log("A group is undefined somehow. This is a newer bug");
    return "";
  }
  const groupName = groupObj.name;
  if (!groupName) {
    return "";
  }

  const emojiRegex =
    /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;

  if (groupObj?.isSimple) {
    // Either a simple or complex group
    const groupWords =
      groupName
        ?.toUpperCase()
        .split(" ")
        .filter((word) => word && word !== "THE")
        .map((word) => word.replace(emojiRegex, "")) ?? "";

    if (groupWords.length !== 0 && groupWords.length !== 2)
      // Just return initial of first word
      return groupWords[0][0];
    else if (groupWords.length === 2)
      // Group name has 2 separate words
      return groupWords.map((word) => word[0]).join("");
    else return "T";
  } else {
    // DM, so groupObj is actually a user object
    const firstLetters =
      groupName
        ?.toUpperCase()
        .split(" ")
        .filter((word) => word)
        .map((word) => word.replace(emojiRegex, ""))
        .map((word) => word[0]) ?? "";
    if (firstLetters.length <= 2) return firstLetters.join("");
    else return firstLetters[0] + firstLetters.slice(-1);
    // User has name longer than 2 words, return first and last name
  }
};

export const isPreviewMessage = (message) => {
  if (message?.isNewUserMessage) return false;
  if (message?.isRemoveUserMEssage) return false;
  return true;
};

export const formatTimestampChatInterruption = (timestamp) => {
  if (!timestamp) {
    return {
      formattedDate: "",
      dayToDisplay: "",
      timeToDisplay: "",
    };
  }

  // NOTE: Implementation is copied from "ChatTimestamp" component in the mobile app

  const weekday = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const date = new Date(timestamp);

  const timeDiff = (() => {
    const endOfDay = (() => {
      const date = new Date();

      date.setHours(23, 59, 59);

      return date;
    })();

    return dateDiffInDays(date, endOfDay);
  })();

  const yearsDiff = (() => {
    const endOfDay = (() => {
      const date = new Date();

      date.setHours(23, 59, 59);

      return date;
    })();

    return dateDiffInYears(date, endOfDay);
  })();

  let dayToDisplay;

  if (timeDiff === 0) {
    dayToDisplay = "Today";
  } else if (timeDiff === 1) {
    dayToDisplay = "Yesterday";
  } else if (timeDiff <= 7) {
    dayToDisplay = weekday[date.getDay()];
  } else if (yearsDiff == 0) {
    dayToDisplay = `${weekday[date.getDay()]}, ${
      monthNames[date.getMonth()]
    } ${date.getDate()}`;
  } else {
    dayToDisplay = `${weekday[date.getDay()]}, ${
      monthNames[date.getMonth()]
    } ${date.getDate()}, ${date.getFullYear()}`;
  }

  const timeToDisplay = date.toLocaleTimeString("en-EN", {
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  });

  return {
    formattedDate: `${dayToDisplay} at ${timeToDisplay}`,
    dayToDisplay,
    timeToDisplay,
  };
};

export const formatFutureEventTimestamp = (timestamp) => {
  if (!timestamp) return "";
  const eventTime = new Date(timestamp);
  const timeDiff = differenceInDays(eventTime, new Date());

  let dayToDisplay;
  if (timeDiff === 0) dayToDisplay = "Today";
  else if (timeDiff === 1) dayToDisplay = "Tomorrow";
  else if (timeDiff < 7) dayToDisplay = format(eventTime, "eeee");
  else dayToDisplay = format(eventTime, "eeee, MMM d");

  return `${dayToDisplay} at ${format(eventTime, "h:mm a")}`;
};

export const getPreviewText = (message, senderName) => {
  if (!message) return "Get the chat started!";
  let previewText = "";
  if (message.deleted) {
    previewText = "Deleted Message";
  } else if (message.newNickname) {
    const newNickName = message.newNickname.nickname;
    const avatar = message.newNickname.avatar;

    if (newNickName && avatar) {
      previewText = `${senderName} changed their photo and nickname to  “${newNickName}”`;
    } else if (newNickName) {
      previewText = `${senderName} changed their nickname to “${newNickName}”`;
    } else if (avatar) {
      previewText = `${senderName} changed their photo!`;
    }
  } else if (message.text) {
    previewText = message.text;
  } else if (message.event) {
    previewText = `${senderName} shared an event`;
  } else if (message.image) {
    previewText = `${senderName} sent an image`;
  } else if (message.video) {
    previewText = `${senderName} sent a video`;
  } else if (message.poll) {
    previewText = `${senderName} sent a poll`;
  } else if (message.group) {
    previewText = `${senderName} invited you to a group`;
  } else if (message.isAiEventSuggestion) {
    previewText = `New Event Suggestion`;
  } else if (message?.isEventActivityUpdate) {
    previewText = `Event Activity Update`;
  }

  return previewText;
};

const DiveReaction = styled(DiveReactionSvg)`
  width: ${(props) => props?.size}px;
  height: ${(props) => props?.size}px;
`;

export const replaceSpecialEmoji = (text, size = 20) => {
  if (text === "❤") {
    return "❤️";
  }
  if (text === "♥️") {
    return "❤️";
  }
  if (text === ":Dive:" || text === ":dive:") {
    return <DiveReaction size={size} />;
  }

  if (text === mmhCustomEmoji.id || text === mmhCustomEmoji.id.toLowerCase()) {
    return (
      <img
        src={mmhCustomEmoji.imgUrl}
        alt={mmhCustomEmoji.names[0]}
        style={{
          width: `${size}px`,
          height: `${size}px`,
        }}
      />
    );
  }

  const storeSnapshot = store.getState();

  const isCustomEmoji =
    text && text[0] === ":" && text[text.length - 1] === ":";

  if (isCustomEmoji) {
    const emojiId = parseEmojiIdFromReactionId(text);

    const customEmojiData = dataCustomEmojiByIdSelector(storeSnapshot, emojiId);

    if (customEmojiData) {
      return (
        <img
          src={customEmojiData.imageUrl}
          alt={""}
          style={{
            width: `${size}px`,
            height: `${size}px`,
            objectFit: "contain",
          }}
        />
      );
    } else {
      return "🤙";
    }
  }

  return text;
};

export function getMentionRegex(profileList) {
  const mentionRegexString = [...profileList]
    .filter((profile) => profile)
    .sort((el1, el2) => el2.name?.length - el1.name?.length)
    .map((item) => `(\@${item.name})`)
    .join("|");

  return new RegExp(mentionRegexString);
}
