import React, { FC, useCallback, useEffect, useState } from "react";

import { ModalProps } from "dive/provider/ModalProvider";
import { useModal } from "dive/hook/useModal.hook";
import { logDebug } from "dive/util/log.util";
import { useDispatch, useSelector } from "dive/store";
import {
  dataChannelsByIdSelector,
  dataGroupByIdSelector,
  dataSelectedGroupSidebarSelector,
  setSelectedChannel,
  selectSidebar,
} from "dive/store/data";
import analytics from "dive/util/analytics";
import { useInitialPayload } from "dive/hook/useInitialPayload.hook";
import { getGroupColor } from "dive/constants/groupColors";
import { useCreateChannel } from "dive/hook/useCreateChannel.hook";

import { NewSubchatModal } from "./NewSubchatModal";
import { useNewSubchatModalFormData } from "./useNewSubchatModalFormData";

type NewSubchatModalContainerProps = ModalProps & {};

export const NewSubchatModalContainer: FC<
  NewSubchatModalContainerProps
> = ({}) => {
  const dispatch = useDispatch();

  const { hideModal } = useModal();
  const {
    data,
    resetData,
    meta: { initialData },
  } = useNewSubchatModalFormData();

  const groupId = useSelector(dataSelectedGroupSidebarSelector);
  // @ts-expect-error
  const groupData = useSelector((st) => dataGroupByIdSelector(st, groupId));

  const groupColor = getGroupColor(groupData);

  const { hasChanged } = useInitialPayload(data);

  const [isLoading, setIsLoading] = useState(false);
  const [navigationPayload, setNavigationPayload] = useState<{
    channelId: string;
  } | null>(null);

  const { createChannel } = useCreateChannel();

  const newChannelData = useSelector((st) =>
    // @ts-expect-error
    dataChannelsByIdSelector(st, navigationPayload?.channelId)
  );

  // Wait for newly created group data to appear in Redux store
  useEffect(
    () => {
      if (
        !newChannelData ||
        !navigationPayload ||
        !navigationPayload.channelId
      ) {
        return;
      }

      dispatch(setSelectedChannel(navigationPayload.channelId));
      dispatch(selectSidebar(navigationPayload.channelId));

      hideModal();

      resetData();

      // No need to reseat loading state, as at this point the modal
      //   should be closed.
      // setIsLoading(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [newChannelData]
  );

  const onSubmit = useCallback(
    () => {
      setIsLoading(true);

      createChannel({
        groupId: groupId,
        name: data.name,
        emoji: data.emoji,
        isAutoAdd: data.isAutoAdd,
        type: data.type,
      })
        .then(({ uid, emoji, createdBy, isPublic, isDefault, isAdminPost }) => {
          setNavigationPayload({ channelId: uid });

          analytics.track("channel_created", {
            emoji,
            isPublic,
            isDefault,
            isAdminPost,
            createdBy,
          });
        })
        .catch((ex) => {
          logDebug(
            `[NewSubchatModalContainer][onSubmit] Cannot create new channel`,
            ex
          );
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, groupId]
  );

  const onClose = useCallback(() => {
    hideModal();

    // TODO: Track analytic events

    resetData();
  }, [hideModal, resetData]);

  return (
    <NewSubchatModal
      hasChanged={hasChanged}
      isLoading={isLoading}
      isFormValid={data?.name?.length > 2 && !!data?.emoji.length}
      color={groupColor}
      onClose={onClose}
      onSubmit={onSubmit}
    />
  );
};
