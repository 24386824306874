import { RefObject, createContext } from "react";

export type ShowProfileCardPayload = {
  targetElementRef: RefObject<HTMLElement>;
};

export type ProfileCardProviderContextType = {
  showProfileCard: (userId: string, payload: ShowProfileCardPayload) => void;
  hideProfileCard: () => void;
};

export const ProfileCardProviderContext =
  createContext<ProfileCardProviderContextType>({
    showProfileCard: () => {},
    hideProfileCard: () => {},
  });
