import React, { FC, useCallback, useEffect, useMemo } from "react";
import Picker, {
  EmojiClickData,
  Props as PickerProps,
} from "emoji-picker-react";

import { logDebug } from "../../util/log.util";

import { diveCustomEmoji, mmhCustomEmoji } from "./EmojiPicker.constant";

type EmojiPickerProps = PickerProps & {
  isCustomEmojiVisible: boolean;
};

export const EmojiPicker: FC<EmojiPickerProps> = ({
  isCustomEmojiVisible,
  ...pickerProps
}) => {
  useEffect(() => {
    if (isCustomEmojiVisible) {
      return;
    }

    const emojiPickerContainer = document.querySelector("aside.epr-main");

    const customCategoryContainer = emojiPickerContainer?.querySelector(
      "li[data-name='custom']"
    );

    const diveButtonContainer = emojiPickerContainer?.querySelector(
      `button[data-unified='${diveCustomEmoji.id.toLocaleLowerCase()}']`
    );

    const mmhButtonContainer = emojiPickerContainer?.querySelector(
      `button[data-unified='${mmhCustomEmoji.id.toLocaleLowerCase()}']`
    );

    //
    // For some reason the `emoji-picker-react` package caches
    //   the `customEmojis` property, so even if it's not passed as a prop,
    //   the custom emoji category still appears.
    //
    // Since in some cases we don't need to show custom emojis,
    //   e.g. the user enters a text message into input toolbar,
    //   we need to manually hide custom emoji nodes from HTML tree.
    //
    customCategoryContainer?.setAttribute("style", "display: none");
    diveButtonContainer?.setAttribute("style", "display: none");
    mmhButtonContainer?.setAttribute("style", "display: none");
  }, []);

  const customEmojiList = useMemo(() => {
    if (!isCustomEmojiVisible) {
      return [];
    }

    const data: PickerProps["customEmojis"] = [];

    data.push(diveCustomEmoji);
    data.push(mmhCustomEmoji);

    return data;
  }, [isCustomEmojiVisible]);

  const onEmojiClick = useCallback(
    (emojiData: EmojiClickData, event: MouseEvent) => {
      if (!emojiData.isCustom) {
        pickerProps.onEmojiClick?.(emojiData, event);

        return;
      }

      let customEmojiId: string;

      if (emojiData.names[0] === diveCustomEmoji.names[0]) {
        customEmojiId = diveCustomEmoji.id;
      } else if (emojiData.names[0] === mmhCustomEmoji.names[0]) {
        customEmojiId = mmhCustomEmoji.id;
      } else {
        logDebug(
          `[EmojiPicker][onEmojiClick] Cannot identify custom emoji ID`,
          {
            emojiData,
            event,
          }
        );
      }

      // @ts-expect-error
      pickerProps.onEmojiClick?.({ ...emojiData, id: customEmojiId }, event);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pickerProps.onEmojiClick]
  );

  return (
    <Picker
      {...pickerProps}
      {...(isCustomEmojiVisible ? { customEmojis: customEmojiList } : {})}
      onEmojiClick={onEmojiClick}
    />
  );
};
