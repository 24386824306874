import React, { FC, useCallback, useState } from "react";

import { ModalProps } from "dive/provider/ModalProvider";
import { useModal } from "dive/hook/useModal.hook";
import { createWebEvent } from "dive/firebase/event";
import { logDebug } from "dive/util/log.util";
import { useDispatch } from "dive/store";
import { selectEvent, setIsEventsSidebarOpen } from "dive/store/data";
import analytics from "dive/util/analytics";
import { useInitialPayload } from "dive/hook/useInitialPayload.hook";

import { NewEventModal } from "./NewEventModal";
import { useNewEventModalFormData } from "./useNewEventModalFormData";

type NewEventModalContainerProps = ModalProps & {};

export const NewEventModalContainer: FC<NewEventModalContainerProps> = ({}) => {
  const dispatch = useDispatch();

  const { hideModal } = useModal();
  const {
    data,
    resetData,
    meta: { initialData },
  } = useNewEventModalFormData();

  const { hasChanged } = useInitialPayload(data);

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = useCallback(
    () => {
      setIsLoading(true);

      createWebEvent(data)
        .then((response) => {
          if (!response?.data || !response.data.eventUid) {
            throw new Error("Cannot get event ID");
          }

          const eventId = response.data.eventUid;

          dispatch(setIsEventsSidebarOpen(true));
          dispatch(selectEvent(eventId));

          hideModal();

          resetData();
        })
        .catch((ex) => {
          logDebug(`[NewEventModalContainer] Cannot create new event`, ex);
        })
        .then(() => {
          setIsLoading(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );

  const onClose = useCallback(() => {
    hideModal();

    analytics.track("web_abort_create_event");

    resetData();
  }, [hideModal, resetData]);

  return (
    <NewEventModal
      hasChanged={hasChanged}
      isLoading={isLoading}
      isFormValid={data?.title?.length > 2}
      colorId={data?.colorId || initialData.colorId}
      onClose={onClose}
      onSubmit={onSubmit}
    />
  );
};
