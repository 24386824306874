import React, { FC } from "react";
import { CircleSpinner } from "react-spinners-kit";

import { ReactComponent as PlusSvg } from "../../assets/plus.svg";
import { ReactComponent as CheckSvg } from "../../assets/check.svg";
import Squircle from "../Squircle";

import {
  ChatMessageNewChatBlock,
  ChatMessageNewChatBlockButton,
  ChatMessageNewChatBlockButtonPrefix,
  ChatMessageNewChatBlockEmoji,
  ChatMessageNewChatBlockInfo,
  ChatMessageNewChatBlockSection,
  ChatMessageNewChatBlockTitle,
} from "./ChatMessageNewChat.style";

type ChatMessageNewChatProps = {
  name: string;
  emoji: string;
  chatColor: string;
  memberCount: number;
  hasJoined: boolean;
  isLoading: boolean;
  onJoin: () => void;
};

export const ChatMessageNewChat: FC<ChatMessageNewChatProps> = ({
  name,
  emoji,
  chatColor,
  memberCount,
  hasJoined,
  isLoading,
  onJoin,
}) => {
  const buttonPrefix = hasJoined ? (
    <CheckSvg fill="#FFFFFF" />
  ) : (
    <PlusSvg fill={chatColor} />
  );

  return (
    <ChatMessageNewChatBlock>
      <ChatMessageNewChatBlockEmoji>
        <Squircle
          width={34}
          channelEmoji={emoji}
          channelEmojiBg="transparent"
          channelEmojiSize={28}
        />
      </ChatMessageNewChatBlockEmoji>
      <ChatMessageNewChatBlockSection>
        <ChatMessageNewChatBlockTitle>{name}</ChatMessageNewChatBlockTitle>
        <ChatMessageNewChatBlockInfo>
          {`${memberCount} member${memberCount > 1 ? "s" : ""}`}
        </ChatMessageNewChatBlockInfo>
      </ChatMessageNewChatBlockSection>
      <ChatMessageNewChatBlockButton
        isLoading={isLoading}
        chatColor={chatColor}
        hasJoined={hasJoined}
        disabled={isLoading}
        onClick={onJoin}
      >
        {isLoading ? (
          <CircleSpinner size={10} color="#000000" />
        ) : (
          <>
            <ChatMessageNewChatBlockButtonPrefix>
              {buttonPrefix}
            </ChatMessageNewChatBlockButtonPrefix>
            {hasJoined ? "Joined" : "Join"}
          </>
        )}
      </ChatMessageNewChatBlockButton>
    </ChatMessageNewChatBlock>
  );
};
