import React, { useEffect, useState, useRef } from "react";
import { signInWithPhoneNumber, RecaptchaVerifier } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import styled from "styled-components";
import GlobalFonts from "../assets/fonts/fonts";

import { auth as clientAuth } from "../firebase/client";

import { ReactComponent as InstagramSvg } from "../assets/instagram.svg";
import { ReactComponent as TiktokSvg } from "../assets/tiktok.svg";
import { ReactComponent as TwitterSvg } from "../assets/twitter.svg";

import { ReactComponent as DownloadQrSvg } from "../assets/downloadQr.svg";
import { ReactComponent as QrIconSvg } from "../assets/qr.svg";
import { ReactComponent as DiveLogoSvg } from "../assets/gradientShaka.svg";
import { ReactComponent as CircleArrowSvg } from "../assets/circleArrow.svg";
import { ReactComponent as CautionIconSvg } from "../assets/cautionIcon.svg";

import backgroundNoise from "../assets/noise.svg";

import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { motion, AnimatePresence } from "framer-motion";
import {
  formatPhoneNumber,
  isPossiblePhoneNumber,
} from "react-phone-number-input";
import { getUserId } from "../firebase/phoneMap";
import { logDebug } from "../util/log.util";

const Login = () => {
  // Inputs
  const [mynumber, setnumber] = useState("");
  const [otp, setotp] = useState("");
  const [show, setshow] = useState(false);
  const [final, setfinal] = useState("");
  const [showDownloadQr, setShowDownloadQr] = useState(false);
  const [user, loading, error] = useAuthState(clientAuth);
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [errorText, setErrorText] = useState("");

  const [isQrLabelVisible, setIsQrLabelVisible] = useState(false);
  const [isPhoneNumberInputEditable, setIsPhoneNumberInputEditable] =
    useState(true);

  const inputEls = useRef([]);
  const phoneNumberInputRef = useRef(null);

  const captchaWrapperRef = useRef(null);

  useEffect(() => {
    console.log(user);
  }, [user]);

  useEffect(() => {
    if (mynumber && mynumber.length > 0 && isPossiblePhoneNumber(mynumber)) {
      setIsPhoneNumberInputEditable(false);

      getUserId(mynumber)
        .then((userId) => {
          if (userId && typeof userId === "string") {
            setTimeout(() => {
              inputEls.current[0].focus();
            }, 1500);

            setShowOtpInput(true);

            signin();
          } else {
            setIsQrLabelVisible(true);
            setShowDownloadQr(true);

            phoneNumberInputRef.current?.blur();
          }
        })
        .catch((ex) => {
          logDebug(`[Login] Cannot check phone number`, ex);
        })
        .then(() => {
          setIsPhoneNumberInputEditable(true);
        });
    }

    if (
      showDownloadQr
      // && isQrLabelVisible
    ) {
      setShowDownloadQr(false);
    }
  }, [mynumber]);

  // Sent OTP
  const signin = () => {
    // if (mynumber === "" || mynumber.length < 10) return;
    const verify = new RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: (response) => {},
      },
      clientAuth
    );

    // let verify = new RecaptchaVerifier("recaptcha-container");
    signInWithPhoneNumber(clientAuth, mynumber, verify)
      .then((result) => {
        setfinal(result);
        setshow(true);
      })
      .catch((err) => {
        console.log(err);
        verify.clear();
        captchaWrapperRef.current.innerHTML = `<div id="recaptcha-container"></div>`;
        console.log("sign in error");
        setErrorText("Login Failed");
      });
  };

  useEffect(() => {
    if (otp.length === 6) {
      console.log("validating account");
      ValidateOtp();
    }
  }, [otp]);

  // Validate OTP
  const ValidateOtp = () => {
    if (otp === null || final === null) return;
    final
      .confirm(otp)
      .then((result) => {
        // success
      })
      .catch((err) => {
        console.log(err);
        setErrorText("Wrong code");
        captchaWrapperRef.current.innerHTML = `<div id="recaptcha-container"></div>`;
      });
  };

  const getOtpSegment = (idx) => {
    if (otp.length <= idx) return "";
    return parseInt(otp?.[idx]);
  };
  const sendNewCode = () => {
    setErrorText("");
    signin();
  };
  const setOtpSegmentFactory = (idx) => (e) => {
    setErrorText("");
    let val = e.target.value;
    if (val.length > 1) return;
    if ("" + parseInt(val) !== val && val.length > 0) return;
    if (val.length > 0) {
      setotp(otp.substring(0, idx) + val + otp.substring(idx + 1));
      inputEls.current?.[idx + 1]?.focus();
    } else {
      setotp(otp.substring(0, idx) + otp.substring(idx + 1));
      inputEls.current?.[idx - 1]?.focus();
    }
  };

  const onInputclickFactory = (idx) => () => {
    if (idx > otp.length) inputEls.current?.[otp.length]?.focus();
  };

  const inputPaste = (e) => {
    let text = ("" + parseInt(e.clipboardData.getData("text"))).substring(0, 6);
    if (text !== "NaN") {
      inputEls.current?.[text.length - 1]?.focus();
      setotp(text);
    }
    // e.prevendDefault();
  };

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const maskArr = [0, 0, 0, 0, 0, 0];

  return (
    <LoginContainer>
      <GlobalFonts />
      <BottomNav>
        <WordContainer onClick={() => openInNewTab("https://dive.chat")}>
          home
        </WordContainer>
        <SeparatorDot />
        <WordContainer
          onClick={() => openInNewTab("https://www.dive.chat/policy")}
        >
          privacy
        </WordContainer>
        <SeparatorDot />
        <WordContainer
          onClick={() => openInNewTab("https://www.dive.chat/terms")}
        >
          terms
        </WordContainer>
        <SeparatorDot />
        <WordContainer
          onClick={() => (window.location.href = "mailto:hello@dive.chat")}
        >
          contact
        </WordContainer>
        <Instagram
          onClick={() => openInNewTab("https://www.instagram.com/dive.chat/")}
        />
        <TikTok
          onClick={() => openInNewTab("https://www.tiktok.com/@divechat")}
        />
        <Twitter
          onClick={() => openInNewTab("https://mobile.twitter.com/dive_chat")}
        />
      </BottomNav>
      <HorizontalFlex>
        <SquareInputcontainer
          // initial={{ x: -300 }}
          animate={{ x: showDownloadQr ? -154 : 0 }}
          // exit={{ x: -300 }}
          transition={{ type: "spring", duration: 0.8 }}
        >
          <AnimatePresence>
            {!showOtpInput ? (
              <VerticalFlexMotion
                initial={{ x: 0 }}
                animate={{ x: 0 }}
                exit={{ x: -400 }}
                transition={{ type: "spring", duration: 0.8, bounce: 0.25 }}
                key={"phoneInput"}
              >
                <DiveLogo />
                <LoginHeaderText>Login to Dive Chat</LoginHeaderText>
                <StyledPhoneInput
                  {...(isPhoneNumberInputEditable
                    ? {}
                    : { readonly: "readonly" })}
                  ref={phoneNumberInputRef}
                  placeholder="Phone Number"
                  type="tel"
                  value={mynumber}
                  onChange={setnumber}
                  defaultCountry="US"
                  autoComplete="off"
                  autoFocus
                />
                <NoAccountText>Don't have an account?</NoAccountText>
                <DownloadTheAppText
                  onClick={() => {
                    setShowDownloadQr((s) => !s);

                    if (showDownloadQr) {
                      setIsQrLabelVisible(false);
                    }
                  }}
                >
                  Download the App
                </DownloadTheAppText>
              </VerticalFlexMotion>
            ) : (
              <VerticalFlexMotion
                initial={{ x: 400 }}
                animate={{ x: 0 }}
                exit={{ x: -300 }}
                transition={{ type: "spring", duration: 0.8, bounce: 0.1 }}
                key={"otpInput"}
              >
                <DiveLogo />
                <TextedYouAtText>
                  Just texted you at{" "}
                  {mynumber.length > 0 ? formatPhoneNumber(mynumber) : ""}
                </TextedYouAtText>
                <HorizontalFlex
                  animate={{
                    x: errorText.length > 0 ? [-10, 10, -5, 0] : 0,
                  }}
                  transition={{
                    type: "spring",
                    duration: 0.3,
                    bounce: 0.2,
                  }}
                >
                  {maskArr.map((item, idx) => (
                    <MaskWindow
                      type="text"
                      autoFocus={idx === 0}
                      value={getOtpSegment(idx)}
                      onChange={setOtpSegmentFactory(idx)}
                      ref={(el) => (inputEls.current[idx] = el)}
                      disabled={otp.length === 6 && errorText.length === 0}
                      onClick={onInputclickFactory(idx)}
                      onPaste={inputPaste}
                    />
                  ))}
                </HorizontalFlex>
                {errorText.length > 0 && (
                  <ErrorText>
                    <CautionIcon />
                    Hmm, that didn't work
                  </ErrorText>
                )}
                <DownloadTheAppText
                  onClick={() => {
                    sendNewCode();
                    setotp("");
                    setErrorText("");
                  }}
                >
                  Resend Code
                  <CircleArrow />
                </DownloadTheAppText>
              </VerticalFlexMotion>
            )}
          </AnimatePresence>
        </SquareInputcontainer>
        <AnimatePresence>
          {showDownloadQr && (
            <QrContainer
              initial={{ x: -200 }}
              animate={{ x: 0 }}
              exit={{ x: -200 }}
              transition={{ type: "spring", duration: 0.8 }}
            >
              {isQrLabelVisible ? (
                <DownloadQrLabel>
                  To start using Dive Chat, <br />
                  first create an account in the app.
                </DownloadQrLabel>
              ) : null}
              <DownloadQr />
              <ScanToDownloadText
                $fontSize={isQrLabelVisible ? "14px" : "17px"}
              >
                <QrIcon />
                &nbsp;Scan to Download 👆
              </ScanToDownloadText>
            </QrContainer>
          )}
        </AnimatePresence>
      </HorizontalFlex>
      <div ref={captchaWrapperRef}>
        <div id="recaptcha-container"></div>
      </div>
    </LoginContainer>
  );
};

{
  /* <div style={{ marginTop: "200px" }}>
<center>
  <div style={{ display: !show ? "block" : "none" }}>
    <input
      value={mynumber}
      onChange={(e) => {
        setnumber(e.target.value);
      }}
      placeholder="phone number"
    />
    <br />
    <br />
    <div id="recaptcha-container"></div>
    <button onClick={signin}>Send OTP</button>
  </div>
  <div style={{ display: show ? "block" : "none" }}>
    <input
      type="text"
      placeholder={"Enter your OTP"}
      onChange={(e) => {
        setotp(e.target.value);
      }}
    ></input>
    <br />
    <br />
    <button onClick={ValidateOtp}>Verify</button>
  </div>
</center>
</div> */
}

export default Login;

const FullOpacity = styled.div`
  width: 100%;
  height: 100%;
  opacity: 1;
`;

const MaskWindow = styled.input`
  width: 46px;
  height: 60px;

  background: #ffffff;
  border-style: solid;
  border-width: 0.5px;
  border-color: rgba(0, 0, 0, 0.05);
  /* Shadows/100 */

  box-shadow: 0px 2px 4px -2px rgba(24, 39, 75, 0.1);
  border-radius: 16px;
  margin-right: 4px;
  margin-left: 4px;

  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 28px;
  font-variant-numeric: tabular-nums;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  text-align: center;
  letter-spacing: 0.01em;
  position: relative;
  overflow: hidden;

  /* UI Tones/Dark/Gray 6 */

  color: #1c262a;

  &:focus {
    outline: none;
  }
  text-align: center;
  margin-bottom: 81px;
`;

const StyledPhoneInput = styled(PhoneInput)`
  width: 100%;
  & > .PhoneInputInput {
    border: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    // width: 264px;
    height: 49px;
    // padding-left: 67px;

    background: #ffffff;
    border: 0.5px solid rgba(0, 0, 0, 0.1);
    /* Shadows/100 */

    box-shadow: 0px 2px 4px -2px rgba(24, 39, 75, 0.1);
    border-radius: 16px;

    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
    /* identical to box height, or 125% */

    text-align: center;
    letter-spacing: 0.01em;

    /* UI Tones/Light/Gray 3 */

    caret-color: #ff2d55;

    // color: #c7c7cc;
    color: black;
    &:focus {
      outline: none;
    }
  }

  & > .PhoneInputCountry {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    width: 62px;
    height: 50px;

    background: #ffffff;
    border: 0.5px solid rgba(0, 0, 0, 0.1);
    /* Shadows/100 */

    border-radius: 16px;
    margin-right: 8px;

    &:focus {
      outline: none;
      border: none;
    }
    outline: none;

    & > .PhoneInputCountrySelectArrow {
      display: none;
    }
  }
  margin-bottom: 71px;
`;

const DiveLogo = styled(DiveLogoSvg)`
  width: 75px;
  height: 75px;
  margin-bottom: 24px;
`;

const TextedYouAtText = styled.div`
  font-weight: 500;
  font-size: 17px;
  line-height: 22px;
  /* identical to box height, or 129% */

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.02em;

  /* UI Tones/Dark/Gray 6 */
  margin-top: 24px;
  margin-bottom: 16px;

  color: #1c262a;
`;

const LoginHeaderText = styled.div`
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
  /* identical to box height, or 121% */

  display: flex;
  align-items: center;
  text-align: center;

  /* UI Tones/Dark/Gray 5 */

  color: #2b3437;
  margin-bottom: 24px;
`;

const NoAccountText = styled.div`
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;
  /* identical to box height, or 129% */

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.02em;

  /* UI Tones/Light/Gray 1 */

  color: #8e8e93;
  margin-bottom: 2px;
`;

const DownloadTheAppText = styled(NoAccountText)`
  color: #ff2d55;
  cursor: pointer;
`;

const DownloadQr = styled(DownloadQrSvg)`
  width: 200px;
  height: 200px;
  border-radius: 8px;
`;

const DownloadQrLabel = styled.div`
  color: var(--UI-Tones-Dark-Gray-5, #2b3437);
  text-align: center;

  font-family: SF Pro Rounded;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.34px;
`;

const QrIcon = styled(QrIconSvg)`
  width: 14px;
  height: 22px;
`;

const CircleArrow = styled(CircleArrowSvg)`
  width: 20px;
  height: 22px;
  fill: #ff2d55;
  margin-left: 4px;
`;

const CautionIcon = styled(CautionIconSvg)`
  width: 20px;
  height: 22px;
  fill: #ff2d55;
  margin-right: 2px;
`;

const ScanToDownloadText = styled.div`
  font-weight: 400;
  font-size: ${({ $fontSize }) => $fontSize || "14px"};
  line-height: ${({ $lineHeight }) => $lineHeight || "18px"};
  /* identical to box height, or 129% */

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.02em;

  /* UI Tones/Dark/Gray 5 */

  color: #2b3437;
`;

const ErrorText = styled.div`
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;
  /* identical to box height, or 129% */

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.02em;

  /* UI Tones/Dark/Gray 5 */
  color: #ff2d55;
  position: absolute;
  bottom: 101px;
`;

const HorizontalFlex = styled(motion.div)`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const VerticalFlex = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const VerticalFlexMotion = styled(motion.div)`
  display: flex;
  flex-direction: column;
  width: 406px;
  align-items: center;
  justify-content: center;
  padding: 60px 36px 36px;
  position: absolute;
  top: 0;
  left: 0;
`;

const QrContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 36px 18px;
  gap: 24px;

  position: absolute;
  width: 292px;
  height: 338px;
  left: calc(50% - 292px / 2 + 221px);
  top: calc(50% - 338px / 2);
  z-index: 1;

  /* UI Tones/Light/Gray 7 (Canvas) */

  background: #fafafa;

  border: 1px solid rgba(0, 0, 0, 0.1);
  /* Shadows/800 */

  box-shadow: 0px 8px 28px -6px rgba(24, 39, 75, 0.1);
  border-radius: 48px;
  margin-left: 18px;
`;

const SquareInputcontainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  gap: 24px;
  overflow: hidden;

  width: 406px;
  height: 420px;
  position: absolute;
  left: calc(50% - 406px / 2);
  top: calc(50% - 420px / 2);

  /* UI Tones/Light/Gray 7 (Canvas) */

  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.1);
  /* Shadows/800 */

  box-shadow: 0px 8px 28px -6px rgba(24, 39, 75, 0.1);
  border-radius: 48px;

  margin-right: 18px;
  z-index: 2;
`;

const NoiseLayer = styled.div`
  // position: fixed;
  // width: 100%;
  // top: 0;
  // left: 0;
  // height: 100%;
  // background-image: url(${backgroundNoise});
  // background-size: tile;
  // opacity: 0.1;
  // z-index: -1;
`;

const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // z-index: -1;
  width: 100%;
  height: 100%;
  background: radial-gradient(
        35.97% 25.18% at 114.25% 16.57%,
        #ff7a00 0%,
        #ff7a00 16.15%,
        rgba(255, 122, 0, 0) 100%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    radial-gradient(
        51.9% 36.33% at 100% 38.2%,
        #ff7a00 0%,
        #ff7a00 16.15%,
        rgba(255, 122, 0, 0) 100%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    radial-gradient(
        29.9% 40.7% at 7.9% 37.8%,
        #ff3156 0%,
        rgba(255, 49, 86, 0) 100%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    radial-gradient(
        75.84% 65.74% at 11.42% 89.35%,
        #ff3156 0%,
        rgba(255, 49, 86, 0) 100%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    #ff007a;
  &:before {
    content: "";
    background-image: url(${backgroundNoise});
    background-size: tile;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.075;
  }
`;

const BottomNav = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 87px;
  position: absolute;
  bottom: 29px;
`;

const SeparatorDot = styled.div`
  width: 8px;
  height: 8px;

  background: #ffffff;
  opacity: 0.25;
  border-radius: 50%;
  margin: 24px;
`;

const WordContainer = styled.div`
  font-family: "Clash Display" !important;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  /* identical to box height */

  letter-spacing: 0.04em;

  color: #ffffff;
  cursor: pointer;
`;

const Instagram = styled(InstagramSvg)`
  width: 30px;
  height: 30px;
  fill: #ffffff;
  margin-left: 60px;
  margin-top: 4px;
  cursor: pointer;
`;

const TikTok = styled(TiktokSvg)`
  width: 30px;
  height: 30px;
  fill: #ffffff;
  margin-left: 24px;
  margin-top: 4px;
  margin-right: 24px;
  cursor: pointer;
`;

const Twitter = styled(TwitterSvg)`
  width: 30px;
  height: 30px;
  fill: #ffffff;
  margin-top: 4px;
  cursor: pointer;
`;
