import { createAsyncThunk } from "@reduxjs/toolkit";
import { getDatabase, onValue, ref } from "firebase/database";

import { setGroup } from "../data.slice";

export const addGroupListener = createAsyncThunk(
  "data/ADD_GROUP_LISTENER",
  (groupId: string, { getState, dispatch }) => {
    const stateSnapshot = getState();

    if (!groupId) {
      return Promise.reject("The ID of the group is not defined");
    }

    // @ts-expect-error
    if (stateSnapshot?.data?.listenerMap?.[groupId]) {
      return Promise.reject("The listener already exists");
    }

    const db = getDatabase();

    const removeGroupListener = onValue(
      ref(db, `groups/${groupId}`),
      (snapshot) => {
        dispatch(setGroup(snapshot.val()));
      }
    );

    // TODO: Save the "removeGroupListener" to cache/Redux so
    //   we can access this function later to remove group listener.

    return Promise.resolve();
  }
);
