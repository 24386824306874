import chat from "../chat";

type ShareEventWithGroupsPayload = {
  channelIds: string[];
  eventId: string;
};

export async function shareEventWithGroups({
  channelIds,
  eventId,
}: ShareEventWithGroupsPayload) {
  const sendMessageTasks = channelIds.map((channelId) => {
    return chat.sendEventMessage({ channelId, eventId });
  });

  await Promise.all(sendMessageTasks);
}
