import styled from "styled-components";
import { motion } from "framer-motion";

type SidebarBadgeBlockProps = {
  $hasHover: boolean;
};

// @ts-expect-error
export const SidebarBadgeBlock = styled<SidebarBadgeBlockProps>(motion.div)`
  position: absolute;
  width: 379px;
  height: 68px;
  padding: 0 22px;
  bottom: 0;
  left: 0;
  background: rgba(250, 250, 250, 0.8);
  backdrop-filter: blur(25px);
  border-top: 0.5px solid rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 999;

  &:hover {
    cursor: ${({ $hasHover }) => ($hasHover ? "pointer" : "default")};
  }

  svg {
    width: 48px;
    height: 48px;
  }
`;

export const SidebarBadgeInfo = styled.div`
  flex: 1;
  margin: 0 0 0 12px;
`;

export const SidebarBadgeTitle = styled.h3`
  color: var(--ui-tones-dark-black, color(display-p3 0 0 0));
  font-family: SF Pro Rounded;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 18.355px;
  letter-spacing: 0.147px;
  margin: 0 0 2px 0;
`;

type SidebarBadgeDescriptionProps = {
  $isWarning: boolean;
};

export const SidebarBadgeDescription = styled.div<SidebarBadgeDescriptionProps>`
  color: ${({ $isWarning }) =>
    $isWarning ? "var(--solid-red, #ff0c18)" : "rgba(0, 0, 0, 0.40)"};
  font-family: SF Pro Rounded;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 16.152px;
  letter-spacing: 0.25px;
`;
