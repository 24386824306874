import { useEffect } from "react";
import { differenceInDays } from "date-fns";

import { useIsBrowser } from "../../hook/useIsBrowser";
import { useDispatch, useSelector } from "../../store";
import {
  applicationNotificationPermissionStateSelector,
  applicationNotificationPermissionRequestedAtSelector,
  applicationNotificationIsPermissionModalVisibleSelector,
  setIsPermissionModalVisible,
} from "../../store/application";
import {
  INITIAL_NOTIFICATION_STATE,
  REJECTED_NOTIFICATION_STATE,
} from "../../constants/notification.constant";

export function useNotificationPrimingModalState() {
  const dispatch = useDispatch();

  const isBrowser = useIsBrowser();

  const isPrimingModalVisible = useSelector(
    applicationNotificationIsPermissionModalVisibleSelector
  );

  const notificationPermissionState = useSelector(
    applicationNotificationPermissionStateSelector
  );

  const notificationPermissionRequestedAt = useSelector(
    applicationNotificationPermissionRequestedAtSelector
  );

  useEffect(
    () => {
      if (isBrowser) {
        return;
      }

      if (
        notificationPermissionState === INITIAL_NOTIFICATION_STATE ||
        (notificationPermissionState === REJECTED_NOTIFICATION_STATE &&
          differenceInDays(notificationPermissionRequestedAt, Date.now()) > 6)
      ) {
        const timerId = setTimeout(() => {
          dispatch(setIsPermissionModalVisible(true));
        }, 5000);

        return () => {
          clearTimeout(timerId);
        };
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isBrowser]
  );

  return {
    isPrimingModalVisible,
  };
}
