import {
  FloatingFocusManager,
} from "@floating-ui/react";

export default function PopoverWrapper({
  context,
  modal,
  floating,
  strategy,
  y,
  x,
  floatingProps,
  children
}) {
  return (
    <FloatingFocusManager context={context} modal={modal}>
      <div
        ref={floating}
        style={{
          position: strategy,
          top: y ?? 0,
          left: x ?? 0,
          zIndex: 999,
        }}
        {...floatingProps}
      >
        {children}
      </div>
    </FloatingFocusManager>
  )
}