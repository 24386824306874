import { getDatabase, update, ref, get } from "firebase/database";
import { getAuth } from "firebase/auth";

import { ChannelRecord } from "dive/type/channel.type";
import { logDebug } from "dive/util/log.util";

type UpdateOrCreateDmPayload = {
  // The ID of the user you're sending a message (a DM) to
  userId: string;
  channelData: ChannelRecord;
};

// Creates new DM channel or updates the one that already exists
export async function updateOrCreateDm({
  userId,
  channelData,
}: UpdateOrCreateDmPayload) {
  const realtimeDb = getDatabase();
  const authSession = getAuth();

  const myId = authSession.currentUser?.uid;

  if (!userId) {
    const errorMessage = "Cannot retrieve current user ID";

    logDebug(`[updateOrCreateDm] ${errorMessage}`);

    return Promise.reject(new Error(errorMessage));
  }

  if (!channelData || !channelData.uid) {
    const errorMessage = "Channel data is undefined";

    logDebug(`[updateOrCreateDm] ${errorMessage}`);

    return Promise.reject(new Error(errorMessage));
  }

  const channelSnapshot = await get(
    ref(realtimeDb, `channels/${channelData.uid}`)
  );

  const updates: Record<string, unknown> = {};

  if (channelSnapshot.exists()) {
    updates["channels/" + channelData.uid + "/recent"] = 1;
    updates["channels/" + channelData.uid + "/dmDeleted"] = null;
    updates["channels/" + channelData.uid + "/timestamp"] = Date.now();
  } else {
    channelData.recent = 1;
    channelData.timestamp = Date.now();

    // NOTE: Copied from mobile app, not sure why name is {null} value
    // @ts-expect-error
    channelData.name = null;

    updates["channels/" + channelData.uid] = channelData;
  }

  updates["users/" + myId + "/dmChannels/" + userId] = channelData.uid;
  updates["users/" + userId + "/dmChannels/" + myId] = channelData.uid;
  updates[`users/${myId}/timestamp`] = Date.now();

  return update(ref(realtimeDb), updates);
}
