import React, { FC } from "react";

import { ReactComponent as DownloadSvg } from "../../assets/download.svg";

import {
  UpdateAlertBlock,
  UpdateAlertContent,
  UpdateAlertImg,
  UpdateAlertTitle,
  UpdateAlertDescription,
  UpdateAlertActionBox,
  UpdateAlertButton,
} from "./UpdateAlert.style";

type UpdateAlertProps = {
  isDismissible: boolean;
  isUpdating: boolean;
  onDismiss: () => void;
  onUpdate: () => void;
};

export const UpdateAlert: FC<UpdateAlertProps> = ({
  isDismissible,
  isUpdating,
  onDismiss,
  onUpdate,
}) => {
  return (
    <UpdateAlertBlock>
      <UpdateAlertContent>
        <UpdateAlertImg>
          <DownloadSvg />
        </UpdateAlertImg>
        <UpdateAlertTitle>Update Available!</UpdateAlertTitle>
        <UpdateAlertDescription>
          Update Dive Chat to get all the newest features and improvements!
        </UpdateAlertDescription>
        <UpdateAlertActionBox>
          {isDismissible ? (
            <UpdateAlertButton disabled={isUpdating} onClick={onDismiss}>
              Update Next Time
            </UpdateAlertButton>
          ) : null}
          <UpdateAlertButton $isSubmit disabled={isUpdating} onClick={onUpdate}>
            Update Now
          </UpdateAlertButton>
        </UpdateAlertActionBox>
      </UpdateAlertContent>
    </UpdateAlertBlock>
  );
};
