import React, {
  useEffect,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import Squircle from "./Squircle";
import { createSelector } from "@reduxjs/toolkit";
import { motion, AnimatePresence, useAnimation } from "framer-motion";
import { replaceSpecialEmoji } from "../util";
// import { addPollListener } from "../store/data";
// import { ReactComponent as AddEmojiInlineSvg } from "../assets/addReaction.svg";

const EmojiExploder = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    explode(emoji, x, y, num) {
      generateEmojiParticles(emoji, x, y, num);
    },
  }));

  const [reactionParticles, setReactionParticles] = useState([]);

  const generateEmojiParticles = async (emoji, x = 0, y = 0, num = 5) => {
    let time = Date.now();
    for (let i = 0; i < num + 1; i++) {
      const path = generateParabolicTrajectory(x, y);
      setReactionParticles((p) => [...p, { emoji, path, key: time + i }]);
      setTimeout(() => {
        setReactionParticles((p) => [...p.slice(1)]);
      }, 2000);
    }
  };

  const generateParabolicTrajectory = (initialX = 0, initialY = 0) => {
    let dy = -30 + Math.random() * 20;
    let dx = 0 + Math.random() * 30 - 15;
    let ry = 0 + Math.random() * 4 - 2;
    let gravity = 1.2;
    let x = [initialX];
    let y = [initialY];
    let rotate = [0];
    Array.from({ length: 100 }).map((_, i) => {
      x.push(x[i] + dx);
      y.push(y[i] + dy);
      rotate.push(ry * i);
      dy += gravity;
    });
    return { x: x, y: y, rotate: rotate };
  };

  return (
    <>
      {reactionParticles &&
        reactionParticles.map((obj, idx) => {
          return (
            <EmojiExplosionParticle
              animate={obj.path}
              initial={{ x: obj.path.x[0], y: obj.path.y[0] }}
              transition={{ type: "tween", duration: 2 }}
              key={obj.key}
            >
              {replaceSpecialEmoji(obj.emoji, 52)}
            </EmojiExplosionParticle>
          );
        })}
    </>
  );
});

export default EmojiExploder;

const EmojiExplosionParticle = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 64px;
  height: 64px;
  font-size: 48px;
  z-index: 18;

  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
  pointer-events: none;
`;
