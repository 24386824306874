import { useCallback } from "react";

import { generateGroupId } from "dive/firebase/group/generateGroupId";
import {
  createChannelTemplate,
  createGroupTemplate,
} from "dive/util/template.util";
import { generateChannelId } from "dive/firebase/channel/generateChannelId";
import { logDebug } from "dive/util/log.util";
import { addToGroup, createGroup } from "dive/firebase/group";
import { sendChannelInitialMessage } from "dive/firebase/channel";
import { CustomGroupFormData } from "dive/store/form";
import { getFileFromDataUrl } from "dive/util/file.util";
import storage from "dive/firebase/storage";
import { useSelector } from "dive/store";
import { dataMyselfIdSelector } from "dive/store/data";
import { FileMimeMap } from "dive/constants/mime.constant";

type DiveTemplateChannel = {
  name: string;
  emoji: string;
  isPublic: boolean;
  isDefault: boolean;
  isAdminPost: boolean;
};

type CreateDiveTemplatePayload = {
  name: string;
  color: string;
  emoji: string;
  image: CustomGroupFormData["image"];
  channels: DiveTemplateChannel[];
};

export function useCreateDive() {
  // @ts-expect-error
  const myId = useSelector(dataMyselfIdSelector);

  const createDive = useCallback(
    async (payload: CreateDiveTemplatePayload) => {
      const newGroupId = generateGroupId();

      if (!newGroupId) {
        logDebug(`[useCreateDive][createDive] Cannot generate group ID`);

        return Promise.reject();
      }

      const groupData = createGroupTemplate({
        myId,
        groupId: newGroupId,
        name: payload.name,
        color: payload.color,
        isSimple: false,
      });

      const channelList = payload.channels.map((channelTemplate) => {
        const newChannelId = generateChannelId();

        if (!newChannelId) {
          logDebug(`[useCreateDive][createDive] Cannot generate channel ID`, {
            newChannelId,
          });

          throw new Error("Cannot generate channel ID");
        }

        const channelData = createChannelTemplate({
          myId,
          channelId: newChannelId,
          name: channelTemplate.name,
          emoji: channelTemplate.emoji,
          isPublic: channelTemplate.isPublic,
        });

        return { ...channelData, ...channelTemplate };
      });

      if (payload.image) {
        // @ts-expect-error
        const fileExt: string = FileMimeMap[payload.image.type];

        const imageFile = await getFileFromDataUrl(payload.image.rawContent, {
          fileName: `${Date.now()}.${fileExt}`,
          mimeType: payload.image.type,
        });

        const [smallImageUrl, mediumImageUrl, largeImageUrl] =
          await Promise.all([
            storage.uploadFileToStorage(imageFile, 250),
            storage.uploadFileToStorage(imageFile, 650),
            storage.uploadFileToStorage(imageFile, 1200),
          ]);

        // @ts-expect-error
        groupData.avatarSM = smallImageUrl;
        // @ts-expect-error
        groupData.avatarMD = mediumImageUrl;
        groupData.avatar = largeImageUrl;
      }

      groupData.emoji = payload.emoji;
      // @ts-expect-error
      groupData.main = channelList[0].uid || "";
      // @ts-expect-error
      groupData.fromTemplate = false;
      // @ts-expect-error
      groupData.exampleDive = false;
      // @ts-expect-error
      groupData.events = {};

      try {
        const responseData = await createGroup({
          data: groupData,
          // @ts-expect-error
          channelList: channelList,
        });

        const taskList = channelList.map((channel) => {
          // @ts-expect-error
          const isMain = channel.uid === groupData.main;

          return sendChannelInitialMessage({
            channelId: channel.uid,
            // @ts-expect-error
            showCuratedEmojiSuggestion: groupData.exampleDive && isMain,
            isMain: isMain,
            isAdminPost: channel.isAdminPost,
            isPublic: channel.isPublic,
          });
        });

        taskList.push(addToGroup(newGroupId, { userIds: [myId] }));

        await Promise.all(taskList).then(() => groupData);

        return responseData;
      } catch (ex) {
        logDebug(
          `[useCreateDive][createDive] Cannot create new Dive group`,
          ex
        );

        return Promise.reject(ex);
      }
    },
    [myId]
  );

  return {
    createDive,
    meta: {
      myId,
    },
  };
}
