import { getDatabase, update, ref } from "firebase/database";
import { getAuth } from "firebase/auth";

import { logDebug } from "dive/util/log.util";

export type CreateChannelPayload = {
  data: {
    isPublic: boolean;
    isDefault: boolean;
    isAdminPost: boolean;
    isAdminChannel: boolean;
    isDM: boolean;
    uid: string;
    groupUid: string;
    createdBy: string;
    name: string;
    emoji: string;
    typing: Record<string, any>;
    recent: number;
  };
};

export function createChannel(payload: CreateChannelPayload) {
  const { data } = payload;

  const realtimeDb = getDatabase();
  const authSession = getAuth();

  const userId = authSession.currentUser?.uid;

  if (!userId) {
    logDebug("[createChannel] Cannot retrieve current user ID");

    return Promise.reject(new Error("Cannot retrieve current user ID"));
  }

  const updates: Record<string, number | string | boolean | object> = {};

  const dbRecord = {
    ...data,
    timestamp: Date.now(),
  };

  updates["channels/" + dbRecord.uid] = dbRecord;
  updates["groups/" + dbRecord.groupUid + "/channels/" + dbRecord.uid] = true;
  updates["groups/" + dbRecord.groupUid + "/timestamp"] = Date.now();

  const channelInfo = {
    name: dbRecord.name,
    groupUid: dbRecord.groupUid,
  };

  updates["channelMap/" + dbRecord.uid] = channelInfo;

  return update(ref(realtimeDb), updates);
}
