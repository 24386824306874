import styled, { css } from "styled-components";
import { motion } from "framer-motion";

export const ChatMessageIndicatorBlock = styled.div`
  margin: 4px 4px 8px 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const labelClass = css`
  font-family: SF Pro Rounded;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.24px;
`;

export const ChatMessageIndicatorLabel = styled.div`
  ${labelClass}
  color: #8e8e93;
`;

export const ChatMessageIndicatorButton = styled.button`
  ${labelClass}
  font-weight: 700;
  padding: 0;
  margin: 0 0 0 8px;
  color: #ff0c18;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  border-width: 0;
  background: transparent;
  transition: 0.1s;

  &:hover {
    cursor: pointer;
  }

  &:active {
    transform: scale(0.98);
    opacity: 0.9;
  }
`;
