import React, { useEffect } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";

import analytics from "../util/analytics";

export const EventDetails = ({}) => {
  const selectedEventId = useSelector((state) => state?.data?.selectedEvent);

  useEffect(() => {
    if (!selectedEventId) {
      return;
    }

    analytics.track("event_view", {
      eventUid: selectedEventId,
      through: "Web",
    });
  }, [selectedEventId]);

  const eventDetailsPageUrl =
    process.env.REACT_APP_VERCEL_ENV === "production"
      ? "https://www.dive.chat/e"
      : "https://dev.followor.xyz/e";

  return (
    <EventDetailsIFrameContainer
      src={`${eventDetailsPageUrl}/${selectedEventId}`}
      title="Event Details"
    />
  );
};

const EventDetailsIFrameContainer = styled.iframe`
  width: 100%;
  height: 100%;
`;
