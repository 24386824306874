/* eslint-disable default-case */
import {
  getStorage,
  ref as storageRef,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { getApp } from "firebase/app";
import { getDatabase, update, push, ref } from "firebase/database";
import imageCompression from "browser-image-compression";
import { logDebug, logWarning } from "../util/log.util";
const firebaseApp = getApp();
const storage = getStorage();
const db = getDatabase();
const publicBucket = getStorage(firebaseApp, "gs://my-custom-bucket");

const uploadFileToStorage = async (file, maxWidthOrHeight = 400) => {
  if (!file) {
    logWarning(`[uploadFileToStorage] File doesn't exist`);

    return;
  }

  // See all options:
  // https://github.com/Donaldcwl/browser-image-compression#main-function
  const options = {
    maxWidthOrHeight,
  };
  let compressedFile;
  try {
    compressedFile = await imageCompression(file, options);
  } catch (err) {
    logWarning(`[uploadFileToStorage] Compression error`, err);
  }

  logDebug(
    `[uploadFileToStorage] Compressed file size ${
      compressedFile.size / 1024 / 1024
    } MB`
  );

  const postListRef = ref(db, `messages`);
  const postKey = push(postListRef).key;

  const imageLocRef = storageRef(storage, `webapp/${postKey}`);
  const uploadTask = uploadBytesResumable(imageLocRef, compressedFile);

  // Listen for state changes, errors, and completion of the upload.
  return new Promise(async (res, rej) => {
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        logDebug("[uploadFileToStorage] Upload is " + progress + "% done");
        switch (snapshot.state) {
          case "paused":
            logDebug("[uploadFileToStorage] Upload is paused");
            break;
          case "running":
            logDebug("[uploadFileToStorage] Upload is running");
            break;
        }
      },
      (error) => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors

        logWarning(`[uploadFileToStorage] Error with upload`, error);

        rej(error);
      },
      () => {
        // Upload completed successfully, now we can get the download URL
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          res(downloadURL);
        });
      }
    );
  });
};

export default {
  uploadFileToStorage,
};
