import { getDatabase, update, ref } from "firebase/database";
import { getAuth } from "firebase/auth";

import { logDebug } from "../../util/log.util";
import { NotificationMode } from "../../type/notification.type";

export function setDesktopNotificationMode(notificationMode: NotificationMode) {
  const realtimeDb = getDatabase();
  const authSession = getAuth();

  const userId = authSession.currentUser?.uid;

  if (!userId) {
    logDebug("[setDesktopNotificationMode] Cannot retrieve current user ID");

    return Promise.reject("Cannot retrieve current user ID");
  }

  const updates = {
    [`users/${userId}/desktopAppNotifications`]: notificationMode,
  };

  return update(ref(realtimeDb), updates);
}
