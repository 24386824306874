import { CSSProperties } from "react";
import styled from "styled-components";

import { flexCenter } from "dive/constants/DesignSystem";

export const NewSubchatModalEmojiBlock = styled.div`
  ${flexCenter}
  margin: 0 0 0 0;
  position: relative;
`;

export const NewSubchatModalEmojiButton = styled.button`
  ${flexCenter}
  position: relative;
  width: 92px;
  height: 92px;
  border-radius: 50%;
  border: 0.5px solid rgba(0, 0, 0, 0.1);
  background: #353536;
  box-shadow: 0px 2px 4px -2px rgba(24, 39, 75, 0.1);
  transition: 0.1s;

  &:hover {
    cursor: pointer;
  }

  &:active {
    transform: scale(0.98);
  }
`;

type NewSubchatModalEmojiBadgeProps = {
  $bg: CSSProperties["background"];
};

export const NewSubchatModalEmojiBadge = styled.div<NewSubchatModalEmojiBadgeProps>`
  ${flexCenter}
  position: absolute;
  width: 30px;
  height: 30px;
  padding: 5px 0 0 0;
  border-radius: 50%;
  bottom: 0;
  right: 0;
  background: ${({ $bg }) => $bg};

  svg {
    path {
      fill: #ffffff;
    }
  }
`;

export const NewSubchatModalEmojiText = styled.div`
  font-size: 44px;
`;

export const NewSubchatModalEmojiPicker = styled.div`
  position: absolute;
  display: inline-block;
  top: 104px;
  z-index: 10;
`;
