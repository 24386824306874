import React, { useCallback, useEffect, useRef, useState } from "react";

import { getClientRect } from "../../util/rect.util";

import Squircle from "../Squircle";

import {
  SuggestionListContainer,
  SuggestionListLabel,
  SuggestionListDataContainer,
  SuggestionListItemContainer,
  SUGGESTION_LIST_MAX_WIDTH,
} from "./SuggestionList.style";

export const SuggestionList = ({
  x,
  y,
  containerWidth,
  containerHeight,
  label,
  data,
  selectedId,
  onSelectedIdChange,
  onSubmit,
  color,
}) => {
  const contentContainerRef = useRef(null);
  const titleContainerRef = useRef(null);
  const listContainerRef = useRef(null);

  const [clientRect, setClientRect] = useState({
    initialX: 0,
    initialY: 0,
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });

  // Creates an event handler that invokes passed callback if target id is found
  const withItemId = (onEvent) => {
    return (event) => {
      if (!event.target) {
        return;
      }

      const itemId = event.target.getAttribute("data-id");

      if (!itemId) {
        return;
      }

      return onEvent(itemId);
    };
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onClick = useCallback(
    withItemId((itemId) => {
      onSubmit(itemId);
    }),
    [onSubmit]
  );

  useEffect(() => {
    if (contentContainerRef.current) {
      const containerRect = getClientRect(contentContainerRef.current);

      setClientRect({
        initialX: clientRect.initialX || containerRect.x,
        initialY: clientRect.initialY || containerRect.y,
        y: containerRect.y,
        x: containerRect.x,
        width: containerRect.width,
        height: containerRect.height,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.length]);

  useEffect(() => {
    const timerId = setTimeout(() => {
      const selectedIndex = data.findIndex(({ uid }) => uid === selectedId);

      if (selectedIndex === 0) {
        titleContainerRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });

        return;
      }

      const selectedNode = listContainerRef.current?.querySelector(
        `[data-id="${selectedId}"]`
      );

      if (selectedNode) {
        selectedNode.scrollIntoView({ behavior: "smooth" });
      }
    }, 0);

    return () => {
      clearTimeout(timerId);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedId]);

  const xPos = x - clientRect.initialX;
  const yPos = -clientRect.height + y - clientRect.initialY;

  return (
    <SuggestionListContainer
      ref={contentContainerRef}
      {...(clientRect.height
        ? {
            x:
              containerWidth - xPos < SUGGESTION_LIST_MAX_WIDTH
                ? containerWidth - SUGGESTION_LIST_MAX_WIDTH
                : xPos,
            y: yPos,
            isReady: true,
          }
        : {})}
    >
      <div ref={titleContainerRef} />
      <SuggestionListLabel>{label}</SuggestionListLabel>
      <SuggestionListDataContainer ref={listContainerRef} onClick={onClick}>
        {data.map(({ uid, name }) => (
          <SuggestionListItemContainer
            key={uid}
            data-id={uid}
            isSelected={uid === selectedId}
            color={color}
          >
            <Squircle
              userUid={uid}
              width={26}
              fontSize={10}
              style={{ marginRight: "10px" }}
            />
            {name}
          </SuggestionListItemContainer>
        ))}
      </SuggestionListDataContainer>
    </SuggestionListContainer>
  );
};
