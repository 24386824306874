import { getDatabase, ref, get } from "firebase/database";

import { logDebug } from "../../util/log.util";
import { Group } from "../../type/group.type";

export function getGroupData(groupId: string) {
  if (!groupId) {
    logDebug(`[getGroupData] Group ID is not defined`);

    return Promise.reject();
  }

  const realtimeDb = getDatabase();

  return get(ref(realtimeDb, `groups/${groupId}`)).then((snapshot) => {
    if (!snapshot.exists()) {
      logDebug(`[getGroupData] Snapshot doesn't exist`);

      throw new Error("Group doesn't exist");
    }

    const groupData = snapshot.val() as Group;

    return groupData;
  });
}
