import React, { FC, MouseEvent, useCallback, useRef } from "react";
import { CircleSpinner } from "react-spinners-kit";

// @ts-expect-error
import { ReactComponent as XSvg } from "dive/assets/icons/xWhite.svg";
import { logDebug } from "dive/util/log.util";

import {
  NewGroupModalCard,
  NewGroupModalBlock,
  NewGroupModalClose,
  NewGroupModalIconBlock,
  NewGroupModalTitle,
  NewGroupModalDescription,
  NewGroupModalTemplateGrid,
  NewGroupModalTemplateItem,
  NewGroupModalTemplateItemName,
} from "./NewGroupModal.style";
import { GroupTemplate } from "./NewGroupModal.type";

type NewGroupModalProps = {
  hasChanged: boolean;
  loadingIndex: null | number;
  templateList: GroupTemplate[];
  onTemplateClick: (index: number) => void;
  onClose: () => void;
};

export const NewGroupModal: FC<NewGroupModalProps> = ({
  hasChanged,
  loadingIndex,
  templateList,
  onTemplateClick,
  onClose,
}) => {
  const contentBlockRef = useRef<HTMLDivElement>(null);

  const onBlockClick = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      if (
        !hasChanged &&
        // @ts-expect-error
        !contentBlockRef.current?.contains(event?.target)
      ) {
        onClose();
      }
    },
    [hasChanged, onClose]
  );

  // Add event listener to the parent container (not each item) to decrease count
  //   of event listeners on the page.
  const onGridClick = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      if (!event.target) {
        logDebug(`[NewGroupModal][onGridClick] Target is not defined`, event);

        return;
      }

      // @ts-expect-error
      const templateIndex = event.target.getAttribute("data-index");

      if (!templateIndex) {
        logDebug(
          `[NewGroupModal][onGridClick] Cannot get element index`,
          templateIndex
        );

        return;
      }

      onTemplateClick(+templateIndex);
    },
    [onTemplateClick]
  );

  return (
    <NewGroupModalBlock
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ type: "spring", duration: 0.4, bounce: 0.2 }}
      onClick={onBlockClick}
    >
      <NewGroupModalCard ref={contentBlockRef}>
        <NewGroupModalClose onClick={onClose}>
          <NewGroupModalIconBlock>
            <XSvg width="44" height="44" />
          </NewGroupModalIconBlock>
        </NewGroupModalClose>
        <NewGroupModalTitle>
          Start by picking a group from your real life!
        </NewGroupModalTitle>
        <NewGroupModalDescription>
          A group keeps all the subchats and upcoming events for your group
          under one roof.
        </NewGroupModalDescription>
        <NewGroupModalTemplateGrid onClick={onGridClick}>
          {templateList.map(({ icon, name }, index) => (
            <NewGroupModalTemplateItem
              key={index}
              data-index={`${index}`}
              disabled={typeof loadingIndex === "number"}
              $isDisabled={typeof loadingIndex === "number"}
            >
              {loadingIndex === index ? (
                <CircleSpinner size={18} color="#000000" />
              ) : (
                <>
                  {icon}
                  <NewGroupModalTemplateItemName>
                    {name}
                  </NewGroupModalTemplateItemName>
                </>
              )}
            </NewGroupModalTemplateItem>
          ))}
        </NewGroupModalTemplateGrid>
      </NewGroupModalCard>
    </NewGroupModalBlock>
  );
};
