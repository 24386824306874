// import channel from './channel';
import storage from "./storage";
import chat from "./chat";
import event from "./event";
// import feedback from './feedback';
// import group from './group';
// import hang from './hang';
import user from "./user";
// import util from './util';
// import token from './addExpoToken';

export default {
  //   ...channel,
  ...chat,
  ...storage,
  ...event,
  //   ...feedback,
  //   ...group,
  //   ...hang,
  ...user,
  //   ...util,
  //   ...token,
};
