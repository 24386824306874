import styled from "styled-components";
import { motion } from "framer-motion";

import { meshBackground } from "../../constants/DesignSystem";

// @ts-expect-error
export const NotificationPrimingBlock = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(217, 217, 217, 0.8);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const NotificationPrimingContent = styled.div`
  ${meshBackground}
  width: 500px;
  border-radius: 16px;
  padding: 24px;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 16px 10px rgba(0, 0, 0, 0.14);
`;

export const NotificationPrimingImg = styled.img`
  width: 375px;
`;

export const NotificationPrimingTitle = styled.h3`
  color: var(--ui-tones-light-white, #fff);
  text-align: center;
  font-family: Clash Display !important;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0 0 12px 0;
`;

export const NotificationPrimingDescription = styled.div`
  color: var(--ui-tones-light-white, #fff);
  text-align: center;
  font-family: SF Pro Rounded;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.26px;
`;

export const NotificationPrimingActionBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

type NotificationPrimingButtonProps = {
  isSubmit?: boolean;
};

export const NotificationPrimingButton = styled.button<NotificationPrimingButtonProps>`
  flex: 1;
  padding: 10px;
  margin: 16px 10px 0 10px;
  box-shadow: 0 0 20px 10px rgba(19, 40, 78, 0.1);
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0);
  background: ${({ isSubmit }) => (isSubmit ? "#FFF" : "rgba(0, 0, 0, 0.2)")};
  color: ${({ isSubmit }) => (isSubmit ? "#FF004F" : "#FFFFFF")};
  text-align: center;
  font-family: SF Pro Rounded;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.26px;
  transition: 0.1s;

  &:hover {
    cursor: pointer;
  }

  &:active {
    transform: scale(0.98);
    opacity: 0.9;
  }
`;
