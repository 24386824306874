import React, {
  ChangeEvent,
  FC,
  InputHTMLAttributes,
  PropsWithChildren,
  memo,
  useCallback,
  useState,
} from "react";

type InputProps = PropsWithChildren<InputHTMLAttributes<HTMLInputElement>>;

const InputComponent: FC<InputProps> = ({ ...inputProps }) => {
  const [value, setValue] = useState(inputProps.defaultValue);

  // Need to implement a custom change handler to avoid input lagging
  //   when entering the data too fast.
  // See more:
  //   https://dev.to/kevinkh89/how-to-solve-input-delay-lagging-in-react-j2o
  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setValue(event.target.value);

      inputProps.onChange?.(event);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [inputProps.onChange]
  );

  return <input {...inputProps} value={value} onChange={onChange} />;
};

export const Input = memo(InputComponent);
