import styled from "styled-components";

export const ChatMessageNewChatBlock = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  border-radius: 20px;
  padding: 15px 15px;
  border: 0.5px solid rgba(0, 0, 0, 0.1);
`;

export const ChatMessageNewChatBlockEmoji = styled.div`
  width: 46px;
  height: 46px;
  border-radius: 50%;
  font-size: 32px;
  line-height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.05);
  border: 0.5px solid rgba(255, 255, 255, 0.1);
  overflow: hidden;
`;

export const ChatMessageNewChatBlockSection = styled.div`
  flex: 1;
  margin: 0 30px 0 15px;
`;

export const ChatMessageNewChatBlockTitle = styled.h3`
  font-size: 13px;
  line-height: 15px;
  font-weight: 600;
`;

export const ChatMessageNewChatBlockInfo = styled.span`
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
  opacity: 0.6;
`;

export const ChatMessageNewChatBlockButton = styled.button<{
  isLoading: boolean;
  chatColor: string;
  hasJoined: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 38px;
  width: 100px;
  border-radius: 100px;
  background: ${({ hasJoined, chatColor }) =>
    hasJoined ? chatColor : "transparent"};
  text-transform: uppercase;
  font-weight: 700;
  color: ${({ hasJoined, chatColor }) => (hasJoined ? "#FFF" : chatColor)};
  transition: 0.1s;

  border: 3px solid
    ${({ hasJoined, chatColor }) => (hasJoined ? "#FFF" : chatColor)};

  ${({ isLoading }) =>
    isLoading
      ? "opacity: 0.8;"
      : `
  
  &:hover {
    cursor: pointer;
  }

  &:active {
    transform: scale(0.98);
    opacity: 0.9;
  }
  
  `}
`;

export const ChatMessageNewChatBlockButtonPrefix = styled.div`
  margin: 0 5px 0 0;
`;
