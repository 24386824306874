import React, { FC, SVGProps, useRef, useState } from "react";

import { ReactComponent as AppleMusicSvg } from "../../assets/LinkPreviews/apple-music.svg";
import { ReactComponent as InstagramSvg } from "../../assets/LinkPreviews/instagram.svg";
import { ReactComponent as SpotifySvg } from "../../assets/LinkPreviews/spotify.svg";
import { ReactComponent as TiktokSvg } from "../../assets/LinkPreviews/tiktok.svg";
import { ReactComponent as TwitterSvg } from "../../assets/LinkPreviews/twitter.svg";
import { ReactComponent as YoutubeSvg } from "../../assets/LinkPreviews/youtube.svg";
import { ReactComponent as PlaySvg } from "../../assets/play.svg";
import { ReactComponent as DiveReactionSvg } from "../../assets/diveReaction.svg";
import { LinkPreviewType, ProviderNameType } from "../../type/link.type";
import {
  APPLE_MUSIC_PROVIDER,
  DIVE_GROUP_PROVIDER,
  DIVE_PROVIDER,
  INSTAGRAM_PROVIDER,
  SPOTIFY_PROVIDER,
  TIKTOK_PROVIDER,
  TWITTER_PROVIDER,
  VIDEO_OTHER_LINK_TYPE,
  YOUTUBE_PROVIDER,
} from "../../constants/link.constant";

import { ChatMessageLinkPreviewContent } from "./ChatMessageLinkPreviewContent";
import {
  ChatMessageLinkPreviewAudioPostfix,
  ChatMessageLinkPreviewLogo,
  ChatMessageLinkPreviewMediaBlock,
  ChatMessageLinkPreviewMediaImg,
  ChatMessageLinkPreviewMediaText,
  ChatMessageLinkPreviewVideoBadge,
} from "./ChatMessageLinkPreview.style";

export type ChatMessageLinkPreviewProps = {
  title: string;
  description: string;
  url: string;
  type: LinkPreviewType;
  providerName: ProviderNameType;
  authorPictureUrl?: string;
  faviconUrl?: string;
  mediaText?: string;
  mediaType?: string;
  mediaUrl?: string;
};

export const ChatMessageLinkPreview: FC<ChatMessageLinkPreviewProps> = ({
  title,
  description,
  url,
  type,
  providerName,
  authorPictureUrl,
  faviconUrl,
  mediaText,
  mediaType,
  mediaUrl,
}) => {
  const [isImageFailedToLoad, setIsImageFailedToLoad] = useState(false);
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const isVideo = type === VIDEO_OTHER_LINK_TYPE;
  const isAudio = [SPOTIFY_PROVIDER, APPLE_MUSIC_PROVIDER].includes(
    providerName
  );

  const providerIconMap: { [key in ProviderNameType]?: FC<SVGProps<any>> } = {
    [APPLE_MUSIC_PROVIDER]: AppleMusicSvg,
    [INSTAGRAM_PROVIDER]: InstagramSvg,
    [SPOTIFY_PROVIDER]: SpotifySvg,
    [TIKTOK_PROVIDER]: TiktokSvg,
    [TWITTER_PROVIDER]: TwitterSvg,
    [YOUTUBE_PROVIDER]: YoutubeSvg,
    [DIVE_PROVIDER]: DiveReactionSvg,
    [DIVE_GROUP_PROVIDER]: DiveReactionSvg,
  };

  let ProviderIconComponent = providerIconMap[providerName];

  // TODO: Update our back-end for link preview generation to specify
  //   the `DIVE_PROVIDER` link type on links matching the condition below.
  if (
    url &&
    (url.includes("dive.chat") ||
      (url.includes("dive") && url.includes("chat")) ||
      url.includes("dev.followor"))
  ) {
    ProviderIconComponent = DiveReactionSvg;
  }

  const hasHeader = mediaText || mediaUrl;

  const onMediaError = useRef(() => {
    setIsImageFailedToLoad(true);
  }).current;

  const onMediaLoadEnd = useRef(() => {
    setIsImageLoaded(true);
  }).current;

  // TODO: Change this line when Twitter Tweet preview type is working
  const isMediaTextVisible = false;

  const header = hasHeader ? (
    // @ts-ignore
    <ChatMessageLinkPreviewMediaBlock>
      {mediaUrl && !isImageFailedToLoad ? (
        <ChatMessageLinkPreviewMediaBlock>
          <ChatMessageLinkPreviewMediaImg
            src={mediaUrl}
            onError={onMediaError}
            onLoad={onMediaLoadEnd}
          />
          {isVideo && isImageLoaded && !isImageFailedToLoad ? (
            <ChatMessageLinkPreviewVideoBadge>
              <PlaySvg />
            </ChatMessageLinkPreviewVideoBadge>
          ) : null}
        </ChatMessageLinkPreviewMediaBlock>
      ) : null}
      {mediaText && isMediaTextVisible ? (
        <ChatMessageLinkPreviewMediaText>
          {mediaText}
        </ChatMessageLinkPreviewMediaText>
      ) : null}
    </ChatMessageLinkPreviewMediaBlock>
  ) : null;

  let contentPrefix = authorPictureUrl ? (
    <ChatMessageLinkPreviewLogo src={authorPictureUrl} marginRightPx={15} />
  ) : null;

  let contentPostfix = isAudio ? (
    <ChatMessageLinkPreviewAudioPostfix
      isRed={providerName === APPLE_MUSIC_PROVIDER}
    >
      <PlaySvg width="16" height="16" />
    </ChatMessageLinkPreviewAudioPostfix>
  ) : ProviderIconComponent ? (
    <ProviderIconComponent width="24" height="24" />
  ) : faviconUrl ? (
    <ChatMessageLinkPreviewLogo src={faviconUrl} widthPx={24} heightPx={24} />
  ) : null;

  const badge =
    isAudio && ProviderIconComponent ? <ProviderIconComponent /> : null;

  // Copied from mobile app implementation
  if (providerName === TWITTER_PROVIDER && !authorPictureUrl && !!mediaUrl) {
    contentPrefix = contentPostfix;
    contentPostfix = null;
  }

  return (
    <ChatMessageLinkPreviewContent
      isBlurBody={isAudio}
      url={url}
      title={title}
      description={description}
      header={header}
      badge={badge}
      contentPrefix={contentPrefix}
      contentPostfix={contentPostfix}
      bgImageUrl={isAudio ? mediaUrl : undefined}
    />
  );
};
