import React, { useCallback, useEffect, useRef } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";

import {
  dataSelectedChannelIdSelector,
  dataMyselfIdSelector,
  dataSelectedGroupSelector,
  dataGroupsSelectedGroupSelector,
} from "../store/data";
import { setLastOnlineStatus } from "../firebase/user";
import { logDebug } from "../util/log.util.ts";

import ChatHeader from "./ChatHeader.js";
import { Chat } from "./Chat";
import ChatInputToolbar from "./ChatInputToolbar.js";
import { AnimatedCounter } from "./AnimatedCounter";
import { getGroupColor } from "../constants/groupColors.js";

const ChatContainer = () => {
  const selectedChannelId = useSelector(dataSelectedChannelIdSelector);
  const myId = useSelector(dataMyselfIdSelector);
  const selectedGroupId = useSelector(dataSelectedGroupSelector);
  const selectedGroupData = useSelector(dataGroupsSelectedGroupSelector);

  const animatedCounterRef = useRef(null);

  const showAnimatedCounter = useCallback(
    (count) => {
      const groupColor = getGroupColor(selectedGroupData);

      animatedCounterRef.current?.show(count, {
        textColor: groupColor,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedGroupId]
  );

  useEffect(
    () => {
      setLastOnlineStatus(myId, Date.now())
        .then(() => {
          logDebug("[ChatContainer] The last online status has been updated", {
            myId,
            selectedChannelId,
          });
        })
        .catch((ex) => {
          logDebug("[ChatContainer] Couldn't update last online status", {
            myId,
            selectedChannelId,
          });
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedChannelId]
  );

  return (
    <VerticalFlexContainer>
      <ChatHeader></ChatHeader>
      <Chat showAnimatedCounter={showAnimatedCounter}></Chat>
      <ChatInputToolbar></ChatInputToolbar>
      <AnimatedCounterSection>
        <AnimatedCounter ref={animatedCounterRef} />
      </AnimatedCounterSection>
    </VerticalFlexContainer>
  );
};

export default ChatContainer;

const VerticalFlexContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  border-right: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 0px 6px 8px -6px rgba(24, 39, 75, 0.1);
`;

const AnimatedCounterSection = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  pointer-events: none;
`;
