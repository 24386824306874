import React, { FC, PropsWithChildren } from "react";
import { AnimatePresence } from "framer-motion";

import { useSelector } from "dive/store";
import { uiModalPresentationStackSelector } from "dive/store/ui";

import { modalComponentMap } from "./ModalProvider.config";

type ModalProviderProps = PropsWithChildren<{}>;

export const ModalProvider: FC<ModalProviderProps> = ({ children }) => {
  const modalPresentationStack = useSelector(uiModalPresentationStackSelector);

  return (
    <>
      {children}
      <AnimatePresence>
        {modalPresentationStack.map((modalId, index) => {
          const ModalComponent = modalComponentMap[modalId];

          return <ModalComponent key={modalId} index={index} />;
        })}
      </AnimatePresence>
    </>
  );
};
