import { CSSProperties } from "react";
import styled from "styled-components";

import { flexCenter } from "dive/constants/DesignSystem";

export const CreateSubchatBlock = styled.div`
  width: 100%;
  display: flex;
`;

type CreateSubchatButtonProps = {
  $color: CSSProperties["color"];
};

export const CreateSubchatButton = styled.button<CreateSubchatButtonProps>`
  flex: 1;
  padding: 14px 18px 18px 22px;
  margin: 9px 0 28px 0;
  border-width: 0;
  border-radius: 16px;
  background: transparent;
  display: flex;
  align-items: center;
  color: ${({ $color }) => $color};
  transition: transform 0.1s;

  svg {
    path {
      fill: ${({ $color }) => $color};
    }
  }

  &:hover {
    cursor: pointer;
    background: #e1e1e1;
  }

  &:active {
    transform: scale(0.98);
    opacity: 0.9;
  }
`;

export const CreateSubchatIconBlock = styled.div`
  ${flexCenter}
  width: 46px;
  height: 46px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.05);
`;

export const CreateSubchatLabel = styled.div`
  margin: 0 0 0 18px;
  font-family: "SF Pro Rounded";
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.34px;
`;
