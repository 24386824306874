import styled from "styled-components";

type AnimatedCounterBlockContainerProps = {
  zIndex: number;
  topPercent: number;
  leftPercent: number;
  color: string;
  content: string;
};

export const AnimatedCounterBlockContainer = styled.div<AnimatedCounterBlockContainerProps>`
  width: 190px;
  height: 75px;
  position: absolute;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  font-family: Clash Display Bold !important;
  font-size: 62px;

  &:after {
    content: "${({ content }) => content}";
    position: absolute;
    left: 0;
    z-index: -1;
    -webkit-text-stroke: 8px #ffffff;
    font-size: 1em;
    text-shadow: #000000 0 0 6px;
  }

  ${({ zIndex }) => `z-index: ${zIndex};`}
  ${({ topPercent }) => `top: ${topPercent}%;`}
  ${({ leftPercent }) => `left: calc(${leftPercent}% - 190px);`}
  ${({ color }) => `color: ${color};`}
`;
