import { createSelector } from "@reduxjs/toolkit";

import { AppRootState } from "dive/type/shared.type";

import { uiReducerName } from "./ui.slice";

export const uiSelector = (state: AppRootState) => state[uiReducerName];

export const uiModalPresentationStackSelector = createSelector(
  [uiSelector],
  (ui) => ui.modalPresentationStack
);
