import { getDatabase, update, ref, push } from "firebase/database";
import { getAuth } from "firebase/auth";
import axios from "axios";

const auth = getAuth();
const db = getDatabase();

async function getOnesignalHash() {
  console.log("calling get onesignal hash");
  const idToken = await auth.currentUser.getIdToken();
  const config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
      "Content-Type": "application/json",
    },
  };
  /* get project id for firebase */
  console.log(idToken);
  console.log(db.app.options.projectId);

  const result = await axios.post(
    `https://us-central1-${db.app.options.projectId}.cloudfunctions.net/getOnesignalHash`,
    {},
    config
  );
  console.log("result from running cloud function:");
  return result?.data?.hash;
}

/**
 * @param {string} userId
 * @param {number} lastOnlineDate
 */
export function setLastOnlineStatus(userId, lastOnlineDate) {
  const realtimeDbInstance = getDatabase();

  const updates = {
    [`users/${userId}/lastOnlineWeb`]: lastOnlineDate,
  };

  return update(ref(realtimeDbInstance), updates);
}

/*

Change this file to use the proper sdk 9 syntax
Call this file from the onesignal init file and get the hash
identify external uid using the hash
send a test push notification using the hash
try it live

*/

export default {
  getOnesignalHash,
};
