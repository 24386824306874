import React, { useEffect } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import {
  addEventListener,
  setIsEventsSidebarOpen,
  selectEvent,
} from "../store/data";
import { formatFutureEventTimestamp, replaceSpecialEmoji } from "../util";

import { ReactComponent as GoingIconSvg } from "../assets/goingIcon.svg";
import { ReactComponent as InterestedIconSvg } from "../assets/interestedIcon.svg";
import { ReactComponent as NotGoingIconSvg } from "../assets/notGoingIcon.svg";
import { ReactComponent as RightChevronSvg } from "../assets/rightChevron.svg";
import { createSelector } from "@reduxjs/toolkit";

const EventTile = (props) => {
  const dispatch = useDispatch();

  const selectEventObj = (state) =>
    state?.data?.events?.[props?.eventUid] ?? {};

  const eventObj = useSelector(selectEventObj);

  const myself = useSelector((state) => state?.data?.myself);

  const rsvpIdx = eventObj?.responses?.[myself?.uid] ?? -1;

  useEffect(() => {
    if (props?.eventUid) {
      dispatch(addEventListener(props?.eventUid));
    }
  }, []);

  const rsvpIdxToJsx = (rsvpIdx) => {
    switch (rsvpIdx) {
      case 1:
        return <GoingIcon />;
      case 2:
        return <InterestedIcon />;
      case 5:
        return <InterestedIcon />;
      case 3:
        return <NotGoingIcon />;
      default:
        return <></>;
    }
  };

  const openEvent = () => {
    dispatch(setIsEventsSidebarOpen(true));
    dispatch(selectEvent(props?.eventUid));
  };

  const selectSelectedEventUid = (state) => state?.data?.selectedEvent;

  const selectIsSelected = createSelector(
    selectSelectedEventUid,
    (selectedEventUid) => {
      // console.log(props?.eventUid, selectedEventUid);
      return props?.eventUid === selectedEventUid;
    }
  );

  const isSelected = useSelector(selectIsSelected);

  return (
    <EventContainer
      onClick={openEvent}
      color={eventObj?.color ?? "#007AFF"}
      isInSidebar={props?.isInSidebar}
      isInChat={props?.isInChat}
      isSelected={isSelected}
    >
      <EventEmojiContainer
        isInSidebar={props?.isInSidebar}
        color={eventObj?.color ?? "#007AFF"}
      >
        {replaceSpecialEmoji(eventObj?.emoji, 30) ?? "😊"}
        {rsvpIdxToJsx(rsvpIdx)}
      </EventEmojiContainer>
      <VerticalFlex>
        <TitleText isInSidebar={props?.isInSidebar}>
          {eventObj?.title}
        </TitleText>
        <TimeText isInSidebar={props?.isInSidebar}>
          {formatFutureEventTimestamp(eventObj?.date ?? eventObj?.startTime)}
        </TimeText>
      </VerticalFlex>
      {/* <RightChevron /> */}
    </EventContainer>
  );
};

export default EventTile;

const RightChevron = styled(RightChevronSvg)`
  width: 13px;
  height: 16px;
  fill: #d1d1d6;
  flex-shrink: 0;
  position: absolute;
  right: 15px;
`;

const EventEmojiContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 4px;
  margin-right: 6px;
  gap: 10px;

  width: 46px;
  height: 46px;

  background: rgba(255, 255, 255, 0.15);
  ${(props) =>
    props.isInSidebar &&
    `background: linear-gradient(180deg, rgba(255, 255, 255, 0.25) 0%, rgba(255, 255, 255, 0) 100%), ${props?.color};`}
  border: 0.5px solid rgba(255, 255, 255, 0.1);
  border-radius: 100px;
  font-size: 32px;
  flex-shrink: 0;
  position: relative;
`;

const EventContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 15px;
  margin-left: 2px;

  ${({ isInChat }) =>
    isInChat
      ? ``
      : `
  gap: 10px;
  width: 374px;
  height: 90px;
  `};

  position: relative;

  /* Depth/Orange */

  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.25) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    ${(props) => props?.color};
  border: 0.5px solid rgba(0, 0, 0, 0.1);
  /* Shadows/100 */

  box-shadow: 0px 2px 4px -2px rgba(24, 39, 75, 0.1);
  border-radius: 20px;

  margin-bottom: 8px;

  ${(props) => props?.isInSidebar && `width: 100%; background: #FFF; `}

  ${(props) =>
    props?.isInSidebar &&
    props?.isSelected &&
    `background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), #FFFFFF;`}


  &:hover {
    cursor: pointer;
    ${(props) =>
      props?.isInSidebar &&
      `background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), #FFFFFF;`}
  }
`;

const TitleText = styled.div`
  width: 264px;
  height: 22px;

  /* Body / Semibold */

  font-family: "SF Pro Rounded";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 129% */

  letter-spacing: 0.02em;

  /* UI Tones/Light/White */

  /* overflow ellipses */
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  color: #ffffff;
  ${(props) => props?.isInSidebar && `color: #000;`}
`;

const TimeText = styled.div`
  width: 100%
  height: 18px;

  /* Footnote / Regular */

  font-family: "SF Pro Rounded";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  /* identical to box height, or 138% */

  letter-spacing: 0.02em;

  /* UI Tones/Light/White */

  color: #ffffff;
  ${(props) => props?.isInSidebar && `color: #000;`}
`;

const VerticalFlex = styled.div`
  display: flex;
  width: 100%;
  height: 41px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
`;

const GoingIcon = styled(GoingIconSvg)`
  width: 18.9px;
  height: 18.9px;
  flex-shrink: 0;
  position: absolute;
  bottom: -6px;
  right: -6px;
  z-index: 1;
  background: #ffffff;
  border-radius: 100px;
  border: 2.5px solid rgba(255, 255, 255, 1);
`;

const InterestedIcon = styled(InterestedIconSvg)`
  width: 23.4px;
  height: 23.4px;
  flex-shrink: 0;
  position: absolute;
  bottom: -6px;
  right: -6px;
  z-index: 1;
  background: #ffffff;
  border-radius: 100px;
  //   border: 1.5px solid rgba(255, 255, 255, 1);
`;

const NotGoingIcon = styled(NotGoingIconSvg)`
  width: 23.4px;
  height: 23.4px;
  flex-shrink: 0;
  position: absolute;
  bottom: -6px;
  right: -6px;
  z-index: 1;
  background: #ffffff;
  border-radius: 100px;
  //   border: 1px solid rgba(255, 255, 255, 1);
`;
