const STORAGE_PREFIX = "dive";

type StorageItemValue = number | string | Record<string, unknown> | unknown[];

// TODO: Add custom storage interface
// TODO: Implement custom Event dispatching
// TODO: Add custom serialize / parse utils
const storage = {
  setItem: (key: string, data: StorageItemValue) => {
    const path = `${STORAGE_PREFIX}:${key}`;

    localStorage.setItem(path, JSON.stringify(data));
  },
  getItem: <T>(key: string) => {
    const path = `${STORAGE_PREFIX}:${key}`;

    const serializedData = localStorage.getItem(path);

    if (!serializedData) {
      return null;
    }

    return JSON.parse(serializedData) as T;
  },
  removeItem: (key: string) => {
    const path = `${STORAGE_PREFIX}:${key}`;

    localStorage.removeItem(path);
  },
};

export function getStorage() {
  return storage;
}
