import React, { ReactNode } from "react";
import { useSelector } from "react-redux";

import { dataUsersByIdSelector } from "../store/data";
import { socialPlatformMap } from "../constants/profile.constant";

type SocialLink = {
  title: string;
  href: string;
  color: string;
  icon: ReactNode;
};

export function useSocialLinks(userId: string) {
  const socialLinks: SocialLink[] = [];

  // @ts-expect-error
  const profileData = useSelector((st) => dataUsersByIdSelector(st, userId));

  const numSocialMedias =
    // @ts-ignore
    Boolean(profileData.instagramHandle) +
    Boolean(profileData.tikTokHandle) +
    Boolean(profileData.twitterHandle) +
    Boolean(profileData.snapchatHandle) +
    Boolean(profileData.venmoHandle) +
    Boolean(profileData.cashAppHandle) +
    Boolean(profileData.appleMusicHandle) +
    Boolean(profileData.spotifyHandle);

  if (profileData.instagramHandle) {
    socialLinks.push({
      title: socialPlatformMap.instagram.title,
      color: socialPlatformMap.instagram.color,
      href: `${socialPlatformMap.instagram.webLink}${profileData.instagramHandle}`,
      icon: <socialPlatformMap.instagram.IconComponent />,
    });
  }

  if (profileData.tikTokHandle) {
    socialLinks.push({
      title: socialPlatformMap.tikTok.title,
      color: socialPlatformMap.tikTok.color,
      href: `${socialPlatformMap.tikTok.webLink}${profileData.tikTokHandle}`,
      icon: <socialPlatformMap.tikTok.IconComponent />,
    });
  }

  if (profileData.twitterHandle) {
    socialLinks.push({
      title: socialPlatformMap.twitter.title,
      color: socialPlatformMap.twitter.color,
      href: `${socialPlatformMap.twitter.webLink}${profileData.twitterHandle}`,
      icon: <socialPlatformMap.twitter.IconComponent />,
    });
  }

  if (profileData.snapchatHandle) {
    socialLinks.push({
      title: socialPlatformMap.snapchat.title,
      color: socialPlatformMap.snapchat.color,
      href: `${socialPlatformMap.snapchat.webLink}${profileData.snapchatHandle}`,
      icon: <socialPlatformMap.snapchat.IconComponent />,
    });
  }

  if (profileData.venmoHandle) {
    socialLinks.push({
      title: socialPlatformMap.venmo.title,
      color: socialPlatformMap.venmo.color,
      href: `${socialPlatformMap.venmo.webLink}${profileData.venmoHandle}`,
      icon: <socialPlatformMap.venmo.IconComponent />,
    });
  }

  if (profileData.cashAppHandle) {
    socialLinks.push({
      title: socialPlatformMap.cashApp.title,
      color: socialPlatformMap.cashApp.color,
      href: `${socialPlatformMap.cashApp.webLink}${profileData.cashAppHandle}`,
      icon: <socialPlatformMap.cashApp.IconComponent />,
    });
  }

  if (profileData.appleMusicHandle) {
    socialLinks.push({
      title: socialPlatformMap.appleMusic.title,
      color: socialPlatformMap.appleMusic.color,
      href: `${socialPlatformMap.appleMusic.webLink}${profileData.appleMusicHandle}`,
      icon: <socialPlatformMap.appleMusic.IconComponent />,
    });
  }

  if (profileData.spotifyHandle) {
    socialLinks.push({
      title: socialPlatformMap.spotify.title,
      color: socialPlatformMap.spotify.color,
      href: `${socialPlatformMap.spotify.webLink}${profileData.spotifyHandle}`,
      icon: <socialPlatformMap.spotify.IconComponent />,
    });
  }

  return {
    socialLinks,
    numSocialMedias,
  };
}
