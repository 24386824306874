import { getDatabase, update, ref } from "firebase/database";
import { getAuth } from "firebase/auth";

import { logDebug } from "../../util/log.util";

export function setLastActive(lastActiveDate?: number) {
  if (!lastActiveDate) {
    lastActiveDate = Date.now();

    if (lastActiveDate % 10 === 0) {
      lastActiveDate = lastActiveDate + 1;
    }
  }

  const realtimeDb = getDatabase();
  const authSession = getAuth();

  const userId = authSession.currentUser?.uid;

  if (!userId) {
    logDebug("[setLastActive] Cannot retrieve current user ID");

    return;
  }

  const updates = {
    [`lastActive/${userId}`]: lastActiveDate,
  };

  return update(ref(realtimeDb), updates);
}
