import { DataSnapshot, getDatabase, onValue, ref } from "firebase/database";

type AddDesktopVersionListenerPayload = {
  onDesktopVersionChange: (snapshot: DataSnapshot) => void;
};

export function addDesktopVersionListener({
  onDesktopVersionChange,
}: AddDesktopVersionListenerPayload) {
  if (!onDesktopVersionChange || typeof onDesktopVersionChange !== "function") {
    throw new Error("[addDesktopVersionListener] Listener is not provided");
  }

  const db = getDatabase();

  const removeDesktopVersionListener = onValue(
    ref(db, "desktopVersionString"),
    onDesktopVersionChange
  );

  return removeDesktopVersionListener;
}
