import { ColorId } from "dive/type/theme.type";

// See more:
//   https://www.figma.com/file/D5buGCdTkF31Vq8gtPawvv/Universal-Library?node-id=1182%3A4&mode=dev
export const PINK_COLOR_ID = "pink";
export const BLUE_COLOR_ID = "blue";
export const BROWN_COLOR_ID = "brown";
export const CYAN_COLOR_ID = "cyan";
export const GREEN_COLOR_ID = "green";
export const PURPLE_COLOR_ID = "purple";
export const ORANGE_COLOR_ID = "orange";
export const RED_COLOR_ID = "red";
export const MINT_COLOR_ID = "mint";
export const YELLOW_COLOR_ID = "yellow";
export const DARK_GRAY_COLOR_ID = "dark-gray";
export const LIGHT_GRAY_COLOR_ID = "light-gray";

// Use array structure rather than an object (map) to
//   guarantee the order of the elements
export const paletteGlassFills: {
  id: ColorId;
  cssBackgroundValue: string;
}[] = [
  {
    id: PINK_COLOR_ID,
    cssBackgroundValue: `var(--Glass-Pink, radial-gradient(111.8% 111.8% at 100% 100%, rgba(255, 45, 85, 0.80) 0%, rgba(255, 45, 85, 0.00) 100%), radial-gradient(111.8% 111.8% at 0% 0%, rgba(255, 45, 146, 0.80) 0%, rgba(255, 45, 146, 0.00) 100%), #FF8BA1);`,
  },
  {
    id: BLUE_COLOR_ID,
    cssBackgroundValue: `var(--Glass-Blue, radial-gradient(111.8% 111.8% at 100% 100%, rgba(0, 122, 255, 0.80) 0%, rgba(0, 122, 255, 0.00) 100%), radial-gradient(111.8% 111.8% at 0% 0%, rgba(0, 71, 255, 0.80) 0%, rgba(0, 71, 255, 0.00) 100%), #A7CEFF);`,
  },
  {
    id: BROWN_COLOR_ID,
    cssBackgroundValue: `var(--Glass-Brown, radial-gradient(111.8% 111.8% at 100% 100%, rgba(118, 97, 70, 0.80) 0%, rgba(118, 97, 70, 0.00) 100%), radial-gradient(111.8% 111.8% at 0% 0%, rgba(118, 97, 70, 0.80) 0%, rgba(162, 132, 94, 0.00) 100%), #B8AA98);`,
  },
  {
    id: CYAN_COLOR_ID,
    cssBackgroundValue: `var(--Glass-Cyan, radial-gradient(111.8% 111.8% at 100% 100%, rgba(50, 173, 230, 0.80) 0%, rgba(50, 173, 230, 0.00) 100%), radial-gradient(111.8% 111.8% at 0% 0%, rgba(50, 144, 230, 0.80) 0%, rgba(50, 144, 230, 0.00) 100%), #9DDCF9);`,
  },
  {
    id: GREEN_COLOR_ID,
    cssBackgroundValue: `var(--Glass-Green, radial-gradient(111.8% 111.8% at 100% 100%, rgba(52, 199, 89, 0.80) 0%, rgba(52, 199, 89, 0.00) 100%), radial-gradient(111.8% 111.8% at 0% 0%, rgba(53, 175, 146, 0.80) 0%, rgba(53, 175, 146, 0.00) 100%), #C3F9B2);`,
  },
  {
    id: PURPLE_COLOR_ID,
    cssBackgroundValue: `var(--Glass-Purple, radial-gradient(111.8% 111.8% at 100% 100%, rgba(144, 82, 222, 0.80) 0%, rgba(144, 82, 222, 0.00) 100%), radial-gradient(111.8% 111.8% at 0% 0%, rgba(175, 82, 222, 0.80) 0%, rgba(175, 82, 222, 0.00) 100%), #E7B7FF);`,
  },
  {
    id: ORANGE_COLOR_ID,
    cssBackgroundValue: `var(--Glass-Orange, radial-gradient(111.8% 111.8% at 100% 100%, rgba(255, 107, 0, 0.80) 0%, rgba(255, 107, 0, 0.00) 100%), radial-gradient(111.8% 111.8% at 0% 0%, rgba(255, 149, 0, 0.80) 0%, rgba(255, 149, 0, 0.00) 100%), #FFCF8B);`,
  },
  {
    id: RED_COLOR_ID,
    cssBackgroundValue: `var(--Glass-Red, radial-gradient(111.8% 111.8% at 100% 100%, rgba(255, 39, 74, 0.80) 0%, rgba(255, 39, 74, 0.00) 100%), radial-gradient(111.8% 111.8% at 0% 0%, rgba(255, 51, 44, 0.80) 0%, rgba(255, 51, 44, 0.00) 100%), #FF9FA4);`,
  },
  {
    id: MINT_COLOR_ID,
    cssBackgroundValue: `var(--Glass-Mint, radial-gradient(111.8% 111.8% at 100% 100%, rgba(0, 199, 190, 0.80) 0%, rgba(0, 199, 190, 0.00) 100%), radial-gradient(111.8% 111.8% at 0% 0%, rgba(0, 199, 139, 0.80) 0%, rgba(0, 199, 139, 0.00) 100%), #7BDDD9);`,
  },
  {
    id: YELLOW_COLOR_ID,
    cssBackgroundValue: `var(--Glass-Yellow, radial-gradient(111.8% 111.8% at 100% 100%, rgba(255, 168, 0, 0.80) 0%, rgba(255, 168, 0, 0.00) 100%), radial-gradient(111.8% 111.8% at 0% 0%, rgba(255, 199, 0, 0.80) 0%, rgba(255, 199, 0, 0.00) 100%), #EDD78A);`,
  },
  {
    id: DARK_GRAY_COLOR_ID,
    cssBackgroundValue: `var(--Glass-Dark-Gray, radial-gradient(111.8% 111.8% at 100% 100%, rgba(28, 38, 42, 0.80) 0%, rgba(28, 38, 42, 0.00) 100%), radial-gradient(111.8% 111.8% at 0% 0%, rgba(28, 38, 42, 0.80) 0%, rgba(28, 38, 42, 0.00) 100%), #767778);`,
  },
  {
    id: LIGHT_GRAY_COLOR_ID,
    cssBackgroundValue: `var(--Glass-Light-Gray, radial-gradient(111.8% 111.8% at 100% 100%, rgba(142, 142, 147, 0.80) 0%, rgba(142, 142, 147, 0.00) 100%), radial-gradient(111.8% 111.8% at 0% 0%, rgba(142, 142, 147, 0.80) 0%, rgba(142, 142, 147, 0.00) 100%), #D0D0D0);`,
  },
];
