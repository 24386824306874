import axios from "axios";
import { getDatabase } from "firebase/database";
import { getAuth } from "firebase/auth";

import { logDebug } from "../../util/log.util";

type AddToGroupPayload = {
  userIds: string[];
};

export async function addToGroup(groupId: string, payload: AddToGroupPayload) {
  if (!groupId) {
    logDebug(`[addToGroup] Group ID is not defined`);

    return Promise.reject();
  }

  const realtimeDb = getDatabase();
  const authSession = getAuth();

  const projectId = realtimeDb.app.options.projectId;

  if (!projectId) {
    logDebug("[addToGroup] Cannot retrieve Firebase project ID");

    return;
  }

  const idToken = await authSession.currentUser?.getIdToken();
  const myId = authSession.currentUser?.uid;

  if (!idToken || !myId) {
    logDebug("[addToGroup] Cannot retrieve an ID token of current user");

    return;
  }

  const config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
      "Content-Type": "application/json",
    },
  };

  return axios
    .post(
      `https://us-central1-${projectId}.cloudfunctions.net/addUsersToGroupAuth`,
      {
        groupUid: groupId,
        users: payload.userIds,
        addNewUserMessage: true,
        newUserMessageUid: null,
        newUserMessageCreatedAt: null,
        addedBy: myId,
      },
      config
    )
    .then((response) => {
      return response.data;
    });
}
