import { useEffect } from "react";

import { useIsBrowser } from "../../hook/useIsBrowser";
import { NotificationMeta } from "../../type/notification.type";
import { logDebug } from "../../util/log.util";

import { useOpenNotificationSource } from "./useOpenNotificationSource";

export function useInitialPayloadEffect() {
  const isBrowser = useIsBrowser();

  const openNotificationSource = useOpenNotificationSource();

  useEffect(
    () => {
      if (isBrowser) {
        return;
      }

      if (!window.diveNativeDesktopApi) {
        logDebug(`[useInitialPayloadEffect] Desktop API is not available`);

        return;
      }

      // @ts-expect-error
      const initialPayload: NotificationMeta =
        window.diveNativeDesktopApi.getInitialPayload();

      const channelId = initialPayload.channelId;
      const groupId = initialPayload.groupId;
      const eventId = initialPayload.eventId;

      if (channelId || groupId || eventId) {
        openNotificationSource({
          channelId,
          groupId,
          eventId,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isBrowser]
  );
}
