import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { createSelector } from "@reduxjs/toolkit";
import { motion, AnimatePresence, useAnimation } from "framer-motion";

import { getOtherPersonUid, replaceSpecialEmoji } from "../util";
import { ReactComponent as PeopleSvg } from "../assets/icons/people.svg";
import { getGroupColor } from "../constants/groupColors";
import { getUserLastActiveStatusText } from "../util/user.util";
import {
  dataChannelsByIdMembersProfilesSelector,
  dataLastActiveMapSelector,
  dataMessageMapByChannelSelector,
  dataSelectedChannelIdSelector,
  dataMyselfIdSelector,
} from "../store/data";
import { useSelector } from "../store";
import { logDebug } from "../util/log.util";

import Squircle from "./Squircle";

const ChatHeader = () => {
  const [isMemberListVisible, setIsMemberListVisible] = useState(false);
  const [sendingMessageId, setSendingMessageId] = useState(null);

  const memberCountBlockRef = useRef(null);
  const memberListPresentTimerIdRef = useRef(null);
  const sendingMessagePayloadRef = useRef({
    messageId: null,
    timerId: null,
    isOpacityAnimationStarted: false,
    isOpacityAnimationFinished: false,
  });

  useEffect(() => {
    const onDocumentClick = (event) => {
      if (
        memberCountBlockRef.current &&
        !memberCountBlockRef.current.contains(event.target)
      ) {
        setIsMemberListVisible(false);
      }
    };

    document.addEventListener("click", onDocumentClick);

    return () => {
      document.removeEventListener("click", onDocumentClick);
    };
  }, []);

  const onMouseEnter = useCallback(() => {
    memberListPresentTimerIdRef.current = setTimeout(() => {
      setIsMemberListVisible(true);
    }, 800);
  }, []);

  const onMouseLeave = useCallback(() => {
    if (memberListPresentTimerIdRef.current) {
      clearTimeout(memberListPresentTimerIdRef.current);

      memberListPresentTimerIdRef.current = null;
    }

    setIsMemberListVisible(false);
  }, []);

  //if DM, get other person name
  //if simple group, get simple group name
  //if Dive, get channel name

  const channelId = useSelector(dataSelectedChannelIdSelector);
  const messageByChannelMap = useSelector((st) =>
    // @ts-expect-error
    dataMessageMapByChannelSelector(st, channelId)
  );

  const animationControl = useAnimation();

  useEffect(
    () => {
      if (sendingMessagePayloadRef.current.timerId) {
        clearTimeout(sendingMessagePayloadRef.current.timerId);

        sendingMessagePayloadRef.current.timerId = null;
      }

      if (!messageByChannelMap) {
        logDebug(`[ChatHeader] Message map doesn't exist`);

        return;
      }

      const messageList = Object.values(messageByChannelMap).sort(
        (left, right) => {
          return (right?.createdAt || 0) - (left?.createdAt || 0);
        }
      );

      const lastLocalMessage = messageList.find(
        (message) => message.localMessage && !message.showNotSent
      );

      logDebug(`[ChatHeader] Last local message`, lastLocalMessage);

      if (
        lastLocalMessage &&
        lastLocalMessage.uid !== sendingMessagePayloadRef.current.messageId &&
        !sendingMessagePayloadRef.current.isOpacityAnimationStarted
      ) {
        sendingMessagePayloadRef.current.timerId = setTimeout(() => {
          animationControl.stop();

          animationControl.set({ x: "-98%" });

          animationControl
            .start({ opacity: 1, transition: { duration: 0.35 } })
            .then(() => {
              sendingMessagePayloadRef.current.isOpacityAnimationFinished = true;

              animationControl.stop();

              animationControl.start({
                x: "-20%",
                transition: { duration: 4 },
              });
            });

          sendingMessagePayloadRef.current.isOpacityAnimationStarted = true;
        }, 1000);
      } else if (
        !lastLocalMessage &&
        sendingMessagePayloadRef.current.messageId &&
        sendingMessagePayloadRef.current.isOpacityAnimationStarted
      ) {
        animationControl.stop();

        if (!sendingMessagePayloadRef.current.isOpacityAnimationFinished) {
          animationControl.set({ opacity: 1 });
        }

        animationControl
          .start({ x: "0%", transition: { duration: 0.35 } })
          .then(() => {
            animationControl.stop();

            animationControl
              .start({ opacity: 0, transition: { duration: 0.35 } })
              .then(() => {
                sendingMessagePayloadRef.current.isOpacityAnimationStarted = false;
              });
          });
      }

      sendingMessagePayloadRef.current.messageId = lastLocalMessage?.uid;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [messageByChannelMap]
  );

  const selectChannelName = (state) =>
    state?.data?.channels?.[state?.data?.selectedChannel]?.name ?? "";

  const selectChannelMemberCount = (state) =>
    Object.keys(
      state?.data?.channels?.[state?.data?.selectedChannel]?.members ?? {}
    ).length;

  const channelMembercount = useSelector(selectChannelMemberCount);

  const memberList = useSelector((st) =>
    dataChannelsByIdMembersProfilesSelector(st, st?.data?.selectedChannel)
  );

  const lastActiveMap = useSelector(dataLastActiveMapSelector);
  const myId = useSelector(dataMyselfIdSelector);

  const renderLastActiveSection = useCallback(
    (profile) => {
      // User should always see it's own active session as 'Active now'
      if (profile?.uid === myId) {
        return <span>{getUserLastActiveStatusText(true)}</span>;
      }
      if (lastActiveMap?.[profile?.uid]) {
        return (
          <span>{getUserLastActiveStatusText(lastActiveMap[profile.uid])}</span>
        );
      }
      return null;
    },
    [lastActiveMap, myId]
  );

  const selectGroupName = (state) =>
    state?.data?.groups?.[state?.data?.selectedGroup]?.name ?? "";

  const selectIsSimpleGroup = (state) =>
    state?.data?.groups?.[state?.data?.selectedGroup]?.isSimple ?? false;

  const selectChannelEmoji = (state) =>
    state?.data?.channels?.[state?.data?.selectedChannel]?.emoji ?? "";

  const selectIsDmChannel = (state) =>
    state?.data?.channels?.[state?.data?.selectedChannel]?.isDM ?? false;

  const groupColor = useSelector((state) =>
    getGroupColor(state?.data?.groups?.[state?.data?.selectedGroup])
  );

  const selectOtherPersonName = (state) =>
    state?.data?.users[
      getOtherPersonUid(
        state?.data?.myself?.uid ?? "",
        state?.data?.selectedChannel ?? ""
      )
    ]?.name;

  const selectHeaderText = createSelector(
    selectChannelName,
    selectChannelEmoji,
    selectIsDmChannel,
    selectIsSimpleGroup,
    selectGroupName,
    selectOtherPersonName,
    (name, emoji, isDm, isSimpleGroup, groupName, otherPersonName) => {
      if (isDm) {
        return `${otherPersonName}`;
      } else if (isSimpleGroup) {
        return `${groupName}`;
      } else {
        return (
          <>
            {replaceSpecialEmoji(emoji)}&nbsp;{name}
          </>
        );
      }
    }
  );
  const headerText = useSelector(selectHeaderText);

  return (
    <>
      <HeaderContainer>
        {headerText}
        <MemberCountContainer
          ref={memberCountBlockRef}
          onClick={() => setIsMemberListVisible(true)}
          onMouseEnter={onMouseEnter}
        >
          <PeopleIcon />
          {channelMembercount}
        </MemberCountContainer>
        <AnimatePresence>
          {isMemberListVisible ? (
            <MemberListContextMenuOuterBlock
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ type: "spring", duration: 0.4, bounce: 0.1 }}
              onMouseLeave={onMouseLeave}
            >
              <MemberListContextMenuBlock>
                {memberList.map((profile) => {
                  return (
                    <MemberListContextMenuBlockItem key={profile.uid}>
                      <Squircle
                        userUid={profile.uid}
                        width={28}
                        fontSize={10}
                        style={{ margin: "2px 8px 0 0" }}
                      />
                      <MemberListContextMenuBlockItemInfo>
                        <MemberListContextMenuBlockItemInfoName>
                          {profile?.name}
                        </MemberListContextMenuBlockItemInfoName>
                        {renderLastActiveSection(profile)}
                      </MemberListContextMenuBlockItemInfo>
                    </MemberListContextMenuBlockItem>
                  );
                })}
              </MemberListContextMenuBlock>
            </MemberListContextMenuOuterBlock>
          ) : null}
        </AnimatePresence>
      </HeaderContainer>
      <motion.div
        animate={animationControl}
        style={{
          borderRadius: "12px",
          width: "100%",
          height: "4px",
          opacity: 0,
          x: "-100%",
          background: groupColor,
        }}
      />
    </>
  );
};

export default ChatHeader;

const HeaderContainer = styled.div`
  height: 58px;
  left: calc(50% - 1103px / 2 + 204.5px);
  width: 100%;

  background: rgba(255, 255, 255, 0.85);
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;

  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height, or 125% */

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.01em;

  /* UI Tones/Dark/Black */
  z-index: 3;
  flex-shrink: 0;

  color: #000000;
  // position: relative;
`;

const MemberCountContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 12px;
  gap: 8px;

  height: 30px;

  font-size: 16px
  line-height: 18px;

  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 100px;
  position: absolute;
  top: 14px;
  right: 16px;
  /* no select */
  user-select: none;
  color: #2a3437;

  &:hover {
    cursor: pointer;
  }
`;

const PeopleIcon = styled(PeopleSvg)`
  width: 23.4px;
  height: 15.3px;
  fill: #2a3437;
`;

const MemberListContextMenuOuterBlock = styled(motion.div)`
  position: absolute;
  top: 15px;
  right: 16px;
  padding: 35px 0 0 0;
  overflow: visible;
`;

const MemberListContextMenuBlock = styled.div`
  max-width: 220px;
  max-height: 350px;
  background: #ffffff;
  box-shadow: 0 0 10px 1px rgba(24, 39, 75, 0.15);
  border-radius: 20px;
  padding: 10px 0 10px 0;
  overflow-y: scroll;
  overflow-x: hidden;
`;

const MemberListContextMenuBlockItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 4px 8px;
  padding: 8px 8px;
  border-radius: 8px;
  text-wrap: nowrap;
  user-select: none;

  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
`;

const MemberListContextMenuBlockItemInfo = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
  white-space: nowrap;

  span {
    font-size: 10px;
    font-weight: 400;
    line-height: 10px;
    text-align: left;
    color: rgba(0, 0, 0, 0.5);
  }
`;

const MemberListContextMenuBlockItemInfoName = styled.p`
  width: 100%;
  font-size: 16px;
  text-overflow: ellipsis;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
`;
