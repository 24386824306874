import React, { FC } from "react";

import {
  NotificationPrimingActionBox,
  NotificationPrimingBlock,
  NotificationPrimingButton,
  NotificationPrimingContent,
  NotificationPrimingDescription,
  NotificationPrimingImg,
  NotificationPrimingTitle,
} from "./NotificationPriming.style";

type NotificationPrimingProps = {
  onAllow: () => void;
  onDecline: () => void;
};

export const NotificationPriming: FC<NotificationPrimingProps> = ({
  onAllow,
  onDecline,
}) => {
  return (
    <NotificationPrimingBlock
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ type: "spring", duration: 0.3, bounce: 0.1 }}
    >
      <NotificationPrimingContent>
        <NotificationPrimingImg
          src={require("../../assets/image/desktop-notification-priming.png")}
        />
        <NotificationPrimingTitle>
          Know when your friends text you!
        </NotificationPrimingTitle>
        <NotificationPrimingDescription>
          Allow notifications you don’t miss new messages or event invites from
          your friends.
        </NotificationPrimingDescription>
        <NotificationPrimingActionBox>
          <NotificationPrimingButton onClick={onDecline}>
            Maybe Later
          </NotificationPrimingButton>
          <NotificationPrimingButton isSubmit onClick={onAllow}>
            Allow Notifications
          </NotificationPrimingButton>
        </NotificationPrimingActionBox>
      </NotificationPrimingContent>
    </NotificationPrimingBlock>
  );
};
