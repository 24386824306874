import { createAsyncThunk } from "@reduxjs/toolkit";

import { getProfileScore } from "../../../firebase/user";

export const retrieveProfileScore = createAsyncThunk(
  "data/retrieveProfileScore",
  (userId: string) => {
    return getProfileScore(userId);
  }
);
