import { getDatabase, ref, get } from "firebase/database";

import { logDebug, logWarning } from "../../util/log.util";

export function getDesktopVersion() {
  const realtimeDb = getDatabase();

  return new Promise<string>((resolve, reject) => {
    get(ref(realtimeDb, "desktopVersionString"))
      .then((snapshot) => {
        if (!snapshot.exists()) {
          logDebug(`[getDesktopVersion] Snapshot doesn't exist`);

          return resolve({} as string);
        }

        const desktopAppVersion = snapshot.val() as string;

        resolve(desktopAppVersion);
      })
      .catch((error) => {
        logWarning(`[getDesktopVersion] Operation failed`, error);

        reject(error);
      });
  });
}
