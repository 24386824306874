import React from "react";
import styled from "styled-components";

import { ReactComponent as CameraSvg } from "../assets/camera.svg";
import { ReactComponent as EmojiPickerSvg } from "../assets/emojiPicker.svg";
import { ReactComponent as SendMessageSvg } from "../assets/sendMessage.svg";
import { ReactComponent as MakeMomentsHappenSvg } from "../assets/mmh.svg";
import { Title3Regular } from "../constants/DesignSystem";

const EmptyStateChat = (props) => {
  return (
    <MainContainer>
      <TopBar />
      <MakeMomentsHappen />
      <BottomBar>
        <Camera />
        <InputBar>
          Send a message...
          <EmojiPickerOpenButton />
        </InputBar>
        <SendMessage />
      </BottomBar>
    </MainContainer>
  );
};

export default EmptyStateChat;

const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  user-select: none;
`;

const TopBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding: 16px;
  gap: 16px;

  position: absolute;
  width: 100%;
  height: 62px;
  left: 0px;
  top: 0px;

  background: rgba(255, 255, 255, 0.85);
  /* Shadows/100 */

  box-shadow: 0px 2px 4px -2px rgba(24, 39, 75, 0.2);
`;

const BottomBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  gap: 8px;

  position: absolute;
  width: 100%;
  //   height: 81px;
  left: 0px;
  bottom: 0px;

  background: rgba(250, 250, 250, 0.85);
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

const InputBar = styled(Title3Regular)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 16px 12px 24px;

  height: 55px;
  flex-grow: 1;

  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 100px;
  color: #d1d1d6;
  position: relative;
`;

const EmojiPickerOpenButton = styled(EmojiPickerSvg)`
  width: 24px;
  height: 24px;
  fill: #d1d1d6;
  cursor: pointer;
  position: absolute;
  right: 16px;
  bottom: 14px;
`;

const Camera = styled(CameraSvg)`
  width: 27px;
  height: 21px;
  fill: #d1d1d6;
  margin: 9px;
`;

const SendMessage = styled(SendMessageSvg)`
  width: 27px;
  height: 21px;
  fill: #d1d1d6;
  margin: 9px;
`;

const MakeMomentsHappen = styled(MakeMomentsHappenSvg)`
  width: 422.8px;
  height: 229.6px;
  fill: #d1d1d6;
`;
