import ReactDOM from "react-dom";

export function getClientRect(nodeRef) {
  const clientRect = { x: 0, y: 0, width: 0, height: 0 };

  if (!nodeRef) {
    return clientRect;
  }

  const node = ReactDOM.findDOMNode(nodeRef);

  if (!node) {
    return clientRect;
  }

  const containerRect = node.getBoundingClientRect();

  if (!containerRect) {
    return clientRect;
  }

  clientRect.x = containerRect.x;
  clientRect.y = containerRect.y;
  clientRect.width = containerRect.width;
  clientRect.height = containerRect.height;

  return clientRect;
}
