import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import throttle from "lodash/throttle";
import { useSelector, useDispatch } from "react-redux";

import { LeftSidebar } from "../components/LeftSidebar";
import ChatContainer from "../components/ChatContainer";
import { EventDetails } from "../components/EventDetails";
import EmptyStateChat from "../components/EmptyStateChat";
import { addMyselfListener, setLoadTimestamp } from "../store/data";
import GlobalFonts from "../assets/fonts/fonts";
import { OneSignalProvider } from "../provider/OneSignalProvider";
import { setLastActive } from "../firebase/user";
import { logDebug } from "../util/log.util";
import { clearTypingIndicatorCacheOnDestroy } from "../firebase/channel/addTypingIndicator";

export const Mainpage = () => {
  const dispatch = useDispatch();
  const selectedChannel = useSelector((state) => state.data.selectedChannel);
  const selectedEvent = useSelector((state) => state.data.selectedEvent);

  useEffect(() => {
    dispatch(setLoadTimestamp(Date.now()));
    dispatch(addMyselfListener());
  }, [dispatch]);

  const pickMainContents = () => {
    if (selectedEvent) {
      return <EventDetails />;
    } else if (selectedChannel) {
      return <ChatContainer />;
    } else {
      return <EmptyStateChat />;
    }
  };

  const onPageClick = useRef(
    throttle(
      () => {
        setLastActive()
          .then(() => {
            logDebug("[Mainpage] The last active status has been updated");
          })
          .catch((ex) => {
            logDebug("[Mainpage] Couldn't update last active status");
          });
      },
      45000,
      { leading: true, trailing: false }
    )
  ).current;

  useEffect(() => {
    // Attempt to update last active status on every re-render
    onPageClick();
  });

  useEffect(() => {
    const setOfflineStatus = () => {
      const lastActiveDate = Date.now();

      // Set last digit to 0 to indicate the user is offline
      setLastActive(lastActiveDate - (lastActiveDate % 10));
    };

    window.addEventListener("beforeunload", setOfflineStatus);
    window.addEventListener("beforeunload", clearTypingIndicatorCacheOnDestroy);

    return () => {
      window.removeEventListener("beforeunload", setOfflineStatus);
      window.removeEventListener(
        "beforeunload",
        clearTypingIndicatorCacheOnDestroy
      );
    };
  }, []);

  return (
    <MainContainer onClick={onPageClick}>
      <OneSignalProvider>
        <>
          <GlobalFonts />
          <LeftSidebar></LeftSidebar>
          {pickMainContents()}
        </>
      </OneSignalProvider>
    </MainContainer>
  );
};

const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  background: #fafafa;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
