/**
 * Returns image information by URL or base64 content
 * @param {string} url The address or URL of the a media resource that is to be considered.
 * @returns {Promise<{ width: number; height: number; naturalWidth: number; naturalHeight: number }>} Image information
 */
export function getImageDimension(url) {
  // https://developer.mozilla.org/en-US/docs/Web/API/HTMLImageElement
  const image = new Image();

  image.src = url;

  return new Promise((resolve, reject) => {
    image.onload = () => {
      const { width, height, naturalWidth, naturalHeight } = image;

      resolve({ width, height, naturalWidth, naturalHeight });
    };

    image.onerror = () => {
      reject();
    };
  });
}
