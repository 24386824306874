import React, { FC, useMemo } from "react";

import { ColorId } from "dive/type/theme.type";
import { NewEventFormData } from "dive/store/form";

import { useNewEventModalFormData } from "../useNewEventModalFormData";

import { NewEventModalPoster } from "./NewEventModalPoster";

type NewEventModalPosterContainerProps = {};

export const NewEventModalPosterContainer: FC<
  NewEventModalPosterContainerProps
> = ({}) => {
  const {
    data,
    setData,
    meta: { initialData },
  } = useNewEventModalFormData();

  const { onImageRawContentChange, onEmojiChange, onColorIdChange } =
    useMemo(() => {
      return {
        onImageRawContentChange: (image: NewEventFormData["image"]) => {
          setData({ image });
        },
        onEmojiChange: (emoji: string) => {
          setData({ emoji });
        },
        onColorIdChange: (colorId: ColorId) => {
          setData({ colorId });
        },
      };
    }, [setData]);

  return (
    <NewEventModalPoster
      imageRawContent={data?.image?.rawContent || data?.image?.rawContent}
      emoji={data?.emoji || initialData.emoji}
      colorId={data?.colorId || initialData.colorId}
      onImageRawContentChange={onImageRawContentChange}
      onEmojiChange={onEmojiChange}
      onColorIdChange={onColorIdChange}
    />
  );
};
