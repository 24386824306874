import React, { FC, useCallback } from "react";

import { useDispatch } from "../../store";
import {
  setIsPermissionModalVisible,
  setNotificationPermissionRequestedAt,
  setNotificationPermissionState,
} from "../../store/application";
import {
  ALLOWED_NOTIFICATION_STATE,
  ALL_NOTIFICATION_MODE,
  NONE_NOTIFICATION_MODE,
  REJECTED_NOTIFICATION_STATE,
} from "../../constants/notification.constant";
import { setDesktopNotificationMode } from "../../firebase/user";
import { logDebug } from "../../util/log.util";
import analytics from "../../util/analytics";

import { NotificationPriming } from "./NotificationPriming";

type NotificationPrimingContainerProps = {};

export const NotificationPrimingContainer: FC<
  NotificationPrimingContainerProps
> = () => {
  const dispatch = useDispatch();

  const onAllow = useCallback(
    () => {
      analytics.track("desktop_notification_permission_decision", {
        decision: ALL_NOTIFICATION_MODE,
      });

      new Notification("Notification Permission Request");

      dispatch(setIsPermissionModalVisible(false));
      dispatch(setNotificationPermissionState(ALLOWED_NOTIFICATION_STATE));

      setDesktopNotificationMode(ALL_NOTIFICATION_MODE).catch((ex) => {
        logDebug(
          `[NotificationPrimingContainer] [onAllow] Cannot update desktop notification mode`,
          ex
        );
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const onDecline = useCallback(
    () => {
      analytics.track("desktop_notification_permission_decision", {
        decision: NONE_NOTIFICATION_MODE,
      });

      dispatch(setIsPermissionModalVisible(false));
      dispatch(setNotificationPermissionState(REJECTED_NOTIFICATION_STATE));
      dispatch(setNotificationPermissionRequestedAt(Date.now()));

      setDesktopNotificationMode(NONE_NOTIFICATION_MODE).catch((ex) => {
        logDebug(
          `[NotificationPrimingContainer] [onDecline] Cannot update desktop notification mode`,
          ex
        );
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return <NotificationPriming onAllow={onAllow} onDecline={onDecline} />;
};
