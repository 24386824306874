import React, { useEffect, memo } from "react";
import styled, { css, keyframes } from "styled-components";
import SuperEllipse from "react-superellipse";
import fillerAvatar from "../assets/filler/avatar2.png";
import { getInitialsGroup, replaceSpecialEmoji } from "../util";
import { useSelector, useDispatch } from "react-redux";
import { addUserListener } from "../store/data";
import Theme, { newColorPalette, useTheme } from "../constants/Theme";
import { createSelector } from "@reduxjs/toolkit";
import { dataLastActiveByUserIdAndPermissionSelector } from "../store/data";
import { getIsUserActiveStatus } from "../util/user.util";

const avatarColors = Object.values(newColorPalette);

const getInitialsString = (name) => {
  if (!name) {
    return "";
  }
  const nameWords = name.toUpperCase().split(" ");
  if (nameWords.length === 1) {
    return nameWords[0][0];
  } else if (nameWords.length === 2) {
    return nameWords.map((word) => word[0]).join("");
  } else {
    return nameWords[0][0] + nameWords[nameWords.length - 1][0];
  }
};

const Squircle = (props) => {
  const dispatch = useDispatch();
  const {
    width,
    groupObj,
    color,
    userUid,
    channelEmoji,
    channelEmojiBg,
    channelEmojiSize,
    loading,
    indicator,
    style,
    innerRef,
    onMouseEnter,
    onMouseLeave,
    onClick,
  } = props;

  const userName = useSelector((state) =>
    userUid && userUid.length > 0 ? state?.data?.users?.[userUid]?.name : null
  );
  const userAvatarMd = useSelector((state) =>
    userUid && userUid.length > 0
      ? state?.data?.users?.[userUid]?.avatarMD
      : null
  );

  const lastActiveDate = useSelector((st) =>
    dataLastActiveByUserIdAndPermissionSelector(st, userUid)
  );

  const isActive = getIsUserActiveStatus(lastActiveDate);

  const key = "avatarContainer:" + groupObj?.uid ? groupObj?.uid : userUid;
  // console.log(channelEmoji);

  useEffect(
    () => {
      if (userUid) {
        dispatch(addUserListener(props?.userUid));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const activeIndicatorSizePx = width > 37 ? 13 : width > 24 ? 10 : 8;

  return (
    <EllipseContainer
      ref={innerRef}
      isClickable={!!onClick}
      isAutomatedMessage={!!props.automatedMessageEmoji}
      style={{ width, height: width, ...(style ? style : {}) }}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <SuperEllipse
        r1={0.1}
        r2={0.35}
        style={{ width, height: width, flexShrink: 0, position: "relative" }}
        key={key}
      >
        {InsideSquircle(props, userName, userAvatarMd, channelEmoji, loading)}
        {indicator}
      </SuperEllipse>
      {isActive ? <OnlineIndicator sizePx={activeIndicatorSizePx} /> : null}
    </EllipseContainer>
  );
};

const InsideSquircle = (
  props,
  userName,
  userAvatarMd,
  channelEmoji,
  loading
) => {
  if (loading) {
    return (
      <ShimmerFittedRectangle
        color="rgba(0,0,0,0.1)"
        width={props?.width}
        key={loading}
      >
        {getInitialsString(userName)}
      </ShimmerFittedRectangle>
    );
  } else if (channelEmoji) {
    return (
      <FittedEmoji
        color={props.color}
        $bgColor={props.channelEmojiBg}
        $fontSize={props.channelEmojiFontSize || props.channelEmojiSize}
        $lineHeight={props.channelEmojiLineHeight}
      >
        {replaceSpecialEmoji(channelEmoji, props.channelEmojiSize || 32)}
      </FittedEmoji>
    );
  } else if (userName) {
    if (userAvatarMd) {
      return (
        <FittedImage
          src={userAvatarMd}
          alt="profile"
          style={{
            width: "100%",
            height: "100%",
          }}
          key={userAvatarMd}
        ></FittedImage>
      );
    } else {
      return (
        <FittedRectangle
          color={avatarColors[(userName?.length ?? 0) % avatarColors.length]}
          width={props?.width}
          fontSize={props?.fontSize}
          borderWidth={props?.borderWidth}
          key={userName}
        >
          {getInitialsString(userName)}
        </FittedRectangle>
      );
    }
  } else if (props?.groupObj) {
    if (props?.groupObj?.avatarMD) {
      return (
        <FittedImage
          src={props?.groupObj?.avatarMD}
          alt=""
          style={{
            width: "100%",
            height: "100%",
          }}
          key={props?.groupObj?.avatarMD}
        ></FittedImage>
      );
    } else if (props?.groupObj?.emoji) {
      return (
        <FittedRectangle
          color={props.color}
          emoji={props?.groupObj.emoji}
          width={props?.width}
          key={"groupAvatar" + props?.groupObj?.uid}
        >
          {replaceSpecialEmoji(props?.groupObj.emoji, 36)}
        </FittedRectangle>
      );
    } else {
      return (
        <FittedRectangle
          color={props.color}
          width={props?.width}
          key={"groupAvatar" + props?.groupObj?.uid}
        >
          {getInitialsGroup(props?.groupObj)}
        </FittedRectangle>
      );
    }
  } else if (props.automatedMessageEmoji) {
    return (
      <AutomatedMessageEmoji sizePx={props.width}>
        {replaceSpecialEmoji(props.automatedMessageEmoji, 32)}
      </AutomatedMessageEmoji>
    );
  }

  return <ShimmerFittedRectangle width={props?.width} key={loading} />;
};

export default memo(Squircle);

const keyframesShimmer = keyframes`
  0% {
    background-position: -80vw 0;
  }
  100% {
    background-position: 80vw 0;
  }
`;

const shimmerAnimation2 = css`
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.03) 4%,
    rgba(0, 0, 0, 0.05) 25%,
    rgba(0, 0, 0, 0.1) 36%
  );
  background-size: 80vw 100%;
  animation: ${keyframesShimmer} 3s infinite linear;
`;

const FittedImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const AutomatedMessageEmoji = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  line-height: 34px;
  background: #ffffff;

  width: ${({ sizePx }) => sizePx}px;
  height: ${({ sizePx }) => sizePx}px;
`;

const FittedRectangle = styled.div`
  width: 100%;
  height: 100%;
  background: ${(props) => props?.color};
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 700;
  font-size: 22px;
  ${(props) => props?.emoji && `font-size: 30px;`}
  ${(props) => props?.width === 64 && `font-size: 30px;`}
  ${(props) => props?.width === 34 && `font-size: 16px;`}
  ${(props) => props?.width === 38 && `font-size: 18px;`}
  ${(props) => props?.width === 21 && `font-size: 10px;`}
  ${(props) => props?.width === 21 && `font-size: 10px;`}
  ${(props) => props?.fontSize && `font-size: ${props.fontSize}px;`}
  ${(props) =>
    props?.borderWidth &&
    `border: ${props.borderWidth}px solid rgba(0, 0, 0, 0.1);`}
  line-height: 25px;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;

  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
  pointer-events: none;
`;

const FittedEmoji = styled(FittedRectangle)`
  font-size: ${({ $fontSize }) => $fontSize || "30px"};
  line-height: ${({ $lineHeight }) => $lineHeight || "30px"};
  background: ${({ $bgColor }) => $bgColor || "rgba(0, 0, 0, 0.05)"};
  border-radius: 1000px;
`;

const ShimmerFittedRectangle = styled(FittedRectangle)`
  background: rgba(0, 0, 0.1);
  ${shimmerAnimation2}
`;

const EllipseContainer = styled.div`
  position: relative;

  ${({ isAutomatedMessage }) =>
    isAutomatedMessage
      ? "border-radius: 12px; box-shadow: 0px 0px 2px rgba(0, 0, 0, .1);"
      : ""}

  ${({ isClickable }) =>
    isClickable
      ? `
  &:hover {
    cursor: pointer;
  }
  `
      : ""}
`;

const OnlineIndicator = styled.div`
  position: absolute;
  width: ${({ sizePx }) => sizePx}px;
  height: ${({ sizePx }) => sizePx}px;
  border: 2px solid #ffffff;
  border-radius: 50%;
  background-color: #34c759;
  right: -1px;
  bottom: -1px;
`;
