export function getCaretCoordinates() {
  let x = 0;
  let y = 0;
  let width = 0;
  let height = 0;

  const isSupported = typeof window.getSelection !== "undefined";

  if (isSupported) {
    const selection = window.getSelection();

    if (selection.rangeCount !== 0) {
      const range = selection.getRangeAt(0).cloneRange();

      range.collapse(true);

      const rect = range.getClientRects()[0];

      if (rect) {
        x = rect.left;
        y = rect.top;
        width = rect.width;
        height = rect.height;
      }
    }
  }

  return { x, y, width, height };
}

export function getCaretIndex() {
  const sel = document.getSelection();

  try {
    sel.modify("extend", "backward", "paragraphboundary");
  } catch (e) {
    console.log(e);
  }

  const pos = sel.toString().length;

  if (sel.anchorNode) {
    sel.collapseToEnd();
  }

  return pos;
}
