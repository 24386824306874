import axios from "axios";
import { getDatabase } from "firebase/database";
import { getAuth } from "firebase/auth";

import { logDebug } from "../../util/log.util";

type GenerateAiEventPosterPayload = {
  eventId: string;
  saveBackgroundUri: boolean;
};

export async function generateAiEventPoster({
  eventId,
  saveBackgroundUri,
}: GenerateAiEventPosterPayload) {
  const realtimeDb = getDatabase();
  const authSession = getAuth();

  const projectId = realtimeDb.app.options.projectId;

  if (!projectId) {
    logDebug("[generateAiEventPoster] Cannot retrieve Firebase project ID");

    return;
  }

  const idToken = await authSession.currentUser?.getIdToken();

  if (!idToken) {
    logDebug(
      "[generateAiEventPoster] Cannot retrieve an ID token of current user"
    );

    return;
  }

  const config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
      "Content-Type": "application/json",
    },
  };

  return axios
    .post(
      `https://us-central1-${projectId}.cloudfunctions.net/generateEventAIPoster`,
      {
        eventUid: eventId,
        saveBackgroundUri,
      },
      config
    )
    .then((response) => response.data)
    .catch((error) => {
      logDebug(
        "[generateAiEventPoster] Error while generating AI event poster.",
        error
      );
    });
}
