import { getDatabase, update, ref } from "firebase/database";

type InviteToEventPayload = {
  userId: string;
  eventId: string;
};

export function inviteToEvent({ userId, eventId }: InviteToEventPayload) {
  const realtimeDb = getDatabase();

  const updates = {
    [`users/${userId}/events/${eventId}`]: 0,
  };

  return update(ref(realtimeDb), updates);
}
