import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { EmojiClickData } from "emoji-picker-react";

// @ts-expect-error
import { ReactComponent as EmojiPickerSvg } from "dive/assets/emoji-picker.svg";
// @ts-expect-error
import { ReactComponent as PenSvg } from "dive/assets/pen.svg";
import { EmojiPicker } from "dive/components/EmojiPicker";

import {
  NewSubchatModalEmojiBadge,
  NewSubchatModalEmojiBlock,
  NewSubchatModalEmojiButton,
  NewSubchatModalEmojiPicker,
} from "./NewSubchatModalEmoji.style";
import Squircle from "dive/components/Squircle";

type NewSubchatModalEmojiProps = {
  emoji: string;
  color: string;
  onEmojiChange: (emoji: string) => void;
};

export const NewSubchatModalEmoji: FC<NewSubchatModalEmojiProps> = ({
  emoji,
  color,
  onEmojiChange,
}) => {
  const [isEmojiPickerVisible, setIsEmojiPickerVisible] = useState(false);

  const emojiPickerRef = useRef<HTMLDivElement>(null);
  const editPickerButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const onClickOutside: EventListener = (event) => {
      if (
        // @ts-expect-error
        !editPickerButtonRef.current?.contains(event.target) &&
        // @ts-expect-error
        !emojiPickerRef.current?.contains(event.target)
      ) {
        setIsEmojiPickerVisible(false);
      }
    };

    document.addEventListener("mousedown", onClickOutside);

    return () => {
      document.removeEventListener("mousedown", onClickOutside);
    };
  }, []);

  const onEmojiClick = useCallback(
    (emojiData: EmojiClickData) => {
      // @ts-expect-error
      onEmojiChange(emojiData.id || emojiData.emoji);

      setIsEmojiPickerVisible(false);
    },
    [onEmojiChange]
  );

  return (
    <NewSubchatModalEmojiBlock>
      <NewSubchatModalEmojiButton
        ref={editPickerButtonRef}
        onClick={() => setIsEmojiPickerVisible(true)}
      >
        {emoji ? (
          <Squircle
            channelEmoji={emoji}
            channelEmojiBg="transparent"
            channelEmojiSize={52}
            channelEmojiFontSize="44px"
            channelEmojiLineHeight="52px"
          />
        ) : (
          <EmojiPickerSvg />
        )}
        <NewSubchatModalEmojiBadge $bg={color}>
          <PenSvg />
        </NewSubchatModalEmojiBadge>
      </NewSubchatModalEmojiButton>
      {isEmojiPickerVisible ? (
        <NewSubchatModalEmojiPicker ref={emojiPickerRef}>
          <EmojiPicker
            isCustomEmojiVisible
            autoFocusSearch
            lazyLoadEmojis
            width={280}
            height={320}
            previewConfig={{ showPreview: false }}
            onEmojiClick={onEmojiClick}
          />
        </NewSubchatModalEmojiPicker>
      ) : null}
    </NewSubchatModalEmojiBlock>
  );
};
