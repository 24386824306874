import { useRef, useState } from "react";
import isEqual from "lodash/isEqual";
import cloneDeep from "lodash/cloneDeep";
import isObject from "lodash/isObject";

type UseInitialPayloadBase =
  | number
  | string
  | boolean
  | Record<string, unknown>;

export function useInitialPayload<T extends UseInitialPayloadBase>(payload: T) {
  const [initialValue, setInitialValue] = useState(
    isObject(payload) ? cloneDeep(payload) : payload
  );

  const hasChangedValue = !isEqual(initialValue, payload);

  const hasChangedRef = useRef(hasChangedValue);

  // Duplicate the value in a ref so it can be accessed right away.
  // Useful when you need the data but can't wait for the next render.
  hasChangedRef.current = hasChangedValue;

  return {
    initialValue: initialValue,
    hasChanged: hasChangedValue,
    hasChangedRef,
    setInitialValue,
  };
}
