import React, { FC, useCallback } from "react";

import { useIsBrowser } from "dive/hook/useIsBrowser";
import { useDispatch, useSelector } from "dive/store";
import { setIsPermissionModalVisible } from "dive/store/application";
import { dataMyselfSelector } from "dive/store/data";
import { ReactComponent as BellCrossedSvg } from "dive/assets/bell-crossed.svg";

import { SidebarBadge } from "./SidebarBadge";

type NotificationPermissionBadgeProps = {};

export const NotificationPermissionBadge: FC<
  NotificationPermissionBadgeProps
> = ({}) => {
  const dispatch = useDispatch();

  const isBrowser = useIsBrowser();

  const profileData = useSelector(dataMyselfSelector);

  const onClick = useCallback(
    () => {
      dispatch(setIsPermissionModalVisible(true));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <SidebarBadge
      isWarning
      isVisible={!isBrowser && profileData?.desktopAppNotifications === "NONE"}
      title="You’re missing messages"
      description="Turn on notifications!"
      icon={<BellCrossedSvg />}
      onClick={onClick}
    />
  );
};
