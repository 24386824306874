import React, { FC, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import analytics from "../../util/analytics";
import { createAiEvent } from "../../firebase/event/createAiEvent";
import { addEventListener } from "../../store/data";
import { setAiEventCreated, shareEventWithGroups } from "../../firebase/event";
import { dataSelectedChannelIdSelector } from "../../store/data";
import { CanvasBackgroundImage } from "../../type/canvas.type";
import { incrementBgDownloadCount } from "../../util/event.util";
import { logDebug } from "../../util/log.util";

import { AiEventSuggestion } from "./AiEventSuggestion";

type AiEventSuggestionContainerProps = {
  isDismissed: boolean;
  messageId: string;
  title: string;
  emoji: string;
  time: string;
  date: number;
  bgImage: CanvasBackgroundImage;
  onDismiss: () => void;
};

export const AiEventSuggestionContainer: FC<
  AiEventSuggestionContainerProps
> = ({
  isDismissed,
  messageId,
  title,
  emoji,
  date,
  time,
  bgImage,
  onDismiss,
}) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const selectedChannelUid = useSelector(dataSelectedChannelIdSelector);

  const onCreateEvent = useCallback(
    async () => {
      setIsLoading(true);

      if (bgImage?.photoId) {
        await incrementBgDownloadCount({ photoId: bgImage.photoId });
      }

      analytics.track("create_event_from_ai_tile", {
        title,
        emoji,
        date,
        time,
        messageId,
        channelUid: selectedChannelUid,
      });

      const eventData = await createAiEvent({
        title,
        emoji,
        date,
        bgImage,
      });

      if (!eventData) {
        // TODO: Log error to Sentry
        return;
      }

      // @ts-expect-error
      dispatch(addEventListener(eventData.uid));

      // Wait 1.5 seconds for client to load in event object data
      await new Promise((resolve) => {
        setTimeout(resolve, 1500);
      });

      // Hides AI Event tile for other users in the chat
      await setAiEventCreated({
        channelId: selectedChannelUid,
        messageId: messageId,
      });

      await shareEventWithGroups({
        channelIds: [selectedChannelUid],
        eventId: eventData.uid,
      });

      setIsLoading(false);

      logDebug(
        "[AiEventSuggestionContainer] AI Suggestion event has been created",
        eventData
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [messageId, selectedChannelUid]
  );

  const onDismissMessage = useCallback(
    () => {
      analytics.track("dismiss_event_from_ai_tile", {
        messageId,
        title,
        emoji,
        date,
        time,
      });

      onDismiss();

      logDebug(
        "[AiEventSuggestionContainer] AI Event Suggestion has been dismissed",
        { messageId }
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [messageId]
  );

  if (isDismissed) {
    return null;
  }

  return (
    <AiEventSuggestion
      title={title}
      emoji={emoji}
      date={date}
      isLoading={isLoading}
      onCreateEvent={onCreateEvent}
      onDismiss={onDismissMessage}
    />
  );
};
