import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAuth } from "firebase/auth";
import {
  getDatabase,
  ref,
  onValue,
  query,
  limitToLast,
  onChildAdded,
  endBefore,
  get,
  orderByKey,
} from "firebase/database";

import fb from "../../firebase/firebaseCalls.js";
import { addDesktopVersionListener } from "../application/thunk";

import { dataLastActiveByUserIdSelector } from "./data.selector";
import {
  retrieveProfileScore,
  retrieveGroupData,
  addGroupListener,
  retrieveProfileSharedData,
  addCustomEmojiMapListener,
} from "./thunk";

const db = getDatabase();
const auth = getAuth();

export const dataInitialState = {
  //all of these mirror the backend DB with entries we'd be able to see
  groups: {},
  channels: {},
  messages: {},
  previewMessages: {},
  groupLastMessage: {},
  //difference from mobile, we don't index by channel here
  comments: {},
  events: {},
  polls: {},
  hangs: {},
  phoneMap: {},
  users: {},
  galleries: {},
  // maps channelUid -> false if there are no more messages to load
  noMoreMessages: {},
  //the reacts and comments for individual profiles
  profileScores: {},
  myself: {
    dmChannels: {},
    channels: {},
    pinned: {},
    pinnedGroups: {},
    groups: {},
    bools: {},
  },
  defaultGroups: {},
  visibleGroups: {},
  groupProfiles: {},
  loadingMoreMessages: false,
  emptyGroup: {
    name: "",
    isPublic: false,
    isDefault: false,
    isMuted: false,
    color: "#2E77D8",
    members: {},
    channels: {},
    admins: {},
    emojis: { 0: "👍", 1: "👎", 2: "\u2764", 3: "😡", 4: "😂" },
  },
  emptyChannel: {
    name: "",
    details: "",
    createdBy: "",
    isDefault: false,
    isDM: false,
    isPublic: false,
    isAdminPost: false,
    members: {},
    typing: {},
    uid: -1,
    recent: -1,
    isAdminChannel: false,
    carouselPermission: 0,
  },
  emptyEvent: {
    title: "",
    creator: "",
    date: "",
    startTime: "",
    endTime: "",
    private: false,
    allowMaybes: true,
    avatar: "",
    location: "",
    description: "",
    type: "custom",
    responses: {},
    uid: "",
  },
  //these are null instead of -1 which is a difference from mobile
  selectedEvent: null,
  // selectedEvent: "-NAQfzRP-pG_T1gDp2n2",
  selectedGroup: null,
  selectedChannel: null,
  selectedGroupSidebar: null,
  // array of UIDs
  selectedSidebar: [],
  //web only
  isNestedSidebarOpen: false,
  //web only
  isEventsSidebarOpen: false,
  //sidebar before events
  sidebarBeforeEvents: "groups",
  discoverableEvents: {},
  oldestUnreads: {},
  lastSelectedMessage: -1, //uid
  numMessages: 0,
  eventUpdates: {},
  recents: {},
  lastViewed: {},
  //unique to web
  //null if not commenting at all, otherwise {commentingOnComment: true, commentUid: "...", messageUid: "..."}
  isCommenting: null,
  radarPeople: {},
  numUnseenUpdates: 0,
  userLoggedIn: false,
  systemNotificationsEnabled: true,
  drafts: {},
  listenerMap: {},
  groupSearchQuery: "",
  //web app only, used to have fixed time loading state minimums
  loadTimestamp: 1000000000000000,
  lastActiveMap: {},

  //
  //  See `CustomEmoji` type in `dive/types/custom-emoji.type.ts` file
  //
  // {
  //   [groupId]: {
  //     [emojiId]: {
  //       createdAt: number;
  //       creatorUid: string;
  //       emojiUid: string;
  //       imageUrl: string;
  //      }
  //   }
  // }
  //
  customEmojiMap: {},
};

const addChannelListener = (
  meSnapshot,
  myUid,
  channelUid,
  thunkAPI,
  state,
  isDm,
  dmUserUid
) => {
  //if we're not actually in the channel, don't add listeners
  if (
    typeof meSnapshot?.channels?.[channelUid] === "undefined" &&
    isDm &&
    typeof meSnapshot?.dmChannels[dmUserUid] === "undefined"
  )
    return null;
  //don't add redundant listeners
  if (state.data.listenerMap[channelUid]) return null;
  //listen to the channel obj itself
  onValue(ref(db, `channels/${channelUid}`), (channelSnapshot) => {
    thunkAPI.dispatch(setChannel(channelSnapshot.val()));
  });
  //listen to last viewed for the channel
  onValue(
    ref(db, `lastViewed/${myUid}/channels/${channelUid}`),
    (channelSnapshot) => {
      thunkAPI.dispatch(
        setLastViewed({
          channelUid: channelSnapshot.key,
          timestamp: channelSnapshot.val(),
        })
      );
    }
  );
  //listen to only the newly added messages, limit to last 1 ensures that on setup,
  //only one message is loaded
  const messageRef = query(ref(db, `messages/${channelUid}`), limitToLast(5));
  onChildAdded(messageRef, (messageSnapshot) => {
    if (messageSnapshot.exists()) {
      addMessageListener(
        meSnapshot,
        myUid,
        channelUid,
        messageSnapshot.val()?.uid,
        messageSnapshot.val()?.user?.uid,
        thunkAPI,
        state
      );
    }
  });
};
//noMoreMessages
export const loadMoreMessages = createAsyncThunk(
  "data/loadMoreMessages",
  async (channelUid, thunkAPI) => {
    if (!channelUid) {
      return Promise.reject(`The ID of the channel is not defined`);
    }

    const state = thunkAPI.getState();

    if (state?.data?.noMoreMessages?.[channelUid] === true) {
      return Promise.reject(`No more messages exist`);
    }

    const sortedMessages = Object.values(
      state?.data?.messages?.[channelUid]
    )?.sort((a, b) => {
      return a.createdAt - b.createdAt;
    });

    const messageRef = query(
      ref(db, `messages/${channelUid}`),
      limitToLast(3),
      orderByKey(),
      endBefore(sortedMessages[0]?.uid)
    );

    await get(messageRef).then((snapshot) => {
      if (snapshot.exists()) {
        const messages = Object.values(snapshot.val());
        messages.forEach((message) => {
          thunkAPI.dispatch(
            setMessage({
              channelUid: channelUid,
              messageObj: message,
            })
          );

          addMessageListener(
            state?.data?.myself,
            state?.data?.myself?.uid,
            channelUid,
            message?.uid,
            message?.user?.uid,
            thunkAPI,
            state
          );
        });
      } else {
        thunkAPI.dispatch(setNoMoreMessages(channelUid));
      }
    });

    return Promise.resolve();
  }
);

const addMessageListener = (
  meSnapshot,
  myUid,
  channelUid,
  messageUid,
  messageSenderUid,
  thunkAPI,
  state
) => {
  if (meSnapshot?.blocked?.[messageSenderUid]) {
    return null;
  }

  if (state.data.listenerMap?.[messageUid]) {
    return null;
  }

  onValue(
    ref(db, `messages/${channelUid}/${messageUid}`),
    (messageSnapshot) => {
      const messageData = messageSnapshot.val() || {};

      // Using internal undocumented Firebase's APIs to get sync status info
      const pendingServerDbWrites =
        messageSnapshot.ref?._repo?.serverSyncTree_?.pendingWriteTree_
          ?.allWrites || [];

      const isServerDbWritePending = !!pendingServerDbWrites.find(
        (dbWriteOperation) => {
          const pathList = Object.keys(dbWriteOperation?.children || {}) || [];

          return pathList.includes(
            `messages/${channelUid}/${messageData?.uid}`
          );
        }
      );

      thunkAPI.dispatch(
        setMessage({
          channelUid: channelUid,
          messageObj: messageData
            ? {
                ...messageData,
                ...(isServerDbWritePending ? { localMessage: true } : {}),
              }
            : null,
        })
      );
    }
  );
};

// const addCommentListener = (channelUid, messageUid) => {
//   if (meSnapshot?.blocked?.[messageSenderUid]) return null;
//   if (state.data.listenerMap?.[messageUid]) return null;
//   onValue(
//     ref(db, `messages/${channelUid}/${messageUid}`),
//     (messageChangedsnapshot) => {
//       thunkAPI.dispatch(
//         setMessage({
//           channelUid: channelUid,
//           messageObj: messageChangedsnapshot.val(),
//         })
//       );
//     }
//   );
// };

export const addCommentListener = createAsyncThunk(
  "data/addCommentListener",
  async (payload, thunkAPI) => {
    const { channelUid, commentUid, parentMessageUid } = payload;
    const state = thunkAPI.getState();
    const meSnapshot = state.data.myself;

    if (state?.data?.listenerMap?.messageUid) {
      return Promise.reject("The listener already exists");
    }

    onValue(
      ref(db, `comments/${channelUid}/${parentMessageUid}/${commentUid}`),
      (snapshot) => {
        if (meSnapshot?.blocked?.[snapshot.val()?.user?.uid]) {
          return null;
        }

        thunkAPI.dispatch(
          setComment({
            channelUid: channelUid,
            parentMessageUid: parentMessageUid,
            messageObj: snapshot.val(),
          })
        );
      }
    );

    return Promise.resolve();
  }
);

export const addMyselfNestedListeners = createAsyncThunk(
  "data/ADD_MYSELF_NESTED_LISTENERs",
  (snapshot, thunkAPI) => {
    const state = thunkAPI.getState();

    const myUid = snapshot.val()?.uid;

    // Add all the channel listeners for the user
    Object.keys(snapshot.val()?.channels).forEach((channelUid) => {
      if (state.data.listenerMap[channelUid]) {
        // logDebug(
        //   `[addMyselfNestedListeners] The channel listener already exists, skipping`,
        //   {
        //     channelUid,
        //   }
        // );

        return;
      }

      addChannelListener(
        snapshot.val(),
        myUid,
        channelUid,
        thunkAPI,
        state,
        false,
        ""
      );
    });

    // Add all the DM channel listeners
    Object.values(snapshot.val()?.dmChannels).forEach((channelUid, idx) => {
      if (state.data.listenerMap[channelUid]) {
        // logDebug(
        //   `[addMyselfNestedListeners] The DM channel listener already exists, skipping`,
        //   {
        //     channelUid,
        //     idx,
        //   }
        // );

        return;
      }

      addChannelListener(
        snapshot.val(),
        myUid,
        channelUid,
        thunkAPI,
        state,
        true,
        Object.keys(snapshot.val()?.dmChannels)[idx]
      );
    });

    // Add all the event listeners
    Object.keys(snapshot.val()?.events).forEach((eventUid, idx) => {
      if (state.data.listenerMap[eventUid]) {
        // logDebug(
        //   `[addMyselfNestedListeners] The event listener already exists, skipping`,
        //   {
        //     eventUid,
        //     idx,
        //   }
        // );

        return;
      }

      thunkAPI.dispatch(addEventListener(eventUid));
    });
  }
);

export const addMyselfListener = createAsyncThunk(
  "data/ADD_MYSELF_LISTENER",
  (_, thunkAPI) => {
    const state = thunkAPI.getState();

    // If this function has been called before, don't add another `myself` listener
    if (state?.data?.listenerMap?.["myself"]) {
      return Promise.reject();
    }

    const myUid = auth.currentUser?.uid;

    // Called every time the myself object changes.
    // Would be more efficient to only listen to the changes on specific child elements.
    // All the "myself" obj data is reloaded each time, oh well
    onValue(ref(db, `users/${myUid}`), (snapshot) => {
      thunkAPI.dispatch(setMyself(snapshot.val()));

      thunkAPI.dispatch(addMyselfNestedListeners(snapshot));
    });

    return Promise.resolve();
  }
);

export const addUserListener = createAsyncThunk(
  "data/ADD_USER_LISTENER",
  async (userId, { getState, dispatch }) => {
    const state = getState();

    if (state?.data?.listenerMap?.[userId]) {
      return Promise.reject("The listener already exists");
    }

    onValue(ref(db, `users/${userId}/sharedData`), (snapshot) => {
      if (snapshot.exists()) {
        dispatch(setUser(snapshot.val()));
      }
    });

    onValue(ref(db, `lastActive/${userId}`), (snapshot) => {
      if (!snapshot.exists()) {
        return;
      }

      const lastActiveDate = snapshot.val();

      const localLastActiveDate =
        dataLastActiveByUserIdSelector(getState(), userId) || 0;

      if (localLastActiveDate < lastActiveDate) {
        dispatch(setLastActiveByUserId({ userId, lastActiveDate }));
      }
    });

    return Promise.resolve();
  }
);

export const addPollListener = createAsyncThunk(
  "data/addPollListener",
  async (pollUid, thunkAPI) => {
    const state = thunkAPI.getState();
    if (state?.data?.listenerMap?.[pollUid]) return new Promise();
    onValue(ref(db, `polls/${pollUid}`), (snapshot) => {
      if (snapshot.exists()) {
        thunkAPI.dispatch(setPoll(snapshot.val()));
      }
    });
    return new Promise();
  }
);

export const addEventListener = createAsyncThunk(
  "data/ADD_EVENT_LISTENER",
  (eventId, { getState, dispatch }) => {
    const state = getState();

    if (state?.data?.listenerMap?.[eventId]) {
      return Promise.reject();
    }

    onValue(ref(db, `events/${eventId}`), (snapshot) => {
      if (snapshot.exists()) {
        dispatch(setEvent(snapshot.val()));
      }
    });

    return Promise.resolve();
  }
);

// export const template = createAsyncThunk(
//   "data/fetchTodos",
//   async (eventUid, thunkAPI) => {
//     const state = thunkAPI.getState();
//     // if (state?.data?.listenerMap?.eventUid) return new Promise();
//     // onValue(ref(db, `events/${eventUid}`), (snapshot) => {
//     //   if (snapshot.exists()) {
//     //     thunkAPI.dispatch(setEvent(snapshot.val()));
//     //   }
//     // });
//     return new Promise();
//   }
// );

export const selectChannel = createAsyncThunk(
  "data/selectChannel",
  async (channelUid, { dispatch }) => {
    fb.setLastViewedChannels(channelUid);

    dispatch(setSelectedChannel(channelUid));

    // if (state?.data?.listenerMap?.eventUid) return new Promise();
    // onValue(ref(db, `events/${eventUid}`), (snapshot) => {
    //   if (snapshot.exists()) {
    //     thunkAPI.dispatch(setEvent(snapshot.val()));
    //   }
    // });
    return Promise.resolve();
  }
);

export const dataReducerName = "data";

const dataSlice = createSlice({
  name: dataReducerName,
  initialState: dataInitialState,
  reducers: {
    setEvent(state, action) {
      // console.log("setting event");
      const eventObj = action.payload;
      state.events[eventObj.uid] = eventObj;
      state.listenerMap[eventObj.uid] = true;
    },
    setMyself(state, action) {
      // console.log("setting myself");
      state.myself = action.payload;
      state.listenerMap["myself"] = true;
    },
    setUser(state, action) {
      // console.log("setting user");
      state.users[action.payload.uid] = action.payload;
      state.listenerMap[action?.payload?.uid] = true;
    },
    setIsCommenting(state, action) {
      state.isCommenting = action.payload;
    },
    setGroup(state, action) {
      // console.log("setting group");
      state.groups[action.payload?.uid] = action.payload;
      state.listenerMap[action.payload?.uid] = true;
    },
    setChannel(state, action) {
      // console.log("setting channel");
      state.channels[action.payload?.uid] = action.payload;
      state.listenerMap[action.payload?.uid] = true;
    },
    setLastViewed(state, action) {
      // console.log("setting last viewed");
      state.lastViewed[action.payload?.channelUid] = action.payload?.timestamp;
    },
    setMessage(state, action) {
      // console.log("setting message");
      const channelUid = action.payload?.channelUid;
      const messageObj = action.payload?.messageObj;
      messageObj.channelUid = channelUid;
      if (!state?.messages?.[channelUid]) {
        state.messages[channelUid] = {};
        state.previewMessages[channelUid] = {};
      }
      state.messages[channelUid][messageObj?.uid] = messageObj;
      //special collection in redux purely for the most recent mesage
      if (!messageObj?.isNewUserMessage && !messageObj?.isRemoveUserMessage) {
        state.previewMessages[channelUid][messageObj?.uid] = messageObj;
      }
      state.listenerMap[messageObj?.uid] = true;
    },
    setComment(state, action) {
      // console.log("setting comment");
      // console.log(action.payload);
      const { channelUid, parentMessageUid, messageObj } = action.payload;
      state.comments[messageObj?.uid] = {
        ...messageObj,
        parentMessageUid: parentMessageUid,
        channelUid: channelUid,
      };
      // console.log(messageObj?.uid);
      state.listenerMap[messageObj?.uid] = true;
    },
    setNoMoreMessages(state, action) {
      // console.log("setting no more messages");
      state.noMoreMessages[action.payload] = true;
    },
    setPoll(state, action) {
      // console.log("setting poll");
      const pollObj = action.payload;
      state.polls[pollObj?.uid] = pollObj;
      state.listenerMap[pollObj?.uid] = true;
    },
    selectGroup(state, action) {
      // console.log("selecting group");
      state.selectedGroup = action.payload;
    },
    selectGroupSidebar(state, action) {
      // console.log("selecting group");
      state.selectedGroupSidebar = action.payload;
    },
    setSelectedChannel(state, action) {
      // console.log("selecting channel");
      state.selectedChannel = action.payload;
    },
    selectEvent(state, action) {
      // console.log("selecting event");
      state.selectedEvent = action.payload;
    },
    selectSidebar(state, action) {
      // console.log("selecting sidebar");
      state.selectedSidebar = action.payload;
    },
    setIsNestedSidebarOpen(state, action) {
      // console.log("setting is nested sidebar");
      state.isNestedSidebarOpen = action.payload;
    },
    setIsEventsSidebarOpen(state, action) {
      // console.log("setting is events open");
      state.isEventsSidebarOpen = action.payload;
    },
    setSidebarBeforeEvents(state, action) {
      // console.log("setting sidebar before events");
      state.sidebarBeforeEvents = action.payload;
    },
    setGroupSearchQuery(state, action) {
      state.groupSearchQuery = action.payload;
    },
    logoutRedux(state, action) {
      Object.assign(state, dataInitialState);
    },
    setLoadTimestamp(state, action) {
      state.loadTimestamp = action.payload;
    },
    setLastActiveMap: (state, action) => {
      state.lastActiveMap = action.payload;
    },
    setLastActiveByUserId: (state, { payload }) => {
      state.lastActiveMap = {
        ...state.lastActiveMap,
        [payload.userId]: payload.lastActiveDate,
      };
    },
    setDraftMap: (state, { payload }) => {
      const { draftMap } = payload;

      state.drafts = draftMap;
    },
    setChannelDraft: (state, { payload }) => {
      // The "draft" is an instance of "Draft" type from "type/draft.type" module
      const { channelId, draft } = payload;

      state.drafts = { ...state.drafts, [channelId]: draft };
    },
    removeMessage: (state, { payload }) => {
      const { channelId, messageId } = payload;

      const { [messageId]: _, ...messageMap } = state.messages[channelId] || {};

      state.messages = {
        ...state.messages,
        [channelId]: messageMap,
      };
    },
    removeComment: (state, { payload }) => {
      const { commentId } = payload;

      const { [commentId]: _, ...commentMap } = state.comments || {};

      state.comments = commentMap;
    },
    setCustomEmojiMap: (state, { payload }) => {
      const { groupId, customEmojiMap } = payload;

      if (!state.customEmojiMap) {
        state.customEmojiMap = {};
      }

      state.customEmojiMap[groupId] = {
        ...customEmojiMap,
      };
    },
    unsetCustomEmojiMap: (state, { payload }) => {
      const { groupId } = payload;

      if (!state.customEmojiMap) {
        state.customEmojiMap = {};
      }

      const { [groupId]: groupCustomEmojiMap, ...customEmojiMap } =
        state.customEmojiMap;

      state.customEmojiMap = customEmojiMap;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(retrieveProfileScore.fulfilled, (state, action) => {
        const userId = action.meta.arg;
        const profileScore = action.payload;

        state.profileScores = {
          ...(state.profileScores || {}),
          [userId]: profileScore,
        };
      })
      .addCase(retrieveGroupData.fulfilled, (state, action) => {
        const groupId = action.meta.arg;
        const groupData = action.payload;

        state.groups = { ...(state.groups || {}), [groupId]: groupData };
      })
      .addCase(addGroupListener.fulfilled, (state, action) => {
        const groupId = action.meta.arg;

        state.listenerMap = { ...(state.listenerMap || {}), [groupId]: true };
      })
      .addCase(retrieveProfileSharedData.fulfilled, (state, action) => {
        const userId = action.meta.arg;
        const userData = action.payload;

        state.users = {
          ...(state.users || {}),
          [userId]: userData,
        };
      })
      .addCase(addDesktopVersionListener.fulfilled, (state, action) => {
        state.listenerMap = {
          ...(state.listenerMap || {}),
          __desktopVersionString: true,
        };
      })
      .addCase(addCustomEmojiMapListener.fulfilled, (state, action) => {
        const groupId = action.meta.arg;
        const listenerMapId = `customEmojiMap-${groupId}`;

        state.listenerMap = {
          ...(state.listenerMap || {}),
          [listenerMapId]: true,
        };
      });
  },
});

export const {
  setEvent,
  setMyself,
  setUser,
  setIsCommenting,
  setGroup,
  setChannel,
  setLastViewed,
  setMessage,
  setComment,
  setNoMoreMessages,
  setPoll,
  selectGroup,
  selectGroupSidebar,
  setSelectedChannel,
  selectEvent,
  selectSidebar,
  setIsNestedSidebarOpen,
  setIsEventsSidebarOpen,
  setSidebarBeforeEvents,
  setGroupSearchQuery,
  logoutRedux,
  setLoadTimestamp,
  setLastActiveMap,
  setLastActiveByUserId,
  setDraftMap,
  setChannelDraft,
  removeMessage,
  removeComment,
  setCustomEmojiMap,
  unsetCustomEmojiMap,
} = dataSlice.actions;

export const dataReducer = dataSlice.reducer;
