import { useCallback } from "react";

import { useSelector } from "dive/store";
import { dataMyselfIdSelector } from "dive/store/data";
import {
  createChannelTemplate,
  createGroupTemplate,
} from "dive/util/template.util";
import { generateGroupId } from "dive/firebase/group/generateGroupId";
import { logDebug } from "dive/util/log.util";
import { generateChannelId } from "dive/firebase/channel/generateChannelId";
import { addToGroup, createGroup } from "dive/firebase/group";
import { sendChannelInitialMessage } from "dive/firebase/channel";

export type DiveTemplateChannel = {
  name: string;
  emoji: string;
  isPublic: boolean;
  isDefault: boolean;
  isAdminPost: boolean;
};

export type DiveTemplateEvent = {
  title: string;
  emoji: string;
  description: string;
};

export type DiveTemplateGroup = {
  emoji: string;
  color: string;
  name: string;
  channels: DiveTemplateChannel[];
  events: DiveTemplateEvent[];
};

export function useCreateTemplateDive() {
  // @ts-expect-error
  const myId = useSelector(dataMyselfIdSelector);

  const createDiveTemplate = useCallback(
    (payload: DiveTemplateGroup) => {
      const newGroupId = generateGroupId();

      if (!newGroupId) {
        logDebug(
          `[useCreateTemplateDive][createDiveTemplate] Cannot generate group ID`
        );

        return Promise.reject();
      }

      const groupData = createGroupTemplate({
        myId,
        groupId: newGroupId,
        name: payload.name,
        color: payload.color,
        isSimple: false,
      });

      const channelList = payload.channels.map((channelTemplate) => {
        const newChannelId = generateChannelId();

        if (!newChannelId) {
          logDebug(
            `[useCreateTemplateDive][createDiveTemplate] Cannot generate channel ID`,
            {
              newChannelId,
            }
          );

          throw new Error("Cannot generate channel ID");
        }

        const channelData = createChannelTemplate({
          myId,
          channelId: newChannelId,
          name: channelTemplate.name,
          emoji: channelTemplate.emoji,
          isPublic: channelTemplate.isPublic,
        });

        return { ...channelData, ...channelTemplate };
      });

      groupData.emoji = payload.emoji;
      // @ts-expect-error
      groupData.main = channelList[0].uid || "";
      // @ts-expect-error
      groupData.fromTemplate = true;
      // @ts-expect-error
      groupData.exampleDive = true;
      // @ts-expect-error
      groupData.events = {};

      return createGroup({
        data: groupData,
        // @ts-expect-error
        channelList: channelList,
      }).then((groupData) => {
        const taskList = channelList.map((channel) => {
          const isMain = channel.uid === groupData.main;

          return sendChannelInitialMessage({
            channelId: channel.uid,
            showCuratedEmojiSuggestion: groupData.exampleDive && isMain,
            isMain: isMain,
            isAdminPost: channel.isAdminPost,
            isPublic: channel.isPublic,
          });
        });

        taskList.push(addToGroup(newGroupId, { userIds: [myId] }));

        return Promise.all(taskList).then(() => groupData);
      });
    },
    [myId]
  );

  return {
    createDiveTemplate,
    meta: {
      myId,
    },
  };
}
