import { CSSProperties } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

import { flexCenter, meshBackground } from "dive/constants/DesignSystem";
import { devices } from "dive/constants/device.constant";

// @ts-expect-error
export const NewSubchatModalBlock = styled(motion.div)`
  ${flexCenter}
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
`;

type NewSubchatModalCardProps = {};

export const NewSubchatModalCard = styled.div<NewSubchatModalCardProps>`
  display: flex;
  width: 40%;
  max-height: 80%;
  border-radius: 24px;
  background: #2a2a2b;
  backdrop-filter: blur(50px);
  box-shadow: 0px 6px 14px -6px rgba(24, 39, 75, 0.1);
  position: relative;

  @media ${devices.laptop} {
    width: 35%;
  }

  @media ${devices.laptopL} {
    width: 32%;
  }

  @media ${devices.desktopS} {
    width: 30%;
  }

  @media ${devices.desktop} {
    width: 28%;
  }
`;

export const NewEventModalCardContent = styled.div`
  padding: 18px 18px;
  width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 24px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const NewSubchatModalClose = styled.button`
  ${flexCenter}
  position: absolute;
  width: 44px;
  height: 44px;
  top: -14px;
  right: -10px;
  border-radius: 50%;
  border: 3px solid #1c1c1d;
  background: transparent;
  overflow: hidden;
  z-index: 999;

  &:hover {
    cursor: pointer;
  }

  &:active {
    transform: scale(0.98);
  }
`;

export const NewSubchatModalIconBlock = styled.div`
  svg {
    margin: 2px 0 0 0;
  }
`;

export const NewSubchatModalButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 18px 18px 0 18px;
`;

type NewSubchatModalButtonProps = {
  $isDisabled?: boolean;
  $color: string;
};

export const NewSubchatModalButton = styled.button<NewSubchatModalButtonProps>`
  ${({ $isDisabled, $color }) =>
    !$isDisabled ? `background: ${$color};` : "background: #353536;"}
  width: 196px;
  height: 45px;
  text-align: center;
  border-radius: 100px;
  border: 0.5px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 4px 6px -4px rgba(24, 39, 75, 0.1);
  color: ${({ $isDisabled }) => ($isDisabled ? "#606769" : "#fff")};
  text-align: center;
  font-family: SF Pro Rounded;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0.32px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ $isDisabled }) =>
    !$isDisabled
      ? `
  &:hover {
    cursor: pointer;
  }

  &:active {
    transform: scale(0.98);
  }
  `
      : ""}
`;
