import { createSelector } from "@reduxjs/toolkit";
import get from "lodash/fp/get";

import { AppRootState } from "../../type/shared.type";

import { applicationReducerName } from "./application.slice";

export const applicationSelector = (state: AppRootState) =>
  state[applicationReducerName];

export const applicationIsBrowserSelector = createSelector(
  applicationSelector,
  get("isBrowser")
);

export const applicationIsOnlineSelector = createSelector(
  applicationSelector,
  get("isOnline")
);

export const applicationNotificationSelector = createSelector(
  applicationSelector,
  get("notification")
);

export const applicationNotificationIsPermissionModalVisibleSelector =
  createSelector(
    applicationNotificationSelector,
    get(
      "isPermissionModalVisible"
    ) as unknown as () => AppRootState["application"]["notification"]["isPermissionModalVisible"]
  );

export const applicationNotificationPermissionStateSelector = createSelector(
  applicationNotificationSelector,
  get(
    "permissionState"
  ) as unknown as () => AppRootState["application"]["notification"]["permissionState"]
);

export const applicationNotificationPermissionRequestedAtSelector =
  createSelector(
    applicationNotificationSelector,
    get(
      "permissionRequestedAt"
    ) as unknown as () => AppRootState["application"]["notification"]["permissionRequestedAt"]
  );

export const applicationLatestDesktopAppVersionSelector = createSelector(
  applicationSelector,
  get("latestDesktopAppVersion")
);

export const applicationUpdateAlertSelector = createSelector(
  applicationSelector,
  get("updateAlert")
);

export const applicationIsUpdateAlertVisibleSelector = createSelector(
  applicationUpdateAlertSelector,
  get("isVisible") as unknown as () => boolean
);

export const applicationIsUpdateAlertDismissibleSelector = createSelector(
  applicationUpdateAlertSelector,
  get("isDismissible") as unknown as () => boolean
);

export const applicationDesktopAppPromoSelector = createSelector(
  applicationSelector,
  get("desktopAppPromo")
);

export const applicationIsDesktopAppPromoVisibleSelector = createSelector(
  applicationDesktopAppPromoSelector,
  get("isVisible")
);

export const applicationLayoutSelector = createSelector(
  applicationSelector,
  get("layout")
);

export const applicationLayoutInnerWidthSelector = createSelector(
  applicationLayoutSelector,
  get("innerWidth")
);

export const applicationLayoutInnerHeightSelector = createSelector(
  applicationLayoutSelector,
  get("innerHeight")
);
