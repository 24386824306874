import styled, { css } from "styled-components";

export const QuickProps = styled.div`
  ${(props) => props?.color && `color: ${props.color};`}
`;

export const Title2Regular = styled(QuickProps)`
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 28px;
  /* or 127% */

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.01em;
`;

export const Caption1Regular = styled(Title2Regular)`
  font-size: 12px;
  line-height: 16px;
`;

export const Title2Bold = styled(Title2Regular)`
  font-weight: 700;
`;

export const Title3Regular = styled(Title2Regular)`
  font-size: 20px;
  line-height: 25px;
`;

export const Title3Medium = styled(Title3Regular)`
  font-weight: 500;
`;

export const Title3Bold = styled(Title3Regular)`
  font-weight: 700;
`;

export const BodyRegular = styled(QuickProps)`
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;
  /* or 129% */

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.02em;
  ${(props) => props?.left && "text-align: left;"}
`;

export const CalloutRegular = styled(BodyRegular)`
  font-size: 16px;
  line-height: 21px;
`;

export const FootnoteRegular = styled(BodyRegular)`
  font-size: 13px;
  line-height: 18px;
`;

export const meshBackground = css`
  background-color: hsla(348, 100%, 58%, 1);
  background-image: radial-gradient(
      at 92% 27%,
      hsla(25, 100%, 50%, 1) 0px,
      transparent 50%
    ),
    radial-gradient(at 87% 82%, hsla(331, 100%, 50%, 1) 0px, transparent 50%),
    radial-gradient(at 46% 8%, hsla(331, 100%, 50%, 1) 0px, transparent 50%);
`;

export const flexCenter = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
