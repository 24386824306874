import { useEffect, useState } from "react";
import { endOfHour } from "date-fns";

import { logDebug } from "dive/util/log.util";

//
// Returns the time in milliseconds that represents beginning of every next hour.
//
// Consider using it at the top level (not in the list items) to avoid
//   creating too many timers, as they impact memory usage.
//
export function useStartOfHour() {
  const [currentTime, setCurrentTime] = useState(Date.now());

  useEffect(() => {
    const msBeforeNextHour = endOfHour(Date.now()).getTime() - Date.now();

    const timerId = setTimeout(() => {
      logDebug("[useStartOfHour] Current time updated");

      setCurrentTime(Date.now());
    }, msBeforeNextHour + 1000);

    return () => {
      clearTimeout(timerId);
    };
  }, [currentTime]);

  return {
    currentTime,
  };
}
