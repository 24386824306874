import styled from "styled-components";

export const ChatMessageLinkPreviewMediaBlock = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ChatMessageLinkPreviewMediaImg = styled.img`
  width: 100%;
  object-fit: contain;
`;

export const ChatMessageLinkPreviewVideoBadge = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ChatMessageLinkPreviewMediaText = styled.div`
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  font-size: 17px;
  line-height: 19px;
  font-family: "Clash Display Semibold";
`;

export const ChatMessageLinkPreviewAudioPostfix = styled.div<{
  isRed: boolean;
}>`
  width: 26px;
  height: 26px;
  border-radius: 50%;
  padding: 0 0 0 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ isRed }) => (isRed ? "#FA243C" : "#1ED760")};
`;

export const ChatMessageLinkPreviewLogo = styled.img<{
  widthPx?: number;
  heightPx?: number;
  marginTopPx?: number;
  marginRightPx?: number;
  marginBottomPx?: number;
  marginLeftPx?: number;
}>`
  width: ${({ widthPx = 36 }) => widthPx}px;
  height: ${({ heightPx = 36 }) => heightPx}px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.05);
  border: 0.5px solid rgba(255, 255, 255, 0.1);
  margin: ${({
    marginTopPx = 0,
    marginRightPx = 0,
    marginBottomPx = 0,
    marginLeftPx = 0,
  }) =>
    `${marginTopPx}px ${marginRightPx}px ${marginBottomPx}px ${marginLeftPx}px`};
`;

export const ChatMessageLinkPreviewBlock = styled.a`
  border-radius: 20px;
  border: 0.5px solid rgba(0, 0, 0, 0.1);
  max-width: 290px;
  overflow: hidden;
  text-decoration: none;
  margin: 2px 0 4px 0;
`;

export const ChatMessageLinkPreviewBlockHeader = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const ChatMessageLinkPreviewBlockHeaderBadge = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
`;

export const ChatMessageLinkPreviewBlockContent = styled.div<{
  bgImageUrl?: string;
}>`
  ${({ bgImageUrl }) =>
    bgImageUrl
      ? `background-image: url("${bgImageUrl}"); background-size: contain;`
      : ""}
`;

export const ChatMessageLinkPreviewBlockContentBg = styled.div<{
  isBlurBody?: boolean;
}>`
  padding: 15px 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #000;
  ${({ isBlurBody }) =>
    isBlurBody ? `backdrop-filter: blur(25px); color: #FFF;` : ""}
`;

export const ChatMessageLinkPreviewBlockContentInfo = styled.div`
  flex: 1;
  margin: 0 30px 0 0px;
  overflow: hidden;
`;

type ChatMessageLinkPreviewBlockContentInfoTitleProps = {
  $hasTextOverflow?: boolean;
};

export const ChatMessageLinkPreviewBlockContentInfoTitle = styled.h4<ChatMessageLinkPreviewBlockContentInfoTitleProps>`
  overflow: hidden;
  ${({ $hasTextOverflow }) =>
    $hasTextOverflow ? `text-overflow: ellipsis;` : "display: -webkit-box;"}
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  font-size: 13px;
  line-height: 15px;
  font-weight: 600;
`;

export const ChatMessageLinkPreviewBlockContentInfoDescription = styled.span`
  margin: 3px 0 0 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
  opacity: 0.6;
`;
