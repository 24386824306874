// NOTE: Copied from mobile app, see "date.util.ts" file there

export function dateDiffInDays(a: Date, b: Date) {
  const _MS_PER_DAY = 1000 * 60 * 60 * 24;

  // Discard the time and time-zone information.
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}

export function dateDiffInYears(a: Date, b: Date) {
  // @ts-expect-error
  const timeDiffInMs = b - a;
  const ageDate = new Date(timeDiffInMs);

  return Math.abs(ageDate.getUTCFullYear() - 1970);
}
