import styled from "styled-components";

import WandSvg from "../../assets/wand.svg";

export const AiEventSuggestionContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AiEventSuggestionBlock = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  border-radius: 20px;
  padding: 15px 15px;
  border: 0.5px solid rgba(0, 0, 0, 0.1);
  // background: rgba(0, 0, 0, 0.03);
  // box-shadow: 0px 2px 4px -2px rgba(24, 39, 75, 0.1) inset;
`;

export const AiEventSuggestionBlockEmoji = styled.div`
  width: 46px;
  height: 46px;
  border-radius: 50%;
  font-size: 32px;
  line-height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.05);
  border: 0.5px solid rgba(255, 255, 255, 0.1);
`;

export const AiEventSuggestionBlockSection = styled.div`
  flex: 1;
  margin: 0 30px 0 15px;
`;

export const AiEventSuggestionBlockTitle = styled.h3`
  font-size: 13px;
  line-height: 15px;
  font-weight: 600;
`;

export const AiEventSuggestionBlockInfo = styled.span`
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
  opacity: 0.6;
`;

export const AiEventSuggestionBlockButtonContainer = styled.div`
  position: relative;
  height: 38px;
  width: 124px;
`;

export const AiEventSuggestionBlockButtonContainerShadow = styled.div`
  display: flex;
  height: 38px;
  width: 124px
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  border-width: 0;
  opacity: 0.5;
  background: conic-gradient(
      from 180deg at 50% 50%,
      #ff3b30 0deg,
      #ff9500 46.87499821186066deg,
      #fc0 93.74999642372131deg,
      #34c759 140.625deg,
      #007aff 187.49999284744263deg,
      #5856d6 234.37500715255737deg,
      #af52de 281.25deg,
      #ff2d55 328.1249928474426deg,
      #ff3b30 360deg
    ),
    #fff;
  filter: blur(5px);
`;

export const AiEventSuggestionBlockButton = styled.button<{
  isLoading: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 34px;
  width: 120px;
  padding: 0;
  border-width: 0;

  position: absolute;
  top: 2px;
  left: 2px;

  border-radius: 100px;
  background: conic-gradient(
      from 180deg at 50% 50%,
      #ff3b30 0deg,
      #ff9500 46.87499821186066deg,
      #fc0 93.74999642372131deg,
      #34c759 140.625deg,
      #007aff 187.49999284744263deg,
      #5856d6 234.37500715255737deg,
      #af52de 281.25deg,
      #ff2d55 328.1249928474426deg,
      #ff3b30 360deg
    ),
    #fff;

  transition: 0.1s;

  ${({ isLoading }) =>
    isLoading
      ? "opacity: 0.8;"
      : `
  
  &:hover {
    cursor: pointer;
  }

  &:active {
    transform: scale(0.98);
    opacity: 0.9;
  }
  
  `}
`;

export const AiEventSuggestionBlockButtonTitle = styled.div`
  height: 30px;
  width: 116px;
  background: #ffffff;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
`;

export const AiEventSuggestionHideSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 4px 0;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #8e8e93;
`;

export const AiEventSuggestionHideSectionIcon = styled.span`
  background-image: url(${WandSvg});
  width: 14px;
  height: 13px;
  margin: 0 4px 0 0;
`;

export const AiEventSuggestionHideSectionButton = styled.button`
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  color: #ff2d55;
  border-width: 0;
  background: transparent;
  transition: 0.1s;

  &:hover {
    cursor: pointer;
  }

  &:active {
    transform: scale(0.98);
    opacity: 0.9;
  }
`;
