import React, { FC } from "react";

import SimpleScroll from "./SimpleScroll";

type ChatProps = {
  showAnimatedCounter: (count: number) => void;
};

export const Chat: FC<ChatProps> = ({ showAnimatedCounter }) => {
  return <SimpleScroll showAnimatedCounter={showAnimatedCounter} />;
};
