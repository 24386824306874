import React, { useContext, useMemo } from "react";

export const ThemeContext = React.createContext(null);
export function withTheme(Component) {
  // eslint-disable-next-line react/display-name
  const WrappedComponent = React.forwardRef(({ children, ...props }, ref) => {
    return (
      <ThemeContext.Consumer>
        {({ theme, analytics }) => (
          <Component {...props} analytics={analytics} theme={theme} ref={ref}>
            {children}
          </Component>
        )}
      </ThemeContext.Consumer>
    );
  });

  WrappedComponent.navigationOptions = Component.navigationOptions;
  return WrappedComponent;
}

export const colorPalette = {
  blue: "#007AFF",
  brown: "#A2845E",
  cyan: "#32ADE6",
  green: "#34C759",
  purple: "#AF52DE",
  orange: "#FF9F0A",
  pink: "#FF2D55",
  red: "#FF3B30",
  mint: "#00C7BE",
  yellow: "#F5B100",
};

// Copied from mobile app
export const newColorPalette = {
  pink: "#FF2D55",
  blue: "#007AFF",
  brown: "#A2845E",
  cyan: "#32ADE6",
  green: "#34C759",
  purple: "#AF52DE",
  orange: "#FF9500",
  red: "#FF3B30",
  mint: "#00C7BE",
  yellow: "#FFC700",
};

const light = {
  theme: "light",
  primary: "#FFFFFF",
  secondary: "#000000",
  gray1: "#8E8E93",
  gray2: "#AEAEB2",
  gray3: "#C7C7CC",
  gray4: "#D1D1D6",
  gray5: "#E5E5EA",
  gray6: "#F2F2F7",
  gray7: "#FAFAFA",
  main: "#FFFFFF",
  background: "#FAFAFA",
  itemColor: "#FFFFFF",
  chatItemColor: "#F2F2F7",
  chatBackground: "#FFFFFF",
  chatBars: "#FAFAFA",
  indicatorStyle: "black",
  unfilledField: "#FF3B3027",
  shadowTiny: {
    elevation: 1,
    shadowOffset: {
      height: 1,
      width: 0,
    },
    shadowOpacity: 1,
    shadowColor: "rgba(0,0,0,0.15)",
    shadowRadius: 3,
  },
  shadowLow: {
    elevation: 2,
    shadowOffset: {
      height: 1,
      width: 0,
    },
    shadowOpacity: 1,
    shadowColor: "rgba(112,144,176,0.1)",
    shadowRadius: 5,
  },
  shadowMedium: {
    elevation: 3,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowRadius: 15,
    shadowOpacity: 1,
    shadowColor: "rgba(112,144,176,0.15)",
  },
  shadowHigh: {
    elevation: 4,
    shadowOffset: {
      width: 0,
      height: 5,
    },
    shadowRadius: 30,
    shadowOpacity: 1,
    shadowColor: "rgba(112, 144, 176, 0.25)",
  },
  shadow: {
    elevation: 4,
    shadowColor: "rgba(112, 144, 176, 0.25)",
    shadowOpacity: 0.7,
    shadowRadius: 10,
    shadowOffset: {
      height: 0,
      width: 0,
    },
  },
  thinBorder: {
    borderColor: "#00000018",
    borderWidth: 0.5,
  },
  tabShadow: "#e5e5e5",
  icon: "#3A3A3C",
  interiorIcon: "#FFFFFF",
  // logoSrc: require("../assets/images/GradientShaka.light.png"),
};

const dark = {
  theme: "dark",
  primary: "#1C262A",
  secondary: "#FFFFFF",
  gray1: "#959A9B",
  gray2: "#7A8082",
  gray3: "#606769",
  gray4: "#454E50",
  gray5: "#2B3437",
  gray6: "#1C262A",
  gray7: "#101B1E",
  main: "#000000",
  background: "#101B1E",
  itemColor: "#30302f",
  chatItemColor: "#1C262A",
  chatBackground: "#101B1E",
  chatBars: "#1C262A",
  indicatorStyle: "white",
  unfilledField: "#FF3B3031",
  shadowTiny: {
    elevation: 1,
    shadowOffset: {
      height: 1,
      width: 0,
    },
    shadowOpacity: 1,
    shadowColor: "rgba(0,0,0,0.15)",
    shadowRadius: 3,
  },
  shadowLow: {
    shadowOffset: {
      height: 1,
      width: 0,
    },
    shadowOpacity: 1,
    shadowColor: "rgba(0,0,0,0.1)",
    shadowRadius: 5,
  },
  shadowMedium: {
    elevation: 3,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowRadius: 15,
    shadowOpacity: 1,
    shadowColor: "rgba(0,0,0,0.15)",
  },
  shadowHigh: {
    elevation: 4,
    shadowOffset: {
      width: 0,
      height: 5,
    },
    shadowRadius: 30,
    shadowOpacity: 1,
    shadowColor: "rgba(0,0,0, 0.25)",
  },
  shadow: {
    shadowColor: "rgba(0, 0, 0, 0.27)",
    shadowOpacity: 1,
    shadowRadius: 10,
    shadowOffset: {
      height: 0,
      width: 0,
    },
  },
  thinBorder: {
    borderColor: "#FFFFFF18",
    borderWidth: 0.5,
  },
  tabShadow: "#1c1c12",
  icon: "#D1D1D6",
  interiorIcon: "#10101b",
  // logoSrc: require("../assets/images/GradientShaka.dark.png"),
};

const Theme = {
  light,
  dark,
  opposite: {
    light: dark,
    dark: light,
  },
  tabShadow: {
    shadowColor: "#304D5E",
    shadowOpacity: 0.1,
    shadowRadius: 10,
    shadowOffset: {
      height: 0,
      width: -2,
    },
  },
  fontSizing: {
    lgTitle: {
      fontSize: 34,
      lineHeight: 41,
    },
    title1: {
      fontSize: 28,
      lineHeight: 34,
    },
    title2: {
      fontSize: 22,
      lineHeight: 28,
      letterSpacing: 0.22,
    },
    title3: {
      fontSize: 20,
      lineHeight: 25,
      letterSpacing: 0.2,
    },
    body: {
      fontSize: 17,
      lineHeight: 21,
      letterSpacing: 0.34,
    },
    callout: {
      fontSize: 16,
      lineHeight: 22,
      letterSpacing: 0.32,
    },
    commentText: {
      fontSize: 14.5,
      lineHeight: 19,
      letterSpacing: 0.29,
    },
    footnote: {
      fontSize: 13,
      lineHeight: 18,
      letterSpacing: 0.26,
    },
    commentFootnote: {
      fontSize: 0.88 * 13,
      lineHeight: 0.88 * 18,
      letterSpacing: 0.88 * 0.26,
    },
    caption1: {
      fontSize: 12,
      lineHeight: 16,
      letterSpacing: 0.24,
    },
    caption2: {
      fontSize: 11,
      lineHeight: 12,
      letterSpacing: 0.22,
    },
    caption3: {
      fontSize: 10,
      lineHeight: 10,
      letterSpacing: 0.2,
    },
  },
  mf: "Main",
  mfs: "Main Semi",
  mfb: "Main Bold",
  mfm: "Main Med",
  highFadeGrad: ["rgba(255,255,255,0.5)", "rgba(255,255,255,0)"],
  fadeGrad: ["rgba(255,255,255,0.25)", "rgba(255,255,255,0)"],
  lowFadeGrad: ["rgba(255,255,255,0.15)", "rgba(255,255,255,0)"],
  graymeddark: "#474747",
  gray: "#e2ebee",
  accent: "#007AFF",
  indigo: "#5856D6",
  black: "#000000",
  white: "#FFFFFF",
  whiteFaint: "#FFFFFF25",
  whiteFaintText: "#FFFFFF90",
  diveRed: "#FF2D55",
  divePurple: "#Af52d3",
  diveGreen: "#34C759",
  diveOrange: "#FF9500",
  permissionGreen: "#34C759",
  permissionRed: "#FF3B30",
  permissionOrange: "#FF9500",
  darkGray: "#1C1C1E",
  lightGray: "#8E8E93",
  ...colorPalette,
};
export default Theme;

// export const shadowProps = (elevation, theme = 'light') => {
//   return Platform.select({
//     ios: {
//       shadowColor: Theme[theme].secondary,
//       shadowOpacity: 0.3,
//       shadowRadius: 0.8 * elevation,
//       shadowOffset: {
//         width: 0,
//         height: 0.5 * elevation,
//       },
//       zIndex: elevation,
//     },
//     android: {
//       elevation,
//       zIndex: elevation,
//     },
//   });
// };

export function useThemedStyles(styleFunction) {
  const { theme } = useContext(ThemeContext);
  const style = useMemo(() => styleFunction(theme), [theme]);
  return style;
}

export function useTheme() {
  const { theme } = useContext(ThemeContext);
  return theme;
}

// export const compileThemedStyles = (blueprint) => {
//   let lastBP = blueprint;
//   let lastTheme = undefined;
//   let lastStyle = undefined;

//   function memoizedStyle(theme, bp) {
//     if (!(lastTheme && lastTheme === theme) || (bp && !isEqual(lastBP, bp))) {
//       lastTheme = theme;
//       if (lastStyle) {
//         Object.entries(bp || lastBP).forEach(([key, value]) => {
//           if (typeof value === 'function') {
//             lastStyle[key] = value(theme);
//           }
//         });
//       } else {
//         lastStyle = Object.fromEntries(
//           Object.entries(bp || lastBP).map(([key, value]) => {
//             if (typeof value === 'function') {
//               return [key, value(theme)];
//             } else {
//               return [key, value];
//             }
//           }),
//         );
//       }
//     }
//     return lastStyle;
//   }

//   return memoizedStyle;
// };
