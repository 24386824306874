import { useMemo } from "react";
import { useSelector } from "react-redux";

import {
  dataChannelsSelector,
  dataLastViewedMapSelector,
  dataMessagesSelector,
  dataSelectedGroupSidebarSelector,
} from "../store/data";
import { logDebug } from "../util/log.util";

//
// Counts the number of un-reads on the main tab
// Each group can contribute at most 1 to the pip,
//   even if there are multiple un-reads in the group.
//
// See un-read calculation implementation on mobile app in "useNavigationUnreadPipCount".
//
export function useUnreadData() {
  const selectedGroup = useSelector(dataSelectedGroupSidebarSelector);

  const channelMap = useSelector(dataChannelsSelector);
  const messageMap = useSelector(dataMessagesSelector);
  const lastViewedMap = useSelector(dataLastViewedMapSelector);

  const { unreadGroupIds, unreadChannelIds, unreadDmIds } = useMemo(() => {
    const unreadGroupIds = [];
    const unreadChannelIds = [];
    // Technically, DMs are also channels, but since they're shown on the Main tab
    //   we have to consider them separately, when calculate the un-read count.
    const unreadDmIds = [];

    const operationStartTimeMs = Date.now();

    for (const channelId in channelMap) {
      const channelMessageList = Object.values(messageMap?.[channelId] || {})
        // Filter group channel to exclude those we don't count as un-read
        .filter(
          (messageData) =>
            !messageData?.isNewUserMessage && !messageData?.isRemoveUserMessage
        )
        .sort(
          (left, right) => (right?.createdAt || 0) - (left?.createdAt || 0)
        );

      const isUnread =
        (lastViewedMap?.[channelId] || 0) < channelMessageList[0]?.createdAt &&
        channelMessageList[0]?.createdAt > 1642040919000;

      if (isUnread) {
        const isDm = channelMap[channelId]?.isDM;

        if (isDm) {
          unreadDmIds.push(channelId);
        } else {
          unreadChannelIds.push(channelId);
        }

        const groupId = channelMap[channelId]?.groupUid;

        if (groupId && !unreadGroupIds.includes(groupId)) {
          unreadGroupIds.push(groupId);
        }
      }
    }

    const operationEndTimeMs = Date.now();

    logDebug(`[useUnreadData] Re-calculated un-reads`, {
      operationDurationMs: operationEndTimeMs - operationStartTimeMs,
    });

    return {
      unreadGroupIds,
      unreadChannelIds,
      unreadDmIds,
    };
  }, [channelMap, messageMap, lastViewedMap]);

  return {
    unreadGroupIds,
    unreadChannelIds,
    unreadDmIds,
    currentUnreadCount: [...unreadGroupIds, ...unreadDmIds].filter(
      (groupId) => groupId !== selectedGroup
    ).length,
    unreadCount: [...unreadGroupIds, ...unreadDmIds].length,
  };
}
