import React, { FC, useEffect, useState } from "react";

import { ReactComponent as RetrySvg } from "../../../assets/retry.svg";
import { ReactComponent as CancelSvg } from "../../../assets/cancel.svg";

import {
  ChatMessageIndicatorBlock,
  ChatMessageIndicatorButton,
  ChatMessageIndicatorLabel,
} from "./ChatMessageIndicator.style";

type ChatMessageIndicatorProps = {
  localMessage: boolean;
  showNotSent: boolean;
  onRetry: () => void;
  onCancel: () => void;
};

export const ChatMessageIndicator: FC<ChatMessageIndicatorProps> = ({
  localMessage,
  showNotSent,
  onRetry,
  onCancel,
}) => {
  const [indicatorLabel, setIndicatorLabel] = useState<string | null>(null);

  useEffect(
    () => {
      const timerIds: NodeJS.Timeout[] = [];

      if (showNotSent) {
        setIndicatorLabel("Message failed to send!");
      } else if (localMessage) {
        timerIds.push(
          setTimeout(() => {
            setIndicatorLabel("Sending...");
          }, 1000)
        );
      } else if (indicatorLabel) {
        timerIds.push(
          setTimeout(() => {
            setIndicatorLabel("Sent!");
          }, 600)
        );

        timerIds.push(
          setTimeout(() => {
            setIndicatorLabel(null);
          }, 3600)
        );
      }

      return () => {
        timerIds.forEach((timerId) => {
          clearTimeout(timerId);
        });
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [showNotSent, localMessage]
  );

  return (
    <>
      {indicatorLabel ? (
        <ChatMessageIndicatorBlock key={indicatorLabel}>
          <ChatMessageIndicatorLabel>
            {indicatorLabel}
          </ChatMessageIndicatorLabel>
          {showNotSent ? (
            <>
              <ChatMessageIndicatorButton key="retry-button" onClick={onRetry}>
                <RetrySvg />
              </ChatMessageIndicatorButton>
              <ChatMessageIndicatorButton
                key="cancel-button"
                onClick={onCancel}
              >
                <CancelSvg />
              </ChatMessageIndicatorButton>
            </>
          ) : null}
        </ChatMessageIndicatorBlock>
      ) : null}
    </>
  );
};
