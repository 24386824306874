import React, { FC, useCallback } from "react";

import { getGroupColor } from "dive/constants/groupColors";
import { useSelector } from "dive/store";
import {
  dataGroupByIdSelector,
  dataMyselfIdSelector,
  dataSelectedGroupSidebarSelector,
} from "dive/store/data";
import { NEW_SUBCHAT_MODAL_ID } from "dive/store/ui";
import { useModal } from "dive/hook/useModal.hook";

import { CreateSubchat } from "./CreateSubchat";

type CreateSubchatContainerProps = {};

export const CreateSubchatContainer: FC<CreateSubchatContainerProps> = ({}) => {
  const { showModal } = useModal();

  // @ts-expect-error
  const myId = useSelector(dataMyselfIdSelector);
  const groupId = useSelector(dataSelectedGroupSidebarSelector);
  // @ts-expect-error
  const groupData = useSelector((st) => dataGroupByIdSelector(st, groupId));

  const groupColor = getGroupColor(groupData);

  const onClick = useCallback(
    () => {
      showModal(NEW_SUBCHAT_MODAL_ID);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const isAdmin = Object.keys(groupData?.admins || {}).includes(myId);
  const isChatCreationEnabled = groupData?.memberChatCreationType !== "none";

  return (
    <CreateSubchat
      isVisible={isAdmin || isChatCreationEnabled}
      color={groupColor}
      onClick={onClick}
    />
  );
};
