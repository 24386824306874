import { CSSProperties } from "react";
import styled from "styled-components";

type ProfileCardBlockProps = {
  width?: CSSProperties["width"];
  height?: CSSProperties["height"];
};

export const ProfileCardBlock = styled.div<ProfileCardBlockProps>`
  ${({ width }) => (width ? `width: ${width};` : "")}
  ${({ height }) => (height ? `height: ${height};` : "")}
  background: #ffffff;
  box-shadow: 0 0 10px 1px rgba(24, 39, 75, 0.15);
  border-radius: 20px;
  padding: 20px 0 10px 0;
`;

export const ProfileCardActivityBlock = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ProfileCardDataBlock = styled.div``;

export const ProfileCardDataBlockHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 0 0 0;
`;

export const ProfileCardDataBlockHeaderInfo = styled.div`
  margin: 0 0 0 10px;
`;

export const ProfileCardDataBlockName = styled.h4`
  font-size: 18px;
  font-weight: 700;
  margin: 10px 0;
`;

export const ProfileCardDataBlockDescription = styled.div`
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  text-align: center;
  margin: 10px 15px 0 15px;
`;

type ProfileCardDataBlockStatusProps = {
  isActive: boolean;
};

export const ProfileCardDataBlockStatus = styled.div<ProfileCardDataBlockStatusProps>`
  font-size: 12px;
  margin: 0 0 0 0;
  color: ${({ isActive }) => (isActive ? "green" : "rgba(0, 0, 0, .5)")};
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ProfileCardDataBlockStatusIndicator = styled.div<ProfileCardDataBlockStatusProps>`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  border: 1px solid
    ${({ isActive }) => (isActive ? "green" : "rgba(0, 0, 0, .5)")};
  background: ${({ isActive }) => (isActive ? "green" : "transparent")};
  margin: 1px 5px 0 0;
`;

export const ProfileCardScore = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 10px 0 0 0;
`;

export const ProfileCardScoreElement = styled.div`
  padding: 6px 24px;
  margin: 0 6px;
  background: #ffffff;
  box-shadow: 0 0 10px 1px rgba(24, 39, 75, 0.1);
  font-size: 12px;
  text-align: center;
  border-radius: 16px;
`;

export const ProfileCardScoreElementTitle = styled.div`
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  margin: 0 0 4px 0;
`;

export const ProfileCardDataBlockTagList = styled.div`
  display: -webkit-box;
  overflow-x: scroll;
  padding: 10px 10px 0 10px;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const ProfileCardDataBlockTagElement = styled.div`
  height: 32px;
  padding: 0 10px;
  border-radius: 20px;
  margin: 0 12px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  border: 2px solid rgba(0, 0, 0, 0.05);
  text-wrap: nowrap;

  span {
    margin: 0 0 0 4px;
    color: rgba(0, 0, 0, 0.6);
  }
`;

type ProfileCardDataBlockSocialListProps = {
  justifyContent?: CSSProperties["justifyContent"];
};

export const ProfileCardDataBlockSocialList = styled.div<ProfileCardDataBlockSocialListProps>`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  ${({ justifyContent }) =>
    justifyContent ? `justify-content: ${justifyContent};` : ""}
  overflow-x: scroll;
  padding: 10px 10px 0 10px;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

type ProfileCardDataBlockSocialLinkProps = {
  flex?: CSSProperties["flex"];
  bgColor: string;
  isTag?: boolean;
  isLast: boolean;
};

export const ProfileCardDataBlockSocialLink = styled.a<ProfileCardDataBlockSocialLinkProps>`
  ${({ flex }) => (flex ? `flex: ${flex};` : "")}
  background: ${({ bgColor }) => bgColor};
  border-radius: 20px;
  margin: ${({ isLast }) => (isLast ? "" : "0 8px 0 0")};
  ${({ isTag }) =>
    isTag ? "padding: 0 10px; text-decoration: none;" : "min-width: 52px;"}
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    margin: 0 0 0 4px;
    color: white;
    font-size: 12px;
  }
`;

export const ProfileCardBadge = styled.div`
  height: 32px;
  margin: 0 8px 0 0;
  border-radius: 20px;
  background
    conic-gradient(
      from 180deg at 50% 50%,
      #ff3b30 0deg,
      #ff9500 46.87499821186066deg,
      #fc0 93.74999642372131deg,
      #34c759 140.625deg,
      #007aff 187.49999284744263deg,
      #5856d6 234.37500715255737deg,
      #af52de 281.25deg,
      #ff2d55 328.1249928474426deg,
      #ff3b30 360deg
    ), white;

  svg {
    width: 16px;
  }

  span {
    margin: 0 4px 0 0;
    font-size: 12px;
    font-weight: bold;
    font-family: New York Semi;
  }
`;

export const ProfileCardBadgeContent = styled.div`
  height: 28px;
  margin: 2px;
  padding: 0 10px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  background: #ffffff;
`;

type ProfileCardSendDmProps = {
  $isLoading: boolean;
};

export const ProfileCardSendDm = styled.button<ProfileCardSendDmProps>`
  height: 36px;
  width: calc(100% - 24px);
  margin: 10px 12px 5px 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-width: 0;
  border-radius: 24px;
  background: #ff2d55;
  box-shadow: 0px 0px 7px 3px rgba(0, 0, 0, 0.1);
  transition: 0.1s;

  svg {
    path {
      fill: white;
    }
  }

  ${({ $isLoading }) =>
    !$isLoading
      ? `
  &:hover {
    cursor: pointer;
  }

  &:active {
    transform: scale(0.98);
    opacity: 0.9;
  }
  `
      : "opacity: 0.8;"}
`;

export const ProfileCardSendDmText = styled.div`
  margin: 0 0 0 9px;
  font-size: 14px;
  font-weight: 600;
  color: white;
`;
