import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { INITIAL_NOTIFICATION_STATE } from "../../constants/notification.constant";
import { NotificationPermissionState } from "../../type/notification.type";

import { ApplicationInitialState } from "./application.type";

export const applicationReducerName = "application";

export const applicationInitialState: ApplicationInitialState = {
  isBrowser: true,
  isOnline: true,
  notification: {
    isPermissionModalVisible: false,
    permissionState: INITIAL_NOTIFICATION_STATE,
    permissionRequestedAt: 0,
  },
  latestDesktopAppVersion: null,
  updateAlert: {
    isVisible: false,
    isDismissible: true,
  },
  desktopAppPromo: {
    isVisible: true,
  },
  layout: {
    innerWidth: null,
    innerHeight: null,
  },
};

const applicationSlice = createSlice({
  name: applicationReducerName,
  initialState: applicationInitialState,
  reducers: {
    setIsBrowser: (state, action: PayloadAction<boolean>) => {
      state.isBrowser = action.payload;
    },
    setIsOnline: (state, action: PayloadAction<boolean>) => {
      state.isOnline = action.payload;
    },
    setIsPermissionModalVisible: (state, action: PayloadAction<boolean>) => {
      state.notification.isPermissionModalVisible = action.payload;
    },
    setNotificationPermissionState: (
      state,
      action: PayloadAction<NotificationPermissionState>
    ) => {
      state.notification.permissionState = action.payload;
    },
    setNotificationPermissionRequestedAt: (
      state,
      action: PayloadAction<number>
    ) => {
      state.notification.permissionRequestedAt = action.payload;
    },
    setLatestDesktopAppVersion: (state, action: PayloadAction<string>) => {
      state.latestDesktopAppVersion = action.payload;
    },
    setIsUpdateAlertVisible: (state, action: PayloadAction<boolean>) => {
      state.updateAlert.isVisible = action.payload;
    },
    setIsUpdateAlertDismissible: (state, action: PayloadAction<boolean>) => {
      state.updateAlert.isDismissible = action.payload;
    },
    setIsDesktopAppPromoVisible: (state, action: PayloadAction<boolean>) => {
      state.desktopAppPromo.isVisible = action.payload;
    },
    setLayout: (
      state,
      action: PayloadAction<{ innerWidth: number; innerHeight: number }>
    ) => {
      state.layout.innerWidth = action.payload.innerWidth;
      state.layout.innerHeight = action.payload.innerHeight;
    },
  },
});

export const applicationReducer = applicationSlice.reducer;

export const {
  setIsBrowser,
  setIsOnline,
  setIsPermissionModalVisible,
  setNotificationPermissionState,
  setNotificationPermissionRequestedAt,
  setLatestDesktopAppVersion,
  setIsUpdateAlertVisible,
  setIsUpdateAlertDismissible,
  setIsDesktopAppPromoVisible,
  setLayout,
} = applicationSlice.actions;
