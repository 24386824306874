import { ReactComponent as InstagramSvg } from "../assets/social/instagram.svg";
import { ReactComponent as SnapchatSvg } from "../assets/social/snapchat.svg";
import { ReactComponent as TikTokSvg } from "../assets/social/tiktok.svg";
import { ReactComponent as TwitterSvg } from "../assets/social/twitter.svg";
import { ReactComponent as VenmoSvg } from "../assets/social/venmo.svg";
import { ReactComponent as CashAppSvg } from "../assets/social/cashapp.svg";
import { ReactComponent as AppleMusicSvg } from "../assets/social/applemusic.svg";
import { ReactComponent as SpotifySvg } from "../assets/social/spotify.svg";

export const socialPlatformMap = {
  instagram: {
    IconComponent: InstagramSvg,
    color: "#FF0069",
    deepLink: "instagram://user?username=",
    webLink: "https://www.instagram.com/",
    title: "Instagram",
    index: 0,
  },
  snapchat: {
    IconComponent: SnapchatSvg,
    color: "#FFCC00",
    deepLink: "snapchat://add/",
    webLink: "https://www.snapchat.com/add/",
    title: "Snapchat",
    index: 1,
  },
  venmo: {
    IconComponent: VenmoSvg,
    color: "#008cff",
    deepLink: "venmo://paycharge?recipients=",
    webLink: "https://account.venmo.com/u/",
    title: "Venmo",
    index: 2,
  },
  tikTok: {
    IconComponent: TikTokSvg,
    color: "black",
    deepLink: "https://www.tiktok.com/@",
    webLink: "https://www.tiktok.com/@",
    title: "TikTok",
    index: 3,
  },
  twitter: {
    IconComponent: TwitterSvg,
    color: "#00a5f0",
    deepLink: "twitter://user?screen_name=",
    webLink: "https://www.twitter.com/",
    title: "Twitter",
    index: 4,
  },
  cashApp: {
    IconComponent: CashAppSvg,
    color: "#00d632",
    deepLink: "cashapp://",
    webLink: "https://cash.app/$",
    title: "Cash App",
    index: 5,
  },
  appleMusic: {
    IconComponent: AppleMusicSvg,
    color: "#FF0069",
    deepLink: "https://music.apple.com/profile/",
    webLink: "https://music.apple.com/profile/",
    title: "Apple Music",
    index: 6,
  },
  spotify: {
    IconComponent: SpotifySvg,
    iconColor: "#1DB954",
    color: "#000000",
    deepLink: "spotify://",
    webLink: "https://open.spotify.com/user/",
    title: "Spotify",
    index: 7,
  },
};
