// RSVP Gif Suggestions
export const GOING_RSVP_GIF_SUGGESTIONS = [
  {
    url: "https://media0.giphy.com/media/dBGi39HzazuTV21S15/giphy.gif",
    width: 480,
    height: 400,
  },
  {
    url: "https://media0.giphy.com/media/mGDvFv4tWxzZcw4Bn6/giphy.gif",
    width: 480,
    height: 400,
  },
  {
    url: "https://media0.giphy.com/media/eJccIXCxXzgVoztmWw/giphy.gif",
    width: 480,
    height: 270,
  },
  {
    url: "https://media0.giphy.com/media/XDSBGwnjvTpoZGJhxY/giphy.gif",
    width: 480,
    height: 269,
  },
  {
    url: "https://media0.giphy.com/media/kyiHXDANuOg8Jy6ByA/giphy.gif",
    width: 360,
    height: 480,
  },
  {
    url: "https://media0.giphy.com/media/3o6UB3VhArvomJHtdK/giphy.gif",
    width: 400,
    height: 354,
  },
  {
    url: "https://media0.giphy.com/media/DffShiJ47fPqM/giphy.gif",
    width: 178,
    height: 119,
  },
  {
    url: "https://media0.giphy.com/media/jErnybNlfE1lm/giphy.gif",
    width: 245,
    height: 200,
  },
  {
    url: "https://media0.giphy.com/media/Ld0P4AdeSDjoY/giphy.gif",
    width: 200,
    height: 200,
  },
  {
    url: "https://media0.giphy.com/media/f6z5TkrTIBZILYOd1t/giphy.gif",
    width: 432,
    height: 432,
  },
  {
    url: "https://media0.giphy.com/media/J93sVmfYBtsRi/giphy.gif",
    width: 436,
    height: 329,
  },
  {
    url: "https://media0.giphy.com/media/VRa3YCyi3JSWk/giphy.gif",
    width: 335,
    height: 230,
  },
  {
    url: "https://media0.giphy.com/media/l3vRlT2k2L35Cnn5C/giphy.gif",
    width: 339,
    height: 273,
  },
  {
    url: "https://media0.giphy.com/media/gSJfzjAfRUCly/giphy.gif",
    width: 500,
    height: 376,
  },
  {
    url: "https://media0.giphy.com/media/4KF85OSbyjVOfyjksJ/giphy.gif",
    width: 480,
    height: 191,
  },
];

export const NOT_GOING_RSVP_GIF_SUGGESTIONS = [
  {
    url: "https://media0.giphy.com/media/137TKgM3d2XQjK/giphy.gif",
    width: 340,
    height: 347,
  },
  {
    url: "https://media0.giphy.com/media/Q6WPVzFU8LcBWWgQE1/giphy.gif",
    width: 500,
    height: 281,
  },
  {
    url: "https://media0.giphy.com/media/T1WqKkLY753dZghbu6/giphy.gif",
    width: 480,
    height: 400,
  },
  {
    url: "https://media0.giphy.com/media/1BXa2alBjrCXC/giphy.gif",
    width: 300,
    height: 300,
  },
  {
    url: "https://media0.giphy.com/media/iJJ6E58EttmFqgLo96/giphy.gif",
    width: 384,
    height: 480,
  },
  {
    url: "https://media0.giphy.com/media/JEVqknUonZJWU/giphy.gif",
    width: 320,
    height: 154,
  },
  {
    url: "https://media0.giphy.com/media/ISOckXUybVfQ4/giphy.gif",
    width: 480,
    height: 324,
  },
  {
    url: "https://media0.giphy.com/media/RIXdN6jjGtRd7dtIMC/giphy.gif",
    width: 139,
    height: 203,
  },
  {
    url: "https://media0.giphy.com/media/H9nnXKNfa5wf6/giphy.gif",
    width: 274,
    height: 227,
  },
  {
    url: "https://media0.giphy.com/media/46zGsumnZY2FXtq480/giphy.gif",
    width: 500,
    height: 374,
  },
];

export const INTERESTED_RSVP_GIF_SUGGESTIONS = [
  {
    url: "https://media0.giphy.com/media/GHl7ywzbuT8KQ/giphy.gif",
    width: 504,
    height: 312,
  },
  {
    url: "https://media0.giphy.com/media/y3QOvy7xxMwKI/giphy.gif",
    width: 500,
    height: 399,
  },
  {
    url: "https://media0.giphy.com/media/W0QduXZQEcNEa8r0oY/giphy.gif",
    width: 300,
    height: 169,
  },
  {
    url: "https://media0.giphy.com/media/DfdbTJZx6Yjra/giphy.gif",
    width: 500,
    height: 216,
  },
  {
    url: "https://media0.giphy.com/media/fXJyMfUdqVCMPAnPJM/giphy.gif",
    width: 276,
    height: 326,
  },
  {
    url: "https://media0.giphy.com/media/3o6ZtbKWDS0dc0qW8U/giphy.gif",
    width: 384,
    height: 216,
  },
  {
    url: "https://media0.giphy.com/media/26uf0m01UUkHIjdNC/giphy.gif",
    width: 480,
    height: 246,
  },
  {
    url: "https://media0.giphy.com/media/10Yq4LbQdyALNiPf6t/giphy.gif",
    width: 480,
    height: 480,
  },
  {
    url: "https://media0.giphy.com/media/777Aby0ZetYE8/giphy.gif",
    width: 500,
    height: 270,
  },
  {
    url: "https://media0.giphy.com/media/WRQBXSCnEFJIuxktnw/giphy.gif",
    width: 504,
    height: 322,
  },
];
