import { getDatabase, update, ref } from "firebase/database";
import { getAuth } from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";
import analytics from "../../util/analytics";
import { INTERESTED_RSVP } from "../../constants/rsvp.constant";

const auth = getAuth();
const db = getDatabase();
const functions = getFunctions();
// const addMessage = httpsCallable(functions, 'externalEventRsvp');
// addMessage({ text: messageText })
//   .then((result) => {
//     // Read result of the Cloud Function.
//     /** @type {any} */
//     const data = result.data;
//     const sanitizedMessage = data.text;
//   });

function rsvpToEvent(event, rsvpIdx) {
  const updates = {};
  const now = Date.now();
  const myUid = auth.currentUser?.uid;
  if (
    event.approvalRequired &&
    !event.pendingApproval?.[myUid] &&
    !event.responses?.[myUid] &&
    rsvpIdx === INTERESTED_RSVP
  ) {
    // Only want to put user's name here if they haven't RSVP'd in any way yet
    updates[`events/${event.uid}/pendingApproval/${myUid}/time`] = now;
    updates[`events/${event.uid}/timestamp`] = now;
  }
  if (!event.approvalRequired && !event.responses?.[myUid] !== rsvpIdx) {
    // Only want to put user's name here if they haven't RSVP'd
    updates[`events/${event.uid}/responses/${myUid}`] = rsvpIdx;
    updates[`events/${event.uid}/responseTimes/${myUid}`] = now;
    updates[`events/${event.uid}/timestamp`] = now;
  }
  console.log(updates);
  analytics.track("event_rsvp", {
    rsvpIdx: rsvpIdx,
    eventUid: event?.uid,
    hasComment: false,
    userUid: myUid,
    through: "Web",
  });
  update(ref(db), updates);
}

export default {
  rsvpToEvent,
};
