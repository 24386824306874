import { createGlobalStyle } from "styled-components";

import ClashDisplayBold from "./ClashDisplay-Bold.woff";
import ClashDisplayBold2 from "./ClashDisplay-Bold.woff2";

import ClashDisplaySemibold2 from "./ClashDisplay-Semibold.woff2";
import ClashDisplaySemibold from "./ClashDisplay-Semibold.woff";

import ClashDisplay from "./ClashDisplay-Medium.woff";
import ClashDisplay2 from "./ClashDisplay-Medium.woff2";

import NewYorkMediumSemi from "./NewYorkMedium-Semibold.otf";

export default createGlobalStyle`
    @font-face {
        font-family: 'Clash Display Styled';
        src: url(${ClashDisplay2}) format('woff2'),
        url(${ClashDisplay}) format('woff');
    }

    @font-face {
        font-family: 'Clash Display Bold';
        src: url(${ClashDisplayBold2}) format('woff2'),
        url(${ClashDisplayBold}) format('woff');
    }
 
    @font-face {
        font-family: 'Clash Display Semibold';
        src: url(${ClashDisplaySemibold2}) format('woff2'),
        url(${ClashDisplaySemibold}) format('woff');
    }

    @font-face {
        font-family: 'Clash Display';
        src: url(${ClashDisplay2}) format('woff2'),
        url(${ClashDisplay}) format('woff');
    }
 
    @font-face {
        font-family: 'New York Semi';
        src: url(${NewYorkMediumSemi}) format('opentype');
    }
`;
