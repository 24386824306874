import { EmojiPicker as Picker } from "../EmojiPicker";
import { useEffect } from "react";
import styled from "styled-components";

import PopoverWrapper from "./PopoverWrapper";
import usePopover from "./usePopover";

/*
  type EmojiPopoverProps = {
    referenceElementCb: (ref: React.Ref, getReferenceProps: Function<any>) => JSX.Element
    onEmojiClick: (emojiData: EmojiClickData, event: MouseEvent) => void
    onOpenStateChanged?: (open: boolean) => void
  }
*/

export default function EmojiPopover({
  isCustomEmojiVisible,
  referenceElementCb,
  onEmojiClick,
  onOpenStateChanged = () => {},
}) {
  const {
    context,
    open,
    setOpen,
    reference,
    floating,
    getReferenceProps,
    getFloatingProps,
    x,
    y,
    strategy,
  } = usePopover();

  useEffect(() => {
    onOpenStateChanged(open);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const onEmojiClickHandler = (emojiData, event) => {
    if (!emojiData || !emojiData.emoji) {
      return;
    }

    onEmojiClick(event, emojiData);

    setOpen(false);
  };

  return (
    <>
      {referenceElementCb(reference, getReferenceProps)}
      {open && (
        <PopoverWrapper
          context={context}
          modal={false}
          floating={floating}
          strategy={strategy}
          x={x}
          y={y}
          floatingProps={getFloatingProps()}
        >
          <StyledPicker
            autoFocusSearch
            lazyLoadEmojis
            isCustomEmojiVisible={isCustomEmojiVisible}
            width={280}
            height={320}
            previewConfig={{ showPreview: false }}
            onEmojiClick={onEmojiClickHandler}
          ></StyledPicker>
        </PopoverWrapper>
      )}
    </>
  );
}

const StyledPicker = styled(Picker)``;
