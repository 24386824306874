import React, { Fragment, useEffect } from "react";
import styled from "styled-components";
import UpcomingEvents from "dive/components/UpcomingEvents";
import SidebarListItem from "dive/components/SidebarListItem.js";
import { useState } from "react";

import fillerAvatar from "dive/assets/filler/avatar.png";
import orangePlus from "dive/assets/orange_plus.svg";
import magnifyingGlass from "dive/assets/magnifying_glass.svg";
import backChevron from "dive/assets/back_chevron.svg";
import { ReactComponent as BackChevronSvg } from "dive/assets/back_chevron.svg";
import NestedChannelListItem from "dive/components/NestedChannelListItem";

import { getGroupColor } from "dive/constants/groupColors";

import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "@reduxjs/toolkit";
import Squircle from "dive/components/Squircle";
import { dispatch } from "react-redux";
import {
  setIsNestedSidebarOpen,
  setSidebarBeforeEvents,
} from "dive/store/data";
import { motion, AnimatePresence } from "framer-motion";
import { useUnreadData } from "dive/hook/useUnreadData.hook";
import { CreateSubchat } from "./CreateSubchat";
import { addCustomEmojiMapListener } from "dive/store/data/thunk";

export const NestedSidebar = () => {
  const dispatch = useDispatch();

  const [scrollOffset, setScrollOffset] = useState(0);

  const handleChatScroll = (e) => {
    setScrollOffset(e.target.scrollTop);
  };

  const selectSelectedGroupUid = (state) => state?.data?.selectedGroupSidebar;
  const selectGroups = (state) => state?.data?.groups;
  const selectUserChannels = (st) => st?.data?.myself?.channels || {};

  const color = useSelector((state) =>
    getGroupColor(state?.data?.groups?.[state?.data?.selectedGroupSidebar])
  );

  useEffect(() => {
    dispatch(setSidebarBeforeEvents("dive"));
  }, []);

  const selectChannelUids = createSelector(
    selectSelectedGroupUid,
    selectGroups,
    (groupUid, groups) => {
      if (!groupUid) return [];
      if (!groups) return [];
      if (!groups[groupUid]?.channels) return [];
      return Object.keys(groups[groupUid]?.channels);
    }
  );

  const selectedGroupUid = useSelector(selectSelectedGroupUid);
  const selectedGroupObj = useSelector(
    (state) => state?.data?.groups[selectedGroupUid]
  );

  const selectUserChannelInGroup = createSelector(
    selectChannelUids,
    selectUserChannels,
    (groupChannelIds, userChannelMap) => {
      const userChannelIds = Object.keys(userChannelMap) || [];

      // Filter channels to show only those available to user
      return groupChannelIds?.filter((id) => userChannelIds.includes(id)) || [];
    }
  );

  const channelUids = useSelector(selectUserChannelInGroup);

  const prevScreen = useSelector((state) => state?.data?.sidebarBeforeEvents);

  const flipNavAnimation = prevScreen !== "groups";

  const numEvents = useSelector(
    (state) =>
      Object.values(state?.data?.events ?? {}).filter(
        (eventObj) =>
          eventObj?.date > new Date() &&
          selectedGroupObj?.events?.[eventObj?.uid]
      ).length
  );

  const { currentUnreadCount } = useUnreadData();

  const isEventsSidebarOpen = useSelector(
    (state) => state?.data?.isEventsSidebarOpen
  );

  useEffect(
    () => {
      if (!selectedGroupUid) {
        return;
      }

      dispatch(addCustomEmojiMapListener(selectedGroupUid));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedGroupUid]
  );

  return (
    <SidebarContainer>
      <MotionContainer
        initial={{ x: 400 }}
        animate={{ x: isEventsSidebarOpen ? -100 : 0 }}
        exit={{ x: 400 }}
        transition={{ type: "spring", duration: 0.4, bounce: 0.1 }}
      >
        <LeftChevronContainer
          onMouseDown={() => dispatch(setIsNestedSidebarOpen(false))}
        >
          <BackChevron color={color} />
          {currentUnreadCount ? (
            <LeftChevronUnreadBlock bgColor={color}>
              {currentUnreadCount}
            </LeftChevronUnreadBlock>
          ) : null}
        </LeftChevronContainer>
        <Squircle width={58} groupObj={selectedGroupObj} color={color} />
        <GroupTitleText>{selectedGroupObj?.name}</GroupTitleText>
        <UpcomingEvents number={numEvents} screen="dive" />
        <ShadowDivider bottomShadow={scrollOffset >= 1} />
        <ChatsScrollContainer onScroll={handleChatScroll}>
          {/* <Divider bottomShadow={scrollOffset >= 1} /> */}
          {channelUids &&
            channelUids.map((channelUid, idx) => (
              <Fragment key={channelUid}>
                {/* <SidebarListItem {...placeholder} /> */}
                <NestedChannelListItem
                  groupUid={selectedGroupUid}
                  channelUid={channelUid}
                />
                {/* <Divider key={idx + groups?.length} /> */}
                {idx === channelUids.length - 1 ? <CreateSubchat /> : null}
              </Fragment>
            ))}
        </ChatsScrollContainer>
      </MotionContainer>
    </SidebarContainer>
  );
};

const LeftChevronContainer = styled.div`
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
`;

const LeftChevronUnreadBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 4px 7.5px;
  margin: 0 0 0 6px;
  border-radius: 30px;
  background-color: ${({ bgColor }) => bgColor};
  color: #ffffff;
  font-size: 14px;
`;

const LeftChevron = styled.div`
  width: 17px;
  height: 28px;
  margin-top: 36px;
  margin-left: 24px;
  background-image: url(${backChevron});
  background-size: contain;
`;

const BackChevron = styled(BackChevronSvg)`
  width: 15.3px;
  height: 25.2px;
  fill: ${(props) => props?.color};
`;

const GroupTitleText = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  margin-top: 12px;
  margin-bottom: 12px;
  text-align: center;
  letter-spacing: 0.01em;

  color: #000000;
`;

const ChatsScrollContainer = styled.div`
  width: calc(100% - 16px);
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 16px;
`;

const ShadowDivider = styled.div`
  width: 100%;
  height: 16px;
  box-shadow: ${(props) =>
    props?.bottomShadow ? "0px 3px 4px -2px rgba(24, 39, 75, 0.1);" : ""};
`;

const Divider = styled.div`
  width: 307px;
  height: 0px;
  border-top: 1px solid #f2f2f7;
  margin-bottom: -1px;
  margin-top: -1px;
  margin-left: 91px;
`;

const SidebarContainer = styled.div`
  width: 380px;
  height: 100%;
  background: transparent;
  border-right: 1px solid rgba(0, 0, 0, 0.05);
  /* Shadows/300 */
  flex-shrink: 0;
  box-shadow: 0px 6px 8px -6px rgba(24, 39, 75, 0.1);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: absolute;
  overflow-x: hidden;
  z-index: 2;
  top: 0;
  left: 0;
`;

const MotionContainer = styled(motion.div)`
  display: flex;
  padding-top: 36px;
  justify-content: flex-start;
  flex-direction: column;
  overflow-y: scroll;
  align-items: center;
  position: relative;
  width: 380px;
  height: 100%;
  background: #fafafa;
  border-right: 1px solid rgba(0, 0, 0, 0.05);
  flex-shrink: 0;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  overflow-x: hidden;
  z-index: 2;
  padding-right: 8px;
  padding-left: 8px;
`;

const SidebarTop = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 24px;
  width: 100%;
  margin-top: 16px;
  margin-bottom: 16px;
  height: 50px;
  flex-shrink: 0;
`;

const YourProfileButton = styled.div`
  width: 38px;
  height: 38px;
  background-image: url(${fillerAvatar});
  background-size: contain;
`;

const OrangePlus = styled.div`
  width: 38px;
  height: 38px;
  background-image: url(${orangePlus});
  background-size: contain;
  box-shadow: 0px 6px 8px -6px rgba(24, 39, 75, 0.1);
`;

const SearchBarContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border: none;
  //   width: 90%;
  width: calc(100% - 48px);
  height: 36px;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 100px;
  flex-shrink: 0;
`;

const SearchBarInput = styled.input`
  width: 100%;
  height: 22px;
  margin-left: 8px;
  margin-right: 24px;
  border: none;
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;
  background: transparent;
  &:focus {
    outline: none;
  }
`;

const MagnifyingGlass = styled.div`
  width: 26px;
  height: 24px;
  margin-left: 8px;
  background-image: url(${magnifyingGlass});
  background-size: cover;
`;
