import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { debounce } from "lodash";

import { addTypingIndicator } from "../firebase/channel/addTypingIndicator";
import { dataMyselfSharedDataSelector } from "../store/data";

const MAX_TIPYING_EXTRA_INTERVAL = 30000;

const debouncedAddTypingIndicator = debounce(addTypingIndicator, 750, {
  leading: true,
  trailing: true,
});

export const useTypingIndicatorHandlers = () => {
  // @ts-expect-error
  const channelUid = useSelector((state) => state?.data?.selectedChannel) as
    | string
    | undefined;
  const myselfSharedData = useSelector(dataMyselfSharedDataSelector);

  const myChatName = useMemo(
    () => myselfSharedData?.name ?? "",
    [myselfSharedData]
  ) as string;

  const [isTyping, setIsTyping] = useState<boolean>(false);

  useEffect(() => {
    if (!channelUid || !myChatName) {
      return;
    }
    if (!isTyping) {
      debouncedAddTypingIndicator(channelUid);
    }
  }, [isTyping]);

  useEffect(
    () => () => {
      if (!!channelUid) {
        addTypingIndicator(channelUid);
      }
    },
    []
  );

  const timeoutRef = useRef<NodeJS.Timeout>();
  useEffect(() => {
    if (!isTyping && !!timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = undefined;
    } else if (!!isTyping) {
      if (!!timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(() => {
        setIsTyping(false);
      }, MAX_TIPYING_EXTRA_INTERVAL);
    }

    return () => {
      if (!!timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = undefined;
      }
    };
  }, [isTyping]);

  const onInputTextHandler = (text: string) => {
    if (!!text.length) {
      if (!!channelUid) {
        debouncedAddTypingIndicator(channelUid, Date.now());
      }
      setIsTyping(true);
    } else {
      setIsTyping(false);
    }
  };

  const onSubmitHandler = useCallback(() => {
    setIsTyping(false);
    if (!!timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = undefined;
    }
  }, []);

  return { onInputTextHandler, onSubmitHandler };
};
