import React, { FC } from "react";

import { useSelector } from "../../store";
import { applicationIsOnlineSelector } from "../../store/application";
import { ReactComponent as WiFiCrossedSvg } from "../../assets/wifi-crossed.svg";

import { SidebarBadge } from "./SidebarBadge";

type NoNetworkBadgeProps = {};

export const NoNetworkBadge: FC<NoNetworkBadgeProps> = ({}) => {
  const isOnline = useSelector(applicationIsOnlineSelector) as boolean;

  return (
    <SidebarBadge
      isVisible={!isOnline}
      title="No Connection"
      description="Connect to the internet to get the latest messages."
      icon={<WiFiCrossedSvg />}
    />
  );
};
