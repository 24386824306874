import { getDatabase, ref, onValue } from "firebase/database";

import { logDebug } from "../../util/log.util";
import { ChannelRecord } from "../../type/channel.type";

type GetChannelInfoPayload = {
  channelId: string;
};

export function getChannelInfo({ channelId }: GetChannelInfoPayload) {
  if (!channelId) {
    logDebug("[getChannelInfo] Channel ID isn't provided", { channelId });

    return Promise.reject();
  }

  const realtimeDb = getDatabase();

  return new Promise<ChannelRecord>((resolve, reject) => {
    onValue(ref(realtimeDb, `channels/${channelId}`), (snapshot) => {
      if (!snapshot.exists()) {
        reject();

        return;
      }

      const channelData = snapshot.val() as ChannelRecord;

      resolve(channelData);
    });
  });
}
