import { createSelector } from "@reduxjs/toolkit";

import { AppRootState } from "dive/type/shared.type";

import { formReducerName } from "./form.slice";
import { PageOrModalId } from "./form.type";

export const formSelector = (state: AppRootState) => state[formReducerName];

export const formDataSelector = createSelector(
  formSelector,
  (form) => form.data
);

export const formDataByIdSelector = createSelector(
  [formDataSelector, (state, formId: PageOrModalId) => formId],
  (formData, formId) => formData[formId]
);
