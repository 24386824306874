import { CSSProperties } from "react";
import styled from "styled-components";

export const CustomGroupModalPosterBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  li {
    svg {
      path {
        fill: #000;
      }
    }
  }
`;

type CustomGroupModalPosterViewProps = {
  $backgroundValue: CSSProperties["background"];
};

export const CustomGroupModalPosterView = styled.button<CustomGroupModalPosterViewProps>`
  width: 102px;
  height: 102px;
  border-radius: 24px;
  border-width: 0;
  background: ${({ $backgroundValue }) => $backgroundValue};
  box-shadow: 0px 2px 4px -2px rgba(24, 39, 75, 0.1);
  margin: 18px 0 0 0;
  padding: 0;
  position: relative;
  transition: 0.1s;

  &:hover {
    cursor: pointer;
  }

  &:active {
    transform: scale(0.98);
  }
`;

export const CustomGroupModalPosterGrid = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto auto auto;
  row-gap: 12px;
  justify-content: center;

  div:nth-child(7) {
    grid-column: 2;
  }
`;

export const CustomGroupModalPosterImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 22px;
`;

export const CustomGroupModalPosterBadge = styled.div`
  position: absolute;
  bottom: -2px;
  right: -2px;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  border: 0.5px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 2px 4px -2px rgba(24, 39, 75, 0.1);
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CustomGroupModalEmojiPosterPicker = styled.div`
  position: absolute;
  display: inline-block;
  top: 124px;
  z-index: 10;
`;
