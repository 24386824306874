import { getDatabase, update, ref } from "firebase/database";

type SetAiEventCreatedPayload = {
  channelId: string;
  messageId: string;
};

export function setAiEventCreated({
  channelId,
  messageId,
}: SetAiEventCreatedPayload) {
  const realtimeDb = getDatabase();

  const updates = {
    [`messages/${channelId}/${messageId}/eventAlreadyCreated`]: true,
    [`messages/${channelId}/${messageId}/timestamp`]: Date.now(),
  };

  return update(ref(realtimeDb), updates);
}
