import React, { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import { EmojiClickData } from "emoji-picker-react";

import { ColorId } from "dive/type/theme.type";
import {
  DARK_GRAY_COLOR_ID,
  LIGHT_GRAY_COLOR_ID,
  paletteGlassFills,
} from "dive/constants/palette.constant";
import { logDebug, logWarning } from "dive/util/log.util";
import { UiMenu } from "dive/components/UiMenu";
import { UiMenuOption } from "dive/components/UiMenu/UiMenu.type";
import { EmojiPicker } from "dive/components/EmojiPicker";
import Squircle from "dive/components/Squircle";
import { NewEventFormData } from "dive/store/form";
// @ts-expect-error
import { ReactComponent as SmileSvg } from "dive/assets/smile.svg";
// @ts-expect-error
import { ReactComponent as PhotoLibrarySvg } from "dive/assets/photo-library.svg";

import {
  NewEventModalEmojiPosterPicker,
  NewEventModalPosterBlock,
  NewEventModalPosterCard,
  NewEventModalPosterCardEdit,
  NewEventModalPosterImg,
  NewEventModalPosterPalette,
  NewEventModalPosterPaletteItem,
  NewEventModalPosterPaletteRing,
} from "./NewEventModalPoster.style";
import { getImageDimension } from "dive/util/image.util";

type NewEventModalPosterProps = {
  imageRawContent: string | undefined;
  emoji: string;
  colorId: string;
  onImageRawContentChange: (imageRawContent: NewEventFormData["image"]) => void;
  onEmojiChange: (emoji: string) => void;
  onColorIdChange: (colorId: ColorId) => void;
};

export const NewEventModalPoster: FC<NewEventModalPosterProps> = ({
  imageRawContent,
  emoji,
  colorId,
  onImageRawContentChange,
  onEmojiChange,
  onColorIdChange,
}) => {
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [isEmojiPickerVisible, setIsEmojiPickerVisible] = useState(false);

  const menuRef = useRef<HTMLDivElement>(null);
  const emojiPickerRef = useRef<HTMLDivElement>(null);
  const editPosterButtonRef = useRef<HTMLButtonElement>(null);
  const imageSelectInputRef = useRef<HTMLInputElement>(null);

  const selectedColor = paletteGlassFills.find(({ id }) => id === colorId);

  useEffect(() => {
    const onClickOutside: EventListener = (event) => {
      if (
        // @ts-expect-error
        !editPosterButtonRef.current?.contains(event.target) &&
        // @ts-expect-error
        !menuRef.current?.contains(event.target) &&
        // @ts-expect-error
        !emojiPickerRef.current?.contains(event.target)
      ) {
        setIsMenuVisible(false);
        setIsEmojiPickerVisible(false);
      }
    };

    document.addEventListener("mousedown", onClickOutside);

    return () => {
      document.removeEventListener("mousedown", onClickOutside);
    };
  }, []);

  const eventGlassFills = paletteGlassFills.filter(
    (item) => item.id !== DARK_GRAY_COLOR_ID && item.id !== LIGHT_GRAY_COLOR_ID
  );

  const EMOJI_OPTION_ID = "emoji";
  const IMAGE_OPTION_ID = "image";

  const options: UiMenuOption[] = [
    {
      id: EMOJI_OPTION_ID,
      title: "Choose Emoji",
      icon: <SmileSvg width="22" height="22" />,
    },
    {
      id: IMAGE_OPTION_ID,
      title: "Upload Photo",
      icon: <PhotoLibrarySvg width="22" />,
    },
  ];

  const onFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    if (!event.target?.files) {
      logDebug(`[NewEventModalPoster][onFileChange] No files selected`);

      return;
    }

    if (event.target.files.length > 1) {
      logWarning(
        `[NewEventModalPoster][onFileChange] More than 1 file received`,
        event.target.files
      );
    }

    const imageFile = event.target.files[0];

    let fileReader = new FileReader();

    fileReader.readAsDataURL(imageFile);

    fileReader.onloadend = () => {
      if (!fileReader.result) {
        logDebug(
          `[NewEventModalPoster][onFileChange] Cannot get raw image content`
        );

        return;
      }

      const imageRawContent = fileReader.result.toString();

      getImageDimension(imageRawContent)
        .then((imageLayout) => {
          onImageRawContentChange({
            rawContent: imageRawContent,
            type: imageFile.type,
            width: imageLayout.width,
            height: imageLayout.height,
          });
        })
        .catch((ex) => {
          logDebug(
            `[NewEventModalPoster][onFileChange] Cannot get image size`,
            ex
          );
        });

      fileReader.abort();

      // Reset input state. See more:
      //   https://stackoverflow.com/a/16222877
      if (imageSelectInputRef.current) {
        imageSelectInputRef.current.type = "text";
        imageSelectInputRef.current.type = "file";
        imageSelectInputRef.current.value = "";
      }
    };

    fileReader.onerror = () => {
      logDebug(
        `[NewEventModalPoster][onFileChange][onerror] Cannot get raw image content`,
        fileReader.error
      );
    };
  };

  const onEmojiClick = (emojiData: EmojiClickData) => {
    // @ts-expect-error
    onEmojiChange(emojiData.id || emojiData.emoji);
    onImageRawContentChange(undefined);

    setIsEmojiPickerVisible(false);
  };

  const onOptionClick = (optionId: string) => {
    if (optionId === EMOJI_OPTION_ID) {
      setIsEmojiPickerVisible(true);
    } else if (optionId === IMAGE_OPTION_ID) {
      imageSelectInputRef.current?.click();
    }

    setIsMenuVisible(false);
  };

  if (!selectedColor) {
    logDebug("[NewEventModalPoster] Couldn't find selected color", {
      colorId,
      paletteGlassFills,
    });

    return null;
  }

  const cssBackgroundValue = selectedColor.cssBackgroundValue;

  return (
    <>
      <NewEventModalPosterBlock>
        <NewEventModalPosterCard
          $backgroundValue={cssBackgroundValue}
          ref={editPosterButtonRef}
          onClick={() => setIsMenuVisible(true)}
        >
          {imageRawContent ? (
            <NewEventModalPosterImg src={imageRawContent} />
          ) : (
            <Squircle
              channelEmoji={emoji}
              channelEmojiBg="transparent"
              channelEmojiSize={72}
              channelEmojiFontSize="60px"
              channelEmojiLineHeight="72px"
            />
          )}
          <NewEventModalPosterCardEdit>Edit Cover</NewEventModalPosterCardEdit>
        </NewEventModalPosterCard>
        <NewEventModalPosterPalette>
          {eventGlassFills.map(({ id, cssBackgroundValue }) => (
            <NewEventModalPosterPaletteRing
              key={id}
              $isSelected={id === colorId}
            >
              <NewEventModalPosterPaletteItem
                $backgroundValue={cssBackgroundValue}
                onClick={() => onColorIdChange(id)}
              />
            </NewEventModalPosterPaletteRing>
          ))}
        </NewEventModalPosterPalette>
        <UiMenu
          isVisible={isMenuVisible}
          isDark={true}
          menuElementRef={menuRef}
          options={options}
          initial={{ opacity: 0, scale: 0, y: -45 }}
          animate={{ opacity: 1, scale: 1, y: 0 }}
          exit={{ opacity: 0, scale: 0, y: -45 }}
          topOffsetPx={148}
          onOptionClick={onOptionClick}
        />
        {isEmojiPickerVisible ? (
          <NewEventModalEmojiPosterPicker ref={emojiPickerRef}>
            <EmojiPicker
              isCustomEmojiVisible
              autoFocusSearch
              lazyLoadEmojis
              width={280}
              height={320}
              previewConfig={{ showPreview: false }}
              onEmojiClick={onEmojiClick}
            />
          </NewEventModalEmojiPosterPicker>
        ) : null}
      </NewEventModalPosterBlock>
      <input
        ref={imageSelectInputRef}
        hidden
        multiple={false}
        type="file"
        accept="image/*,"
        onChange={onFileChange}
      />
    </>
  );
};
