import styled from "styled-components";
import { motion } from "framer-motion";

// @ts-expect-error
import { ReactComponent as LeaveFeedbackSvg } from "dive/assets/leaveFeedback.svg";
import { flexCenter } from "dive/constants/DesignSystem";

export const ReportBugButtonBlock = styled.button`
  ${flexCenter}
  position:absolute;
  bottom: 28px;
  left: 24px;
  width: 52px;
  height: 52px;
  border-radius: 50%;
  border-width: 0;
  background: #fafafa;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.15);
  z-index: 2;
  transition: 0.1s;

  &:hover {
    cursor: pointer;
    transform: scale(1.1);
  }

  &:active {
    transform: scale(0.99);
  }
`;

export const ReportBugButtonIcon = styled(LeaveFeedbackSvg)`
  width: 32px;
  height: 32px;
`;

export const ReportBugButtonTooltip = styled(motion.div)`
  ${flexCenter}
  position: absolute;
  bottom: 40px;
  left: 94px;
  width: 116px;
  height: 28px;
  font-size: 12px;
  text-align: center;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  background: #ffffff;
  z-index: 2;

  &:before {
    content: "";
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 8px solid white;
    left: -5px;
    top: 5.5px;
  }
`;
