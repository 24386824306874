import { useCallback } from "react";

import { store } from "../../store/store";
import {
  dataChannelsByIdSelector,
  dataGroupByIdSelector,
  dataSelectedEventIdSelector,
  selectEvent,
  selectGroup,
  selectGroupSidebar,
  selectSidebar,
  setIsEventsSidebarOpen,
  setIsNestedSidebarOpen,
  setSelectedChannel,
} from "../../store/data";
import { useDispatch } from "../../store";
import { clearModalPresentationStack } from "dive/store/ui";

type UseOpenNotificationSource = {
  channelId?: string | null;
  groupId?: string | null;
  eventId?: string | null;
};

export function useOpenNotificationSource() {
  const dispatch = useDispatch();

  return useCallback(
    (payload: UseOpenNotificationSource) => {
      const { channelId, groupId, eventId } = payload;

      const storeSnapshot = store.getState();

      // @ts-expect-error
      const channelData = dataChannelsByIdSelector(storeSnapshot, channelId);
      // @ts-expect-error
      const groupData = dataGroupByIdSelector(storeSnapshot, groupId);
      const selectedEventId = dataSelectedEventIdSelector(storeSnapshot);

      if (eventId) {
        // TODO: Test Event Push Notifications
        // dispatch(selectEvent(eventId));
        // dispatch(setIsEventsSidebarOpen(true));
        // dispatch(setIsNestedSidebarOpen(false));
      } else {
        dispatch(selectGroup(groupId));
        dispatch(selectGroupSidebar(groupId));
        dispatch(selectSidebar(channelId || groupId));

        dispatch(setIsNestedSidebarOpen(false));

        if (selectedEventId) {
          dispatch(selectEvent(null));
          dispatch(setIsEventsSidebarOpen(false));
        }

        setTimeout(() => {
          if (groupData && !groupData.isSimple) {
            dispatch(setIsNestedSidebarOpen(true));
          }

          if (channelData) {
            dispatch(setSelectedChannel(channelId));
          }
        }, 500);
      }

      // Close currently opened modals by resetting modal presentation stack
      dispatch(clearModalPresentationStack());

      // ...
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
}
