import React, { CSSProperties, FC } from "react";

import {
  ColorPaletteRingBlock,
  ColorPaletteRingBlockItem,
} from "./ColorPaletteRing.style";

type ColorPaletteRingProps = {
  isSelected: boolean;
  id: string;
  cssBgValue: CSSProperties["background"];
};

export const ColorPaletteRing: FC<ColorPaletteRingProps> = ({
  isSelected,
  cssBgValue,
  id,
}) => {
  return (
    <ColorPaletteRingBlock $isSelected={isSelected} data-id={id}>
      <ColorPaletteRingBlockItem $backgroundValue={cssBgValue} />
    </ColorPaletteRingBlock>
  );
};
