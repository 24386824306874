import React, { FC, PropsWithChildren, useCallback, useEffect } from "react";

import { useDispatch, useSelector } from "../../store";
import {
  applicationIsOnlineSelector,
  applicationIsUpdateAlertVisibleSelector,
  applicationLatestDesktopAppVersionSelector,
  setIsUpdateAlertVisible,
} from "../../store/application";
import { UpdateAlert } from "../../components/UpdateAlert";
import { getIsUpdateAvailableState } from "../../util/update.util";
import { logDebug, logWarning } from "../../util/log.util";

type UpdateAvailableProviderProps = PropsWithChildren<{}>;

export const UpdateAvailableProvider: FC<UpdateAvailableProviderProps> = ({
  children,
}) => {
  const dispatch = useDispatch();

  const latestDesktopAppVersion = useSelector(
    applicationLatestDesktopAppVersionSelector
  );

  const isUpdateAvailableAlertVisible = useSelector(
    applicationIsUpdateAlertVisibleSelector
  );

  const isOnline = useSelector(applicationIsOnlineSelector);

  useEffect(
    () => {
      // If the app hasn't retrieved latest version yet,
      //   or if the update alert is already presented
      //   or if the user is offline
      if (
        !latestDesktopAppVersion ||
        isUpdateAvailableAlertVisible ||
        !isOnline
      ) {
        return;
      }

      (async () => {
        if (!window.diveNativeDesktopApi) {
          return;
        }

        let currentAppVersion;

        try {
          currentAppVersion = await window.diveNativeDesktopApi.getAppVersion();
        } catch (ex) {
          logWarning(`[UpdateAvailableProvider] Cannot get app version`, ex);

          return;
        }

        logDebug(`[UpdateAvailableProvider] Checking app version`, {
          currentAppVersion,
          latestDesktopAppVersion,
        });

        const isUpdateAvailable = getIsUpdateAvailableState(
          currentAppVersion,
          latestDesktopAppVersion
        );

        if (!isUpdateAvailable) {
          logDebug(`[UpdateAvailableProvider] No new app version available`, {
            currentAppVersion,
            latestDesktopAppVersion,
          });

          return;
        }

        logDebug(`[UpdateAvailableProvider] Starting to download new version`, {
          currentAppVersion,
          latestDesktopAppVersion,
        });

        try {
          const arch = window.diveNativeDesktopApi.getArch();

          // TODO: Handle other platforms (e.g. Windows)
          const updateUrl = arch.includes("arm")
            ? `https://storage.googleapis.com/dive-67320-public/desktop_app_downloads/latest/arm64.zip`
            : `https://storage.googleapis.com/dive-67320-public/desktop_app_downloads/latest/x64.zip`;

          await window.diveNativeDesktopApi.downloadAppUpdate(updateUrl);
        } catch (ex) {
          logWarning(
            `[UpdateAvailableProvider] Cannot download new version`,
            ex
          );

          return;
        }

        dispatch(setIsUpdateAlertVisible(isUpdateAvailable));
      })();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [latestDesktopAppVersion]
  );

  const onUpdateNativeClient = useCallback(() => {
    if (!window.diveNativeDesktopApi) {
      return Promise.reject("Desktop app API isn't available");
    }

    return window.diveNativeDesktopApi?.installAppUpdate();
  }, []);

  return (
    <>
      {children}
      {isUpdateAvailableAlertVisible ? (
        <UpdateAlert onUpdateNativeClient={onUpdateNativeClient} />
      ) : null}
    </>
  );
};
