import { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { DatabaseReference, off } from "firebase/database";

import { dataMyselfIdSelector } from "../store/data";
import { useUsersLazily } from "./useUsersLazily";
import { integerToEnglish } from "../util/number.util";
import { listenForTypingInChannel } from "../firebase/channel/listenForTypingInChannel";

export const useTypingIndicatorText = (): string | undefined => {
  // @ts-expect-error
  const channelUid = useSelector((state) => state?.data?.selectedChannel) as
    | string
    | undefined;
  const myselfUid = useSelector(dataMyselfIdSelector) as string | undefined;
  const listener = useRef<DatabaseReference>();

  const [userUids, setUserUids] = useState<string[]>([]);
  const users = useUsersLazily(userUids);

  const typingText = useMemo(() => {
    if (!users || !users?.length) {
      return undefined;
    }
    if (users.length === 1) {
      if (!!users[0]?.name) {
        return `${users[0]?.name} is typing`;
      }
    } else {
      return `${integerToEnglish(users.length)} people are typing`;
    }
  }, [users]);

  useEffect(() => {
    if (!channelUid) {
      return;
    }
    if (!!listener.current) {
      off(listener.current);
      listener.current = undefined;
    }
    listener.current = listenForTypingInChannel(channelUid, (snapshot) => {

      if (snapshot.exists()) {
        const channelTypingRecords: Record<string, number> = snapshot.val();
        if (myselfUid) {
          delete channelTypingRecords[myselfUid];
        }
        const userUids = Object.keys(channelTypingRecords);
        setUserUids(userUids);
      } else {
        setUserUids([]);
      }
    });

    return () => {
      if (!!listener.current) {
        off(listener.current);
        listener.current = undefined;
      }
    };
  }, [channelUid]);

  return typingText;
};
