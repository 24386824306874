import { PUBLIC_SUBCHAT_TYPE } from "dive/constants/subchat.contant";
import { useFormData } from "dive/hook/useFormData.hook";
import { NewSubchatFormData } from "dive/store/form";
import { NEW_SUBCHAT_MODAL_ID } from "dive/store/ui";

export function useNewSubchatModalFormData() {
  return useFormData<NewSubchatFormData>(NEW_SUBCHAT_MODAL_ID, {
    emoji: "",
    name: "",
    type: PUBLIC_SUBCHAT_TYPE,
    isAutoAdd: false,
  });
}
