import React, { useEffect } from "react";
import styled from "styled-components";
import Squircle from "./Squircle.js";

import pinIcon from "../assets/pin.svg";
import muteIcon from "../assets/mute.svg";
import { ReactComponent as LockIcon } from "../assets/private.svg";

import {
  selectGroup,
  selectChannel,
  selectSidebar,
  setIsNestedSidebarOpen,
  addUserListener,
  selectEvent,
  selectGroupSidebar,
} from "../store/data";
import { useDispatch, useSelector } from "react-redux";
import LoadingListItem from "./LoadingListItem.js";

import { truncateStringForListItem } from "../util";

import analytics from "../util/analytics";

const SidebarListItem = (props) => {
  const isSelected = useSelector((state) => {
    return (
      state?.data?.selectedSidebar === props?.channelUid ||
      state?.data?.selectedSidebar === props?.groupObj?.uid ||
      Object.keys(props?.groupObj?.channels ?? {})?.includes(
        state?.data?.selectedSidebar
      )
    );
  });
  const dispatch = useDispatch();

  const selectedGroupSidebar = useSelector(
    (state) => state?.data?.selectedGroupSidebar
  );

  useEffect(() => {
    if (props?.header === "DM") {
      dispatch(addUserListener(props?.userUid));
    }
  }, [props?.header]);

  const showLoading = false;

  if (showLoading) {
    return;
  }

  if (props?.header === "DM") {
    return <></>;
  }

  if (!props?.header) {
    return <LoadingListItem></LoadingListItem>;
  }

  return (
    <GroupListItemContainer
      isPinned={props?.isPinned}
      isUnread={props?.isUnread}
      createdAt={props?.createdAt}
      timestamp={props?.timestamp}
      isSelected={isSelected}
      isMainChat={props?.isMainChat}
      onClick={() => {
        if (props?.groupObj) {
          if (props?.groupObj?.isSimple) {
            dispatch(selectSidebar(props?.groupObj?.uid));
            dispatch(selectGroup(props?.groupObj?.uid));
            dispatch(selectChannel(Object.keys(props?.groupObj?.channels)[0]));
            analytics.track("web_channel_view", {
              channelUid: Object.keys(props?.groupObj?.channels)[0],
              groupUid: props?.groupObj?.uid,
            });
            dispatch(selectEvent(null));
          } else {
            dispatch(selectGroupSidebar(props?.groupObj?.uid));
            dispatch(setIsNestedSidebarOpen(true));
          }
        } else if (props?.channelUid) {
          dispatch(selectSidebar(props?.channelUid));
          dispatch(selectChannel(props?.channelUid));
          dispatch(selectGroup(selectedGroupSidebar));
          analytics.track("web_channel_view", {
            channelUid: props?.channelUid,
            groupUid: selectedGroupSidebar,
          });
          dispatch(selectEvent(null));
        }
      }}
    >
      {props?.isNew && (
        <NewIndicator isUnread={props?.isUnread}>NEW</NewIndicator>
      )}
      {props?.isUnread && <UnreadPip />}

      <Squircle
        width={46}
        groupObj={props?.groupObj}
        userUid={props?.userUid}
        color={props?.color}
        channelEmoji={props?.channelEmoji}
        indicator={
          props?.isPublic === false ? (
            <IconIndicatorContainer isSelected={isSelected}>
              <LockIcon width={10} height={10} />
            </IconIndicatorContainer>
          ) : null
        }
      />
      <VerticalFlexContainer>
        <HorizontalFlexContainer>
          <HeaderText>{props?.header}</HeaderText>
          <TimeText>{props?.time}</TimeText>
        </HorizontalFlexContainer>
        <PreviewText shrink={props?.isPinned || props?.isMuted}>
          {props?.preview ?? "Get the chat started!"}
        </PreviewText>
      </VerticalFlexContainer>
      <IndicatorFlexContainer>
        {props?.isPinned && <PinIcon />}
        {props?.isMuted && <MuteIcon />}
      </IndicatorFlexContainer>
    </GroupListItemContainer>
  );
};

export default SidebarListItem;

const PinIcon = styled.div`
  width: 11.52px;
  height: 17.28px;
  background-image: url(${pinIcon});
  background-size: cover;
  margin-top: 3px;
  margin-bottom: 3px;
`;

const MuteIcon = styled.div`
  width: 11.52px;
  height: 12.96px;
  background-image: url(${muteIcon});
  background-size: cover;
  margin-top: 3px;
  margin-bottom: 3px;
`;

const GroupListItemContainer = styled.div`
  // width: calc(100%);
  width: 100%;
  height: 86px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 16px;
  padding-left: ${(props) => (props?.isUnread ? "0px" : "22px")};
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 16px;
  flex-shrink: 0;
  position: relative;
  order: ${(props) => {
    if (props?.isPinned) {
      return parseInt(props?.timestamp / -1000) - 20000000;
    }
    if (!props?.timestamp) {
      if (!props?.createdAt) {
        return "0";
      }
      return parseInt(props?.createdAt / -1000);
    }
    return parseInt(props?.timestamp / -1000);
  }};
  //guarantees main chat is always at the top
  ${(props) =>
    props?.isMainChat && `order: -16504071360000; background: #fff;`};
  ${(props) =>
    props?.isMainChat && "box-shadow: 0px 2px 4px -2px rgba(24, 39, 75, 0.1);"}
  ${(props) =>
    props?.isMainChat &&
    "height: 92px; padding-top: 10px; margin: 10px 0; border-width: 0.5px 0px;border-style: solid;border-color: rgba(0, 0, 0, 0.1); box-shadow: 0px 0px 4px -1px rgba(24, 39, 75, 0.3);"}
    ${(props) => props?.isSelected && "background: #e1e1e1;"}
    &:hover {
    background: #e1e1e1;
    cursor: pointer;
  }
`;

const UnreadPip = styled.div`
  width: 10px;
  height: 10px;
  margin-right: 6px;
  margin-left: 6px;

  /* Solid/Blue */

  background: #007aff;
  border-radius: 50%;
  flex-shrink: 0;
`;

const Avatar = styled.div`
  width: 52px;
  height: 52px;
  flex-shrink: 0;
`;

const VerticalFlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  padding-left: 0px;
  margin-left: 14px;
  //   margin-right: 16px;
  flex-grow: 1;
`;

const IndicatorFlexContainer = styled.div`
  display: flex;
  width: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-grow: 0;
  flex-shrink: 0;
  position: absolute;
  right: 10px;
  padding-top: 25px;
  margin-left: 0px;
`;

const IconIndicatorContainer = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${(props) =>
    props?.isSelected ? `rgb(214, 214, 214)` : `rgb(233, 233, 233)`};
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 1px;
  right: 1px;
  border: 1px solid white;
  ${GroupListItemContainer}:hover & {
    background-color: rgb(214, 214, 214);
  }
`;

const HorizontalFlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  // width: calc(100% + 28px);
  width: 100%;
  height: 25px;
  flex-grow: 0;
  overflow: hidden;
`;

const HeaderText = styled.div`
  flex: 1;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0em;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

const TimeText = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #8e8e93;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

const PreviewText = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.01em;
  max-width: ${(props) => (props.shrink ? "220px" : "250px")};

  color: #8e8e93;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  margin-top: 2px;
  -webkit-box-orient: vertical;

  word-wrap: break-word;
  hyphens: auto;
`;

const NewIndicator = styled.div`
  position: absolute;
  width: 41px;
  height: 17px;
  display: flex;
  justify-content: center;
  align-items: center;

  background: linear-gradient(89.06deg, #ff007a 1.42%, #ff6b00 99.4%);
  border-radius: 100px;
  transform: translate(
      ${(props) => (!props?.isUnread ? "-12px" : "10px")},
      -24px
    )
    rotate(-10deg);
  z-index: 1;
  overflow: visible;
  font-weight: 600;
  font-size: 11px;
  line-height: 13px;
  /* identical to box height, or 118% */

  text-align: center;
  letter-spacing: 0.02em;
  padding-bottom: 2px;

  /* UI Tones/Light/White */

  color: #ffffff;
`;
