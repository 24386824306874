import styled from "styled-components";

export const NewEventModalPosterBlock = styled.div`
  margin: 18px 18px 0 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
`;

type NewEventModalPosterCardProps = {
  $backgroundValue: string;
};

export const NewEventModalPosterCard = styled.button<NewEventModalPosterCardProps>`
  position: relative;
  width: 129px;
  height: 129px;
  background: ${({ $backgroundValue }) => $backgroundValue};
  border-width: 0;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 60px;
  margin: 0 0 32px 0;
  padding: 0;
  transition: 0.1s;

  &:hover {
    cursor: pointer;
  }

  &:active {
    transform: scale(0.98);
  }
`;

export const NewEventModalPosterImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 24px;
`;

export const NewEventModalEmojiPosterPicker = styled.div`
  position: absolute;
  display: inline-block;
  top: 148px;
  z-index: 10;
`;

export const NewEventModalPosterCardEdit = styled.div`
  border-radius: 100px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: var(--UI-Tones-Light-White, #fff);
  box-shadow: 0px 4px 6px -4px rgba(24, 39, 75, 0.1);
  color: var(--UI-Tones-Dark-Black, #000);
  text-align: center;
  font-family: SF Pro Rounded;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  text-align: center;
  height: 28px;
  padding: 0 12px;
  position: absolute;
  bottom: -14px;
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
`;

export const NewEventModalPosterPalette = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  row-gap: 12px;
`;

export const NewEventModalPosterPaletteItem = styled.button<NewEventModalPosterCardProps>`
  background: ${({ $backgroundValue }) => $backgroundValue};
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.1);

  &:hover {
    cursor: pointer;
  }

  &:active {
    transform: scale(0.98);
  }
`;

type NewEventModalPosterPaletteRingProps = {
  $isSelected: boolean;
};

export const NewEventModalPosterPaletteRing = styled.div<NewEventModalPosterPaletteRingProps>`
  margin: 0 6px;
  border-radius: 50%;
  border: 4px solid
    ${({ $isSelected }) => ($isSelected ? "#fff" : "transparent")};
`;
