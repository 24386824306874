import styled from "styled-components";
import DatePicker from "react-datepicker";

import { Input } from "dive/components/Input";
import { TextArea } from "dive/components/TextArea";

export const NewEventModalInfoBlock = styled.div`
  margin: 18px 18px 0 18px;
`;

export const NewEventModalInfoSection = styled.div`
  margin: 18px 0 0 0;
  border-radius: 16px;
  border-radius: 16px;
  border: 0.5px solid rgba(255, 255, 255, 0.1);
  background: rgba(0, 0, 0, 0.2);
`;

export const NewEventModalInfoLocationSection = styled(
  NewEventModalInfoSection
)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 0 0 18px;

  svg {
    fill: #ffffff;
    opacity: 0.5;
  }
`;

export const NewEventModalInfoTitle = styled(Input)`
  color: #fff;
  background: transparent;
  border-width: 0;
  height: 52px;
  width: calc(100% - 42px);
  margin: 0 18px;
  font-family: Clash Display !important;
  font-size: 28px;
  line-height: 34px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  ::placeholder {
    color: #ffffff;
    opacity: 0.5;
  }

  &:focus {
    outline: none;
  }
`;

export const NewEventModalInfoLabel = styled.div`
  margin: 12px 18px 6px 18px;
  color: #e5e5ea;
  font-family: SF Pro Rounded;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.34px;
`;

export const NewEventModalInfoTextarea = styled(TextArea)`
  color: #fff;
  font-family: SF Pro Rounded;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.34px;
  background: transparent;
  border-width: 0;
  margin: 0 18px 18px 18px;
  width: calc(100% - 40px) !important;
  min-width: calc(100% - 40px);
  max-width: calc(100% - 40px);
  height: 160px;
  max-height: 160px;
  min-height: 160px;
  resize: none;

  ::placeholder {
    color: #ffffff;
    opacity: 0.5;
  }

  &:focus {
    outline: none;
  }
`;

export const NewEventModalInfoLocation = styled(Input)`
  color: #fff;
  background: transparent;
  border-width: 0;
  width: 100%;
  height: 45px;
  padding: 0 12px;

  font-family: SF Pro Rounded;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 131.25% */
  letter-spacing: 0.32px;

  ::placeholder {
    color: #ffffff;
    opacity: 0.5;
  }

  &:focus {
    outline: none;
  }
`;

export const NewEventModalInfoDateBlock = styled.div`
  margin: 18px 0 0 0;
  padding: 0 18px;
  border-radius: 16px;
  border: 0.5px solid rgba(255, 255, 255, 0.1);
  background: rgba(0, 0, 0, 0.2);
`;

export const NewEventModalInfoDateSection = styled.div`
  height: 56px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const NewEventModalInfoDateSectionSeparator = styled.div`
  height: 0.5px;
  background: rgba(255, 255, 255, 0.1);
`;

export const NewEventModalInfoDateSectionText = styled.div`
  flex: 1;
  color: #fff;
  font-family: SF Pro Rounded;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.34px;
`;

export const NewEventModalInfoDateSectionPicker = styled(DatePicker)`
  width: ${({ $width }) => $width};
  margin: ${({ $margin = "0" }) => $margin};
  height: 34px;
  border-width: 0;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.1);
  padding: 0 12px;
  color: #fff;
  font-family: SF Pro Rounded;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.34px;
  text-align: center;

  &:hover {
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }
`;
