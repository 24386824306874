import axios from "axios";
import { getDatabase } from "firebase/database";
import { getAuth } from "firebase/auth";

import { NewEventFormData } from "dive/store/form";
import { logDebug } from "dive/util/log.util";
import { CreateEventRequestPayload } from "dive/type/event.type";
import storage from "dive/firebase/storage";
import { IMAGE_MAX_WIDTH } from "dive/constants/image";
import { getFileFromDataUrl } from "dive/util/file.util";
import { FileMimeMap } from "dive/constants/mime.constant";

type CreateWebEventPayload = NewEventFormData;

export async function createWebEvent(payload: CreateWebEventPayload) {
  if (!payload) {
    logDebug("[createWebEvent] The payload wasn't provided", {
      payload,
    });

    return;
  }

  const realtimeDb = getDatabase();
  const authSession = getAuth();

  const userId = authSession.currentUser?.uid;

  if (!userId) {
    logDebug("[createWebEvent] Cannot retrieve current user ID");

    return;
  }

  const idToken = await authSession.currentUser?.getIdToken();

  if (!idToken) {
    logDebug("[createWebEvent] Cannot retrieve an ID token of current user");

    return;
  }

  const projectId = realtimeDb.app.options.projectId;

  if (!projectId) {
    logDebug("[createWebEvent] Cannot retrieve Firebase project ID");

    return;
  }

  const formData: CreateEventRequestPayload = {
    title: payload.title,
    emoji: payload.emoji || "🔥",
    location: payload.location,
    description: payload.description,
    eventCoverType: "emoji",
    isAllDay: payload.isAllDay,
    startTime: payload.startTime,
    endTime: payload.endTime,
    source: {
      emoji: payload.emoji,
      background: payload.colorId,
    },
  };

  if (payload.image) {
    // @ts-expect-error
    const fileExt: string = FileMimeMap[payload.image.type];

    const imageFile = await getFileFromDataUrl(payload.image.rawContent, {
      fileName: `${Date.now()}.${fileExt}`,
      mimeType: payload.image.type,
    });

    const imageUrl = await storage.uploadFileToStorage(
      imageFile,
      IMAGE_MAX_WIDTH
    );

    // @ts-expect-error
    formData.eventCoverType = "photo";
    formData.source = {
      // @ts-expect-error
      width: payload.image.width,
      height: payload.image.height,
      url: imageUrl,
    };
  }

  const config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
      "Content-Type": "application/json",
    },
  };

  return axios.post<{ eventUid: string }>(
    `https://us-central1-${projectId}.cloudfunctions.net/createEvent`,
    {
      ...formData,
    },
    config
  );
}
