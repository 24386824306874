import { FC } from "react";

import {
  NEW_DM_MODAL_ID,
  CUSTOM_GROUP_MODAL_ID,
  NEW_EVENT_MODAL_ID,
  NEW_GROUP_MODAL_ID,
  NEW_SUBCHAT_MODAL_ID,
  UiModalId,
} from "dive/store/ui";
import { NewGroupModal } from "dive/components/NewGroupModal";
import { CustomGroupModal } from "dive/components/CustomGroupModal";
import { NewEventModal } from "dive/components/NewEventModal";
import { NewSubchatModal } from "dive/components/NewSubchatModal";

import { ModalProps } from "./ModalProvider.type";

export const modalComponentMap: Record<UiModalId, FC<ModalProps>> = {
  [NEW_GROUP_MODAL_ID]: NewGroupModal,
  [CUSTOM_GROUP_MODAL_ID]: CustomGroupModal,
  [NEW_EVENT_MODAL_ID]: NewEventModal,
  [NEW_DM_MODAL_ID]: () => null,
  [NEW_SUBCHAT_MODAL_ID]: NewSubchatModal,
};
