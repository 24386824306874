import * as Sentry from "@sentry/react";

import { logDebug } from "./log.util";

export function initErrorLogger() {
  if (process.env.NODE_ENV === "development") {
    logDebug(
      "[initErrorLogger] Attempting to init Sentry logger in the Development mode"
    );

    return;
  }

  const SENTRY_DSN = `https://95bd12177b35ec2cd16b7448a5952bc1@o553691.ingest.sentry.io/4506734093795328`;

  Sentry.init({
    dsn: SENTRY_DSN,
    // See list of integrations (some of them enabled by default):
    //   https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/
    integrations: [Sentry.browserTracingIntegration()],
    tracesSampleRate: 1.0,
    tracePropagationTargets: ["localhost"],
  });
}

export function logException(error: any, extra: Record<string, unknown>) {
  return Sentry.captureException(error, extra);
}

// The ID can be `null` if user logs out
export function setUserId(userId: string | null) {
  return Sentry.setUser(userId ? { id: userId } : null);
}

export function setTag(key: string, value: string | number | boolean) {
  return Sentry.setTag(key, value);
}

export function getReduxEnhancer() {
  const sentryReduxEnhancer = Sentry.createReduxEnhancer({
    attachReduxState: false,
    actionTransformer: (action) => {
      if (!action) {
        return null;
      }

      const { type } = action;

      // The action should not contain a "payload" or any other field
      //   otherwise we might send user's personal data to Sentry's APIs
      //   as action's payload can include message text, media URLs, etc.
      return {
        type,
      };
    },
  });

  return sentryReduxEnhancer;
}
