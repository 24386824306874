import { useSelector } from "react-redux";

import {
  dataGroupByIdSelector,
  dataLastViewedMapSelector,
  dataMessagesSelector,
} from "../store/data";

export function useGroupUnreadPipStatus(groupId) {
  const messageMap = useSelector(dataMessagesSelector);
  const lastViewedMap = useSelector(dataLastViewedMapSelector);
  const group = useSelector((st) => dataGroupByIdSelector(st, groupId));

  if (group) {
    return !!Object.keys(group.channels).find((channelId) => {
      const channelMessageList = Object.values(messageMap?.[channelId] || {})
        // Filter group channel to exclude those we don't count as un-read
        .filter(
          (messageData) =>
            !messageData?.isNewUserMessage && !messageData?.isRemoveUserMessage
        )
        .sort(
          (left, right) => (right?.createdAt || 0) - (left?.createdAt || 0)
        );

      return lastViewedMap?.[channelId] < channelMessageList[0]?.createdAt;
    });
  }

  return false;
}
