import { getDatabase, ref, get } from "firebase/database";

import { logDebug } from "../../util/log.util";
import { User } from "../../type/user.type";

export function getProfileSharedData(userId: string) {
  if (!userId) {
    logDebug(`[getProfileSharedData] User ID is not defined`);

    return Promise.reject();
  }

  const realtimeDb = getDatabase();

  return get(ref(realtimeDb, `users/${userId}/sharedData`)).then((snapshot) => {
    if (!snapshot.exists()) {
      logDebug(`[getProfileSharedData] Snapshot doesn't exist`);

      throw new Error("User doesn't exist");
    }

    const userData = snapshot.val() as User;

    return userData;
  });
}
