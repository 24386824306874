import React, { FC } from "react";
import { useSelector } from "react-redux";
import { AnimatePresence } from "framer-motion";

import {
  dataIsEventSidebarOpenSelector,
  dataIsNestedSidebarOpenSelector,
} from "dive/store/data";
import { GroupList } from "dive/components/GroupList";
import { NestedSidebar } from "dive/components/NestedSidebar";
import EventsSidebar from "dive/components/EventsSidebar.js";

import { NotificationPermissionBadge } from "./NotificationPermissionBadge";
import { NoNetworkBadge } from "./NoNetworkBadge";
import { ReportBugButton } from "./ReportBugButton";

type LeftSidebarProps = {};

export const LeftSidebar: FC<LeftSidebarProps> = () => {
  const isNestedSidebarOpen = useSelector(dataIsNestedSidebarOpenSelector);
  const isEventsSidebarOpen = useSelector(dataIsEventSidebarOpenSelector);

  return (
    <>
      <GroupList key="groupList" />
      <AnimatePresence>
        {isNestedSidebarOpen ? <NestedSidebar key="nestedSidebar" /> : null}
        {isEventsSidebarOpen ? <EventsSidebar key="eventsSidebar" /> : null}
      </AnimatePresence>
      <NotificationPermissionBadge />
      <NoNetworkBadge />
      <ReportBugButton />
    </>
  );
};
