import styled from "styled-components";

import ContentEditable from "react-contenteditable";

export const TemplateInputContainer = styled(ContentEditable)`
  z-index: 2;
  white-space: pre-wrap;
  height: auto;

  &::before {
    pointer-events: none;
    z-index: 1;
    position: absolute;
    left: 24px;
    content: "${({ placeholder }) => placeholder}";
    color: #8e8e93;
    opacity: ${({ html }) => (html ? 0 : 1)};
  }
`;
