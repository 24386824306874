import styled, { CSSProperties } from "styled-components";
import { HTMLMotionProps, motion } from "framer-motion";

type UiMenuBlockProps = HTMLMotionProps<"div"> & {
  $top?: CSSProperties["top"];
  $right?: CSSProperties["right"];
  $bottom?: CSSProperties["bottom"];
  $left?: CSSProperties["left"];
  $isDark?: boolean;
};

// @ts-expect-error
export const UiMenuBlock = styled<UiMenuBlockProps>(motion.ul)`
  position: absolute;
  ${({ $top }) => ($top ? `top: ${$top};` : "")}
  ${({ $right }) => ($right ? `right: ${$right};` : "")}
  ${({ $bottom }) => ($bottom ? `bottom: ${$bottom};` : "")}
  ${({ $left }) => ($left ? `left: ${$left};` : "")}
  width: 200px;
  font-size: 12px;
  text-align: center;
  box-shadow: 0px 1px 26px 0px
    ${({ $isDark }) =>
      $isDark ? "rgba(0, 0, 0, 0.35)" : "rgba(0, 0, 0, 0.15)"};
  border-radius: 12px;
  background: ${({ $isDark }) =>
    $isDark ? "rgba(0, 0, 0, 0.4)" : "rgba(255, 255, 255, 0.8)"};
  backdrop-filter: blur(25px);
  z-index: 2;
  overflow: hidden;
`;

type UiMenuSeparatorProps = {
  $isDark?: boolean;
};

export const UiMenuSeparator = styled.li<UiMenuSeparatorProps>`
  height: 1px;
  background: ${({ $isDark }) =>
    $isDark ? "rgba(255, 255, 255, 0.1)" : "rgba(0, 0, 0, 0.05)"};
`;

type UiMenuOptionBlockProps = {
  $isDark?: boolean;
};

export const UiMenuOptionBlock = styled.li<UiMenuOptionBlockProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 42px;

  &:hover {
    cursor: pointer;
    background: ${({ $isDark }) =>
      $isDark ? "rgba(255, 255, 255, 0.1)" : "rgba(0, 0, 0, 0.05)"};
  }
`;

type UiMenuOptionTextProps = {
  $isDark?: boolean;
};

export const UiMenuOptionText = styled.div<UiMenuOptionTextProps>`
  pointer-events: none;
  flex: 1;
  margin: 0 16px;
  color: ${({ $isDark }) => ($isDark ? "#FFF" : "#000")};
  font-feature-settings: "clig" off, "liga" off;
  text-overflow: ellipsis;
  text-align: left;
  white-space: nowrap;
  font-family: SF Pro;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.4px;
`;

export const UiMenuOptionIcon = styled.div`
  pointer-events: none;
  width: 40px;
  margin: 0 16px 0 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;
