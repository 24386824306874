import { createAsyncThunk } from "@reduxjs/toolkit";

import { addToGroup } from "../../../firebase/group/addToGroup";

export const addUserToGroup = createAsyncThunk(
  "data/ADD_USER_TO_GROUP",
  ({ groupId, userIds }: { groupId: string; userIds: string[] }) => {
    return addToGroup(groupId, {
      userIds,
    });
  }
);
