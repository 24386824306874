import React, {
  FC,
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
} from "react";

import { useDispatch, useSelector } from "../../store";
import {
  setIsOnline,
  setLatestDesktopAppVersion,
  setLayout,
} from "../../store/application";
import { getDesktopVersion } from "../../firebase/application/getDesktopVersion";
import { logDebug, logWarning } from "../../util/log.util";
import { addDesktopVersionListener } from "../../store/application/thunk/addDesktopVersionListener";
import analytics from "../../util/analytics";
import * as crashlytic from "dive/util/crashlytic.util";
import { dataMyselfIdSelector } from "dive/store/data";

type ApplicationProviderProps = PropsWithChildren<{}>;

export const ApplicationProvider: FC<ApplicationProviderProps> = ({
  children,
}) => {
  const [isReady, setIsReady] = useState(false);

  const dispatch = useDispatch();

  const hasShownNoNetworkAlertRef = useRef(false);

  // @ts-expect-error
  const myId = useSelector(dataMyselfIdSelector);

  useEffect(
    () => {
      const getNetworkStateHandler = () => {
        return async () => {
          const isOnline = window.navigator.onLine;

          dispatch(setIsOnline(isOnline));

          let isWindowFocused;

          if (window.diveNativeDesktopApi?.getIsWindowFocused) {
            isWindowFocused =
              await window.diveNativeDesktopApi?.getIsWindowFocused();
          }

          if (
            isWindowFocused &&
            !isOnline &&
            !hasShownNoNetworkAlertRef.current
          ) {
            window.diveNativeDesktopApi?.showNoNetworkDialog();

            hasShownNoNetworkAlertRef.current = true;
          }

          if (
            // In case the user connects to the network again, attempt to close it programmatically
            isOnline
          ) {
            window.diveNativeDesktopApi?.hideNoNetworkDialog();
          }
        };
      };

      // Check connection initially before all events
      getNetworkStateHandler()();

      const onOnline = getNetworkStateHandler();
      const onOffline = getNetworkStateHandler();

      window.addEventListener("online", onOnline);
      window.addEventListener("offline", onOffline);

      return () => {
        window.removeEventListener("online", onOnline);
        window.removeEventListener("offline", onOffline);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(
    () => {
      (async () => {
        try {
          const currentAppVersion =
            await window.diveNativeDesktopApi?.getAppVersion();

          if (!currentAppVersion) {
            throw new Error("Incorrect app version");
          }

          // Register new super property for Mixpanel
          // See more in docs:
          //   https://github.com/mixpanel/mixpanel-js/blob/v2.45.0/doc/readme.io/javascript-full-api-reference.md#mixpanelregister
          analytics.register({ desktop_version: currentAppVersion });

          crashlytic.setTag("desktop_version", currentAppVersion);
        } catch (ex) {
          logWarning(`[ApplicationProvider] Cannot get app version`, ex);
        }

        setIsReady(true);
      })();

      getDesktopVersion()
        .then((desktopAppVersion) => {
          logDebug(
            `[ApplicationProvider] Desktop app version`,
            desktopAppVersion
          );

          // Set desktop app version value to the Redux
          dispatch(setLatestDesktopAppVersion(desktopAppVersion));

          // ... and add DB listener for its value
          dispatch(addDesktopVersionListener());
        })
        .catch((ex) => {
          logWarning(
            `[ApplicationProvider] Cannot get desktop app version`,
            ex
          );
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(
    () => {
      const onWindowResize = () => {
        dispatch(
          setLayout({
            innerWidth: window.innerWidth,
            innerHeight: window.innerHeight,
          })
        );
      };

      // Set initial values
      onWindowResize();

      window.addEventListener("resize", onWindowResize);

      return () => {
        window.removeEventListener("resize", onWindowResize);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    crashlytic.setUserId(myId ? myId : null);
  }, [myId]);

  return isReady ? <>{children}</> : null;
};
