export const deviceSizes: { key: keyof typeof sizes; sizePx: number }[] = [
  { key: "mobileS", sizePx: 320 },
  { key: "mobileM", sizePx: 375 },
  { key: "mobileL", sizePx: 425 },
  { key: "tablet", sizePx: 768 },
  { key: "laptop", sizePx: 1024 },
  { key: "laptopL", sizePx: 1440 },
  { key: "desktopS", sizePx: 1920 },
  { key: "desktop", sizePx: 2560 },
];

export const sizes = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tablet: "768px",
  laptop: "1024px",
  laptopL: "1440px",
  desktopS: "1920px",
  desktop: "2560px",
};

export const devices = {
  mobileS: `(min-width: ${sizes.mobileS})`,
  mobileM: `(min-width: ${sizes.mobileM})`,
  mobileL: `(min-width: ${sizes.mobileL})`,
  tablet: `(min-width: ${sizes.tablet})`,
  laptop: `(min-width: ${sizes.laptop})`,
  laptopL: `(min-width: ${sizes.laptopL})`,
  desktopS: `(min-width: ${sizes.desktopS})`,
  desktop: `(min-width: ${sizes.desktop})`,
};
