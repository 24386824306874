import React, {
  ChangeEvent,
  FC,
  MouseEvent,
  useCallback,
  useState,
} from "react";
// @ts-expect-error
import Switch from "react-ios-switch";

// @ts-expect-error
import { ReactComponent as CheckSvg } from "dive/assets/check.svg";
// @ts-expect-error
import { ReactComponent as PublicSvg } from "dive/assets/new-subchat/public.svg";
// @ts-expect-error
import { ReactComponent as PrivateSvg } from "dive/assets/new-subchat/private.svg";
// @ts-expect-error
import { ReactComponent as AnnouncementSvg } from "dive/assets/new-subchat/announcement.svg";
import { SubchatType } from "dive/type/subchat.type";
import {
  ANNOUNCEMENT_SUBCHAT_TYPE,
  PRIVATE_SUBCHAT_TYPE,
  PUBLIC_SUBCHAT_TYPE,
} from "dive/constants/subchat.contant";
import { logDebug } from "dive/util/log.util";

import {
  NewSubchatModalInfoBlock,
  NewSubchatModalInfoLabel,
  NewSubchatModalInfoName,
  NewSubchatModalInfoSection,
  NewSubchatModalInfoSwitchBlock,
  NewSubchatModalInfoSwitcher,
  NewSubchatModalInfoType,
  NewSubchatModalInfoTypeBlock,
  NewSubchatModalInfoTypeCheck,
  NewSubchatModalInfoTypeCheckBlock,
  NewSubchatModalInfoTypeDescription,
  NewSubchatModalInfoTypeIcon,
  NewSubchatModalInfoTypeTitle,
} from "./NewSubchatModalInfo.style";

type NewSubchatModalInfoProps = {
  name: string;
  type: SubchatType;
  isAutoAdd: boolean;
  enabledTypes: SubchatType[];
  color: string;
  onNameChange: (name: string) => void;
  onTypeChange: (type: SubchatType) => void;
  onIsAutoAddChange: (isAutoAdd: boolean) => void;
};

export const NewSubchatModalInfo: FC<NewSubchatModalInfoProps> = ({
  name,
  type,
  isAutoAdd,
  enabledTypes,
  color,
  onNameChange,
  onTypeChange,
  onIsAutoAddChange,
}) => {
  const [isSwitchReadOnly, setIsSwitchReadOnly] = useState(false);

  const subchatTypeList = [
    {
      id: PUBLIC_SUBCHAT_TYPE,
      icon: <PublicSvg width="36" height="36" />,
      title: "Public",
      description: "Anyone in the Dive Group can discover and join the chat.",
    },
    {
      id: PRIVATE_SUBCHAT_TYPE,
      icon: <PrivateSvg width="32" height="32" />,
      title: "Private",
      description:
        "Only you and people you add to the chat can discover it and add others.",
      notAllowedPermissionDescription:
        "Contact an admin to create a private chat",
    },
    {
      id: ANNOUNCEMENT_SUBCHAT_TYPE,
      icon: <AnnouncementSvg width="28" height="28" />,
      title: "Announcement Subchat",
      description:
        "Only admins can send messages. Everyone in the Dive Group can join.",
      notAllowedPermissionDescription:
        "Contact an admin to create an announcement chat",
    },
  ];

  const onTypeInfoSectionClick = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      if (!event.target) {
        logDebug(
          `[NewSubchatModalInfo][onTypeInfoSectionClick] Target is not defined`,
          event
        );

        return;
      }

      // @ts-expect-error
      const subchatTypeId = event.target.getAttribute("data-id");

      if (!subchatTypeId) {
        logDebug(
          `[NewSubchatModalInfo][onTypeInfoSectionClick] Cannot get subchat type ID`,
          subchatTypeId
        );

        return;
      }

      const isEnabled = enabledTypes.includes(subchatTypeId);

      if (!isEnabled) {
        logDebug(
          `[NewSubchatModalInfo][onTypeInfoSectionClick] Subchat chat isn't allowed in group settings`,
          subchatTypeId
        );

        return;
      }

      onTypeChange(subchatTypeId);

      if (subchatTypeId === PRIVATE_SUBCHAT_TYPE) {
        setIsSwitchReadOnly(true);

        onIsAutoAddChange(false);
      } else {
        setIsSwitchReadOnly(false);

        if (subchatTypeId === PUBLIC_SUBCHAT_TYPE) {
          onIsAutoAddChange(false);
        } else if (subchatTypeId === ANNOUNCEMENT_SUBCHAT_TYPE) {
          onIsAutoAddChange(true);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onTypeChange, onIsAutoAddChange, enabledTypes]
  );

  const onNameTextChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onNameChange(event.target.value);
    },
    [onNameChange]
  );

  return (
    <NewSubchatModalInfoBlock>
      <NewSubchatModalInfoName
        placeholder="Subchat Name"
        type="text"
        maxLength={30}
        onChange={onNameTextChange}
      />
      <NewSubchatModalInfoLabel>
        What type of subchat is this?
      </NewSubchatModalInfoLabel>
      <NewSubchatModalInfoSection onClick={onTypeInfoSectionClick}>
        {subchatTypeList.map(
          ({
            id,
            icon,
            title,
            description,
            notAllowedPermissionDescription,
          }) => {
            const isSelected = id === type;
            // @ts-expect-error
            const isEnabled = enabledTypes.includes(id);

            return (
              <NewSubchatModalInfoType
                key={id}
                $isSelected={isSelected}
                $isEnabled={isEnabled}
                data-id={id}
              >
                <NewSubchatModalInfoTypeIcon>
                  {icon}
                </NewSubchatModalInfoTypeIcon>
                <NewSubchatModalInfoTypeBlock>
                  <NewSubchatModalInfoTypeTitle>
                    {title}
                  </NewSubchatModalInfoTypeTitle>
                  <NewSubchatModalInfoTypeDescription $isSelected={isSelected}>
                    {isEnabled ? description : notAllowedPermissionDescription}
                  </NewSubchatModalInfoTypeDescription>
                </NewSubchatModalInfoTypeBlock>
                <NewSubchatModalInfoTypeCheckBlock>
                  {!isEnabled ? (
                    <PrivateSvg width="18" height="18" />
                  ) : isSelected ? (
                    <NewSubchatModalInfoTypeCheck>
                      <CheckSvg fill="#353536" width="12" height="12" />
                    </NewSubchatModalInfoTypeCheck>
                  ) : null}
                </NewSubchatModalInfoTypeCheckBlock>
              </NewSubchatModalInfoType>
            );
          }
        )}
      </NewSubchatModalInfoSection>
      <NewSubchatModalInfoSection $isHorizontal={true}>
        <NewSubchatModalInfoSwitchBlock>
          <NewSubchatModalInfoTypeTitle>
            Auto-Add Everyone
          </NewSubchatModalInfoTypeTitle>
          <NewSubchatModalInfoTypeDescription $isSelected={false}>
            Anyone who joins the Dive Group is automatically added to this chat.
          </NewSubchatModalInfoTypeDescription>
        </NewSubchatModalInfoSwitchBlock>
        <NewSubchatModalInfoSwitcher>
          <Switch
            readOnly={isSwitchReadOnly}
            checked={isAutoAdd}
            offColor="#454E50"
            pendingOnColor={color}
            onChange={onIsAutoAddChange}
          />
        </NewSubchatModalInfoSwitcher>
      </NewSubchatModalInfoSection>
    </NewSubchatModalInfoBlock>
  );
};
