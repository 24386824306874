import { useMemo } from "react";
import { add, getHours, getMinutes, set } from "date-fns";

import { PINK_COLOR_ID } from "dive/constants/palette.constant";
import { useFormData } from "dive/hook/useFormData.hook";
import { NewEventFormData } from "dive/store/form";
import { NEW_EVENT_MODAL_ID } from "dive/store/ui";

export function useNewEventModalFormData() {
  const initialFormData: NewEventFormData = useMemo(() => {
    const now = new Date();

    const isFirstHalfHour = getMinutes(now) <= 30;
    const hour = getHours(now);
    const initialDate = set(now, {
      milliseconds: 0,
      seconds: 0,
      minutes: isFirstHalfHour ? 30 : 0,
      hours: Number(!isFirstHalfHour) + hour,
    });

    return {
      title: "",
      description: "",
      emoji: "🎉",
      location: "",
      colorId: PINK_COLOR_ID,
      isAllDay: false,
      startTime: initialDate.getTime(),
      endTime: add(initialDate, { hours: 1 }).getTime(),
    };
  }, []);

  return useFormData<NewEventFormData>(NEW_EVENT_MODAL_ID, initialFormData);
}
