import { useEffect } from "react";

import { useUnreadData } from "../../hook/useUnreadData.hook";
import { useIsBrowser } from "../../hook/useIsBrowser";
import { logDebug } from "../../util/log.util";

export function useBadgeCountEffect() {
  const isBrowser = useIsBrowser();

  const { unreadCount } = useUnreadData();

  useEffect(() => {
    if (isBrowser) {
      return;
    }

    if (!window.diveNativeDesktopApi) {
      logDebug(`[useBadgeCountEffect] Desktop API is not available`);

      return;
    }

    window.diveNativeDesktopApi.setBadgeCount(unreadCount);
  }, [isBrowser, unreadCount]);
}
