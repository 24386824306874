import React, { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";

import { setIsEventsSidebarOpen, setSidebarBeforeEvents } from "../store/data";

import { ReactComponent as RightChevronSvg } from "../assets/rightChevron.svg";
import { ReactComponent as CalendarSvg } from "../assets/calendar.svg";

const UpcomingEvents = (props) => {
  const dispatch = useDispatch();

  const loadTimestamp = useSelector((state) => state?.data?.loadTimestamp);
  // const [doneLoading, setDoneLoading] = useState(
  //   Date.now() - loadTimestamp > 1000
  // );
  const doneLoading = true;
  useEffect(() => {
    // setTimeout(() => {
    //   setDoneLoading(true);
    // }, 1000);
  }, []);

  const openEventsSidebar = () => {
    dispatch(setIsEventsSidebarOpen(true));
  };
  if (!doneLoading) return <EmptyState></EmptyState>;
  return (
    <UpcomingEventsContainer onClick={openEventsSidebar}>
      <CalendarIcon />
      <VerticalFlexContainer>
        <HeaderText>Upcoming Events</HeaderText>
        <PreviewText>{props?.number} happening this week</PreviewText>
      </VerticalFlexContainer>
      <RightChevron />
    </UpcomingEventsContainer>
  );
};

export default UpcomingEvents;

const RightChevron = styled(RightChevronSvg)`
  width: 13px;
  height: 22px;
  margin-right: 24px;
  fill: #d1d1d6;
`;

const EmptyState = styled.div`
  width: calc(100% - 30px - 24px);
  height: 75px;
  margin-top: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  // margin-left: 8px;
  // margin-right: 8px;
  border-radius: 16px;
  flex-shrink: 0;
  background: rgba(0, 0, 0, 0.05);
`;

const UpcomingEventsContainer = styled.div`
  width: calc(100% - 16px);
  height: 75px;
  margin-top: 16px;
  // margin-bottom: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 8px;
  margin-right: 8px;
  border-radius: 16px;
  flex-shrink: 0;
  &:hover {
    background: #e1e1e1;
    cursor: pointer;
  }
`;

// const CalendarIcon = styled.div`
//   width: 52px;
//   height: 52px;
//   background-image: url(${calendarIcon});
//   background-size: cover;
//   margin-left: 22px;
//   margin-right: 6px;
// `;

const CalendarIcon = styled(CalendarSvg)`
  width: 46px;
  height: 46px;
  margin-left: 22px;
  margin-right: 6px;
  // border: 0.195606px solid rgba(0, 0, 0, 0.1);
  // box-shadow: 0px 2.34727px 3.1297px rgba(24, 39, 75, 0.1);
  //   0px 3.1297px 6.2594px rgba(0, 0, 0, 0.08);
`;

// const RightChevron = styled.div`
//   width: 13px;
//   height: 22px;
//   background-image: url(${rightChevron});
//   background-size: cover;
//   margin-right: 24px;
// `;

const VerticalFlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  //   width: 100%;
  height: 100%;
  padding-left: 8px;
  padding-right: 8px;
  flex-grow: 1;
`;

const HeaderText = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
`;

const PreviewText = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 129% */

  letter-spacing: 0.02em;

  /* UI Tones/Light/Gray 1 */

  color: #8e8e93;
`;
