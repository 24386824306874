import React, { FC } from "react";
import { useChatColor } from "../../hook/useChatColor";
import { setIsEventsSidebarOpen, selectEvent } from "../../store/data";
import { useEventsLazily } from "../../hook/useEventsLazily";
import { useUsersLazily } from "../../hook/useUsersLazily";
import { UpdateText, AccentText } from "./EventActivityUpdateMessage.style";

import { useDispatch } from "react-redux";

// This file is copied almost entirely from the mobile app
type EventActivityUpdateMessageProps = {
  data: any;
};

const GOING_RSVP = 1;
const NOT_GOING_RSVP = 3;
const INTERESTED_RSVP = 5;

const ANNOUNCEMENT_CHANGE_TYPE = "ANNOUNCEMENT";
const TITLE_CHANGE_TYPE = "TITLE";
const DESCRIPTION_CHANGE_TYPE = "DESCRIPTION";
const DATE_CHANGE_TYPE = "DATE";
const DATE_AND_TIME_CHANGE_TYPE = "DATE_AND_TIME";
const TIME_CHANGE_TYPE = "TIME";
const LOCATION_CHANGE_TYPE = "LOCATION";

export const EventActivityUpdateMessage: FC<
  EventActivityUpdateMessageProps
> = ({ data }) => {
  const chatColor = useChatColor();
  const dispatch = useDispatch();
  const allUsers: string[] = [];
  const eventUids: any[] = [];
  const updateKeys = Object.keys(data ?? {});
  for (const key of updateKeys) {
    const update = data[key];
    if (!update) continue;
    const userUids = Object.keys(update?.userUids ?? {});
    for (const uid of userUids) {
      if (!allUsers.includes(uid)) {
        allUsers.push(uid);
      }
    }
    const eventUid = update?.eventUid;
    if (!eventUids.includes(eventUid)) {
      eventUids.push(eventUid);
    }
  }
  const activityText: any = {
    [GOING_RSVP]: <>{`going to`}&nbsp;</>,
    [INTERESTED_RSVP]: <>{`interested in`}&nbsp;</>,
    [NOT_GOING_RSVP]: <>&nbsp;{`can't go to`}&nbsp;</>,
  };
  const rsvpRenderIcons: any = {
    [GOING_RSVP]: "✅",
    [INTERESTED_RSVP]: "🩷",
    [NOT_GOING_RSVP]: "❌",
  };
  const hostUpdateTypes: any = {
    [TITLE_CHANGE_TYPE]: "details",
    [DESCRIPTION_CHANGE_TYPE]: "details",
    [DATE_CHANGE_TYPE]: "details",
    [TIME_CHANGE_TYPE]: "details",
    [DATE_AND_TIME_CHANGE_TYPE]: "details",
    [LOCATION_CHANGE_TYPE]: "details",
    [ANNOUNCEMENT_CHANGE_TYPE]: "announcement",
    CANCELLED: "cancelled",
  };
  const hostUpdateText: any = {
    details: "changed the details of",
    announcement: "sent an announcement in",
    cancelled: "cancelled",
  };
  const hostUpdateRenderIcons: any = {
    details: "✏️",
    announcement: "📣",
    cancelled: "❌",
  };
  const users = useUsersLazily(allUsers);
  const events = useEventsLazily(eventUids, false);

  const orderedUpdateObjects = Object.values(data ?? {}).sort(
    (a, b) => a?.timestamp - b?.timestamp
  );

  const openEvent = (eventUid: any) => {
    dispatch(setIsEventsSidebarOpen(true));
    dispatch(selectEvent(eventUid));
  };

  return (
    <>
      {orderedUpdateObjects.map((update: any, index) => {
        // sort userUids by timestamp
        const userUids = Object.keys(update?.userUids ?? {}).sort(
          (a, b) => update?.userUids[b] - update?.userUids[a]
        );
        const eventUid = update?.eventUid;
        const rsvpIdx = update?.rsvpIdx;
        const event = events.find((event) => event.uid === eventUid);
        return (
          <div key={`eventActivityUpdate-${index}`}>
            <div
              style={{
                alignItems: "center",
                // maxWidth: "88%",
                marginBottom: 8,
                marginTop: 8,
                justifyContent: "center",
                alignSelf: "center",
                borderRadius: 25,
              }}
            >
              <UpdateText semi={false}>
                {rsvpIdx ? (
                  <>
                    {rsvpRenderIcons[rsvpIdx]}&nbsp;
                    {userUids.slice(0, 3).map((userUid, index) => {
                      const user = users.find((user) => user.uid === userUid);
                      const userFullName = user?.name;
                      const andIndex =
                        userUids.length > 3 ? 2 : userUids.length === 2 ? 0 : 1;
                      return (
                        <>
                          <UpdateText
                            key={`eventActivityUpdateName-${userUid}-${eventUid}`}
                            semi={true}
                          >
                            {userFullName}
                          </UpdateText>
                          {index < andIndex && userUids.length > 1 ? (
                            <>{","}&nbsp;</>
                          ) : index == andIndex ? (
                            <>&nbsp;{"and"}&nbsp;</>
                          ) : (
                            ""
                          )}
                        </>
                      );
                    })}
                    {userUids.length > 3
                      ? `${userUids.length - 3} other${
                          userUids.length > 4 ? "s" : ""
                        }`
                      : ""}
                    {rsvpIdx !== NOT_GOING_RSVP ? (
                      userUids.length > 1 ? (
                        <>&nbsp;{"are"}&nbsp;</>
                      ) : (
                        <>&nbsp;{"is"}&nbsp;</>
                      )
                    ) : (
                      ""
                    )}
                    {activityText[rsvpIdx]}
                    <UpdateText semi={true}>{event?.title}</UpdateText>
                    {`. `}
                  </>
                ) : (
                  <>
                    {hostUpdateRenderIcons[hostUpdateTypes[update?.type]]}&nbsp;
                    <UpdateText semi={true}>
                      {
                        users.find(
                          (user) =>
                            user.uid === Object.keys(update?.userUids ?? {})[0]
                        )?.name
                      }
                    </UpdateText>
                    &nbsp;{hostUpdateText[hostUpdateTypes[update?.type]]}&nbsp;
                    <UpdateText semi={true}>{event?.title}</UpdateText>
                    {`. `}
                  </>
                )}
                {update?.type != "CANCELLED" && (
                  <AccentText
                    color={chatColor}
                    semi={true}
                    onClick={() => {
                      openEvent(event?.uid);
                    }}
                  >
                    &nbsp;Open Event.
                  </AccentText>
                )}
              </UpdateText>
            </div>
          </div>
        );
      })}
    </>
  );
};
