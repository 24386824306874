import React, { ChangeEvent, FC, MouseEvent, useCallback, useRef } from "react";
import { CircleSpinner } from "react-spinners-kit";

// @ts-expect-error
import { ReactComponent as XSvg } from "dive/assets/icons/xWhite.svg";
import { logDebug } from "dive/util/log.util";

import {
  CustomGroupModalCard,
  CustomGroupModalBlock,
  CustomGroupModalClose,
  CustomGroupModalIconBlock,
  CustomGroupModalTitle,
  CustomGroupModalButtonContainer,
  CustomGroupModalButton,
} from "./CustomGroupModal.style";
import { CustomGroupModalPoster } from "./CustomGroupModalPoster";

type CustomGroupModalProps = {
  hasChanged: boolean;
  isLoading: boolean;
  isFormValid: boolean;
  userName: string;
  onNameChange: (name: string) => void;
  onSubmit: () => void;
  onClose: () => void;
};

export const CustomGroupModal: FC<CustomGroupModalProps> = ({
  hasChanged,
  isLoading,
  isFormValid,
  userName,
  onNameChange,
  onSubmit,
  onClose,
}) => {
  const contentBlockRef = useRef<HTMLDivElement>(null);

  const onNameTextChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onNameChange(event.target.value);
    },
    [onNameChange]
  );

  const mediaPickerPostfix = (
    <CustomGroupModalTitle
      type="text"
      defaultValue=""
      placeholder={`${userName}'s Group`}
      maxLength={30}
      onChange={onNameTextChange}
    />
  );

  return (
    <CustomGroupModalBlock
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ type: "spring", duration: 0.4, bounce: 0.2 }}
    >
      <CustomGroupModalCard ref={contentBlockRef}>
        <CustomGroupModalClose onClick={onClose}>
          <CustomGroupModalIconBlock>
            <XSvg width="44" height="44" />
          </CustomGroupModalIconBlock>
        </CustomGroupModalClose>

        <CustomGroupModalPoster mediaPickerPostfix={mediaPickerPostfix} />
        <CustomGroupModalButtonContainer>
          <CustomGroupModalButton
            $isDisabled={!isFormValid || isLoading}
            disabled={!isFormValid || isLoading}
            onClick={onSubmit}
          >
            {isLoading ? (
              <CircleSpinner size={16} color="#FFF" />
            ) : (
              "Create Group!"
            )}
          </CustomGroupModalButton>
        </CustomGroupModalButtonContainer>
      </CustomGroupModalCard>
    </CustomGroupModalBlock>
  );
};
