import React, { useCallback } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect, useRef } from "react";
import { useFloating } from "@floating-ui/react";
import toLower from "lodash/toLower";
import includes from "lodash/includes";

import { ReactComponent as CameraSvg } from "../assets/camera.svg";
import { ReactComponent as EmojiPickerSvg } from "../assets/emojiPicker.svg";
import { ReactComponent as CalendarSvg } from "../assets/calendarSimple.svg";
import { ReactComponent as PollSvg } from "../assets/poll.svg";
import { ReactComponent as SendMessageSvg } from "../assets/sendMessage.svg";
import { ReactComponent as xButtonSvg } from "../assets/xButton.svg";

import { getGroupColor } from "../constants/groupColors";
import { FileDrop } from "react-file-drop";
import heic2any from "heic2any";
import { EmojiPicker as Picker } from "./EmojiPicker";
import { createSelector } from "@reduxjs/toolkit";
import fb from "../firebase/firebaseCalls.js";
import {
  setIsCommenting,
  setComment,
  setMessage,
  addUserListener,
  setChannelDraft,
  dataDraftByChannelIdSelector,
  dataChannelsByIdSelector,
  dataGroupsSelectedGroupSelector,
  setLastViewed,
} from "../store/data";
import { eo } from "date-fns/locale";
import { TemplateInput } from "./TemplateInput";
import {
  dataChannelsByIdMembersSelector,
  dataMessagesByChannelPublishersAndMembersSelector,
  dataMyselfIdSelector,
  dataUsersSelector,
} from "../store/data";
import { SuggestionList } from "./SuggestionList";
import { getCaretCoordinates, getCaretIndex } from "../util/window.util";
import { useSuggestionReducer } from "./SuggestionList/useSuggestionReducer";
import { getMentionRegex } from "../util";
import { getClientRect } from "../util/rect.util";
import { logDebug } from "../util/log.util";
import { useTypingIndicatorText } from "../hook/useTypingIndicatorText";
import { useTypingIndicatorHandlers } from "../hook/useTypingIndicatorHandlers";
import { ImpulseSpinner } from "react-spinners-kit";
import { store } from "../store";
import { useOnMessageSent } from "../hook/useOnMessageSent";
import { updateOrCreateDm } from "dive/firebase/chat/updateOrCreateDm";
import analytics from "dive/util/analytics";

const ChatInputToolbar = (props) => {
  const dispatch = useDispatch();
  const color = useSelector((state) =>
    getGroupColor(state?.data?.groups?.[state?.data?.selectedGroup])
  );

  const myselfId = useSelector(dataMyselfIdSelector);

  const selectedChannelId = useSelector(
    (state) => state?.data?.selectedChannel
  );

  const draft = useSelector((st) =>
    dataDraftByChannelIdSelector(st, selectedChannelId)
  );

  const groupData = useSelector(dataGroupsSelectedGroupSelector);

  const channelData = useSelector((st) =>
    dataChannelsByIdSelector(st, selectedChannelId)
  );

  const isAnnouncementsChannel = channelData?.isAdminPost;
  const isGroupAdmin = groupData?.admins?.[myselfId];

  const [inputBarText, setInputBarText] = useState(draft?.text || "");
  const [attachedImages, setAttachedImages] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  const emojiPickerRef = useRef(null);
  const inputBarRef = useRef(null);

  const onClickOutside = (e) => {
    setShowEmojiPicker(false);
  };

  const clearInputBar = () => {
    setInputBarText("");

    setAttachedImages([]);

    dispatch(setIsCommenting(null));
  };

  useEffect(
    () => {
      clearInputBar();

      inputBarRef.current?.focus();

      if (draft?.text) {
        logDebug(`[ChatInputToolbar] Setting input text from a draft`, {
          channelId: selectedChannelId,
        });

        setInputBarText(draft.text);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedChannelId]
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        emojiPickerRef.current &&
        !emojiPickerRef.current.contains(event.target)
      ) {
        onClickOutside && onClickOutside();
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  const isCommenting = useSelector((state) => state?.data?.isCommenting);

  const onHeightChange = (height) => {
    // console.log(height);
  };

  const dragEnter = (e) => {
    setIsDragging(true);
  };

  const dragLeave = (e) => {
    setIsDragging(false);
  };

  const dropFile = (files, e) => {
    console.log("drop file");
    setIsDragging(false);
    console.log(files, e);
    handleImageChange(files);
  };

  const convertHEIC = (imageFile) => {
    if (imageFile.type.includes("heic")) {
      return heic2any({ blob: imageFile });
    } else {
      return new Promise((res, err) => {
        res(imageFile);
      });
    }
  };

  const handleImageAdded = (imageFile) => {
    convertHEIC(imageFile).then((imageFile) => {
      let reader = new FileReader();

      reader.readAsDataURL(imageFile);
      reader.onloadend = () => {
        setAttachedImages((images) => [
          ...images,
          {
            file: imageFile,
            imagePreviewUrl: reader.result,
          },
        ]);
      };
    });
  };

  const handleImageChange = (files) => {
    // props.showLoading();

    for (let i = 0; i < files.length; i++) {
      handleImageAdded(files[i]);
    }

    // props.hideLoading();
  };

  const deleteImageUpload = (imgUrl) => {
    setAttachedImages((images) =>
      images.filter((img) => img.imagePreviewUrl !== imgUrl)
    );
  };

  const fileUploadButton = () => {
    document.getElementById("fileButton").click();
  };

  // emojiData: EmojiClickData, event: MouseEvent
  const onEmojiClick = (emojiData, event) => {
    setInputBarText((text) => text + emojiData.emoji);
    setShowEmojiPicker(false);
  };

  const toggleEmojiPicker = () => {
    setShowEmojiPicker((show) => true);
  };

  const makeNamePossessive = (name) => {
    if (name.endsWith("s")) {
      return name + "'";
    } else {
      return name + "'s";
    }
  };

  const selectSelectedMessage = (state) => {
    if (state?.data?.isCommenting === null) return null;
    let commentingObj = state?.data?.isCommenting;
    return commentingObj?.commentingOnComment
      ? state?.data?.messages?.[commentingObj?.channelUid]?.[
          state?.data?.comments?.[commentingObj?.commentUid]?.parentMessageUid
        ]
      : state?.data?.messages?.[commentingObj?.channelUid]?.[
          commentingObj?.messageUid
        ];
  };

  const selectedMessage = useSelector(selectSelectedMessage);

  useEffect(() => {
    if (selectedMessage) {
      inputBarRef.current?.focus();
    }
  }, [selectedMessage]);

  const selectUsers = (state) => state?.data?.users;

  const selectSelectedMessageSenderName = createSelector(
    selectSelectedMessage,
    selectUsers,
    (message, users) => {
      if (!message) return null;
      return users?.[message.user?.uid]?.name;
    }
  );

  const selectedParentMessageUid = (state) => {
    if (state?.data?.isCommenting === null) return null;
    const commentingObj = state?.data?.isCommenting;
    return commentingObj?.commentingOnComment
      ? state?.data?.comments?.[commentingObj?.commentUid]?.uid
      : state?.data?.messages?.[commentingObj?.channelUid]?.[
          commentingObj?.messageUid
        ]?.uid;
  };

  const selectedMessageSenderName = useSelector(
    selectSelectedMessageSenderName
  );

  const userMap = useSelector(dataUsersSelector);
  const recentPublishersThenMembers = useSelector((st) =>
    dataMessagesByChannelPublishersAndMembersSelector(st, selectedChannelId)
  );
  const currentChannelMemberMap = useSelector((st) =>
    dataChannelsByIdMembersSelector(st, selectedChannelId)
  );

  const onMessageSent = useOnMessageSent();

  const submitInput = async () => {
    onSubmitHandler();

    const now = Date.now();
    const inputBarTextCopy = inputBarText.slice();
    const attachedImagesCopy = [...attachedImages];

    clearInputBar();

    const setLocalState = (optimisticMessage) => {
      //comment
      if (selectedMessage?.uid) {
        dispatch(
          setComment({
            channelUid: selectedChannelId,
            parentMessageUid: selectedMessage?.uid,
            messageObj: optimisticMessage,
          })
        );
        dispatch(
          setMessage({
            channelUid: selectedChannelId,
            messageObj: {
              ...selectedMessage,
              commentMap: {
                ...selectedMessage?.commentMap,
                [optimisticMessage.uid]: now,
              },
              commentTimestamp: now,
              timestamp: now,
            },
          })
        );
      } else {
        //sending a message
        dispatch(
          setMessage({
            channelUid: selectedChannelId,
            messageObj: optimisticMessage,
          })
        );
      }
    };

    if (!channelData) {
      logDebug(`[ChatInputToolbar][submitInput] Channel data is not defined`);

      return;
    }

    if (channelData.isDM && channelData.localOnly) {
      // Retrieve an ID of the user user sends message to.
      // NOTE: For DMs, the {members} map contains only two keys
      const userId = Object.keys(channelData.members).find(
        (id) => id !== myselfId
      );

      const { localOnly, ...channelPayload } = channelData;

      await updateOrCreateDm({
        userId,
        channelData: channelPayload,
      });

      analytics.track("dm_created");

      dispatch(
        setLastViewed({ channelUid: channelData.uid, timestamp: Date.now() })
      );

      logDebug(
        `[ChatInputToolbar][submitInput] Channel doesn't exist, create new`,
        {
          channelData,
        }
      );
    }

    if (attachedImagesCopy.length > 0) {
      const uploadTaskList = [];

      for (let i = 0; i < attachedImagesCopy.length; i++) {
        const uploadTask = fb
          .optimisticSendImageMessage(
            selectedChannelId,
            attachedImagesCopy[i],
            selectedMessage?.uid,
            setLocalState,
            now + i * 5
          )
          .then((messageData) => {
            onMessageSent({
              messageId: messageData.uid,
              channelId: selectedChannelId,
            });
          });

        uploadTaskList.push(uploadTask);
      }

      await Promise.all(uploadTaskList);
    }

    const profiles = recentPublishersThenMembers.map(
      (memberId) => userMap[memberId]
    );

    const mentionRegex = getMentionRegex(profiles);

    const people = inputBarTextCopy
      .split(mentionRegex)
      .reduce((acc, mentionPart) => {
        if (mentionPart?.match(mentionRegex)) {
          const userName = mentionPart.slice(1);
          const profile = profiles.find((item) => item?.name === userName);

          if (!profile || acc.includes(profile.uid)) {
            return acc;
          }

          return [...acc, profile.uid];
        } else {
          return acc;
        }
      }, []);

    if (inputBarTextCopy) {
      await fb
        .sendTextMessage(
          selectedChannelId,
          inputBarTextCopy,
          selectedMessage?.uid,
          now + attachedImagesCopy.length * 10,
          people,
          setLocalState
        )
        .then(({ messageData, channelId }) => {
          // The Promise should only be resolved when new record
          //   is synced to the remote DB.

          const messageId = messageData?.uid;

          onMessageSent({
            messageId: messageId,
            channelId: channelId,
          });
        });
    }

    if (draft?.text) {
      const messageDraft = { text: "", timestamp: Date.now() };

      dispatch(
        setChannelDraft({ channelId: selectedChannelId, draft: messageDraft })
      );
    }
  };

  const onMessageSendError = () => {
    new Notification("Message failed to send!", {
      body: "Check your connection and try sending again.",
    });
  };

  const {
    suggestionList,
    selectedId: selectedSuggestionId,
    caretIndex,
    caretPosition,
    caretContainerRect,
    setSuggestionList,
    setSelectedId: setSelectedSuggestionId,
    setCaretData,
    setCaretContainerRect,
    resetSuggestionList,
  } = useSuggestionReducer();

  const onKeyDownCapture = (e) => {
    if (suggestionList.length) {
      const currentIndex = suggestionList.findIndex(
        (item) => item.uid === selectedSuggestionId
      );

      if (currentIndex === -1) {
        return;
      }

      const next = suggestionList[currentIndex + 1];
      const prev = suggestionList[currentIndex - 1];

      if (e.keyCode === 40 && next) {
        e.preventDefault();

        return setSelectedSuggestionId(next.uid);
      } else if (e.keyCode === 38 && prev) {
        e.preventDefault();

        return setSelectedSuggestionId(prev.uid);
      }
    }

    if (e.keyCode === 13 && e.shiftKey === false && e.altKey === false) {
      e.preventDefault();

      if (selectedSuggestionId) {
        onAppendMention(selectedSuggestionId);
      } else {
        submitInput().catch(onMessageSendError);
      }
    }
  };

  const isMatch = (str, searchEntry) => {
    return includes(toLower(str), toLower(searchEntry));
  };

  useEffect(() => {
    setCaretData(getCaretIndex(), getCaretCoordinates());

    const inputBarRect = getClientRect(inputBarRef.current);

    if (
      caretContainerRect.width !== inputBarRect.width ||
      caretContainerRect.height !== inputBarRect.height
    ) {
      setCaretContainerRect(inputBarRect.width, inputBarRect.height);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputBarText]);

  useEffect(() => {
    const topPublisherProfiles =
      recentPublishersThenMembers
        .map((memberId) => userMap[memberId])
        .filter((item) => item && item.uid !== myselfId) || [];

    let lastMention = "";

    if (inputBarText.slice(0, caretIndex).includes("@")) {
      lastMention = inputBarText.slice(0, caretIndex).split("@").pop();
    }

    const searchMatches = topPublisherProfiles.filter((item) =>
      isMatch(item.name, lastMention)
    );

    if (
      inputBarText[caretIndex] === "@" ||
      inputBarText[caretIndex - 1] === "@"
    ) {
      fetchAllUsersInChannel();
      setSuggestionList(topPublisherProfiles);
    } else if (
      lastMention &&
      searchMatches.length &&
      !(searchMatches.length === 1 && searchMatches[0].name === lastMention)
    ) {
      setSuggestionList(searchMatches);
    } else if (suggestionList.length) {
      setSuggestionList([]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caretIndex, recentPublishersThenMembers]);

  /* fetches the full profile for every user in this channel and adds it to redux */
  /* this is safe to call multiple times, it will only fetch users that are not already in redux */
  const fetchAllUsersInChannel = () => {
    const memberUids = Object.keys(currentChannelMemberMap || {});
    for (let i = 0; i < memberUids.length; i++) {
      dispatch(addUserListener(memberUids[i]));
    }
  };

  const renderInputValue = (value) => {
    const memberProfiles = Object.keys(currentChannelMemberMap || {})
      .map((memberId) => userMap[memberId])
      .filter((item) => item && item.uid !== myselfId);

    return memberProfiles.reduce((acc, profile) => {
      if (acc.includes(`@${profile.name}`)) {
        return acc.replaceAll(
          `@${profile.name}`,
          `<span style="color: ${color}">@${profile.name}</span>`
        );
      }

      return acc;
    }, value);
  };

  const onAppendMention = useCallback(
    (userId) => {
      const userProfile = suggestionList.find((item) => item.uid === userId);

      if (!userProfile) {
        return;
      }

      const userName = userProfile.name || "";

      const newInputText = inputBarText
        .slice(0, caretIndex)
        .split("@")
        .map((item, index, arr) => {
          // Find the last mention in the given range of text
          //   from 0th to {caretIndex}th index.
          if (index !== arr.length - 1) {
            return item;
          }

          const messageWords = item.split(" ");
          const userNameWords = userName.split(" ");
          const rest = messageWords.filter(
            (word, index) => !isMatch(userNameWords[index], word)
          );

          return `${userName} ${rest.join(" ")}`;
        })
        .join("@")
        .concat(inputBarText.slice(caretIndex + 1));

      setInputBarText(newInputText);

      inputBarRef.current?.focus();

      resetSuggestionList();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [inputBarText, suggestionList, caretIndex]
  );

  const { onInputTextHandler, onSubmitHandler } = useTypingIndicatorHandlers();
  const typingText = useTypingIndicatorText();

  const onTextChange = useCallback(
    (text) => {
      onInputTextHandler(text);
      setInputBarText(text);

      const messageDraft = {
        text: text,
        timestamp: Date.now(),
      };

      dispatch(
        setChannelDraft({
          channelId: selectedChannelId,
          draft: messageDraft,
        })
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedChannelId, onInputTextHandler]
  );

  if (isAnnouncementsChannel && !isGroupAdmin) {
    return (
      <ToolbarContainer>
        <InfoBlock>
          Only admins can send messages in announcement subchats.
        </InfoBlock>
      </ToolbarContainer>
    );
  }

  return (
    <>
      <StyledFileDrop
        onFrameDragEnter={dragEnter}
        onFrameDragLeave={dragLeave}
        onFrameDrop={dragLeave}
        onDragOver={dragEnter}
        onDragLeave={dragLeave}
        onDrop={dropFile}
        isDragging={isDragging}
      >
        Drop Images Here
      </StyledFileDrop>
      {isCommenting && <CommentingOverlay />}

      <ToolbarContainer>
        {selectedMessageSenderName && (
          <CommentingText>
            Commenting on&nbsp;
            <Bold>{makeNamePossessive(selectedMessageSenderName)}</Bold>
            &nbsp;message...
          </CommentingText>
        )}
        {!selectedMessageSenderName && !!typingText && (
          <CommentingText>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-end",
              }}
            >
              <div style={{ marginRight: 5 }}>{`${typingText}`}</div>
              <div style={{ paddingBottom: 1 }}>
                <ImpulseSpinner
                  size={25}
                  frontColor="#8e8e93"
                  backColor="rgba(142,142,147,0.5)"
                />
              </div>
            </div>
          </CommentingText>
        )}

        {/* <GrayCircle>
        <Poll color={color} />
      </GrayCircle>
      <GrayCircle>
        <Calendar color={color} />
      </GrayCircle> */}
        <GrayCircle onClick={() => fileUploadButton()}>
          <Camera color={color} />
        </GrayCircle>
        <InputContainer onKeyDownCapture={onKeyDownCapture}>
          {attachedImages[0] && (
            <ImagesRow>
              {attachedImages.map((img, i) => {
                return (
                  <ImagePreviewContainer key={i} color={color ?? "black"}>
                    <CancelButton
                      color={color ?? "black"}
                      onClick={() => deleteImageUpload(img.imagePreviewUrl)}
                    ></CancelButton>
                    <ImagePreview alt={"image" + i} src={img.imagePreviewUrl} />
                  </ImagePreviewContainer>
                );
              })}
            </ImagesRow>
          )}
          {suggestionList.length ? (
            <SuggestionList
              label="Use @ to tag and notify someone."
              data={suggestionList}
              onSelectedIdChange={setSelectedSuggestionId}
              onSubmit={onAppendMention}
              selectedId={selectedSuggestionId}
              color={color}
              x={caretPosition.x}
              y={caretPosition.y}
              containerWidth={caretContainerRect.width}
              containerHeight={caretContainerRect.height}
            />
          ) : null}
          <StyledTextArea
            autofocus
            placeholder={"Send a message..."}
            minRows={1}
            maxRows={5}
            innerRef={inputBarRef}
            onHeightChange={onHeightChange}
            value={inputBarText}
            onTextChange={onTextChange}
            onPaste={(event) => {
              var items = (
                event.clipboardData || event.originalEvent.clipboardData
              ).items;
              // if you paste one image, items will be string then image.
              const result = [];
              for (let index = 0; index < items.length; index++) {
                var item = items[index];
                if (item.kind === "file") {
                  var blob = item.getAsFile();
                  result.push(blob);
                  event.preventDefault(); // don't paste file name
                }
              }
              handleImageChange(result);
            }}
            renderValue={renderInputValue}
          />

          {/* <MessageInput placeholder={"Send a message..."}></MessageInput> */}
          <EmojiPickerOpenButton
            onClick={toggleEmojiPicker}
          ></EmojiPickerOpenButton>
          {showEmojiPicker && (
            <PickerContainer ref={emojiPickerRef} native={true}>
              <StyledPicker
                isCustomEmojiVisible={false}
                autoFocusSearch
                lazyLoadEmojis
                width={280}
                height={320}
                previewConfig={{ showPreview: false }}
                onEmojiClick={onEmojiClick}
              ></StyledPicker>
            </PickerContainer>
          )}
        </InputContainer>
        <GrayCircle
          color={
            inputBarText.length === 0 && attachedImages.length === 0
              ? null
              : color
          }
        >
          <SendMessage
            color={inputBarText.length === 0 ? "#D1D1D6" : "#FFF"}
            onClick={() => submitInput().catch(onMessageSendError)}
          />
        </GrayCircle>
      </ToolbarContainer>

      <input
        id="fileButton"
        type="file"
        multiple={true}
        accept="image/*,"
        onChange={(e) => {
          e.preventDefault();
          handleImageChange(e.target.files);
        }}
        hidden
      />
    </>
  );
};

export default ChatInputToolbar;

const StyledPicker = styled(Picker)``;

const CommentingText = styled.div`
  font-size: 14px;
  width: 100%;
  color: #8e8e93;
  position: absolute;
  top: -25px;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 4;
  pointer-events: none;
`;

const Bold = styled.div`
  font-weight: bold;
`;

const PickerContainer = styled.div`
  position: absolute;
  right: 0px;
  // width: 300px;
  display: inline-block;
  // height: 300px;
  top: -320px;
  z-index: 10;
`;

// Image preview in toolbar-----
const ImagePreview = styled.img`
  width: 7rem;
  height: 7rem;
  border-radius: 1rem;
  object-fit: cover;
`;
const ImagesRow = styled.div`
  // display: flex;
  // width: 200px;
  // width: auto;
  // width: 100%;
  flex-direction: row;
  padding-bottom: 6px;
  // overflow-x: auto;
  // white-space: wrap;
  // flex-grow: 1;
  overflow: wrap;
`;
const ImagePreviewContainer = styled.div`
  position: relative;
  // border: 1px solid ${(props) => props.color};
  box-shadow: 0px 1px 4px 0px rgba(24, 39, 75, 0.2);
  border-radius: 1em;
  margin-top: 10px;
  margin-right: 20px;
  display: inline-block;
`;

const CancelButton = styled(xButtonSvg)`
  position: absolute;
  width: 27px;
  height: 27px;
  top: -8px;
  right: -12px;
  fill: ${(props) => props.color};
  z-index: 10;
  cursor: pointer;
`;

//-----

const StyledFileDrop = styled(FileDrop)`
  position: fixed;
  left: 420px;
  // width: calc(100%-4px);
  width: calc(100% - 415px);
  height: 100%;
  z-index: ${(props) => (props?.isDragging ? "1" : "-1")};
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  color: #8e8e93;
`;

const CommentingOverlay = styled(FileDrop)`
  position: fixed;
  left: 420px;
  width: calc(100% - 415px);
  height: 100%;
  background: rgba(255, 255, 255, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  color: #8e8e93;
  pointer-events: none;
`;

const StyledTextArea = styled(TemplateInput)`
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  background: transparent;

  font-size: 18px;
  line-height: 22px;
  /* identical to box height, or 125% */

  letter-spacing: 0.01em;

  /* UI Tones/Light/Gray 1 */

  color: black;
  resize: none;

  word-break: break-word;
`;

const GrayCircle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 44px;
  height: 44px;

  background: rgba(0, 0, 0, 0.05);
  ${(props) =>
    props?.color &&
    `background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.25) 0%,
    rgba(255, 255, 255, 0) 100%
  ),
  ${props?.color};`}

  flex-shrink: 0;
  border-radius: 100px;
  cursor: pointer;
`;

const EmojiPickerOpenButton = styled(EmojiPickerSvg)`
  width: 22px;
  height: 22px;
  fill: #8e8e93;
  cursor: pointer;
  position: absolute;
  right: 16px;
  bottom: 13px;
`;

const Camera = styled(CameraSvg)`
  width: 24.3px;
  height: 18.9px;
  fill: ${(props) => props?.color};
`;

const Poll = styled(PollSvg)`
  width: 27px;
  height: 21px;
  fill: ${(props) => props?.color};
`;

const Calendar = styled(CalendarSvg)`
  width: 27px;
  height: 21px;
  fill: ${(props) => props?.color};
`;

const SendMessage = styled(SendMessageSvg)`
  width: 24.3px;
  height: 18.9px;
  fill: ${(props) => props?.color};
`;

const ToolbarContainer = styled.div`
  width: 100%;
  // height: 81px;
  background: rgba(250, 250, 250, 0.85);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  flex-shrink: 0;
  position: relative;
  padding: 16px;
  gap: 8px;
`;

const InfoBlock = styled.div`
  margin: 16px 8px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  color: #8e8e93;
  user-select: none;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px 16px 12px 24px;

  width: 100%;
  // height: 49px;

  /* UI Tones/Light/White */

  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  /* Shadows/100 */

  box-shadow: 0px 2px 4px -2px rgba(24, 39, 75, 0.1);
  border-radius: 30px;
  position: relative;
  padding-right: 50px;
`;
