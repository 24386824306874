import React, { Fragment, useEffect, useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

import UpcomingEvents from "../UpcomingEvents";
import GroupListItem from "../GroupListItem.js";
import LoadingListItem from "../LoadingListItem";

import fillerAvatar from "../../assets/filler/avatar.png";
import diveChat from "../../assets/light-mode/dive_chat.png";
import orangePlus from "../../assets/orange_plus.svg";
import magnifyingGlass from "../../assets/magnifying_glass.svg";

import { ReactComponent as SignoutSvg } from "../../assets/signoutIcon.svg";
import { ReactComponent as DownloadAppSvg } from "../../assets/downloadIcon.svg";
import { ReactComponent as LeaveFeedbackSvg } from "../../assets/leaveFeedback.svg";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import DmListItem from "../DmListItem";
import Squircle from "../Squircle";
import { auth } from "../../firebase/client";

import {
  logoutRedux,
  setSidebarBeforeEvents,
  setGroupSearchQuery,
} from "../../store/data";
import { Caption1Regular } from "../../constants/DesignSystem";
import { useIsBrowser } from "../../hook/useIsBrowser";
import { CreateButton } from "./CreateButton";
import { DesktopSidebarTile } from "../DesktopSidebarTile";

export const GroupList = () => {
  const dispatch = useDispatch();

  const loadTimestamp = useSelector((state) => state?.data?.loadTimestamp);

  // const [doneLoading, setDoneLoading] = useState(
  //   Date.now() - loadTimestamp > 1000
  // );

  const doneLoading = true;

  const [scrollOffset, setScrollOffset] = useState(0);
  const [showLogoutButton, setShowLogoutButton] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [showLeaveFeedbackTooltip, setShowLeaveFeedbackTooltip] =
    useState(false);
  const groups = useSelector(
    (state) => Object.keys(state?.data?.myself?.groups ?? {}),
    shallowEqual
  );

  const dmChannelUids = useSelector(
    (state) => Object.values(state?.data?.myself?.dmChannels ?? {}),
    shallowEqual
  );

  const handleChatScroll = (e) => {
    setScrollOffset(e.target.scrollTop);
  };

  const logoutRef = React.useRef(null);

  const myself = useSelector((state) => state?.data?.myself);

  const displayShowLogoutButton = () => {
    setShowLogoutButton(true);
  };

  const hideLogoutButton = () => {
    setShowLogoutButton(false);
  };

  const isBrowser = useIsBrowser();

  const logout = () => {
    console.log("logging out");
    dispatch(logoutRedux({}));
    auth.signOut();

    // If app is running in the Desktop environment, reset to initial state
    if (!isBrowser) {
      window.diveNativeDesktopApi.resetAppState();
    }
  };

  useEffect(() => {
    dispatch(setSidebarBeforeEvents("groups"));
    const handleClickOutside = (event) => {
      if (logoutRef.current && !logoutRef.current.contains(event.target)) {
        hideLogoutButton && hideLogoutButton();
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    dispatch(setGroupSearchQuery(searchQuery));
  }, [searchQuery]);

  const openInNewTab = (url) => {
    const win = window.open(url, "_blank");
  };

  const numEvents = useSelector(
    (state) =>
      Object.values(state?.data?.events ?? {}).filter(
        (eventObj) => eventObj?.date > new Date() && !eventObj.cancelled
      ).length
  );

  const isNestedSidebarOpen = useSelector(
    (state) => state?.data?.isNestedSidebarOpen
  );

  const isEventsSidebarOpen = useSelector(
    (state) => state?.data?.isEventsSidebarOpen
  );

  return (
    <SidebarContainer>
      <MotionContainer
        initial={{ x: -400 }}
        animate={{ x: isNestedSidebarOpen || isEventsSidebarOpen ? -100 : 0 }}
        exit={{ x: -400 }}
        transition={{ type: "spring", duration: 0.4, bounce: 0.1 }}
        key="group-sidebar"
      >
        <SidebarTop>
          {showLogoutButton && (
            <ContextMenu ref={logoutRef}>
              <ContextListItem onClick={logout}>
                <SignoutIcon />
                Sign Out
              </ContextListItem>
              <ContextListItem
                onClick={() => {
                  openInNewTab("https://dive-chat.app.link/download");
                  setShowLogoutButton(false);
                }}
                ref={logoutRef}
              >
                <DownloadIcon />
                Download the App
              </ContextListItem>
            </ContextMenu>
          )}
          <AvatarContainer onClick={displayShowLogoutButton}>
            <Squircle
              userUid={myself?.uid}
              width={38}
              key={myself?.uid + "avatar"}
            />
          </AvatarContainer>

          <DiveChatLogo />
          <CreateButton />
          {/* <OrangePlus /> */}
        </SidebarTop>
        <SearchBarContainer>
          <MagnifyingGlass />
          <SearchBarInput
            placeholder="Search Groups"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          ></SearchBarInput>
        </SearchBarContainer>
        <DesktopSidebarTile />
        <UpcomingEvents number={numEvents} screen="groups" />
        <ShadowDivider bottomShadow={scrollOffset >= 1} />
        <ChatsScrollContainer onScroll={handleChatScroll}>
          {/* <Divider bottomShadow={scrollOffset >= 1} /> */}
          {groups &&
            groups.map((groupUid, idx) => (
              <Fragment key={groupUid}>
                {/* <SidebarListItem {...placeholder} /> */}
                {doneLoading ? (
                  <GroupListItem groupUid={groupUid} />
                ) : (
                  <LoadingListItem></LoadingListItem>
                )}

                {/* <Divider key={idx} /> */}
              </Fragment>
            ))}
          {dmChannelUids &&
            dmChannelUids.map((channelUid, idx) => (
              <Fragment key={channelUid}>
                {/* <SidebarListItem {...placeholder} /> */}
                {doneLoading ? (
                  <DmListItem channelUid={channelUid} />
                ) : (
                  <LoadingListItem></LoadingListItem>
                )}

                {/* <Divider key={idx + groups?.length} /> */}
              </Fragment>
            ))}
        </ChatsScrollContainer>
      </MotionContainer>
    </SidebarContainer>
  );
};

const LeaveFeedbackText = styled(Caption1Regular)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 12px;
  gap: 10px;

  position: absolute;
  // width: 113px;
  height: 28px;
  bottom: -35px;

  white-space: nowrap;

  /* UI Tones/Light/White */

  background: #ffffff;
  border: 0.5px solid rgba(0, 0, 0, 0.1);
  /* Shadows/400 */

  box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.1);
  border-radius: 8px;
`;

const ChatsScrollContainer = styled.div`
  overflow-y: scroll;
  height: 100%;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding-bottom: 90px;
  padding-left: 8px;
  padding-right: 8px;
`;

const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 38px;
  width: 38px;
  cursor: pointer;
`;

const ContextListItem = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  width: calc(100% - 20px);
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin: 4px 10px;
  /* identical to box height, or 129% */

  letter-spacing: 0.02em;

  /* UI Tones/Dark/Gray 6 */

  color: #1c262a;
  border-radius: 8px;

  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const SignoutIcon = styled(SignoutSvg)`
  width: 20px;
  height: 18px;
  fill: #ff2d55;
  padding-right: 8px;
  margin: 0 5px 0 0;
`;

const DownloadIcon = styled(DownloadAppSvg)`
  width: 25px;
  height: 22px;
  fill: #ff2d55;
  padding-right: 8px;
`;

const LeaveFeedbackContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 34px;
  position: relative;
  overflow: visible;
`;

const LeaveFeedbackIcon = styled(LeaveFeedbackSvg)`
  width: 38px;
  height: 34px;
  cursor: pointer;
  /* scale up on hover */
  &:hover {
    transform: scale(1.3);
  }
  position: auto;
`;

const ContextMenu = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  // padding: 10px;
  // padding-top: 8px;
  // padding-bottom: 8px;
  gap: 6px;
  padding: 4px 0;

  position: absolute;
  top: 70px;
  left: 20px;
  // width: 200px;

  /* UI Tones/Light/White */

  background: #ffffff;
  border: 0.5px solid rgba(0, 0, 0, 0.1);
  /* Shadows/300 */

  box-shadow: 0px 6px 8px -6px rgba(24, 39, 75, 0.1);
  border-radius: 15px;
  z-index: 9;
`;

const LogoutButton = styled.div`
  display: inline-block;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 70px;
  left: 20px;
  padding: 10px;
  background-color: pink;
  z-index: 2;
  border-radius: 12px;
  color: black;
  cursor: pointer;
`;

const ShadowDivider = styled.div`
  width: 100%;
  height: 16px;
  box-shadow: ${(props) =>
    props?.bottomShadow ? "0px 3px 4px -2px rgba(24, 39, 75, 0.1);" : ""};
`;

const Divider = styled.div`
  width: 307px;
  height: 0px;
  border-top: 1px solid #f2f2f7;
  margin-bottom: -1px;
  margin-top: -1px;
  margin-left: 91px;
`;

const MotionContainer = styled(motion.div)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
`;

const SidebarContainer = styled.div`
  width: 380px;
  height: 100%;
  background: #fafafa;

  border-right: 1px solid rgba(0, 0, 0, 0.05);
  /* Shadows/300 */
  flex-shrink: 0;
  // overflow-x: hidden;

  box-shadow: 0px 6px 8px -6px rgba(24, 39, 75, 0.1);

  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 2;
`;

const SidebarTop = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 24px;
  width: 100%;
  margin-top: 16px;
  margin-bottom: 16px;
  height: 50px;
  flex-shrink: 0;
`;

const YourProfileButton = styled.div`
  width: 38px;
  height: 38px;
  background-image: url(${fillerAvatar});
  background-size: contain;
`;

const DiveChatLogo = styled.div`
  width: 142.71px;
  height: 34.2px;
  background-image: url(${diveChat});
  background-size: contain;
  background-repeat: no-repeat;
`;

const OrangePlus = styled.div`
  width: 38px;
  height: 38px;
  background-image: url(${orangePlus});
  background-size: contain;
  box-shadow: 0px 6px 8px -6px rgba(24, 39, 75, 0.1);
  visibility: hidden;
`;

const SearchBarContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border: none;
  //   width: 90%;
  width: calc(100% - 48px);
  height: 36px;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 100px;
  flex-shrink: 0;
`;

const SearchBarInput = styled.input`
  width: 100%;
  height: 22px;
  margin-left: 8px;
  margin-right: 24px;
  border: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  background: transparent;
  &:focus {
    outline: none;
  }
`;

const MagnifyingGlass = styled.div`
  width: 26px;
  height: 24px;
  margin-left: 8px;
  background-image: url(${magnifyingGlass});
  background-size: cover;
`;
