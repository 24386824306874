type CreateDmChannelTemplatePayload = {
  channelId: string;
  name: string;
  myId: string;
  otherId: string;
};

export function createDmChannelTemplate({
  channelId,
  name,
  myId,
  otherId,
}: CreateDmChannelTemplatePayload) {
  return {
    name,
    details: "",
    createdBy: myId,
    isDM: true,
    isPublic: false,
    isAdminPost: false,
    isAdminChannel: false,
    members: {
      [myId]: true,
      [otherId]: true,
    },
    expoTokens: {},
    uid: channelId,
    createdAt: Date.now(),
    timestamp: Date.now(),
    recent: -1,
  };
}

type CreateChannelTemplatePayload = {
  channelId: string;
  myId: string;
  name: string;
  emoji: string;
  isPublic: boolean;
};

export function createChannelTemplate({
  channelId,
  myId,
  name,
  emoji,
  isPublic,
}: CreateChannelTemplatePayload) {
  return {
    name,
    emoji,
    details: "",
    createdBy: myId,
    isDefault: true,
    isDM: false,
    isPublic,
    isAdminPost: false,
    isAdminChannel: false,
    members: {
      [myId]: true,
    },
    expoTokens: {},
    uid: channelId,
    createdAt: Date.now(),
    timestamp: Date.now(),
  };
}

type CreateGroupTemplatePayload = {
  groupId: string;
  myId: string;
  name: string;
  color: string;
  isSimple: boolean;
};

export function createGroupTemplate({
  groupId,
  myId,
  name,
  color,
  isSimple,
}: CreateGroupTemplatePayload) {
  return {
    avatar: "",
    name,
    color,
    channels: {},
    carouselPermission: false,
    emoji: "",
    isSimple,
    isPublic: false,
    defaultName: true,
    allowNewChannels: true,
    adminCustomizeOnly: false,
    uid: groupId,
    createdBy: myId,
    createdAt: Date.now(),
    timestamp: Date.now(),
    owner: myId,
    admins: {
      [myId]: true,
    },
    members: {},
  };
}

type CreateMessageTemplatePayload = {
  messageId: string;
  myId: string;
};

export function createMessageTemplate({
  messageId,
  myId,
}: CreateMessageTemplatePayload) {
  return {
    _id: messageId,
    uid: messageId,
    createdAt: Date.now(),
    timestamp: Date.now(),
    user: {
      _id: myId,
      uid: myId,
    },
  };
}
