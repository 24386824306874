import {
  DataSnapshot,
  getDatabase,
  onValue,
  ref,
  off,
} from "firebase/database";

import { CustomEmoji } from "dive/type/custom-emoji.type";
import { logDebug } from "dive/util/log.util";

type CustomEmojiMap = {
  [emojiId: string]: CustomEmoji;
};

type ListenForCustomEmojiMapPayload = {
  groupId: string;
  onValue: (customEmojiMap: CustomEmojiMap | null) => void;
};

// Appends a new DB listener and returns a cleanup function
export function listenForCustomEmojiMap(
  payload: ListenForCustomEmojiMapPayload
) {
  if (!payload || !payload.groupId || !payload.onValue) {
    logDebug(`[listenForCustomEmojiMap] Payload data is not provided`, {
      payload,
    });

    return;
  }

  const db = getDatabase();

  const dbRef = ref(db, `customEmojis/${payload.groupId}`);

  function onValueChange(dataSnapshot: DataSnapshot) {
    let customEmojiMap: CustomEmojiMap | null = null;

    if (dataSnapshot.exists()) {
      customEmojiMap = dataSnapshot.val() as CustomEmojiMap;
    }

    payload.onValue(customEmojiMap);
  }

  onValue(dbRef, onValueChange);

  return function () {
    off(dbRef, "value");
  };
}
