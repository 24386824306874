import styled from "styled-components";

// @ts-expect-error
import { ReactComponent as PlusCircleSvg } from "dive/assets/plus-circle.svg";

export const CreateButtonBlock = styled.button`
  width: 40px;
  height: 40px;
  border-width: 0;
  border-radius: 12px;
  background: radial-gradient(
      100% 100% at 0% 0%,
      #f09 0%,
      rgba(255, 0, 153, 0) 100%
    ),
    radial-gradient(70.71% 70.71% at 100% 100%, #ff007a 0%, #ff8a00 100%);
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.2);

  transition: 0.1s;

  &:hover {
    cursor: pointer;
  }

  &:active {
    transform: scale(0.98);
  }
`;

export const CreateButtonIcon = styled(PlusCircleSvg)`
  margin: 5px 0 0 -1.5px;
`;
