import styled from "styled-components";

import { Input } from "dive/components/Input";
import { flexCenter } from "dive/constants/DesignSystem";

export const NewSubchatModalInfoBlock = styled.div``;

// @ts-expect-error
export const NewSubchatModalInfoName = styled(Input)`
  color: #fff;
  border-radius: 16px;
  border: 0.5px solid rgba(255, 255, 255, 0.1);
  background: #353536;
  height: 52px;
  width: calc(100% - 4px);
  margin: 18px 0 0 0;
  font-family: Clash Display !important;
  font-size: 28px;
  line-height: 34px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;

  ::placeholder {
    color: #ffffff;
    opacity: 0.5;
  }

  &:focus {
    outline: none;
  }
`;

export const NewSubchatModalInfoLabel = styled.div`
  color: #959a9b;
  text-align: left;
  font-family: "SF Pro Rounded";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.32px;
  margin: 18px 0 0 9px;
`;

type NewSubchatModalInfoSectionProps = {
  $isHorizontal?: boolean;
};

export const NewSubchatModalInfoSection = styled.div<NewSubchatModalInfoSectionProps>`
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.05);
  margin: 9px 0;
  display: flex;
  ${({ $isHorizontal }) =>
    $isHorizontal
      ? "display: flex; align-items: center; flex-direction: row;"
      : "flex-direction: column;"}
`;

type NewSubchatModalInfoTypeProps = {
  $isSelected: boolean;
  $isEnabled: boolean;
};

export const NewSubchatModalInfoType = styled.button<NewSubchatModalInfoTypeProps>`
  display: flex;
  align-items: center;
  flex-direction: row;
  margin: 4px 4px;
  padding: 13.5px 18px;
  border-radius: 16px;
  border-width: 0.5px;
  border-color: transparent;
  background: transparent;
  user-select: none;
  transition: 0.1s;
  ${({ $isSelected }) =>
    $isSelected
      ? "border-color: rgba(0, 0, 0, 0.1); background: rgba(255, 255, 255, 0.3); box-shadow: 0px 2px 4px -2px rgba(24, 39, 75, 0.1);"
      : ""}

  ${({ $isEnabled }) =>
    $isEnabled
      ? `
 &:hover {
  cursor: pointer;
}

&:active {
  transform: scale(0.98);
}
 `
      : ""}
`;

export const NewSubchatModalInfoTypeIcon = styled.div`
  ${flexCenter}
  min-width: 42px;
  pointer-events: none;
`;

export const NewSubchatModalInfoTypeBlock = styled.div`
  flex: 1;
  margin: 0 18px;
  pointer-events: none;
`;

export const NewSubchatModalInfoTypeTitle = styled.div`
  color: #e5e5ea;
  font-family: "SF Pro Rounded";
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.34px;
  text-align: left;
  margin: 0 0 2px 0;
`;

type NewSubchatModalInfoTypeDescriptionProps = {
  $isSelected: boolean;
};

export const NewSubchatModalInfoTypeDescription = styled.div<NewSubchatModalInfoTypeDescriptionProps>`
  color: ${({ $isSelected }) => ($isSelected ? "#E5E5EA" : "#959a9b")};
  font-family: "SF Pro Rounded";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.26px;
  text-align: left;
`;

export const NewSubchatModalInfoTypeCheckBlock = styled.div`
  min-width: 24px;
  min-height: 24px;
  pointer-events: none;
`;

export const NewSubchatModalInfoTypeCheck = styled.div`
  ${flexCenter}
  min-width: 24px;
  min-height: 24px;
  border-radius: 50%;
  background: #e5e5ea;
`;

export const NewSubchatModalInfoSwitchBlock = styled.div`
  flex: 1;
  margin: 18px 27px;
  pointer-events: none;
`;

export const NewSubchatModalInfoSwitcher = styled.div`
  margin: 0 27px 0 0;
`;
