import { logDebug } from './log.util';

export function generateCustomReactionId(emojiId: string) {
  if (!emojiId) {
    logDebug(`[generateCustomReactionId] The ID of emoji is not defined`);

    return '';
  }

  return `:${emojiId}:`;
}

export function parseEmojiIdFromReactionId(reactionId: string) {
  if (!reactionId) {
    logDebug(`[parseEmojiIdFromReactionId] The ID of reaction is not defined`);

    return '';
  }

  return reactionId.slice(1, -1);
}
