import React, { FC, ReactNode } from "react";
import { AnimatePresence } from "framer-motion";

import {
  SidebarBadgeBlock,
  SidebarBadgeDescription,
  SidebarBadgeInfo,
  SidebarBadgeTitle,
} from "./SidebarBadge.style";

type SidebarBadgeProps = {
  isVisible: boolean;
  isWarning?: boolean;
  title: string;
  description: string;
  icon: ReactNode;
  onClick?: () => void;
};

export const SidebarBadge: FC<SidebarBadgeProps> = ({
  isVisible,
  isWarning,
  title,
  description,
  icon,
  onClick,
}) => {
  return (
    <AnimatePresence>
      {isVisible ? (
        <SidebarBadgeBlock
          $hasHover={!!onClick}
          key="notification-permission-badge"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ type: "spring", duration: 0.3, delay: 1, bounce: 0.1 }}
          onClick={onClick}
        >
          {icon}
          <SidebarBadgeInfo>
            <SidebarBadgeTitle>{title}</SidebarBadgeTitle>
            <SidebarBadgeDescription $isWarning={isWarning || false}>
              {description}
            </SidebarBadgeDescription>
          </SidebarBadgeInfo>
        </SidebarBadgeBlock>
      ) : null}
    </AnimatePresence>
  );
};
