import React, { useEffect } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { addPollListener } from "../store/data";

import { ReactComponent as PollSvg } from "../assets/poll.svg";
import { ReactComponent as VoteInAppSvg } from "../assets/voteInApp.svg";
import { formatDistance } from "date-fns";

const Poll = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (props?.pollUid) {
      dispatch(addPollListener(props?.pollUid));
    }
  }, []);

  const pollObj = useSelector((state) => state?.data?.polls?.[props?.pollUid]);

  const numVotes = Object.values(pollObj?.votes ?? {})?.length || 0;

  const votesString = `${numVotes} vote${numVotes === 1 ? "" : "s"}`;

  const endString = `Ends in ${formatDistance(
    pollObj?.deadline ?? 0,
    new Date()
  )}`;

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <PollContainer onClick={props?.onClick}>
      <PollIcon />
      <PollTitleText>{pollObj?.question}</PollTitleText>
      <PollBodyText>
        {votesString}&nbsp;•&nbsp;{endString}
      </PollBodyText>
      <VoteInApp
        onClick={() => openInNewTab("https://dive-chat.app.link/download")}
      />
    </PollContainer>
  );
};

export default Poll;

const VoteInApp = styled(VoteInAppSvg)`
  width: 194.5px;
  height: 40.5px;
  margin-top: 8px;

  &:hover {
    cursor: pointer;
  }
`;

const PollTitleText = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* or 127% */

  text-align: center;
  letter-spacing: 0.01em;

  /* UI Tones/Light/White */

  color: #ffffff;
  //   margin-top: 8px;
`;

const PollBodyText = styled.div`
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  /* identical to box height, or 129% */

  text-align: center;
  letter-spacing: 0.02em;

  /* UI Tones/Light/White */

  color: #ffffff;
  //   margin-top: 8px;
`;

const PollContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 16px;
  gap: 8px;

  width: 317px;

  /* Depth/Cyan */

  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.25) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    #32ade6;
  border-radius: 32px;
`;

const PollIcon = styled(PollSvg)`
  width: 35.1px;
  height: 34.2px;
  fill: #fff;
`;
