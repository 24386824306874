import axios from "axios";
import { getDatabase } from "firebase/database";
import { getAuth } from "firebase/auth";

import { EventResponseGif } from "../../type/event.type";
import { RsvpIdx } from "../../type/rsvp.type";
import { logDebug } from "../../util/log.util";

type RsvpToEventPayload = {
  eventId: string;
  rsvpIdx: RsvpIdx;
  text?: string;
  gif?: EventResponseGif;
};

export async function rsvpToEvent(payload: RsvpToEventPayload) {
  const { eventId, rsvpIdx, text, gif } = payload;

  const realtimeDb = getDatabase();
  const authSession = getAuth();

  const projectId = realtimeDb.app.options.projectId;

  if (!projectId) {
    logDebug("[rsvpToEvent] Cannot retrieve Firebase project ID");

    return;
  }

  const userId = authSession.currentUser?.uid;

  if (!userId) {
    logDebug("[rsvpToEvent] Cannot retrieve current user ID");

    return;
  }

  const idToken = await authSession.currentUser?.getIdToken();

  if (!idToken) {
    logDebug("[rsvpToEvent] Cannot retrieve an ID token of current user");

    return;
  }

  const config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
      "Content-Type": "application/json",
    },
  };

  return axios.post(
    `https://us-central1-${projectId}.cloudfunctions.net/rsvpToEvent_v2`,
    {
      eventUid: eventId,
      userUid: userId,
      rsvpIdx,
      text,
      gif,
    },
    config
  );
}
