import { CustomEmoji } from "emoji-picker-react/dist/config/customEmojiConfig";

export const diveCustomEmoji: CustomEmoji = {
  id: ":Dive:",
  names: ["shaka", "dive", "hand sign", "hang loose"],
  imgUrl: require("../../assets/emoji/diveEmoji.png"),
};

export const mmhCustomEmoji: CustomEmoji = {
  id: ":MakeMomentsHappen:",
  names: [
    "make moments happen",
    "moments",
    "dive",
    "dive chat",
    "manifesto",
    "mission",
    "logo",
    "life",
    "purpose",
    "shaka",
  ],
  imgUrl: require("../../assets/emoji/mmhEmoji.png"),
};
