import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import SidebarListItem from "./SidebarListItem";
import { createSelector } from "@reduxjs/toolkit";
import {
  formatTime,
  mostRecentMessage,
  getOtherPersonUid,
  getPreviewText,
} from "../util";

const NestedChannelListItem = (props) => {
  const selectMessages = (state) =>
    state?.data?.previewMessages?.[props?.channelUid];

  const selectMostRecentMessage = createSelector(selectMessages, (messages) => {
    if (!messages) return null;
    return mostRecentMessage(Object.values(messages));
  });

  const messageForPreview = useSelector(selectMostRecentMessage);
  const channelLastViewed = (state) =>
    state?.data?.lastViewed?.[props?.channelUid];

  const selectIsUnread = createSelector(
    selectMostRecentMessage,
    channelLastViewed,
    (message, channelLastViewed) => {
      if (!message) return false;
      if (!channelLastViewed) return false;
      return message?.createdAt > channelLastViewed;
    }
  );

  const isUnread = useSelector(selectIsUnread);
  const isNew = useSelector(
    (state) => !Boolean(state?.data?.lastViewed?.[props?.channelUid])
  );

  const isMuted = useSelector((state) =>
    Boolean(state?.data?.myself?.channels?.[props?.channelUid])
  );

  const isPinned = useSelector(
    (state) => state?.data?.myself?.pinned?.[props?.channelUid]
  );
  const otherPersonUid = useSelector((state) =>
    getOtherPersonUid(state?.data?.myself?.uid, props?.channelUid)
  );
  const channelObj = useSelector(
    (state) => state?.data?.channels?.[props?.channelUid]
  );

  const isMainChat = useSelector(
    (state) =>
      state?.data?.groups?.[props?.groupUid]?.main === props?.channelUid
  );

  const selectUsers = (state) => state?.data?.users;

  const selectPreviewMessageSenderName = createSelector(
    selectMostRecentMessage,
    selectUsers,
    (message, users) => {
      if (!message) return "";
      return users?.[message?.user?.uid]?.name ?? "";
    }
  );

  const previewMessageSenderName = useSelector(selectPreviewMessageSenderName);

  useEffect(() => {}, []);

  return (
    <SidebarListItem
      isUnread={isUnread}
      isNew={isNew}
      isPinned={isPinned}
      isMuted={isMuted}
      isPublic={channelObj?.isPublic}
      header={channelObj?.name}
      preview={getPreviewText(messageForPreview, previewMessageSenderName)}
      time={formatTime(messageForPreview?.createdAt ?? 0)}
      userUid={otherPersonUid}
      timestamp={
        messageForPreview?.createdAt *
          (isMainChat ? 1.2 : isPinned ? 1.1 : 1) ?? 0
      }
      channelUid={props?.channelUid}
      channelEmoji={channelObj?.emoji}
      isMainChat={isMainChat}
    />
  );
};

export default NestedChannelListItem;
