import { useCallback } from "react";

import { store, useDispatch } from "dive/store";
import {
  UiModalId,
  modalIdList,
  popModalId,
  pushModalId,
  uiModalPresentationStackSelector,
} from "dive/store/ui";
import { logDebug } from "dive/util/log.util";

export function useModal() {
  const dispatch = useDispatch();

  const showModal = useCallback(
    (modalId: UiModalId) => {
      const storeSnapshot = store.getState();

      const modalPresentationStack =
        uiModalPresentationStackSelector(storeSnapshot);

      if (modalPresentationStack.includes(modalId)) {
        logDebug(`[useModal] Modal is already presented`);

        return;
      }

      if (!modalIdList.includes) {
        logDebug(`[useModal] Attempting to show unrecognized modal`);

        return;
      }

      dispatch(pushModalId(modalId));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const hideModal = useCallback(
    () => {
      const storeSnapshot = store.getState();

      const modalPresentationStack =
        uiModalPresentationStackSelector(storeSnapshot);

      if (modalPresentationStack.length === 0) {
        logDebug(`[useModal] No modals presented`);

        return;
      }

      dispatch(popModalId());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return {
    showModal,
    hideModal,
  };
}
