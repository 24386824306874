import React, { FC, ReactNode } from "react";

import {
  ChatMessageLinkPreviewBlock,
  ChatMessageLinkPreviewBlockContent,
  ChatMessageLinkPreviewBlockContentBg,
  ChatMessageLinkPreviewBlockContentInfo,
  ChatMessageLinkPreviewBlockContentInfoDescription,
  ChatMessageLinkPreviewBlockContentInfoTitle,
  ChatMessageLinkPreviewBlockHeader,
  ChatMessageLinkPreviewBlockHeaderBadge,
} from "./ChatMessageLinkPreview.style";

type ChatMessageLinkPreviewContentProps = {
  url: string;
  title: string;
  description?: string;
  header?: ReactNode;
  badge?: ReactNode;
  contentPrefix?: ReactNode;
  contentPostfix?: ReactNode;
  isBlurBody?: boolean;
  bgImageUrl?: string;
};

export const ChatMessageLinkPreviewContent: FC<
  ChatMessageLinkPreviewContentProps
> = ({
  url,
  title,
  description,
  header,
  badge,
  contentPrefix,
  contentPostfix,
  isBlurBody,
  bgImageUrl,
}) => {
  return (
    <ChatMessageLinkPreviewBlock href={url} target="_blank">
      <ChatMessageLinkPreviewBlockHeader>
        {header}
        {badge ? (
          <ChatMessageLinkPreviewBlockHeaderBadge>
            {badge}
          </ChatMessageLinkPreviewBlockHeaderBadge>
        ) : null}
      </ChatMessageLinkPreviewBlockHeader>
      <ChatMessageLinkPreviewBlockContent bgImageUrl={bgImageUrl}>
        <ChatMessageLinkPreviewBlockContentBg isBlurBody={isBlurBody}>
          {contentPrefix}
          <ChatMessageLinkPreviewBlockContentInfo>
            <ChatMessageLinkPreviewBlockContentInfoTitle
              $hasTextOverflow={url === title}
            >
              {title}
            </ChatMessageLinkPreviewBlockContentInfoTitle>
            {description ? (
              <ChatMessageLinkPreviewBlockContentInfoDescription>
                {description}
              </ChatMessageLinkPreviewBlockContentInfoDescription>
            ) : null}
          </ChatMessageLinkPreviewBlockContentInfo>
          {contentPostfix}
        </ChatMessageLinkPreviewBlockContentBg>
      </ChatMessageLinkPreviewBlockContent>
    </ChatMessageLinkPreviewBlock>
  );
};
