import { useReducer, useRef } from "react";

const suggestionInitialState = {
  suggestionList: [],
  selectedId: null,
  caretIndex: null,
  caretPosition: {
    x: 0,
    y: 0,
  },
  caretContainerRect: {
    width: 0,
    height: 0,
  },
};

const SET_SUGGESTION_LIST_TYPE = "useSuggestionReducer/SET_SUGGESTION_LIST";
const SET_SELECTED_ID_TYPE = "useSuggestionReducer/SET_SELECTED_ID";
const SET_CARET_INDEX_TYPE = "useSuggestionReducer/SET_CARET_INDEX";
const SET_CARET_POSITION_TYPE = "useSuggestionReducer/SET_CARET_POSITION";
const SET_CARET_DATA_TYPE = "useSuggestionReducer/SET_CARET_DATA";
const SET_CARET_CONTAINER_RECT_TYPE =
  "useSuggestionReducer/SET_CARET_CONTAINER_RECT";
const RESET_TYPE = "useSuggestionReducer/RESET";

function suggestionReducer(state, { type, payload }) {
  switch (type) {
    case SET_SUGGESTION_LIST_TYPE:
      return {
        ...state,
        suggestionList: payload,
        selectedId: payload[0]?.uid || null,
      };

    case SET_SELECTED_ID_TYPE:
      return { ...state, selectedId: payload };

    case SET_CARET_INDEX_TYPE:
      return { ...state, caretIndex: payload };

    case SET_CARET_POSITION_TYPE:
      return { ...state, caretPosition: payload };

    case SET_CARET_DATA_TYPE:
      return {
        ...state,
        caretIndex: payload.caretIndex,
        caretPosition: payload.caretPosition,
      };

    case SET_CARET_CONTAINER_RECT_TYPE:
      return {
        ...state,
        caretContainerRect: payload,
      };

    case RESET_TYPE:
      return { ...suggestionInitialState };

    default:
      return state;
  }
}

export function useSuggestionReducer() {
  const [
    {
      suggestionList,
      selectedId,
      caretIndex,
      caretPosition,
      caretContainerRect,
    },
    dispatchSuggestionState,
  ] = useReducer(suggestionReducer, suggestionInitialState);

  const setSuggestionList = useRef((data) => {
    dispatchSuggestionState({
      type: SET_SUGGESTION_LIST_TYPE,
      payload: data,
    });
  }).current;

  const setSelectedId = useRef((selectedId) => {
    dispatchSuggestionState({
      type: SET_SELECTED_ID_TYPE,
      payload: selectedId,
    });
  }).current;

  const setCaretIndex = useRef((caretIndex) => {
    dispatchSuggestionState({
      type: SET_CARET_INDEX_TYPE,
      payload: caretIndex,
    });
  }).current;

  const setCaretPosition = useRef((caretPosition) => {
    dispatchSuggestionState({
      type: SET_CARET_POSITION_TYPE,
      payload: caretPosition,
    });
  }).current;

  const setCaretData = useRef((caretIndex, caretPosition) => {
    dispatchSuggestionState({
      type: SET_CARET_DATA_TYPE,
      payload: { caretIndex, caretPosition },
    });
  }).current;

  const setCaretContainerRect = useRef((width, height) => {
    dispatchSuggestionState({
      type: SET_CARET_CONTAINER_RECT_TYPE,
      payload: { width, height },
    });
  }).current;

  const resetSuggestionList = useRef(() => {
    dispatchSuggestionState({ type: RESET_TYPE });
  }).current;

  return {
    suggestionList,
    selectedId,
    caretIndex,
    caretPosition,
    caretContainerRect,
    setSuggestionList,
    setSelectedId,
    setCaretIndex,
    setCaretPosition,
    setCaretData,
    setCaretContainerRect,
    resetSuggestionList,
  };
}
