import React, { FC } from "react";
import { CircleSpinner } from "react-spinners-kit";

import { ReactComponent as ArrowRight } from "../../../assets/material-east.svg";
import Squircle from "../../Squircle";

import {
  GroupTileBlock,
  GroupTileBlockBgOverlay,
  GroupTileIconBlock,
  GroupTileIconBlockBgOverlay,
  GroupTileIconContainer,
  GroupTileInfo,
  GroupTileInfoButton,
} from "./ChatMessageGroupTile.style";

type ChatMessageGroupTileProps = {
  // Indicates whether the group is open to new members or not
  isOpen: boolean;
  isJoined: boolean;
  isLoading: boolean;
  groupId: string;
  name: string;
  description: string;
  color: string;
  avatarUrl?: string;
  emoji?: string;
  onJoin: () => void;
};

export const ChatMessageGroupTile: FC<ChatMessageGroupTileProps> = ({
  isOpen,
  isJoined,
  isLoading,
  groupId,
  name,
  description,
  color,
  avatarUrl,
  emoji,
  onJoin,
}) => {
  const imageList = [
    {
      rotateDeg: 0,
      translateXPx: 0,
      translateYPx: 0,
      opacity: 0.4,
    },
    {
      rotateDeg: 7,
      translateXPx: 7.5,
      translateYPx: 4,
      opacity: 0.3,
    },
    {
      rotateDeg: 14,
      translateXPx: 15,
      translateYPx: 8,
      opacity: 1,
    },
  ];

  if (!isOpen) {
    return (
      <GroupTileBlock bgColor={color}>
        <GroupTileBlockBgOverlay />
        <GroupTileInfo>
          <h4 style={{ margin: "0 12px" }}>
            This group is no longer available for joining
          </h4>
        </GroupTileInfo>
      </GroupTileBlock>
    );
  }

  return (
    <GroupTileBlock bgColor={color}>
      <GroupTileBlockBgOverlay />
      <GroupTileInfo>
        <h4>{name}</h4>
        <span>{description}</span>
        <GroupTileInfoButton
          isLoading={isLoading}
          disabled={isLoading}
          onClick={onJoin}
        >
          {isLoading ? "Joining" : isJoined ? "Joined" : "Join Group"}
          {isLoading ? (
            <CircleSpinner size={10} color="#FFFFFF" />
          ) : (
            <ArrowRight />
          )}
        </GroupTileInfoButton>
      </GroupTileInfo>
      <GroupTileIconContainer>
        {imageList.map(
          ({ rotateDeg, translateXPx, translateYPx, opacity }, index) => {
            const isLast = index === imageList.length - 1;

            return (
              <GroupTileIconBlock
                rotateDeg={rotateDeg}
                translateXPx={translateXPx}
                translateYPx={translateYPx}
                bgColor={color}
              >
                {!isLast ? (
                  <GroupTileIconBlockBgOverlay opacity={opacity} />
                ) : null}
                <Squircle
                  width={80}
                  color={isLast ? color : undefined}
                  groupObj={{
                    uid: groupId,
                    avatarMD: avatarUrl,
                    emoji: emoji,
                    name: name,
                  }}
                />
              </GroupTileIconBlock>
            );
          }
        )}
      </GroupTileIconContainer>
    </GroupTileBlock>
  );
};
