import React, { useEffect, useState, memo } from "react";
import styled from "styled-components";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import pick from "lodash/pick";
import values from "lodash/values";
import ChatMessage from "./ChatMessage";
import {
  addUserListener,
  loadMoreMessages,
  setIsCommenting,
} from "../store/data";

import { ClassicSpinner } from "react-spinners-kit";

import useInfiniteScroll from "react-infinite-scroll-hook";
import { af } from "date-fns/locale";
import { createSelector } from "@reduxjs/toolkit";

import useScrollSnap from "react-use-scroll-snap";
import {
  dataChannelsByIdEmojiSelector,
  dataMessagesByChannelMentionedIdsSelector,
  dataUsersByIdsSelector,
} from "../store/data";
import { useStartOfHour } from "dive/hook/useStartOfHour";

const NewChatScroll = ({ showAnimatedCounter }) => {
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);
  const dispatch = useDispatch();
  const selectSortedMessages = (state) =>
    Object.values(
      state?.data?.messages?.[state?.data?.selectedChannel] ?? {}
    )?.sort((a, b) => {
      return a.createdAt - b.createdAt;
    });

  const noMoreMessages = useSelector(
    (state) =>
      state?.data?.noMoreMessages?.[state?.data?.selectedChannel] ?? false
  );

  const sortedMessages = useSelector(selectSortedMessages, shallowEqual);

  const scrollToBottom = (behavior = "auto") => {
    setTimeout(() => {
      messagesEndRef.current?.scrollIntoView({ behavior });
    }, 0);
  };

  const selectSelectedChannel = (state) => state?.data?.selectedChannel;

  const selectedChannel = useSelector(selectSelectedChannel);

  const chatViewRef = React.useRef(null);
  const sentryRef = React.useRef(null);
  const lastChatHeight = React.useRef(0);

  const selectRenderMessages = createSelector(
    selectSortedMessages,
    selectSelectedChannel,
    (messages, channelUid) => {
      const output = [];

      for (let i = 0; i < messages.length; i++) {
        let message = messages[i];
        output.push({
          uid: message?.uid,
          previousUid: messages?.[i - 1]?.uid,
          key: message?.uid,
          channelUid: channelUid,
          isTop: i === 0,
          people: message?.people,
        });
        let messageComments = Object.keys(message?.commentMap ?? {});
        for (let j = 0; j < messageComments?.length; j++) {
          let commentUid = messageComments?.[j];
          output.push({
            uid: commentUid,
            previousUid: messageComments?.[j - 1],
            isLastComment: j === messageComments?.length - 1,
            key: commentUid,
            channelUid: channelUid,
            parentMessageUid: message?.uid,
            isComment: true,
            people: message?.people,
          });
        }
      }
      return output;
    }
  );

  const channelEmoji = useSelector((st) =>
    dataChannelsByIdEmojiSelector(st, selectedChannel)
  );
  const renderMessages = useSelector(selectRenderMessages, shallowEqual);

  const mentionedPeopleIds = useSelector((st) =>
    dataMessagesByChannelMentionedIdsSelector(st, selectedChannel)
  );

  const mentionedPeopleProfileMap = useSelector((st) =>
    dataUsersByIdsSelector(st, mentionedPeopleIds)
  );

  useEffect(() => {
    mentionedPeopleIds.forEach((userId) => {
      // The subscription handles duplicates internally
      dispatch(addUserListener(userId));
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mentionedPeopleIds.length]);

  const handleClick = () => {
    console.log("clicked");
    dispatch(setIsCommenting(null));
    // dispatch(loadMoreMessages(selectedChannel));
  };

  useEffect(() => {
    isLoadingMessages.current = false;

    scrollToBottom();
    setTimeout(() => {
      scrollToBottom();
    }, 300);
  }, [selectedChannel]);

  useEffect(() => {
    isLoadingMessages.current = false;
    let lastHeight = lastChatHeight.current;
    lastChatHeight.current = chatViewRef.current?.scrollHeight;
    let newMessageHeight = chatViewRef.current?.scrollHeight - lastHeight;
    if (
      chatViewRef.current.scrollHeight -
        chatViewRef.current.clientHeight -
        chatViewRef.current.scrollTop <
      400
    ) {
      scrollToBottom("smooth");
    } else {
      //TODO display indicator that there's a new message
    }
    if (chatViewRef.current.scrollTop < 150) {
      chatViewRef.current.scrollTop += newMessageHeight;
    }
    if (spacerRef.current?.clientHeight > 1) {
      loadMessages();
    }
  }, [renderMessages.length]);

  const { currentTime } = useStartOfHour();

  const messagesEndRef = React.useRef(null);
  const spacerRef = React.useRef(null);
  const scrollComponentRef = React.useRef(null);
  const isLoadingMessages = React.useRef(false);

  const loadMessages = () => {
    if (!isLoadingMessages.current) {
      isLoadingMessages.current = true;
      dispatch(loadMoreMessages(selectedChannel));
    }
  };

  const handleScroll = (e) => {
    let scrollTop = chatViewRef.current.scrollTop;
    if (scrollTop < 200) {
      loadMessages();
    }
  };

  const handleWheel = (e) => {};

  return (
    <ChatOverallContainer
      ref={scrollComponentRef}
      onWheel={handleWheel}
      id="scroll-container"
    >
      <ChatScrollViewContainer
        onClick={handleClick}
        ref={chatViewRef}
        onScroll={handleScroll}
      >
        <Spacer ref={spacerRef}></Spacer>
        <SentryContainer ref={sentryRef} isVisible={!showLoadingIndicator}>
          {selectedChannel &&
            (noMoreMessages !== true && renderMessages.length > 0 ? (
              <ClassicSpinner size={23} color={"#8e8e93;"} />
            ) : (
              <NoMoreMessagesText>You've reached the top!</NoMoreMessagesText>
            ))}
        </SentryContainer>
        {renderMessages &&
          renderMessages.map((message, idx) => (
            <ChatMessage
              uid={message?.uid}
              currentTime={currentTime}
              previousUid={message?.previousUid}
              key={message?.key || message?.uid}
              isTop={message?.isTop}
              isComment={message?.isComment}
              channelEmoji={channelEmoji}
              channelUid={message?.channelUid}
              parentMessageUid={message?.parentMessageUid}
              isLastComment={message?.isLastComment}
              mentionedPeopleProfiles={values(
                pick(mentionedPeopleProfileMap, message?.people || [])
              )}
              showAnimatedCounter={showAnimatedCounter}
            />
          ))}
        <BottomBlock ref={messagesEndRef} />
      </ChatScrollViewContainer>
    </ChatOverallContainer>
  );
};

export default NewChatScroll;

const NoMoreMessagesText = styled.div`
  color: #8e8e93;
`;

const SentryContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding-top: 15px;
  ${(props) => props.isVisible && `visibility: visible; opacity: 1;`}
`;

const ChatScrollViewContainer = styled.div`
  height: 100%;
  display: flex;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
  overflow-y: scroll;
  max-width: 100%;
  overflow-x: hidden;
`;

const BottomBlock = styled.div`
  width: 100%;
  height: 1px;
`;

const Spacer = styled.div`
  height: 100%;
  width: 100%;
  flex-shrink: 1;
`;

const ChatOverallContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  overflow: auto;
`;
