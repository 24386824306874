import React, { FC, useCallback } from "react";

import { useNewSubchatModalFormData } from "../useNewSubchatModalFormData";
import { useSelector } from "dive/store";
import {
  dataGroupByIdSelector,
  dataSelectedGroupSidebarSelector,
} from "dive/store/data";
import { getGroupColor } from "dive/constants/groupColors";

import { NewSubchatModalEmoji } from "./NewSubchatModalEmoji";

type NewSubchatModalEmojiContainerProps = {};

export const NewSubchatModalEmojiContainer: FC<
  NewSubchatModalEmojiContainerProps
> = ({}) => {
  const {
    data,
    setData,
    meta: { initialData },
  } = useNewSubchatModalFormData();

  const groupId = useSelector(dataSelectedGroupSidebarSelector);
  // @ts-expect-error
  const groupData = useSelector((st) => dataGroupByIdSelector(st, groupId));

  const groupColor = getGroupColor(groupData);

  const onEmojiChange = useCallback(
    (emoji: string) => {
      setData({ emoji });
    },
    [setData]
  );

  return (
    <NewSubchatModalEmoji
      emoji={data?.emoji || initialData.emoji}
      color={groupColor}
      onEmojiChange={onEmojiChange}
    />
  );
};
