import React from "react";
import styled from "styled-components";
import UpcomingEvents from "./UpcomingEvents";
import SidebarListItem from "./SidebarListItem.js";
import { useState } from "react";

import fillerAvatar from "../assets/filler/avatar.png";
import orangePlus from "../assets/orange_plus.svg";
import magnifyingGlass from "../assets/magnifying_glass.svg";
import { ReactComponent as ArrowIconSvg } from "../assets/arrowIcon.svg";
import { ReactComponent as InviteIconSvg } from "../assets/inviteIcon.svg";
import backChevron from "../assets/back_chevron.svg";
import { ReactComponent as BackChevronSvg } from "../assets/back_chevron.svg";
import NestedChannelListItem from "./NestedChannelListItem";
import EventTile from "./EventTile";

import { getGroupColor } from "../constants/groupColors";

import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "@reduxjs/toolkit";
import Squircle from "./Squircle";
import { dispatch } from "react-redux";
import {
  setIsEventsSidebarOpen,
  dataIsNestedSidebarOpenSelector,
} from "../store/data";
import { motion, AnimatePresence } from "framer-motion";
import { isSameDay, isSameWeek, isSameMonth, add } from "date-fns";
import { Title2Regular, FootnoteRegular } from "../constants/DesignSystem";
import { ReactComponent as CalendarSvg } from "../assets/calendarIcon.svg";
import { NEED_RSVP } from "../constants/rsvp.constant";

const EventsSidebar = () => {
  const dispatch = useDispatch();

  const [scrollOffset, setScrollOffset] = useState(0);
  const [searchQueryEvents, setSearchQueryEvents] = useState("");

  const handleChatScroll = (e) => {
    setScrollOffset(e.target.scrollTop);
  };

  const isNestedSidebarOpen = useSelector(dataIsNestedSidebarOpenSelector);

  const selectSelectedGroupUid = (state) =>
    isNestedSidebarOpen ? state?.data?.selectedGroupSidebar : null;

  const selectGroups = (state) => state?.data?.groups;

  const selectChannelUids = createSelector(
    selectSelectedGroupUid,
    selectGroups,
    (groupUid, groups) => {
      if (!groupUid) return [];
      if (!groups) return [];
      if (!groups[groupUid]?.channels) return [];
      return Object.keys(groups[groupUid]?.channels);
    }
  );

  const selectPrevScreen = (state) => state?.data?.sidebarBeforeEvents;
  const prevScreen = useSelector(selectPrevScreen);

  const selectedGroupUid = useSelector(selectSelectedGroupUid);
  const selectSelectedGroupObj = (state) =>
    state?.data?.groups[selectedGroupUid];

  const selectedGroupObj = useSelector(selectSelectedGroupObj);

  const color = useSelector((state) => {
    if (!selectedGroupObj || prevScreen === "groups") return "#FF2D55";
    return getGroupColor(state?.data?.groups?.[state?.data?.selectedGroup]);
  });

  const channelUids = useSelector(selectChannelUids);

  const selectAllEventObjs = (state) =>
    Object.values(state?.data?.events ?? {}).filter((eventObj) =>
      eventObj?.title?.toLowerCase()?.includes(searchQueryEvents?.toLowerCase())
    );

  const selectFilteredEventObjs = createSelector(
    selectSelectedGroupObj,
    selectPrevScreen,
    selectAllEventObjs,
    (groupObj, prevScreen, allEventObjs) => {
      if (!selectedGroupObj) return allEventObjs;
      if (prevScreen === "groups") return allEventObjs;
      return allEventObjs.filter(
        (eventObj) => typeof groupObj?.events?.[eventObj?.uid] !== "undefined"
      );
    }
  );

  const eventObjs = useSelector(selectFilteredEventObjs);

  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const myself = useSelector((state) => state?.data?.myself);

  const getCategory = (event, myUid) => {
    const eventTime = new Date(event.date);
    const now = Date.now();

    if ((event?.responses?.[myUid] ?? 0) === NEED_RSVP) return "Invites";
    if (isSameDay(eventTime, now)) return "Today";
    if (isSameDay(eventTime, add(now, { days: 1 }))) return "Tomorrow";

    if (isSameWeek(eventTime, now)) return days[eventTime.getDay()];

    if (isSameWeek(eventTime, add(now, { weeks: 1 }))) return "Next Week";
    if (isSameMonth(eventTime, now)) return "Later This Month";
    if (isSameMonth(eventTime, add(now, { months: 1 }))) return "Next Month";
    return "A Hot Minute From Now";
  };

  const selectEventSections = createSelector(
    selectFilteredEventObjs,
    (eventObjs) => {
      if (!eventObjs) return [];
      // TODO = Filter/improve this on backend?!
      const validEvents = eventObjs.filter((evt) => !evt.cancelled);
      const futureEvents = validEvents.filter((event) => {
        const eventTime = new Date(event.date);
        return eventTime > Date.now() - 0;
        // return eventTime > Date.now() - 1000000000;
        //>>>> remove the above
      });
      let sortedEventObjs = futureEvents.sort((a, b) => {
        return new Date(a?.date) - new Date(b?.date);
      });
      let sections = Object.entries(
        sortedEventObjs.reduce((categories, nextEvent) => {
          const category = getCategory(nextEvent, myself.uid);
          categories[category] = [...(categories[category] ?? []), nextEvent];
          return categories;
        }, {})
      ).map(([category, events]) => ({
        data: events,
        day: new Date(events?.[0]?.date ?? 0)?.getDate(),
        category,
      }));

      //make sure that Needs RSVP always appears at the beginning
      let needsRsvpIdx = -1;
      for (let i = 0; i < sections.length; i++) {
        sections[i].key = `${i}`;
        if (sections[i].category === "Invites") {
          needsRsvpIdx = i;
        }
      }
      if (needsRsvpIdx >= 0) {
        //move to front of array
        sections.unshift(sections.splice(needsRsvpIdx, 1)[0]);
      }

      return sections;
    }
  );

  const eventSections = useSelector(selectEventSections);

  return (
    <SidebarContainer>
      <MotionContainer
        initial={{ x: 400 }}
        animate={{ x: 0 }}
        exit={{ x: 400 }}
        transition={{ type: "spring", duration: 0.4, bounce: 0.1 }}
        key="events-sidebar"
      >
        <LeftChevronContainer
          onMouseDown={() => dispatch(setIsEventsSidebarOpen(false))}
        >
          <BackChevron color={color} />
        </LeftChevronContainer>
        {selectedGroupObj && selectedGroupObj?.name && prevScreen === "dive" ? (
          <HeaderForSpecificGroupContainer>
            <SmallEventsText>Events</SmallEventsText>
            <GroupNameText>{selectedGroupObj?.name}</GroupNameText>
          </HeaderForSpecificGroupContainer>
        ) : (
          <SidebarTitleText>Events</SidebarTitleText>
        )}

        <SearchBarContainer>
          <MagnifyingGlass />
          <SearchBarInput
            placeholder="Search Events"
            value={searchQueryEvents}
            onChange={(e) => setSearchQueryEvents(e.target.value)}
          ></SearchBarInput>
        </SearchBarContainer>
        <ShadowDivider bottomShadow={scrollOffset >= 1} />
        <ChatsScrollContainer onScroll={handleChatScroll}>
          {/* <Divider bottomShadow={scrollOffset >= 1} /> */}
          {eventSections &&
            eventSections.map(({ data, day, category }, idx) => (
              <>
                <SectionHeader
                  category={category}
                  day={day}
                  color={color}
                ></SectionHeader>
                {data &&
                  data.map((eventObj) => (
                    <EventTile
                      eventUid={eventObj?.uid}
                      key={eventObj?.uid}
                      isInSidebar={true}
                    />
                  ))}
              </>
            ))}
          {eventSections && eventSections.length === 0 && (
            <NoEventsContainer>
              <LargeCalendarIcon />
              <NoEventsTextHeader>No events... yet</NoEventsTextHeader>
              <NoEventsTextDescription>
                Create one in the app!
              </NoEventsTextDescription>
            </NoEventsContainer>
          )}
        </ChatsScrollContainer>
      </MotionContainer>
    </SidebarContainer>
  );
};

const SectionHeader = (props) => {
  const iconOrText = () => {
    if (props?.category === "Invites") {
      return <InviteIcon />;
    }
    if (props?.category === "Pending Approval") {
      return "?";
    }
    if (props?.category === "Next Week") {
      return <ArrowIcon />;
    }
    if (props?.category === "Next Month") {
      return <ArrowIcon />;
    }
    if (props?.category === "A Hot Minute From Now") {
      return <ArrowIcon />;
    }
    return props?.day;
  };
  return (
    <SectionContainer>
      <SectionIconContainer>
        <SectionIconCap color={props?.color} />
        {iconOrText()}
      </SectionIconContainer>
      <SectionHeaderText>{props.category}</SectionHeaderText>
    </SectionContainer>
  );
};

export default EventsSidebar;

const LargeCalendarIcon = styled(CalendarSvg)`
  width: 71.1px;
  height: 68.4px;
  margin-bottom: 8px;
  fill: #8e8e93;
`;

const NoEventsTextHeader = styled(Title2Regular)`
  color: #8e8e93;
  font-size: 20px;
  line-height: 26px;
`;

const NoEventsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const NoEventsTextDescription = styled(FootnoteRegular)`
  margin-top: 4px;
  color: #8e8e93;
`;

const HeaderForSpecificGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 38px;
  max-width: 388px;
  margin-top: 26px;
  margin-bottom: 26px;
`;

const SmallEventsText = styled.div`
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  /* identical to box height, or 138% */

  display: flex;
  align-items: flex-end;
  text-align: right;
  letter-spacing: 0.02em;

  /* UI Tones/Light/Gray 1 */

  color: #8e8e93;
`;

const GroupNameText = styled.div`
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  max-width: 300px;
  /* identical to box height, or 129% */

  text-align: center;
  letter-spacing: 0.02em;

  /* UI Tones/Dark/Gray 7 (Canvas) */

  color: #101b1e;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-shrink: 0;
`;

const ArrowIcon = styled(ArrowIconSvg)`
  width: 14px;
  height: 14px;
  margin-top: 1px;
`;

const InviteIcon = styled(InviteIconSvg)`
  width: 14px;
  height: 16px;
`;

const SectionIconCap = styled.div`
  width: 24px;
  height: 6px;

  /* Solid/Pink */

  background: ${(props) => props?.color};
  border-radius: 24px 24px 0px 0px;
  position: absolute;
  top: 0;
  left: 0;
`;

const SectionHeaderText = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height, or 131% */

  display: flex;
  align-items: center;
  letter-spacing: 0.02em;

  /* UI Tones/Dark/Gray 7 (Canvas) */

  color: #101b1e;
`;

const SectionIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  padding-top: 6px;
  margin-right: 8px;

  width: 24px;
  height: 24px;

  /* UI Tones/Light/White */

  background: #ffffff;
  /* Shadows/100 */

  box-shadow: 0px 2px 4px -2px rgba(24, 39, 75, 0.1);
  border-radius: 6px;

  position: relative;

  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  /* or 133% */

  text-align: center;
  letter-spacing: 0.02em;

  /* UI Tones/Dark/Gray 7 (Canvas) */

  color: #101b1e;
`;

const SectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  align-self: flex-start;
  margin-left: 8px;
  margin-right: 8px;
  margin-top: 8px;
  margin-bottom: 8px;
`;

const SidebarTitleText = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  /* identical to box height, or 127% */

  display: flex;
  align-items: flex-end;
  letter-spacing: 0.01em;

  margin-top: 26px;
  margin-bottom: 26px;

  /* UI Tones/Dark/Gray 7 (Canvas) */

  color: #101b1e;
`;

const LeftChevronContainer = styled.div`
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
`;

const LeftChevron = styled.div`
  width: 17px;
  height: 28px;
  margin-top: 26px;
  margin-left: 24px;
  background-image: url(${backChevron});
  background-size: contain;
`;

const BackChevron = styled(BackChevronSvg)`
  width: 15.3px;
  height: 25.2px;
  margin-top: 26px;
  margin-left: 24px;
  fill: ${(props) => props?.color};
`;

const ChatsScrollContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 16px;
  overflow-y: scroll;
  padding-left: 8px;
  padding-right: 8px;
`;

const ShadowDivider = styled.div`
  width: 100%;
  height: 16px;
  box-shadow: ${(props) =>
    props?.bottomShadow ? "0px 3px 4px -2px rgba(24, 39, 75, 0.1);" : ""};
  z-index: 1;
`;

const SidebarContainer = styled.div`
  width: 380px;
  height: 100%;
  background: transparent;

  border-right: 1px solid rgba(0, 0, 0, 0.05);
  /* Shadows/300 */
  flex-shrink: 0;

  box-shadow: 0px 6px 8px -6px rgba(24, 39, 75, 0.1);

  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: absolute;
  overflow-x: hidden;
  z-index: 2;
  top: 0;
  left: 0;
`;

const MotionContainer = styled(motion.div)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  position: relative;
  background: #fafafa;
`;

const SearchBarContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border: none;
  //   width: 90%;
  width: calc(100% - 48px);
  height: 36px;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 100px;
  flex-shrink: 0;
`;

const SearchBarInput = styled.input`
  width: 100%;
  height: 22px;
  margin-left: 8px;
  margin-right: 24px;
  border: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  background: transparent;

  &:focus {
    outline: none;
  }
`;

const MagnifyingGlass = styled.div`
  width: 26px;
  height: 24px;
  margin-left: 8px;
  background-image: url(${magnifyingGlass});
  background-size: cover;
`;
