import { getDatabase, update, ref } from "firebase/database";
import { getAuth } from "firebase/auth";

import { logDebug } from "../../util/log.util";
import { getRandomInt } from "../../util/number.util";
import { GOING_RSVP_GIF_SUGGESTIONS } from "../../constants/event.constant";
import { GOING_RSVP } from "../../constants/rsvp.constant";

import { inviteToEvent } from "./inviteToEvent";
import { rsvpToEvent } from "./rsvpToEvent";

type CreateEventPayload = {
  // TODO: Provide proper TS types
  data: any;
};

export async function createEvent(payload: CreateEventPayload) {
  const { data } = payload;

  const realtimeDb = getDatabase();
  const authSession = getAuth();

  const eventId = data.uid;
  const userId = authSession.currentUser?.uid;

  if (!userId) {
    logDebug("[createEvent] Cannot retrieve current user ID");

    return;
  }

  const updates: Record<string, number | string | boolean | object> = {};

  for (let key in data) {
    updates[`events/${eventId}/${key}`] = data[key];
  }

  updates[`events/${eventId}/timestamp`] = Date.now();
  updates[`users/${userId}/bools/nux/hasCreatedAnEvent`] = true;

  await update(ref(realtimeDb), updates);

  await inviteToEvent({ userId, eventId });

  const randomGifIndex = getRandomInt(0, GOING_RSVP_GIF_SUGGESTIONS.length);

  const randomGif =
    GOING_RSVP_GIF_SUGGESTIONS[randomGifIndex] || GOING_RSVP_GIF_SUGGESTIONS[0];

  await rsvpToEvent({
    eventId: eventId,
    rsvpIdx: GOING_RSVP,
    gif: randomGif,
    text: "",
  });
}
