import React, { FC } from "react";

// @ts-expect-error
import { ReactComponent as PlusSvg } from "dive/assets/plus.svg";

import {
  CreateSubchatBlock,
  CreateSubchatButton,
  CreateSubchatIconBlock,
  CreateSubchatLabel,
} from "./CreateSubchat.style";

type CreateSubchatProps = {
  isVisible: boolean;
  color: string;
  onClick: () => void;
};

export const CreateSubchat: FC<CreateSubchatProps> = ({
  isVisible,
  color,
  onClick,
}) => {
  if (!isVisible) {
    return null;
  }

  return (
    <CreateSubchatBlock>
      <CreateSubchatButton $color={color} onClick={onClick}>
        <CreateSubchatIconBlock>
          <PlusSvg width="20" height="20" />
        </CreateSubchatIconBlock>
        <CreateSubchatLabel>New Subchat</CreateSubchatLabel>
      </CreateSubchatButton>
    </CreateSubchatBlock>
  );
};
