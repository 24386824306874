import React, { FC, useCallback, useEffect, useState } from "react";
import {EventActivityUpdateMessage} from "./EventActivityUpdateMessage"

type EventActivityUpdateMessageContainerProps = {
  data: any;
};

export const EventActivityUpdateMessageContainer: FC<
EventActivityUpdateMessageContainerProps
> = ({ data }) => {

  return (
    <EventActivityUpdateMessage
      data={data}
    />
  );
};
