import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { motion, AnimatePresence } from "framer-motion";

import { ProfileCard } from "../../components/ProfileCard";
import {
  dataLastActiveByUserIdAndPermissionSelector,
  dataProfileScoreByUserIdSelector,
  dataUsersByIdSelector,
  dataUsersSelector,
} from "../../store/data";
import { retrieveProfileScore } from "../../store/data/thunk";
import { store, useDispatch, useSelector } from "../../store";
import { logDebug } from "../../util/log.util";
import { ElementLayout } from "../../type/shared.type";

import {
  ProfileCardProviderContext,
  ProfileCardProviderContextType,
  ShowProfileCardPayload,
} from "./ProfileCardProvider.context";

type ProfileCardProviderProps = PropsWithChildren<{}>;

export const ProfileCardProvider: FC<ProfileCardProviderProps> = ({
  children,
}) => {
  const dispatch = useDispatch();

  const [renderData, setRenderData] = useState<{
    updatedAt: number;
    cardLayout: ElementLayout | null;
    targetLayout: ElementLayout | null;
  }>({
    updatedAt: 0,
    cardLayout: null,
    targetLayout: null,
  });

  const [{ userId, payload }, setProfileCardData] = useState<{
    userId: string | null;
    payload: ShowProfileCardPayload | null;
  }>({
    userId: null,
    payload: null,
  });

  useEffect(() => {
    if (userId) {
      dispatch(retrieveProfileScore(userId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  // @ts-expect-error
  const profileData = useSelector((st) => dataUsersByIdSelector(st, userId));

  const lastActiveDate = useSelector((st) =>
    // @ts-expect-error
    dataLastActiveByUserIdAndPermissionSelector(st, userId)
  );

  const profileScore = useSelector((st) =>
    // @ts-expect-error
    dataProfileScoreByUserIdSelector(st, userId)
  );

  const onProfileCardReady = useCallback((cardLayout: ElementLayout) => {
    setRenderData((prevRenderData) => {
      return { ...prevRenderData, cardLayout };
    });
  }, []);

  const contextData: ProfileCardProviderContextType = useMemo(() => {
    return {
      showProfileCard: (
        nextUserId: string,
        payload: ShowProfileCardPayload
      ) => {
        const targetNode = payload.targetElementRef;

        if (!targetNode.current) {
          logDebug(`[ProfileCardProvider] Cannot get target node`);

          return;
        }

        const storeSnapshot = store.getState();

        const userMap = dataUsersSelector(storeSnapshot);

        if (!userMap?.[nextUserId]) {
          logDebug(`[ProfileCardProvider] User data doesn't exist`, {
            userId: nextUserId,
          });

          return;
        }

        const targetLayoutRect = targetNode.current.getBoundingClientRect();

        setProfileCardData({ userId: nextUserId, payload: payload });

        setRenderData({
          updatedAt: Date.now(),
          cardLayout: null,
          targetLayout: {
            widthPx: targetLayoutRect.width,
            heighPx: targetLayoutRect.height,
            xPosPx: targetLayoutRect.x,
            yPosPx: targetLayoutRect.y,
          },
        });
      },
      hideProfileCard: () => {
        setProfileCardData({ userId: null, payload: null });
        setRenderData({
          updatedAt: Date.now(),
          cardLayout: null,
          targetLayout: null,
        });
      },
    };
  }, []);

  const isVisible = !!userId && !!payload;
  const isReady = !!renderData.cardLayout;

  const windowWidthPx = window.innerWidth;
  const windowHeightPx = window.innerHeight;

  const targetWidth = renderData.targetLayout?.widthPx || 0;
  const targetHeight = renderData.targetLayout?.heighPx || 0;
  const targetXPos = renderData.targetLayout?.xPosPx || 0;
  const targetYPos = renderData.targetLayout?.yPosPx || 0;

  const cardHeight = renderData.cardLayout?.heighPx || 0;

  let profileCardWidthPx = 252;

  let xPosPx = 0;
  let yPosPx = 0;
  let topIndent = 0;
  let bottomIndent = 0;

  if (targetXPos + profileCardWidthPx > windowWidthPx) {
    xPosPx = targetXPos + targetWidth - profileCardWidthPx;
  } else {
    xPosPx = targetXPos;
  }

  if (targetYPos - cardHeight < 0) {
    yPosPx = targetYPos;
    topIndent = targetHeight + 10;
  } else {
    yPosPx = targetYPos - cardHeight - 10;
    bottomIndent = 50;
  }

  return (
    <ProfileCardProviderContext.Provider value={contextData}>
      {children}
      <AnimatePresence>
        {isVisible ? (
          <motion.div
            key={`${userId}-${renderData.updatedAt}`}
            initial={{ opacity: 0 }}
            animate={{ opacity: isReady ? 1 : 0 }}
            exit={{ opacity: 0 }}
            transition={{ type: "spring", duration: 0.4, bounce: 0.1 }}
            style={{
              paddingTop: topIndent,
              paddingBottom: bottomIndent,
              position: "absolute",
              top: yPosPx,
              left: xPosPx,
              zIndex: 999,
            }}
            onMouseLeave={contextData.hideProfileCard}
          >
            <ProfileCard
              isLoading={false}
              layout={{ widthPx: profileCardWidthPx }}
              data={{
                ...profileData,
                userId,
                lastActiveDate,
                reactCount: profileScore?.reacts || 0,
                eventCount: profileScore?.events || 0,
              }}
              onLayoutReady={onProfileCardReady}
              hideProfileCard={contextData.hideProfileCard}
            />
          </motion.div>
        ) : null}
      </AnimatePresence>
    </ProfileCardProviderContext.Provider>
  );
};
