import { useCallback, useEffect, useRef } from "react";
import throttle from "lodash/throttle";

import { useProfileCard } from "../provider/ProfileCardProvider/useProfileCard";

export function useAttachProfileCard(userId: string) {
  const targetElementRef = useRef<HTMLElement>(null);
  const timerIdRef = useRef<number>();

  const { showProfileCard } = useProfileCard();

  useEffect(() => {
    return () => {
      if (timerIdRef.current) {
        clearTimeout(timerIdRef.current);
      }
    };
  }, []);

  const onClick = useCallback(
    () => {
      showProfileCard(userId, {
        targetElementRef,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userId]
  );

  const onMouseEnter = useCallback(
    throttle(
      () => {
        if (timerIdRef.current) {
          clearTimeout(timerIdRef.current);
        }

        // @ts-expect-error
        timerIdRef.current = setTimeout(() => {
          showProfileCard(userId, {
            targetElementRef,
          });
        }, 800);
      },
      1000,
      {
        trailing: false,
      }
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userId]
  );

  const onMouseLeave = useCallback(
    () => {
      if (timerIdRef.current) {
        clearTimeout(timerIdRef.current);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userId]
  );

  return {
    targetElementRef,
    onClick,
    onMouseEnter,
    onMouseLeave,
  };
}
