import { format } from "date-fns";

import { HOUR_MS, MINUTE_MS } from "../constants/time.constant";

export function getIsUserActiveStatus(userLastActive: boolean | number) {
  return (
    userLastActive === true ||
    (Boolean(userLastActive) &&
      typeof userLastActive === "number" &&
      Math.floor((Date.now() - userLastActive) / 60000) <= 2 &&
      userLastActive % 10 !== 0)
  );
}

export const getMeasurementEnding = (num: number) => {
  return num === 1 ? "" : "s";
};

export function getUserLastActiveStatusText(
  lastSeen: any,
  truncated: boolean = false
) {
  const messageStart = truncated ? "Seen" : "Last seen";

  if (!lastSeen) {
    return null;
  } else if (lastSeen === true) {
    return "Active now";
  }

  const activeRecency = Date.now() - lastSeen;

  const activeRecencyInMinutes = Math.floor(activeRecency / MINUTE_MS);
  const activeRecencyInHours = Math.floor(activeRecency / HOUR_MS);
  const activeRecencyInDays = Math.floor((activeRecency / HOUR_MS) * 24);
  const activeRecencyInWeeks = Math.floor((activeRecency / HOUR_MS) * 24 * 7);

  const endOfDay = new Date();

  endOfDay.setHours(23, 59, 59, 999);

  const isYesterday = lastSeen > endOfDay.getTime() - 2 * HOUR_MS * 24;

  if (activeRecencyInMinutes <= 2 && lastSeen % 10 !== 0) {
    return "Active now";
  } else if (activeRecencyInMinutes < 1) {
    return `${messageStart} just now`;
  } else if (activeRecencyInMinutes <= 59) {
    return `${messageStart} ${activeRecencyInMinutes} minute${getMeasurementEnding(
      activeRecencyInMinutes
    )} ago`;
  } else if (activeRecencyInHours <= 23) {
    return `${messageStart} ${activeRecencyInHours} hour${getMeasurementEnding(
      activeRecencyInHours
    )} ago`;
  } else if (isYesterday) {
    return `${messageStart} yesterday`;
  } else if (activeRecencyInDays <= 6) {
    return `${messageStart} ${activeRecencyInDays} day${getMeasurementEnding(
      activeRecencyInDays
    )} ago`;
  } else if (activeRecencyInWeeks <= 3) {
    return `${messageStart} within ${activeRecencyInWeeks} week${getMeasurementEnding(
      activeRecencyInWeeks
    )}`;
  } else {
    return `${messageStart} a while ago`;
  }
}

const schoolIcons = {
  "University of Texas at Austin": ["🤘", "UT Austin"],
  "University of Maryland at College Park": ["🐢", "UMD"],
  "University of Southern California": ["✌️", "USC"],
  "College of William and Mary": ["🦉", "W&M"],
};

const validText = (text: number | string) => {
  return Boolean(text && String(text).trim().length > 0);
};

type GetUserTagsPayload = {
  createdAt?: number;
  school?: string;
  year?: string;
};

export function getUserTags({ createdAt, school, year }: GetUserTagsPayload) {
  const tags: { icon: string; name: string }[] = [];

  if (createdAt && validText(createdAt)) {
    tags.push({
      icon: "🤙",
      name: `Joined ${format(createdAt, "MMMM yyyy")}`,
    });
  }

  if (school && validText(school)) {
    const yearLabel = year && year !== "None" ? `, ${year}` : "";

    tags.splice(0, 0, {
      // @ts-expect-error
      icon: schoolIcons[school]?.[0] ?? "🏛️",
      // @ts-expect-error
      name: `${schoolIcons[school]?.[1] ?? school}${yearLabel}`,
    });
  }

  return tags;
}
