import { CSSProperties } from "react";
import styled from "styled-components";

type ColorPaletteRingBlockProps = {
  $isSelected: boolean;
};

export const ColorPaletteRingBlock = styled.div<ColorPaletteRingBlockProps>`
  margin: 0 6px;
  border-radius: 50%;
  border: 4px solid
    ${({ $isSelected }) => ($isSelected ? "#fff" : "transparent")};

  &:hover {
    cursor: pointer;
  }

  &:active {
    transform: scale(0.98);
  }
`;

type ColorPaletteRingBlockItemProps = {
  $backgroundValue: CSSProperties["background"];
};

export const ColorPaletteRingBlockItem = styled.button<ColorPaletteRingBlockItemProps>`
  background: ${({ $backgroundValue }) => $backgroundValue};
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  pointer-events: none;
`;
