import axios from "axios";

import { getDatabase } from "firebase/database";
import { getAuth } from "firebase/auth";

import { logDebug } from "../../util/log.util";

type RegisterDesktopPushTokenPayload = {
  token: string;
  isApns?: boolean;
};

export async function registerDesktopPushToken({
  token,
  isApns = false,
}: RegisterDesktopPushTokenPayload) {
  if (!token) {
    logDebug("[registerDesktopPushToken] APNS tone isn't provided");

    return;
  }

  const realtimeDb = getDatabase();
  const authSession = getAuth();

  const idToken = await authSession.currentUser?.getIdToken();

  if (!idToken) {
    logDebug(
      "[registerDesktopPushToken] Cannot retrieve an ID token of current user"
    );

    return;
  }

  const projectId = realtimeDb.app.options.projectId;

  if (!projectId) {
    logDebug("[registerDesktopPushToken] Cannot retrieve Firebase project ID");

    return;
  }

  const config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
      "Content-Type": "application/json",
    },
  };

  return axios.post(
    `https://us-central1-${projectId}.cloudfunctions.net/registerWebAppPushToken`,
    {
      token: token,
      isIos: isApns,
    },
    config
  );
}
