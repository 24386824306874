type GetFileFromDataUrlPayload = {
  fileName?: string;
  mimeType?: string;
};

// Copied from:
//   https://stackoverflow.com/a/38935990
export function getFileFromDataUrl(
  dataUrl: string,
  options: GetFileFromDataUrlPayload = {}
) {
  const fileName = options.fileName || `${Date.now}`;

  if (dataUrl.startsWith("data:")) {
    var arr = dataUrl.split(","),
      mime = arr[0].match(/:(.*?);/)?.[1],
      bstr = atob(arr[arr.length - 1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    var file = new File([u8arr], fileName, { type: mime || options.mimeType });

    return Promise.resolve(file);
  }

  return fetch(dataUrl)
    .then((res) => res.arrayBuffer())
    .then((buf) => new File([buf], fileName, { type: options.mimeType }));
}
