import React, { FC, useCallback } from "react";

import { useDispatch, useSelector } from "../../../store";
import {
  dataCommentByIdSelector,
  dataMessagesByIdlSelector,
  dataSelectedChannelIdSelector,
  removeComment,
  removeMessage,
} from "../../../store/data";
import { useOnMessageSent } from "../../../hook/useOnMessageSent";
import fb from "../../../firebase/firebaseCalls.js";
import fbStorage from "../../../firebase/storage";
import { logDebug } from "../../../util/log.util";
import { IMAGE_MAX_WIDTH } from "../../../constants/image";

import { ChatMessageIndicator } from "./ChatMessageIndicator";

type ChatMessageIndicatorContainerProps = {
  isComment: boolean;
  messageId: string;
};

export const ChatMessageIndicatorContainer: FC<
  ChatMessageIndicatorContainerProps
> = ({ isComment, messageId }) => {
  const dispatch = useDispatch();

  const channelId = useSelector(dataSelectedChannelIdSelector);

  const comment = useSelector((st) =>
    // @ts-expect-error
    dataCommentByIdSelector(st, { commentId: messageId })
  );

  const message = useSelector((st) =>
    // @ts-expect-error
    dataMessagesByIdlSelector(st, { messageId, channelId })
  );

  const messageData = isComment ? comment : message;

  const onMessageSent = useOnMessageSent();

  const onRetry = useCallback(
    async () => {
      // TODO: Refactor to use "application" reducer state
      const isOnline = window.navigator.onLine;

      if (!isOnline) {
        logDebug(
          `[ChatMessageIndicatorContainer] [onRetry] User is offline, skipping retry`
        );

        return;
      }

      if (!messageData) {
        logDebug(
          `[ChatMessageIndicatorContainer] [onRetry] Message data doesn't exist`
        );

        return;
      }

      const {
        localMessage,
        showNotSent,
        isOptimistic,
        _mediaFile,
        ...messagePayload
      } = messageData;

      if (messagePayload.image && _mediaFile) {
        messagePayload.image = await fbStorage.uploadFileToStorage(
          _mediaFile,
          IMAGE_MAX_WIDTH
        );
      }

      await fb.firebaseSetMessage(
        messagePayload,
        channelId,
        isComment ? messageData.parentMessageUid : undefined
      );

      onMessageSent({ messageId: messageData.uid, channelId });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [messageData, isComment]
  );

  const onCancel = useCallback(
    () => {
      if (isComment) {
        dispatch(removeComment({ commentId: messageData.uid }));
      } else {
        dispatch(removeMessage({ messageId: messageData.uid, channelId }));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [messageData.uid, channelId, isComment]
  );

  return (
    <ChatMessageIndicator
      localMessage={messageData?.localMessage || false}
      showNotSent={messageData?.showNotSent || false}
      onRetry={onRetry}
      onCancel={onCancel}
    />
  );
};
