import { useCallback, useEffect, useState } from "react";

import { getStorage } from "../../util/storage.util";
import { logDebug } from "../../util/log.util";
import { DISMISSED_AI_SUGGESTIONS_KEY } from "../../constants/storage.constant";

type DismissedAiSuggestionMap = {
  [messageId in string]: number;
};

export function useAiEventSuggestionStatus(messageId: string) {
  // Hide AI suggestion until status is determined
  const [isDismissed, setIsDismissed] = useState(true);

  useEffect(() => {
    if (!messageId) {
      logDebug("[useAiEventSuggestionStatus] Message ID isn't provided", {
        messageId,
      });
    }

    const storage = getStorage();

    const dismissedSuggestionMap = storage.getItem<DismissedAiSuggestionMap>(
      DISMISSED_AI_SUGGESTIONS_KEY
    );

    if (!dismissedSuggestionMap || !dismissedSuggestionMap[messageId]) {
      setIsDismissed(false);
    }
  }, [messageId]);

  const dismissAiEventSuggestion = useCallback(() => {
    const storage = getStorage();

    const dismissedSuggestionMap = storage.getItem<DismissedAiSuggestionMap>(
      DISMISSED_AI_SUGGESTIONS_KEY
    );

    storage.setItem(DISMISSED_AI_SUGGESTIONS_KEY, {
      ...(dismissedSuggestionMap || {}),
      [messageId]: Date.now(),
    });

    setIsDismissed(true);
  }, [messageId]);

  return {
    isDismissed,
    dismissAiEventSuggestion,
  };
}
