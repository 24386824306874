import React, { FC, useCallback, useEffect, useRef, useState } from "react";

import { useDispatch, useSelector } from "../../../store";
import {
  dataGroupByIdSelector,
  dataMyselfIdSelector,
  dataUsersSelector,
  selectGroup,
  selectGroupSidebar,
  selectSidebar,
  setIsNestedSidebarOpen,
  setSelectedChannel,
} from "../../../store/data";
import { logDebug } from "../../../util/log.util";
import { retrieveGroupData } from "../../../store/data/thunk";
import { retrieveProfileSharedData } from "../../../store/data/thunk/retrieveProfileSharedData";
import { addUserToGroup } from "../../../store/data/thunk/addUserToGroup";
import { sleep } from "../../../util/sleep.util";

import { ChatMessageGroupTile } from "./ChatMessageGroupTile";

type ChatMessageGroupTileContainerProps = {
  groupId: string;
};

export const ChatMessageGroupTileContainer: FC<
  ChatMessageGroupTileContainerProps
> = ({ groupId }) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const sidebarTaskTimerIdRef = useRef<NodeJS.Timeout>();

  // @ts-expect-error
  const groupData = useSelector((st) => dataGroupByIdSelector(st, groupId));
  // @ts-expect-error
  const myId = useSelector(dataMyselfIdSelector);
  const userMap = useSelector(dataUsersSelector);

  const members = groupData?.members ? Object.keys(groupData.members) : [];

  const isJoined = members.includes(myId);

  const onJoin = useCallback(
    async () => {
      if (!groupData) {
        logDebug(
          `[ChatMessageGroupTileContainer][onJoin] The group data isn't loaded yet`
        );

        return;
      }

      if (!isJoined) {
        setIsLoading(true);

        await dispatch(
          addUserToGroup({
            groupId: groupData.uid,
            userIds: [myId],
          })
        );

        await sleep(500);

        setIsLoading(false);
      }

      dispatch(selectGroup(groupData.uid));
      dispatch(selectGroupSidebar(groupData.uid));
      dispatch(selectSidebar(groupData.uid));

      dispatch(setIsNestedSidebarOpen(false));

      if (groupData.isSimple) {
        if (groupData.channels) {
          dispatch(setSelectedChannel(Object.keys(groupData.channels)[0]));
        }
      } else {
        dispatch(setSelectedChannel(null));

        // Clear the task from the previous iteration if it exists
        if (sidebarTaskTimerIdRef.current) {
          clearTimeout(sidebarTaskTimerIdRef.current);
        }

        sidebarTaskTimerIdRef.current = setTimeout(() => {
          dispatch(setIsNestedSidebarOpen(true));
        }, 500);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isJoined, groupData]
  );

  useEffect(
    () => {
      if (!groupId) {
        return;
      }

      if (!groupData) {
        logDebug(`[ChatMessageGroupTileContainer] Group data doesn't exist`);

        dispatch(retrieveGroupData(groupId));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [groupId]
  );

  useEffect(
    () => {
      if (!members || !members.length) {
        return;
      }

      members.slice(0, 2).forEach((memberId) => {
        if (!userMap[memberId]) {
          logDebug(
            `[ChatMessageGroupTileContainer] Start fetching missing user profile`,
            {
              userId: memberId,
            }
          );

          dispatch(retrieveProfileSharedData(memberId));
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [members]
  );

  if (!groupId) {
    logDebug(
      `[ChatMessageGroupTileContainer] The ID of the group is not defined`
    );

    return null;
  }

  const topGroupMembers = members
    .slice(0, 2)
    .map((memberId) => userMap[memberId]);

  if (
    !groupData ||
    !members ||
    !members.length ||
    // If some profiles haven't been fetched yet
    topGroupMembers.find((profileData) => !profileData)
  ) {
    return null;
  }

  const isOpen =
    groupData &&
    groupData.name !== "" &&
    Object.keys(groupData?.admins ?? {}).length !== 0 &&
    Object.keys(groupData?.channels ?? {}).length !== 0 &&
    members.length !== 0;

  const listedUsers = topGroupMembers.length
    ? `${topGroupMembers[0]?.name}${
        members.length > 1 ? `, ${topGroupMembers[1]?.name},` : ""
      }${members.length - 2 > 0 ? ` and ${members.length - 2} others` : ""}.`
    : "";

  const description = `Join ${listedUsers}`;

  return (
    <ChatMessageGroupTile
      isOpen={isOpen}
      isJoined={isJoined}
      isLoading={isLoading}
      groupId={groupId}
      // @ts-expect-error
      name={groupData.name}
      description={description}
      color={groupData.color || "#FAFAFA"}
      avatarUrl={groupData.avatar || groupData.avatarMD}
      emoji={groupData.emoji}
      onJoin={onJoin}
    />
  );
};
