import React, { FC } from "react";
import format from "date-fns/format";
import { CircleSpinner } from "react-spinners-kit";

import {
  AiEventSuggestionBlock,
  AiEventSuggestionBlockEmoji,
  AiEventSuggestionBlockSection,
  AiEventSuggestionBlockTitle,
  AiEventSuggestionBlockInfo,
  AiEventSuggestionBlockButton,
  AiEventSuggestionBlockButtonContainer,
  AiEventSuggestionBlockButtonContainerShadow,
  AiEventSuggestionBlockButtonTitle,
  AiEventSuggestionHideSection,
  AiEventSuggestionContent,
  AiEventSuggestionHideSectionIcon,
  AiEventSuggestionHideSectionButton,
} from "./AiEventSuggestion.style";

type AiEventSuggestionProps = {
  title: string;
  emoji: string;
  date: number;
  isLoading: boolean;
  onCreateEvent: () => void;
  onDismiss: () => void;
};

export const AiEventSuggestion: FC<AiEventSuggestionProps> = ({
  emoji,
  title,
  date,
  isLoading,
  onCreateEvent,
  onDismiss,
}) => {
  // TODO: Show "Create Event" button when migrate creation logic from mobile
  const isCreateEventEnabled = false;

  return (
    <AiEventSuggestionContent>
      <AiEventSuggestionBlock>
        <AiEventSuggestionBlockEmoji>{emoji}</AiEventSuggestionBlockEmoji>
        <AiEventSuggestionBlockSection>
          <AiEventSuggestionBlockTitle>{title}</AiEventSuggestionBlockTitle>
          <AiEventSuggestionBlockInfo>
            {format(date, "EEEE")} at {format(date, "h:mm aa")}
          </AiEventSuggestionBlockInfo>
        </AiEventSuggestionBlockSection>
        {isCreateEventEnabled ? (
          <AiEventSuggestionBlockButtonContainer>
            <AiEventSuggestionBlockButtonContainerShadow>
              Create Event
            </AiEventSuggestionBlockButtonContainerShadow>
            <AiEventSuggestionBlockButton
              isLoading={isLoading}
              disabled={isLoading}
              title="Create Event"
              aria-label="Create Event"
              onClick={onCreateEvent}
            >
              <AiEventSuggestionBlockButtonTitle>
                {isLoading ? (
                  <CircleSpinner size={10} color="#000000" />
                ) : (
                  <>{"Create Event"}</>
                )}
              </AiEventSuggestionBlockButtonTitle>
            </AiEventSuggestionBlockButton>
          </AiEventSuggestionBlockButtonContainer>
        ) : null}
      </AiEventSuggestionBlock>
      {isCreateEventEnabled ? (
        <AiEventSuggestionHideSection>
          <AiEventSuggestionHideSectionIcon /> Trying to make plans?
          <AiEventSuggestionHideSectionButton onClick={onDismiss}>
            No thanks
          </AiEventSuggestionHideSectionButton>
        </AiEventSuggestionHideSection>
      ) : null}
    </AiEventSuggestionContent>
  );
};
