import React, { FC, useMemo } from "react";
import has from "lodash/has";

import {
  dataGroupByIdSelector,
  dataMyselfIdSelector,
  dataSelectedGroupSidebarSelector,
} from "dive/store/data";
import { useSelector } from "dive/store";
import { getGroupColor } from "dive/constants/groupColors";
import { SubchatType } from "dive/type/subchat.type";
import {
  ANNOUNCEMENT_SUBCHAT_TYPE,
  PRIVATE_SUBCHAT_TYPE,
  PUBLIC_SUBCHAT_TYPE,
} from "dive/constants/subchat.contant";

import { useNewSubchatModalFormData } from "../useNewSubchatModalFormData";

import { NewSubchatModalInfo } from "./NewSubchatModalInfo";

type NewSubchatModalInfoContainerProps = {};

export const NewSubchatModalInfoContainer: FC<
  NewSubchatModalInfoContainerProps
> = ({}) => {
  const {
    data,
    setData,
    meta: { initialData },
  } = useNewSubchatModalFormData();

  // @ts-expect-error
  const myId = useSelector(dataMyselfIdSelector);
  const groupId = useSelector(dataSelectedGroupSidebarSelector);
  // @ts-expect-error
  const groupData = useSelector((st) => dataGroupByIdSelector(st, groupId));

  const groupColor = getGroupColor(groupData);

  const { onNameChange, onTypeChange, onIsAutoAddChange } = useMemo(() => {
    return {
      onNameChange: (name: string) => {
        setData({ name });
      },
      onTypeChange: (type: SubchatType) => {
        setData({ type });
      },
      onIsAutoAddChange: (isAutoAdd: boolean) => {
        setData({ isAutoAdd });
      },
    };
  }, [setData]);

  const enabledTypes: SubchatType[] = [];

  const isAdmin = Object.keys(groupData?.admins || {}).includes(myId);

  if (isAdmin) {
    enabledTypes.push(PUBLIC_SUBCHAT_TYPE);
    enabledTypes.push(PRIVATE_SUBCHAT_TYPE);
    enabledTypes.push(ANNOUNCEMENT_SUBCHAT_TYPE);
  } else if (
    (groupData && !has(groupData, "memberChatCreationType")) ||
    groupData?.memberChatCreationType === "all"
  ) {
    enabledTypes.push(PRIVATE_SUBCHAT_TYPE);
    enabledTypes.push(PUBLIC_SUBCHAT_TYPE);
  } else if (groupData?.memberChatCreationType === "open") {
    enabledTypes.push(PUBLIC_SUBCHAT_TYPE);
  }

  return (
    <NewSubchatModalInfo
      name={data?.name || initialData.name}
      type={data?.type || initialData.type}
      isAutoAdd={
        has(data, "isAutoAdd") ? data.isAutoAdd : initialData.isAutoAdd
      }
      enabledTypes={enabledTypes}
      color={groupColor}
      onNameChange={onNameChange}
      onTypeChange={onTypeChange}
      onIsAutoAddChange={onIsAutoAddChange}
    />
  );
};
