import { motion } from "framer-motion";
import styled, { css } from "styled-components";

import { flexCenter, meshBackground } from "dive/constants/DesignSystem";
import { Input } from "dive/components/Input";
import { devices } from "dive/constants/device.constant";

// @ts-expect-error
export const CustomGroupModalBlock = styled(motion.div)`
  ${flexCenter}
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
`;

export const CustomGroupModalCard = styled.div`
  position: relative;
  width: 40%;
  max-height: 80%;
  border-radius: 24px;
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(50px);
  box-shadow: 0px 6px 14px -6px rgba(24, 39, 75, 0.1);
  padding: 0 18px 0 18px;

  @media ${devices.laptop} {
    width: 35%;
  }

  @media ${devices.laptopL} {
    width: 32%;
  }

  @media ${devices.desktopS} {
    width: 30%;
  }

  @media ${devices.desktop} {
    width: 28%;
  }
`;

export const CustomGroupModalClose = styled.button`
  ${flexCenter}
  position: absolute;
  width: 44px;
  height: 44px;
  top: -18px;
  right: -14px;
  border-radius: 50%;
  border: 3px solid #1c1c1d;
  background: transparent;
  overflow: hidden;
  z-index: 999;

  &:hover {
    cursor: pointer;
  }

  &:active {
    transform: scale(0.98);
  }
`;

export const CustomGroupModalIconBlock = styled.div`
  svg {
    margin: 2px 0 0 0;
  }
`;

// @ts-expect-error
export const CustomGroupModalTitle = styled(Input)`
  color: #fff;
  border-radius: 16px;
  border: 0.5px solid rgba(255, 255, 255, 0.1);
  background: rgba(255, 255, 255, 0.15);
  height: 52px;
  width: calc(100% - 42px);
  margin: 18px 0;
  padding: 0 18px;
  font-family: Clash Display !important;
  font-size: 28px;
  line-height: 34px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;

  ::placeholder {
    color: #ffffff;
    opacity: 0.5;
  }

  &:focus {
    outline: none;
  }
`;

export const CustomGroupModalButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 18px 18px 18px 18px;
`;

type CustomGroupModalButtonProps = {
  $isDisabled?: boolean;
};

export const CustomGroupModalButton = styled.button<CustomGroupModalButtonProps>`
  ${({ $isDisabled }) =>
    !$isDisabled ? meshBackground : "background: #2B3437;"}
  width: 196px;
  height: 45px;
  text-align: center;
  border-radius: 100px;
  border: 0.5px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 4px 6px -4px rgba(24, 39, 75, 0.1);
  color: var(--UI-Tones-Light-White, #fff);
  text-align: center;
  font-family: SF Pro Rounded;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0.32px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ $isDisabled }) =>
    !$isDisabled
      ? `
  &:hover {
    cursor: pointer;
  }

  &:active {
    transform: scale(0.98);
  }
  `
      : ""}
`;
