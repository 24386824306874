import { getDatabase, ref, update } from "firebase/database";

import { createMessageTemplate } from "dive/util/template.util";
import { logDebug } from "dive/util/log.util";
import {
  ANNOUNCEMENT_CHAT_INITIAL_MESSAGE,
  MAIN_CHAT_INITIAL_MESSAGE,
  PRIVATE_CHAT_INITIAL_MESSAGE,
  REGULAR_CHAT_INITIAL_MESSAGE,
} from "dive/constants/message.constant";
import { generateMessageId } from "dive/firebase/chat/generateMessageId";

type GetInitialChannelMessageTextPayload = {
  isMain: boolean;
  isPublic: boolean;
  isAdminPost: boolean;
};

type SendChannelInitialMessagePayload = GetInitialChannelMessageTextPayload & {
  channelId: string;
  showCuratedEmojiSuggestion: boolean;
};

function getInitialChannelMessageText({
  isMain,
  isPublic,
  isAdminPost,
}: GetInitialChannelMessageTextPayload) {
  if (isMain) {
    return MAIN_CHAT_INITIAL_MESSAGE;
  } else if (isPublic && !isAdminPost) {
    return REGULAR_CHAT_INITIAL_MESSAGE;
  } else if (!isPublic) {
    return PRIVATE_CHAT_INITIAL_MESSAGE;
  } else if (isPublic && isAdminPost) {
    return ANNOUNCEMENT_CHAT_INITIAL_MESSAGE;
  }

  return "";
}

export function sendChannelInitialMessage({
  channelId,
  showCuratedEmojiSuggestion,
  isAdminPost,
  isMain,
  isPublic,
}: SendChannelInitialMessagePayload) {
  const messageId = generateMessageId();

  if (!messageId) {
    logDebug("[addToGroup] Cannot generate message ID");

    return Promise.reject();
  }

  const initialMessage = createMessageTemplate({
    messageId,
    myId: "diveBotMessage",
  });

  // @ts-expect-error
  initialMessage.text = getInitialChannelMessageText({
    isAdminPost,
    isMain,
    isPublic,
  });
  // @ts-expect-error
  initialMessage.prepopulatedMessage = true;
  // @ts-expect-error
  initialMessage.initialMessage = true;

  if (showCuratedEmojiSuggestion) {
    // @ts-expect-error
    initialMessage.showCuratedEmojiSuggestion = true;
  }

  const updates: Record<string, unknown> = {};

  updates["messages/" + channelId + "/" + initialMessage.uid] = initialMessage;

  return update(ref(getDatabase()), updates);
}
