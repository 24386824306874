import React, {
  FC,
  MouseEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { CircleSpinner } from "react-spinners-kit";

// @ts-expect-error
import { ReactComponent as XSvg } from "dive/assets/icons/xWhite.svg";

import {
  NewEventModalCardContent,
  NewSubchatModalBlock,
  NewSubchatModalButton,
  NewSubchatModalButtonContainer,
  NewSubchatModalCard,
  NewSubchatModalClose,
  NewSubchatModalIconBlock,
} from "./NewSubchatModal.style";
import { NewSubchatModalEmoji } from "./NewSubchatModalEmoji";
import { NewSubchatModalInfo } from "./NewSubchatModalInfo";

type NewSubchatModalProps = {
  hasChanged: boolean;
  isLoading: boolean;
  isFormValid: boolean;
  color: string;
  onClose: () => void;
  onSubmit: () => void;
};

export const NewSubchatModal: FC<NewSubchatModalProps> = ({
  hasChanged,
  isLoading,
  isFormValid,
  color,
  onClose,
  onSubmit,
}) => {
  const contentBlockRef = useRef<HTMLDivElement>(null);

  const onBlockClick = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      if (
        !hasChanged &&
        // @ts-expect-error
        !contentBlockRef.current?.contains(event?.target)
      ) {
        onClose();
      }
    },
    [hasChanged, onClose]
  );

  return (
    <NewSubchatModalBlock
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ type: "spring", duration: 0.4, bounce: 0.2 }}
      onClick={onBlockClick}
    >
      <NewSubchatModalCard ref={contentBlockRef}>
        <NewSubchatModalClose onClick={onClose}>
          <NewSubchatModalIconBlock>
            <XSvg width="44" height="44" />
          </NewSubchatModalIconBlock>
        </NewSubchatModalClose>
        <NewEventModalCardContent>
          <NewSubchatModalEmoji />
          <NewSubchatModalInfo />
          <NewSubchatModalButtonContainer>
            <NewSubchatModalButton
              $isDisabled={!isFormValid || isLoading}
              $color={color}
              disabled={!isFormValid || isLoading}
              onClick={onSubmit}
            >
              {isLoading ? (
                <CircleSpinner size={16} color="#FFF" />
              ) : (
                "Create Subchat!"
              )}
            </NewSubchatModalButton>
          </NewSubchatModalButtonContainer>
        </NewEventModalCardContent>
      </NewSubchatModalCard>
    </NewSubchatModalBlock>
  );
};
