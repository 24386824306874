import {
  TypedUseSelectorHook,
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector,
} from "react-redux";

import { store } from "./store";

type AppDispatch = typeof store.dispatch;

type AppRootState = ReturnType<typeof store.getState>;

export const useDispatch: () => AppDispatch = useReduxDispatch;

export const useSelector: TypedUseSelectorHook<AppRootState> = useReduxSelector;
