import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { UiInitialState, UiModalId } from "./ui.type";

export const uiReducerName = "ui";

export const uiInitialState: UiInitialState = {
  modalPresentationStack: [],
};

const uiSlice = createSlice({
  name: uiReducerName,
  initialState: uiInitialState,
  reducers: {
    pushModalId: (state, { payload }: PayloadAction<UiModalId>) => {
      state.modalPresentationStack.push(payload);
    },
    popModalId: (state, action: PayloadAction) => {
      state.modalPresentationStack.pop();
    },
    clearModalPresentationStack: (state, action: PayloadAction) => {
      state.modalPresentationStack = [];
    },
  },
});

export const uiReducer = uiSlice.reducer;

export const { pushModalId, popModalId, clearModalPresentationStack } =
  uiSlice.actions;
