import React, { FC, useEffect, useRef } from "react";
import { CircleSpinner } from "react-spinners-kit";

// @ts-expect-error
import { ReactComponent as SendSvg } from "dive/assets/send.svg";
// @ts-expect-error
import { ReactComponent as VerifyBadgeSvg } from "../../assets/verified.svg";
// @ts-expect-error
import { ReactComponent as ShakaBadgeSvg } from "../../assets/shaka.svg";
import Squircle from "../Squircle";
import {
  getIsUserActiveStatus,
  getUserLastActiveStatusText,
  getUserTags,
} from "../../util/user.util";
import { useSocialLinks } from "../../hook/useSocialLinks";
import { formatNumber } from "../../util/number.util";
import { ElementLayout } from "../../type/shared.type";
import { logDebug } from "../../util/log.util";

import {
  ProfileCardActivityBlock,
  ProfileCardBadge,
  ProfileCardBadgeContent,
  ProfileCardBlock,
  ProfileCardDataBlock,
  ProfileCardDataBlockDescription,
  ProfileCardDataBlockHeader,
  ProfileCardDataBlockHeaderInfo,
  ProfileCardDataBlockName,
  ProfileCardDataBlockSocialLink,
  ProfileCardDataBlockSocialList,
  ProfileCardDataBlockStatus,
  ProfileCardDataBlockStatusIndicator,
  ProfileCardDataBlockTagElement,
  ProfileCardDataBlockTagList,
  ProfileCardScore,
  ProfileCardScoreElement,
  ProfileCardScoreElementTitle,
  ProfileCardSendDm,
  ProfileCardSendDmText,
} from "./ProfileCard.style";

export type ProfileCardProps = {
  isLoading?: boolean;
  isSendDmVisible: boolean;
  isSendDmLoading: boolean;
  layout: {
    widthPx?: number;
    heightPx?: number;
  };
  data: {
    userId: string;
    avatarUrl?: string;
    name: string;
    bio?: string;
    school?: string;
    year?: string;
    letters?: string;
    createdAt?: number;
    lastActiveDate: number | boolean;
    reactCount: number;
    eventCount: number;
  };
  onLayoutReady: (layout: ElementLayout) => void;
  onSendDm: () => void;
};

export const ProfileCard: FC<ProfileCardProps> = ({
  isLoading,
  isSendDmLoading,
  isSendDmVisible,
  layout: { widthPx, heightPx },
  data: {
    userId,
    name,
    bio,
    school,
    year,
    letters,
    createdAt,
    lastActiveDate,
    reactCount,
    eventCount,
  },
  onLayoutReady,
  onSendDm,
}) => {
  const contentContainerRef = useRef<HTMLDivElement>(null);

  const isActive = getIsUserActiveStatus(lastActiveDate);
  const lastSeenStatusText = getUserLastActiveStatusText(lastActiveDate);

  const { socialLinks, numSocialMedias } = useSocialLinks(userId);

  const tagList = getUserTags({
    createdAt,
    school,
    year,
  });

  const hasBadges = !!Object.keys(letters || {}).length;

  useEffect(() => {
    const timerId = setTimeout(() => {
      const contentContainerLayout =
        contentContainerRef.current?.getBoundingClientRect();

      if (!contentContainerLayout) {
        logDebug(`[ProfileCard] Cannot get content container layout`);

        return;
      }

      onLayoutReady({
        widthPx: contentContainerLayout.width,
        heighPx: contentContainerLayout.height,
        xPosPx: contentContainerLayout.x,
        yPosPx: contentContainerLayout.y,
      });
    }, 0);

    return () => {
      clearTimeout(timerId);
    };
  }, []);

  return (
    <ProfileCardBlock
      ref={contentContainerRef}
      {...(widthPx ? { width: `${widthPx}px` } : {})}
      {...(heightPx ? { height: `${heightPx}px` } : {})}
    >
      <ProfileCardDataBlockHeader>
        <Squircle userUid={userId} width={68} />
        <ProfileCardDataBlockName>{name}</ProfileCardDataBlockName>
        <ProfileCardDataBlockStatus isActive={isActive}>
          <ProfileCardDataBlockStatusIndicator isActive={isActive} />
          {lastSeenStatusText}
        </ProfileCardDataBlockStatus>
        {bio ? (
          <ProfileCardDataBlockDescription>
            {bio}
          </ProfileCardDataBlockDescription>
        ) : null}
      </ProfileCardDataBlockHeader>
      <ProfileCardScore>
        <ProfileCardScoreElement>
          <ProfileCardScoreElementTitle>
            {formatNumber(reactCount)}
          </ProfileCardScoreElementTitle>
          reacts
        </ProfileCardScoreElement>
        <ProfileCardScoreElement>
          <ProfileCardScoreElementTitle>
            {formatNumber(eventCount)}
          </ProfileCardScoreElementTitle>
          events
        </ProfileCardScoreElement>
      </ProfileCardScore>
      {tagList.length || numSocialMedias === 1 || hasBadges ? (
        <ProfileCardDataBlockTagList>
          {hasBadges
            ? Object.keys(letters || {}).map((label, index) => {
                return (
                  <ProfileCardBadge key={label}>
                    <ProfileCardBadgeContent>
                      <span>
                        {label === "Dive" ? <ShakaBadgeSvg /> : label}
                      </span>
                      <VerifyBadgeSvg />
                    </ProfileCardBadgeContent>
                  </ProfileCardBadge>
                );
              })
            : null}
          {numSocialMedias === 1 ? (
            <ProfileCardDataBlockSocialLink
              key="single-profile-link"
              target="_blank"
              href={socialLinks[0].href}
              bgColor={socialLinks[0].color}
              isTag={true}
              isLast={false}
            >
              {socialLinks[0].icon}
              <span>{socialLinks[0].title}</span>
            </ProfileCardDataBlockSocialLink>
          ) : null}
          {tagList.map(({ icon, name }, index) => {
            return (
              <ProfileCardDataBlockTagElement key={index}>
                {icon} <span>{name}</span>
              </ProfileCardDataBlockTagElement>
            );
          })}
        </ProfileCardDataBlockTagList>
      ) : null}
      {numSocialMedias > 1 ? (
        <ProfileCardDataBlockSocialList
          justifyContent={numSocialMedias < 4 ? "space-between" : undefined}
        >
          {socialLinks.map((link, index) => {
            return (
              <ProfileCardDataBlockSocialLink
                key={link.title}
                flex={numSocialMedias < 4 ? 1 : undefined}
                target="_blank"
                href={link.href}
                bgColor={link.color}
                isLast={index === socialLinks.length - 1}
              >
                {link.icon}
              </ProfileCardDataBlockSocialLink>
            );
          })}
        </ProfileCardDataBlockSocialList>
      ) : null}
      {isSendDmVisible ? (
        <ProfileCardSendDm
          disabled={isSendDmLoading}
          $isLoading={isSendDmLoading}
          onClick={onSendDm}
        >
          {isSendDmLoading ? (
            <CircleSpinner size={16} color="#FFF" />
          ) : (
            <>
              <SendSvg width="18" height="18" fill="white" />
              <ProfileCardSendDmText>Send DM</ProfileCardSendDmText>
            </>
          )}
        </ProfileCardSendDm>
      ) : null}
    </ProfileCardBlock>
  );
};
