import { createLogger } from "redux-logger";
import { Middleware, StoreEnhancer, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import persistStorage from "redux-persist/lib/storage";
import autoMergeLevel2 from "redux-persist/es/stateReconciler/autoMergeLevel2";
import { batchedSubscribe } from "redux-batched-subscribe";
import debounce from "lodash/debounce";
import cloneDeep from "lodash/cloneDeep";

import { DataInitialState, dataReducer, dataReducerName } from "./data";
import {
  ApplicationInitialState,
  applicationReducer,
  applicationReducerName,
} from "./application";
import { uiReducer, uiReducerName } from "./ui";
import { formReducer, formReducerName } from "./form";
import { getReduxEnhancer } from "dive/util/crashlytic.util";

const persistedApplicationReducer = persistReducer(
  {
    key: "dive-application",
    storage: persistStorage,
    stateReconciler: (
      inboundState: ApplicationInitialState,
      originalState: ApplicationInitialState,
      reducedState: ApplicationInitialState,
      config
    ) => {
      const restoredState = autoMergeLevel2(
        inboundState,
        originalState,
        reducedState,
        config
      );

      const restoredStateCopy = cloneDeep(restoredState);

      // Manually reset "isVisible" value so the alert isn't presented
      //   until new version is downloaded.
      // TODO: Find a better solution to blacklist level2 fields
      if (restoredStateCopy && restoredStateCopy.updateAlert) {
        restoredStateCopy.updateAlert.isVisible = false;
      }

      // TODO: Find a better solution to blacklist level2 fields
      if (restoredStateCopy && restoredStateCopy.desktopAppPromo) {
        restoredStateCopy.desktopAppPromo.isVisible = true;
      }

      return restoredStateCopy;
    },
  },
  applicationReducer
);

const persistedDataReducer = persistReducer(
  {
    key: "dive-data",
    storage: persistStorage,
    blacklist: [
      "messages",
      "comments",
      "drafts",
      "selectedEvent",
      "selectedGroup",
      "selectedChannel",
      "selectedGroupSidebar",
      "selectedSidebar",
      "noMoreMessages",
      "isNestedSidebarOpen",
      "isEventsSidebarOpen",
      "isCommenting",
      "listenerMap",
      "lastActiveMap",
    ],
    stateReconciler: (
      inboundState: DataInitialState,
      originalState: DataInitialState,
      reducedState: DataInitialState,
      config
    ) => {
      const restoredState = autoMergeLevel2(
        inboundState,
        originalState,
        reducedState,
        config
      );

      const appState = Object.keys(restoredState || {}).reduce(
        (acc, stateKey) => {
          // In case, the persisted state contains fields that were blacklisted, no need
          //   to set them. Instead, replace the values with initials for such fields.
          return {
            ...acc,
            [stateKey]: config.blacklist?.includes(stateKey)
              ? // @ts-expect-error
                originalState[stateKey]
              : // @ts-expect-error
                restoredState[stateKey],
          };
        },
        {} as DataInitialState
      );

      return appState;
    },
  },
  dataReducer
);

export const store = configureStore({
  reducer: {
    [applicationReducerName]: persistedApplicationReducer,
    [dataReducerName]: persistedDataReducer,
    [uiReducerName]: uiReducer,
    [formReducerName]: formReducer,
  },
  middleware: (getDefaultMiddleware) => {
    const customMiddlewareList: Middleware[] = [];

    if (process.env.NODE_ENV === "development") {
      const logger = createLogger({});

      // customMiddlewareList.push(logger);
    }

    return getDefaultMiddleware().concat(...customMiddlewareList);
  },
  enhancers: (defaultEnhancers) => {
    const debounceNotify = debounce((notify) => notify(), 50, {
      maxWait: 120,
    });

    const crashlyticEnhancer = getReduxEnhancer();

    return [
      ...defaultEnhancers,
      crashlyticEnhancer,
      batchedSubscribe(debounceNotify),
    ] as StoreEnhancer<{}, {}>[];
  },
});

export const storePersistor = persistStore(store);
