import { FC, PropsWithChildren, useEffect } from "react";
import OneSignal from "react-onesignal";
import { getAuth } from "firebase/auth";

import fb from "../firebase/firebaseCalls";
import { Debug } from "../util/debug";

type OneSignalProviderProps = PropsWithChildren<{}>;

export const OneSignalProvider: FC<OneSignalProviderProps> = ({ children }) => {
  const enableOneSignal = async (myUid: string) => {
    const hashValue = await fb.getOnesignalHash();

    OneSignal.setExternalUserId(myUid, hashValue);
  };

  useEffect(() => {
    const bootOneSignal = async () => {
      if (!process.env.REACT_APP_ONESIGNAL_APPID) {
        Debug("[OneSignalProvider] Missing OneSignal appId credentials");

        return;
      }

      await OneSignal.init({
        appId: process.env.REACT_APP_ONESIGNAL_APPID,
        allowLocalhostAsSecureOrigin: true,
        promptOptions: {
          actionMessage:
            "Don't miss a message or event - turn on notifications!",
        },
        welcomeNotification: {
          disable: true,
        },
        persistNotification: false,
        notificationClickHandlerMatch: "origin",
        notificationClickHandlerAction: "focus",
        serviceWorkerParam: {
          scope: "/onesignal/push",
        },
      });

      Debug("[OneSignalProvider] OneSignal is initialized");

      const isEnabled = await OneSignal.isPushNotificationsEnabled();
      if (isEnabled) {
        return;
      }

      /* get user uid*/
      const auth = getAuth();
      const uid = auth.currentUser?.uid || "";

      // If force: false, the call will wait 3 days before trying again.
      Debug("[OneSignalProvider] OneSignal will prompt for user permission");
      await OneSignal.showSlidedownPrompt({ force: true });

      OneSignal.on("subscriptionChange", function (isSubscribed) {
        if (isSubscribed) {
          enableOneSignal(uid);
        }
        OneSignal.getUserId().then((userId) => {
          Debug("[OneSignalProvider] OneSignal userId:", userId);
        });
      });
    };

    const isBrowser = !navigator?.userAgent
      ?.toLowerCase()
      ?.includes("electron");

    if (isBrowser) {
      bootOneSignal();
    }
  }, []);

  return children;
};
