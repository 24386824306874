import React, { FC, useCallback, useEffect, useState } from "react";

// @ts-expect-error
import { ReactComponent as PenSvg } from "dive/assets/new-group/pen.svg";
import { useModal } from "dive/hook/useModal.hook";
import analytics from "dive/util/analytics";
import { useInitialPayload } from "dive/hook/useInitialPayload.hook";
import { useDispatch, useSelector } from "dive/store";
import { CUSTOM_GROUP_MODAL_ID, popModalId, pushModalId } from "dive/store/ui";
import { newColorPalette } from "dive/constants/Theme";
import { logDebug } from "dive/util/log.util";
import { useCreateTemplateDive } from "dive/hook/useCreateTemplateDive.hook";
import {
  dataGroupByIdSelector,
  selectGroup,
  selectGroupSidebar,
  selectSidebar,
  setIsNestedSidebarOpen,
  setSelectedChannel,
} from "dive/store/data";
import { sleep } from "dive/util/sleep.util";

import { NewGroupModal } from "./NewGroupModal";
import { GroupTemplate } from "./NewGroupModal.type";

type NewGroupModalContainerProps = {};

export const NewGroupModalContainer: FC<NewGroupModalContainerProps> = ({}) => {
  const dispatch = useDispatch();

  const { hideModal } = useModal();

  const [loadingIndex, setLoadingIndex] = useState<number | null>(null);
  const [navigationPayload, setNavigationPayload] = useState<{
    groupId: string;
    mainChannelId: string;
  } | null>(null);

  const { hasChanged } = useInitialPayload({
    loadingIndex,
  });

  const { createDiveTemplate } = useCreateTemplateDive();

  const newGroupData = useSelector((st) =>
    // @ts-expect-error
    dataGroupByIdSelector(st, navigationPayload?.groupId)
  );

  // Wait for newly created group data to appear in Redux store
  useEffect(
    () => {
      if (
        !newGroupData ||
        !navigationPayload ||
        !navigationPayload.groupId ||
        !navigationPayload.mainChannelId
      ) {
        return;
      }

      dispatch(selectGroup(navigationPayload.groupId));
      dispatch(setSelectedChannel(navigationPayload.mainChannelId));
      dispatch(selectGroupSidebar(navigationPayload.groupId));
      dispatch(selectSidebar(navigationPayload.mainChannelId));

      dispatch(setIsNestedSidebarOpen(true));

      hideModal();

      // No need to reseat loading state, as at this point the modal
      //   should be closed.
      // setLoadingIndex(null);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [newGroupData]
  );

  const templateList: GroupTemplate[] = [
    // @ts-expect-error
    { icon: <PenSvg />, name: "Custom" },
    {
      icon: (
        <img
          src={require("dive/assets/new-group/soccer-team.png")}
          alt="Soccer Team"
        />
      ),
      name: "Soccer Team",
      emoji: "⚽",
      color: newColorPalette.green,
    },
    {
      icon: (
        <img
          src={require("dive/assets/new-group/friend-group.png")}
          alt="Friend Group"
        />
      ),
      name: "Friend Group",
      emoji: "😄",
      color: newColorPalette.cyan,
    },
    {
      icon: (
        <img
          src={require("dive/assets/new-group/youth-group.png")}
          alt="Youth Group"
        />
      ),
      name: "Youth Group",
      emoji: "🤲",
      color: newColorPalette.purple,
    },
    {
      icon: (
        <img
          src={require("dive/assets/new-group/student-org.png")}
          alt="Student Org"
        />
      ),
      name: "Student Org",
      emoji: "🤝",
      color: newColorPalette.green,
    },
    {
      icon: (
        <img
          src={require("dive/assets/new-group/running-club.png")}
          alt="Running Club"
        />
      ),
      name: "Running Club",
      emoji: "🏃",
      color: newColorPalette.yellow,
    },
    {
      icon: (
        <img
          src={require("dive/assets/new-group/sorority.png")}
          alt="Sorority"
        />
      ),
      name: "Sorority",
      emoji: "💖",
      color: newColorPalette.pink,
    },
    {
      icon: (
        <img
          src={require("dive/assets/new-group/work-friends.png")}
          alt="Work Friends"
        />
      ),
      name: "Work Friends",
      emoji: "💼",
      color: newColorPalette.orange,
    },
    {
      icon: (
        <img src={require("dive/assets/new-group/family.png")} alt="Family" />
      ),
      name: "Family",
      emoji: "👨‍👩‍👧‍👦",
      color: newColorPalette.mint,
    },
    {
      icon: (
        <img
          src={require("dive/assets/new-group/roommates.png")}
          alt="Roommates"
        />
      ),
      name: "Roommates",
      emoji: "👯",
      color: newColorPalette.brown,
    },
    {
      icon: (
        <img
          src={require("dive/assets/new-group/fraternity.png")}
          alt="Fraternity"
        />
      ),
      name: "Fraternity",
      emoji: "💪",
      color: newColorPalette.red,
    },
    {
      icon: <img src={require("dive/assets/new-group/dorm.png")} alt="Dorm" />,
      name: "Dorm",
      emoji: "🏢",
      color: newColorPalette.pink,
    },
  ];

  const onTemplateClick = useCallback(
    (templateIndex: number) => {
      if (templateIndex === 0) {
        dispatch(popModalId());
        dispatch(pushModalId(CUSTOM_GROUP_MODAL_ID));

        return;
      }

      if (!templateList[templateIndex]) {
        logDebug(
          `[NewGroupModalContainer][onTemplateClick] Cannot find element`,
          { templateIndex }
        );

        return;
      }

      const { name, color, emoji } = templateList[templateIndex];

      const groupTemplate = {
        name,
        color,
        emoji,
        channels: [
          {
            name: "Main Subchat",
            emoji: "👋",
            isPublic: true,
            isDefault: true,
            isAdminPost: false,
          },
          {
            name: "Random Discussion",
            emoji: "🎉",
            isPublic: true,
            isDefault: true,
            isAdminPost: false,
          },
        ],
        events: [],
      };

      setLoadingIndex(templateIndex);

      createDiveTemplate(groupTemplate)
        .then((groupData) => {
          logDebug(
            `[NewGroupModalContainer][onTemplateClick] New group created`,
            groupData
          );

          setNavigationPayload({
            groupId: groupData.uid,
            mainChannelId: groupData.main,
          });
        })
        .catch((ex) => {
          logDebug(
            `[NewGroupModalContainer][onTemplateClick] Cannot create new group`,
            ex
          );

          setLoadingIndex(null);
          setNavigationPayload(null);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const onClose = useCallback(() => {
    hideModal();

    // analytics.track("web_abort_create_event");
  }, [hideModal]);

  return (
    <NewGroupModal
      hasChanged={hasChanged}
      loadingIndex={loadingIndex}
      templateList={templateList}
      onTemplateClick={onTemplateClick}
      onClose={onClose}
    />
  );
};
