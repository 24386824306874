import { DataSnapshot, getDatabase, onValue, ref } from "firebase/database";

export function listenForTypingInChannel(
  channelUid: string,
  cb: (snapshot: DataSnapshot) => void
) {
  const typingIndicatorRef = ref(
    getDatabase(),
    `typingIndicator/${channelUid}/`
  );
  onValue(typingIndicatorRef, (snapshot) => {
    cb(snapshot);
  });
  return typingIndicatorRef;
}
