import { CSSProperties } from "react";
import styled from "styled-components";

type GroupTileBlockProps = {
  bgColor: CSSProperties["background"];
};

export const GroupTileBlock = styled.div<GroupTileBlockProps>`
  margin: 0 10px 10px 10px;
  width: 280px;
  height: 130px;
  border-radius: 25px;
  background: ${({ bgColor }) => bgColor};
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
`;

export const GroupTileBlockBgOverlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background: linear-gradient(
    140deg,
    rgba(0, 0, 0, 0.2),
    rgba(255, 255, 255, 0.1) 65%
  );
  border-radius: 25px;
  overflow: hidden;
`;

export const GroupTileInfo = styled.div`
  flex: 1;
  margin: 0 10px;
  color: #ffffff;
  text-align: center;
  z-index: 2;

  h4 {
    font-size: 16px;
    line-height: 18px;
    font-weight: 500;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  span {
    display: block;
    font-size: 12px;
    margin: 10px 0 0 0;
    color: rgba(255, 255, 255, 0.8);
  }
`;

type GroupTileInfoButtonProps = {
  isLoading: boolean;
};

export const GroupTileInfoButton = styled.button<GroupTileInfoButtonProps>`
  display: flex;
  color: #ffffff;
  font-size: 12px;
  font-weight: 500;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  border-width: 0;
  background: transparent;
  margin: 8px auto 0 auto;
  height: 20px;

  ${({ isLoading }) =>
    isLoading
      ? `opacity: 0.8;`
      : `
      &:hover {
        cursor: pointer;
      }
      `}

  div {
    margin: 2px 0 0 6px;
  }

  svg {
    width: 16px;
    height: 16px;
    margin: 2px 0 0 6px;

    path {
      fill: white;
    }
  }
`;

export const GroupTileIconContainer = styled.div`
  width: 80px;
  height: 80px;
  position: relative;
  margin: -10px 12px 0 0;
  z-index: 3;
`;

type GroupTileIconImageProps = {
  rotateDeg: number;
  translateXPx: number;
  translateYPx: number;
  bgColor: CSSProperties["background"];
};

export const GroupTileIconBlock = styled.div<GroupTileIconImageProps>`
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 14px;
  overflow: hidden;
  background: ${({ bgColor }) => bgColor};
  transform: rotate(${({ rotateDeg }) => `${rotateDeg}deg`})
    translate(
      ${({ translateXPx, translateYPx }) =>
        `${translateXPx}px, ${translateYPx}px`}
    );
`;

type GroupTileIconBlockBgOverlayProps = {
  opacity: CSSProperties["opacity"];
};

export const GroupTileIconBlockBgOverlay = styled.div<GroupTileIconBlockBgOverlayProps>`
  width: 100%;
  height: 100%;
  position: absolute;
  background: white;
  opacity: ${({ opacity }) => opacity};
  border-radius: 14px;
`;
