import React, { FC, MouseEvent, useCallback, useRef } from "react";
import { CircleSpinner } from "react-spinners-kit";

import { ColorId } from "dive/type/theme.type";
import { paletteGlassFills } from "dive/constants/palette.constant";
// @ts-expect-error
import { ReactComponent as XSvg } from "dive/assets/icons/xWhite.svg";

import {
  NewEventModalBlock,
  NewEventModalButton,
  NewEventModalButtonContainer,
  NewEventModalCard,
  NewEventModalCardContent,
  NewEventModalClose,
  NewEventModalIconBlock,
} from "./NewEventModal.style";
import { NewEventModalInfo } from "./NewEventModalInfo";
import { NewEventModalPoster } from "./NewEventModalPoster";

type NewEventModalProps = {
  hasChanged: boolean;
  isLoading: boolean;
  isFormValid: boolean;
  colorId: ColorId;
  onClose: () => void;
  onSubmit: () => void;
};

export const NewEventModal: FC<NewEventModalProps> = ({
  hasChanged,
  isLoading,
  isFormValid,
  colorId,
  onClose,
  onSubmit,
}) => {
  const selectedColor = paletteGlassFills.find(({ id }) => id === colorId);

  const contentBlockRef = useRef<HTMLDivElement>(null);

  const onBlockClick = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      if (
        !hasChanged &&
        // @ts-expect-error
        !contentBlockRef.current?.contains(event?.target)
      ) {
        onClose();
      }
    },
    [hasChanged, onClose]
  );

  return (
    <NewEventModalBlock
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ type: "spring", duration: 0.4, bounce: 0.2 }}
      onClick={onBlockClick}
    >
      <NewEventModalCard
        ref={contentBlockRef}
        $background={selectedColor?.cssBackgroundValue}
      >
        <NewEventModalClose onClick={onClose}>
          <NewEventModalIconBlock>
            <XSvg width="44" height="44" />
          </NewEventModalIconBlock>
        </NewEventModalClose>
        <NewEventModalCardContent>
          <NewEventModalPoster />
          <NewEventModalInfo />
          <NewEventModalButtonContainer>
            <NewEventModalButton
              $isDisabled={!isFormValid || isLoading}
              disabled={!isFormValid || isLoading}
              onClick={onSubmit}
            >
              {isLoading ? (
                <CircleSpinner size={16} color="#FFF" />
              ) : (
                "Create Event!"
              )}
            </NewEventModalButton>
          </NewEventModalButtonContainer>
        </NewEventModalCardContent>
      </NewEventModalCard>
    </NewEventModalBlock>
  );
};
