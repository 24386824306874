import {
  NEW_DM_MODAL_ID,
  CUSTOM_GROUP_MODAL_ID,
  NEW_EVENT_MODAL_ID,
  NEW_GROUP_MODAL_ID,
  NEW_SUBCHAT_MODAL_ID,
} from "./ui.constant";

export const modalIdList = [
  NEW_DM_MODAL_ID,
  CUSTOM_GROUP_MODAL_ID,
  NEW_EVENT_MODAL_ID,
  NEW_GROUP_MODAL_ID,
  NEW_SUBCHAT_MODAL_ID,
] as const;

export type UiModalId = (typeof modalIdList)[number];

export type UiInitialState = {
  modalPresentationStack: UiModalId[];
};
