import React, { useEffect, memo, useState, useRef } from "react";
import styled from "styled-components";
import { useSelector, useDispatch, shallowEqual } from "react-redux";

import { ReactComponent as AddEmojiInlineSvg } from "../../assets/addReaction.svg";
import { ReactComponent as EmojiPickerSvg } from "../../assets/emojiPicker.svg";

import { ReactComponent as AddCommentSvg } from "../../assets/icons/addComment.svg";
import { ReactComponent as AddReactionSvg } from "../../assets/icons/addReaction.svg";

import { ReactComponent as DiveReactionSvg } from "../../assets/diveReaction.svg";
import { ReactComponent as xIconSvg } from "../../assets/icons/xIcon.svg";
import { ReactComponent as DownloadIconSvg } from "../../assets/icons/downArrow.svg";

import { ReactComponent as BottomCommentLineSvg } from "../../assets/commentLine/bottom.svg";

import Squircle from "../Squircle";
import Poll from "../Poll";
import EventTile from "../EventTile";
import { createSelector } from "@reduxjs/toolkit";
import {
  isPreviewMessage,
  formatTimestampChatInterruption,
  replaceSpecialEmoji,
  getPreviewText,
} from "../../util";

import {
  addCommentListener,
  dataMyselfSpamReactViewSelector,
} from "../../store/data";
import { EmojiPicker as Picker } from "../EmojiPicker";
import { setIsCommenting } from "../../store/data";

import { motion, AnimatePresence, useAnimation } from "framer-motion";
import EmojiExploder from "../EmojiExploder";
import fb from "../../firebase/firebaseCalls.js";
import { CalloutRegular, FootnoteRegular } from "../../constants/DesignSystem";
import { colorPalette } from "../../constants/Theme";
import { getGroupColor } from "../../constants/groupColors";
import { logDebug } from "../../util/log.util.ts";

import { saveAs } from "file-saver";

import { getUserBrowser } from "../../util/browser.util";
import EmojiPopover from "../EmojiPopover";
import { ChatMessageLinkPreview } from "../ChatMessageLinkPreview";
import { AiEventSuggestionContainer } from "../AiEventSuggestion/index.ts";
import { ChatMessageNewChatContainer } from "../ChatMessageNewChat";
import { EventActivityUpdateMessageContainer } from "../EventActivityUpdateMessage";
import { useAttachProfileCard } from "../../hook/useAttachProfileCard";

import { useAiEventSuggestionStatus } from "./useAiEventSuggestionStatus";
import { ChatMessageGroupTileContainer } from "./ChatMessageGroupTile";
import { ChatMessageIndicator } from "./ChatMessageIndicator";
import {
  CONCISE_REACT_VIEW,
  VERBOSE_REACT_VIEW,
} from "dive/constants/user.constant";

//auto handle the emoji data -> reaction pill
//flag to render as a comment
//other types of media in addition to just text

const ChatMessage = ({ showAnimatedCounter, channelEmoji, ...props }) => {
  const dispatch = useDispatch();
  const controls = useAnimation();

  const { isDismissed, dismissAiEventSuggestion } = useAiEventSuggestionStatus(
    props?.uid
  );

  const [emojiPickerIsOpen, setEmojiPickerIsOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const [showExpandedImage, setShowExpandedImage] = useState(false);

  const [showDownloadIcon, setShowDownloadIcon] = useState(false);

  const selectMessage = (state) =>
    !props?.isComment
      ? state?.data?.messages?.[state?.data?.selectedChannel]?.[props?.uid]
      : state?.data?.comments?.[props?.uid];

  const message = useSelector(selectMessage);
  const spamReactView = useSelector(dataMyselfSpamReactViewSelector);

  const emojiPickerRef = useRef(null);
  const newEmojiRef = useRef(null);
  const hoverEmojiButtonRef = useRef(null);
  const blinkingTimeout = useRef(null);
  const expandedImageRef = useRef(null);
  const blockCommentRef = useRef(false);
  const hoverInlineButtonRef = useRef(false);
  const prevEmojiState = useRef(null);
  const emojiExploderRef = useRef();

  const {
    targetElementRef,
    onClick: onProfileClick,
    onMouseEnter: onProfileMouseEnter,
    onMouseLeave: onProfileMouseLeave,
  } = useAttachProfileCard(message?.user?.uid);

  const emojiPillRefs = useRef({});

  const [showRdm, setShowRdm] = useState(false);
  const rdmEmoji = useRef(null);
  // const [avatarHidden, setAvatarHidden] = React.useState(true);

  //array that stores all the reaction particles
  const [reactionParticles, setReactionParticles] = useState([]);
  const [pillShakingAnimation, setPillShakingAnimation] = useState({});

  const myself = useSelector((state) => state?.data?.myself);

  const clickReactionPillFactory = (emoji) => (e) => {
    const count = (message?.reacts?.[myself?.uid]?.[emoji]?.count ?? 0) + 1;

    const reacts = message?.reacts || {};

    const totalRectCount = Object.keys(reacts).reduce((acc, userId) => {
      const totalEmojiCountByUser = Object.keys(reacts[userId]).reduce(
        (acc, emoji) => {
          return acc + (reacts[userId][emoji].count || 0);
        },
        0
      );

      return acc + totalEmojiCountByUser;
    }, 0);

    showAnimatedCounter(totalRectCount);

    if (count > 69) {
      if (!pillShakingAnimation[emoji]) {
        setPillShakingAnimation({ ...pillShakingAnimation, [emoji]: true });
        setTimeout(() => {
          setPillShakingAnimation({ ...pillShakingAnimation, [emoji]: false });
        }, 300);
      }
    } else {
      fb.setEmojiCount(
        myself?.uid,
        props?.channelUid,
        props?.uid,
        emoji,
        count,
        props?.isComment,
        props?.parentMessageUid
      );
    }
  };

  const clearReactions = (emoji) => {
    fb.setEmojiCount(
      myself?.uid,
      props?.channelUid,
      props?.uid,
      emoji,
      0,
      props?.isComment,
      props?.parentMessageUid
    );
  };

  const onMessageBodyClick = (e) => {
    if (message?.isOptimistic) return;

    setTimeout(() => {
      const payload = {
        commentingOnComment: props?.isComment,
        messageUid: props?.uid,
        commentUid: props?.uid,
        channelUid: props?.channelUid,
      };
      if (blockCommentRef.current !== true) {
        dispatch(setIsCommenting(payload));
      }
    }, 100);
  };

  const onEmojiClick = (event, emoji) => {
    // const rect = event.target.getBoundingClientRect();
    let rect = { width: 0, height: 0 };
    /*
    if (newEmojiRef.current) {
      rect = newEmojiRef.current.getBoundingClientRect();
    } else {
      rect = hoverEmojiButtonRef.current.getBoundingClientRect();
    }
    */

    const e = {
      pageX: rect?.x + rect?.width,
      pageY: rect?.y + rect?.height,
    };

    clickReactionPillFactory(emoji?.isCustom ? emoji?.id : emoji?.emoji)(e);
  };

  useEffect(() => {
    const imageExpansionHandler = () => {
      const handleClickOutside = (event) => {
        if (
          expandedImageRef.current &&
          !expandedImageRef.current.contains(event.target)
        ) {
          setShowExpandedImage(false);
        }
      };
      document.addEventListener("click", handleClickOutside, true);
      return () => {
        document.removeEventListener("click", handleClickOutside, true);
      };
    };
    return imageExpansionHandler();
  }, []);

  const selectParentMessage = (state) =>
    props?.isComment
      ? state?.data?.messages?.[state?.data?.selectedChannel]?.[
          props?.parentMessageUid
        ]
      : {};

  const selectIsBlinking = createSelector(
    selectMessage,
    (message) =>
      typeof message?.isOptimistic !== "undefined" &&
      message?.failed !== 1 &&
      !message?.showNotSent
  );

  const isBlinking = useSelector(selectIsBlinking);

  useEffect(() => {
    if (isBlinking) {
      clearInterval(blinkingTimeout?.current);
      blinkingTimeout.current = setTimeout(() => {
        controls.start({
          opacity: [0.5, 1],
          transition: {
            repeat: Infinity,
            duration: 0.7,
            repeatType: "reverse",
          },
        });
      }, 5000);
    } else {
      controls.stop();
      controls.set({ opacity: 1 });
      clearInterval(blinkingTimeout?.current);
    }
  }, [isBlinking]);

  const selectPreviousMessage = (state) =>
    !props?.isComment
      ? state?.data?.messages?.[state?.data?.selectedChannel]?.[
          props.previousUid
        ]
      : state?.data?.comments?.[props.previousUid];

  const previousMessage = useSelector(selectPreviousMessage);

  const previousMessageHasEmojis = useSelector((state) =>
    !props?.isComment
      ? state?.data?.messages?.[state?.data?.selectedChannel]?.[
          props.previousUid
        ]?.reacts
      : state?.data?.comments?.[props.previousUid]?.reacts
  );

  const selectUsers = (state) => state?.data?.users;

  const selectSenderName = createSelector(
    selectMessage,
    selectUsers,
    (message, users) => {
      if (!message) return "";
      return users?.[message?.user?.uid]?.name ?? "";
    }
  );
  const senderName = useSelector(selectSenderName);

  const themeColor = useSelector((state) =>
    getGroupColor(state?.data?.groups?.[state?.data?.selectedGroup])
  );
  const groupColor =
    props?.channelUid.length > 30 ? colorPalette.blue : themeColor;

  // const myReactions = useSelector(
  //   (state) =>
  //     state?.data?.messages?.[props?.uid]?.reacts?.[state?.data?.myself?.uid] ??
  //     []
  // );

  const selectMyselfUid = (state) => state?.data?.myself?.uid;

  const selectMyReactions = createSelector(
    selectMessage,
    selectMyselfUid,
    (message, myUid) => {
      if (!message) return [];
      return message?.reacts?.[myUid] ?? {};
    }
  );

  const myReactions = useSelector(selectMyReactions);

  const selectFormattedReactions = createSelector(selectMessage, (message) => {
    //aggregate and format reactions for display
    const reacts = message?.reacts ?? {};
    const reactionKeyedObj = {};
    const reactionTimestamps = {};
    Object.values(reacts).forEach((emojisObject) => {
      Object.entries(emojisObject).forEach(([emoji, { time, count }]) => {
        if (
          !reactionTimestamps[emoji] ||
          (reactionTimestamps[emoji] && time < reactionTimestamps[emoji])
        ) {
          reactionTimestamps[emoji] = time;
        }
        reactionKeyedObj[emoji] = (reactionKeyedObj[emoji] || 0) + count;
      });
    });
    // setNumReacts(Object.keys(reactionKeyedObj).length);
    const sortedReacts = Object.entries(reactionKeyedObj).sort(
      ([e1], [e2]) => reactionTimestamps[e1] - reactionTimestamps[e2]
    );
    return sortedReacts;
  });

  const selectEmojiCountPerPerson = createSelector(selectMessage, (message) => {
    const reacts = message?.reacts ?? {};
    const emojiCountPerPerson = {};
    const userUids = Object.keys(reacts);
    userUids.forEach((userUid) => {
      const emojiDataDict = reacts[userUid];
      const emojis = Object.keys(emojiDataDict);
      emojis.forEach((emoji) => {
        if (!emojiCountPerPerson[emoji]) {
          emojiCountPerPerson[emoji] = {};
        }
        emojiCountPerPerson[emoji][userUid] = {
          ...emojiDataDict[emoji],
          userUid,
        };
      });
    });
    for (const emoji in emojiCountPerPerson) {
      emojiCountPerPerson[emoji] = Object.values(
        emojiCountPerPerson[emoji]
      ).sort((a, b) => a.time - b.time);
    }
    return emojiCountPerPerson;
  });

  const emojiCountPerPerson = useSelector(
    selectEmojiCountPerPerson,
    shallowEqual
  );

  const formattedReactions = useSelector(
    selectFormattedReactions,
    shallowEqual
  );

  useEffect(() => {
    if (!prevEmojiState.current) {
      if (formattedReactions.length > 0) {
        prevEmojiState.current = formattedReactions;
        // const pos =
        //   emojiPillRefs.current[
        //     formattedReactions[0][0]
        //   ]?.getBoundingClientRect();
        // emojiExploderRef.current.explode(
        //   formattedReactions[0][0],
        //   pos.left,
        //   pos.top,
        //   20
        // );
      }
      return;
    }
    if (formattedReactions.length === prevEmojiState.current.length + 1) {
      const emoji = formattedReactions[formattedReactions.length - 1][0];
      const pos = emojiPillRefs.current[emoji]?.getBoundingClientRect();
      emojiExploderRef.current.explode(emoji, pos.left, pos.top, 10);
      prevEmojiState.current = formattedReactions;
    }
    for (let i = 0; i < formattedReactions.length; i++) {
      if (
        formattedReactions?.[i]?.[1] &&
        prevEmojiState.current?.[i]?.[1] &&
        formattedReactions[i]?.[1] === prevEmojiState.current[i]?.[1] + 1
      ) {
        const pos =
          emojiPillRefs.current[
            formattedReactions[i][0]
          ]?.getBoundingClientRect();
        emojiExploderRef.current.explode(
          formattedReactions[i][0],
          pos.left,
          pos.top,
          10
        );
        prevEmojiState.current = formattedReactions;
      }
    }
    prevEmojiState.current = formattedReactions;
  }, [formattedReactions]);

  const selectAvatarHidden = createSelector(
    selectMessage,
    selectPreviousMessage,
    (message, previousMessage) => {
      if (!message) return false;
      if (!previousMessage) return false;
      if (props?.isLastComment) return false;
      if (!isPreviewMessage(previousMessage)) return false;
      if (message?.commentMap) return false;
      if (previousMessage?.commentMap) return false;
      if (message?.user?.uid !== previousMessage?.user?.uid) return false;
      if (message?.createdAt - previousMessage?.createdAt > 1000 * 60 * 30)
        return false;

      return true;
    }
  );

  const selectRenderTimestampAbove = createSelector(
    selectMessage,
    selectPreviousMessage,
    (message, previousMessage) => {
      if (!message) return false;
      if (!previousMessage && !props?.isComment) return true;
      if (!isPreviewMessage(previousMessage) && !props?.isComment) return true;
      if (
        message?.createdAt - previousMessage?.createdAt > 1000 * 60 * 30 &&
        !props?.isComment
      )
        return true;
      return false;
    }
  );

  const renderTimestampAbove = useSelector(selectRenderTimestampAbove);

  const avatarHidden = useSelector(selectAvatarHidden);
  // const avatarHidden = false;

  useEffect(() => {
    prevEmojiState.current = formattedReactions;
    if (props?.isComment) {
      dispatch(
        addCommentListener({
          channelUid: props?.channelUid,
          commentUid: props?.uid,
          parentMessageUid: props?.parentMessageUid,
        })
      );
    }
  }, []);

  const selectIsMyMessage = createSelector(
    selectMessage,
    selectMyselfUid,
    (message, myselfUid) => {
      return message?.user?.uid === myselfUid;
    }
  );
  const isMyMessage = useSelector(selectIsMyMessage);

  const isAiEventSuggestion =
    message?.isAiEventSuggestion && message?.eventArguments;

  const isAutomatedMessage =
    message?.prepopulatedMessage || isAiEventSuggestion;

  const selectIsParentMessageMine = createSelector(
    selectParentMessage,
    selectMyselfUid,
    (parentMessage, myselfUid) => {
      return parentMessage?.user?.uid === myselfUid;
    }
  );
  const isParentMessageMine = useSelector(selectIsParentMessageMine);

  const selectRenderRight = createSelector(
    selectIsMyMessage,
    selectIsParentMessageMine,
    (isMyMessage, isParentMessageMine) => {
      if (isParentMessageMine) {
        return true;
      } else if (isMyMessage && !props?.isComment) {
        return true;
      }
      return false;
    }
  );

  const renderRight = useSelector(selectRenderRight);

  const selectIsHighlighted = (state) => {
    if (state?.data?.isCommenting === null) return null;
    const commentingObj = state?.data?.isCommenting;
    return commentingObj?.commentingOnComment
      ? state?.data?.comments?.[commentingObj?.commentUid]?.uid === props?.uid
      : state?.data?.messages?.[commentingObj?.channelUid]?.[
          commentingObj?.messageUid
        ]?.uid === props?.uid;
  };

  const isHighlighted = useSelector(selectIsHighlighted);

  const hoverStart = () => {
    // The comment button shouldn't be shown for messages that aren't sent yet
    if (message?.localMessage || message?.showNotSent) {
      return;
    }

    setIsHovered(true);
    setShowDownloadIcon(true);
  };

  const blockComment = () => {
    blockCommentRef.current = true;
    setTimeout(() => {
      blockCommentRef.current = false;
    }, 500);
  };

  const hoverEnd = () => {
    if (!emojiPickerIsOpen) {
      setIsHovered(false);
      setShowDownloadIcon(false);
      // setTimeout(() => {
      //   if (hoverInlineButtonRef.current === false) {
      //     setIsHovered(false);
      //     setShowDownloadIcon(false);
      //   }
      // }, 200);
      // setIsHovered(false);
    }
  };

  const downloadImage = () => {
    saveAs(message?.image, "image.jpg"); // Put your image url here.
  };

  const onMentionPress = (userName) => {
    // TODO: Show user profile
    logDebug("userName: ", userName);
  };

  function getFormattedMessage(text, isMyMessage) {
    var urlRegex =
      /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;

    const mentionRegexString = [...(props?.mentionedPeopleProfiles ?? [])]
      .sort((el1, el2) => el2.name?.length - el1.name?.length)
      .map((item) => `(\@${item.name})`)
      .join("|");

    const mentionRegex = new RegExp(mentionRegexString);

    return text
      .split(urlRegex)
      .map((part) => {
        if (part.match(urlRegex)) {
          return (
            <LinkInMessage
              isMyMessage={isMyMessage}
              href={part}
              onClick={blockComment}
              target="_blank"
            >
              {part}
            </LinkInMessage>
          );
        } else {
          if (mentionRegexString) {
            return part.split(mentionRegex).map((mentionPart) => {
              if (mentionPart?.match(mentionRegex)) {
                return (
                  <MentionMessage
                    color={isMyMessage ? "#FFF" : themeColor}
                    onClick={() => onMentionPress(mentionPart.slice(1))}
                  >
                    {mentionPart}
                  </MentionMessage>
                );
              } else {
                return mentionPart;
              }
            });
          } else {
            return part;
          }
        }
      })
      .flat();
  }

  const getMessageBodyJsx = () => {
    if (!message) return <>Null Message</>;
    if (message.deleted) {
      return (
        <ChatBubbleBody
          isMyMessage={isMyMessage}
          groupColor={groupColor}
          isComment={props?.isComment}
          isParentMessageMine={isParentMessageMine}
          renderRight={renderRight}
          isDeleted={true}
        >
          This message has been deleted.
        </ChatBubbleBody>
      );
    } else if (message.newNickname) {
      return (
        <ChatBubbleBody
          note
          isMyMessage={isMyMessage}
          groupColor={groupColor}
          isComment={props?.isComment}
          isParentMessageMine={isParentMessageMine}
          renderRight={renderRight}
          isDeleted={false}
        >
          {getPreviewText(message, senderName)}
        </ChatBubbleBody>
      );
    } else if (message.text) {
      const isLinkMessage =
        message.text?.trim() ===
        Object.values(message.linkPreviewList ?? {})?.[0]?.url;

      const chatBubbleContent = (
        <ChatBubbleBody
          isMyMessage={isMyMessage}
          groupColor={groupColor}
          isComment={props?.isComment}
          isParentMessageMine={isParentMessageMine}
          renderRight={renderRight}
          $isError={message?.showNotSent}
        >
          {getFormattedMessage(message?.text, isMyMessage)}
        </ChatBubbleBody>
      );

      const linkPreviewContent = message.linkPreviewList
        ? Object.values(message.linkPreviewList).map((linkPreview, index) => {
            return (
              <ChatMessageLinkPreview
                key={`${linkPreview.url}-${index}`}
                title={linkPreview.title || linkPreview.url}
                description={linkPreview.description}
                url={linkPreview.url}
                type={linkPreview.type}
                providerName={linkPreview.providerName}
                faviconUrl={linkPreview.faviconUrl}
                authorPictureUrl={linkPreview.authorPictureUrl}
                mediaText={linkPreview.mediaText}
                mediaType={linkPreview.mediaType}
                mediaUrl={linkPreview.mediaPoster || linkPreview.mediaUrl}
              />
            );
          })
        : null;

      if (isLinkMessage) {
        return linkPreviewContent;
      } else if (linkPreviewContent) {
        return (
          <LinkPreviewBodyContainer isMyMessage={isMyMessage}>
            {chatBubbleContent}
            {linkPreviewContent}
          </LinkPreviewBodyContainer>
        );
      }

      return chatBubbleContent;
    } else if (message.event) {
      return <EventTile isInChat eventUid={message.event} />;
    } else if (message.image) {
      return (
        <>
          <ChatImageBody
            src={message?.image}
            onClick={() => setShowExpandedImage(true)}
            $isError={message?.showNotSent}
          />
          {showDownloadIcon && (
            <DownloadIconContainer onClick={downloadImage}>
              <DownloadIcon />
            </DownloadIconContainer>
          )}
        </>
      );
    } else if (message.video) {
      return (
        <ChatVideoBody autoplay controls name="media">
          <source src={message.video}></source>
        </ChatVideoBody>
      );
    } else if (message.poll) {
      return <Poll pollUid={message?.poll}></Poll>;
    } else if (message.group) {
      return <ChatMessageGroupTileContainer groupId={message.group} />;
    } else if (message.isAiEventSuggestion) {
      if (!message.eventArguments || message.eventAlreadyCreated) {
        logDebug("[ChatMessage]: Skipping AI Event Suggestion rendering", {
          message,
        });

        return null;
      }

      return (
        <AiEventSuggestionContainer
          isDismissed={isDismissed}
          messageId={message.uid}
          title={message.eventArguments.title}
          emoji={message.eventArguments.emoji}
          date={message.eventArguments.date}
          time={message.eventArguments.time}
          bgImage={message.eventArguments.backgroundImageObject}
          onDismiss={dismissAiEventSuggestion}
        />
      );
    }
  };

  const hasReactions = formattedReactions.length !== 0;

  //   const sampleText = "yooo whats up everyone";
  const sampleText =
    "Yoooo!!! kickback happening at my place - same time and same place at last week. anyone around?";

  if (
    message?.isNewUserMessage ||
    (message?.isAiEventSuggestion && message?.eventAlreadyCreated) ||
    isDismissed
  ) {
    return <></>;
  }

  if (message?.isEventActivityUpdate) {
    return <EventActivityUpdateMessageContainer data={message?.data} />;
  }

  if (!message) {
    logDebug(`[ChatMessage] Message data doesn't exist`, props);

    // Doesn't show empty message in production
    if (process.env.NODE_ENV !== "development") {
      return null;
    }
  }

  const { dayToDisplay, timeToDisplay } = formatTimestampChatInterruption(
    message?.createdAt
  );

  return (
    <MarginTopAutoContainer>
      {showExpandedImage && (
        <>
          <OpacityOverlay />
          <ExpandedImageContainer>
            <ExpandedImage src={message?.image} ref={expandedImageRef} />
            <CloseExpandedImage filledColor={groupColor} />
          </ExpandedImageContainer>
        </>
      )}
      <EmojiExploder ref={emojiExploderRef} />
      {renderTimestampAbove &&
      message?.createdAt &&
      dayToDisplay &&
      timeToDisplay ? (
        <TimestampContainer>
          <span>{dayToDisplay}</span>
          {timeToDisplay}
        </TimestampContainer>
      ) : null}
      <ChatMessageContainer
        isMyMessage={isMyMessage}
        avatarHidden={avatarHidden}
        isComment={props?.isComment}
        isParentMessageMine={isParentMessageMine}
        renderRight={renderRight}
        previousMessageHasEmojis={previousMessageHasEmojis}
        previousMessage={props?.previousUid}
        isOptimistic={message?.isOptimistic}
        failed={message?.failed}
        isParentMessage={message?.commentMap}
        animate={controls}
        onMouseEnter={hoverStart}
        onMouseLeave={hoverEnd}
        isLastComment={props?.isLastComment}
      >
        {props?.isLastComment && (
          <BottomCommentLine renderRight={renderRight} />
        )}
        {message?.commentMap && (
          <>
            <TopCommentLine
              renderRight={renderRight}
              isMyMessage={isMyMessage}
              avatarHidden={avatarHidden}
              isComment={props?.isComment}
            />
          </>
        )}
        {props?.isComment && !props?.isLastComment && !message?.commentMap && (
          <MiddleCommentLine renderRight={renderRight} />
        )}
        <AvatarContainer
          isMyMessage={isMyMessage}
          avatarHidden={avatarHidden}
          isComment={props?.isComment}
          renderRight={renderRight}
          isParentMessageMine={isParentMessageMine}
          isHighlighted={isHighlighted}
          isAiEventSuggestion={isAiEventSuggestion}
        >
          <Squircle
            innerRef={targetElementRef}
            {...(isAutomatedMessage
              ? { automatedMessageEmoji: channelEmoji }
              : { userUid: message?.user?.uid })}
            width={props?.isComment ? 31 : 40}
            fontSize={props?.isComment ? 15 : 18}
            key={message?.user?.uid + "avatar"}
            onClick={onProfileClick}
            onMouseEnter={onProfileMouseEnter}
            onMouseLeave={onProfileMouseLeave}
          />
        </AvatarContainer>
        <RightOfAvatarContainer
          isMyMessage={isMyMessage}
          isComment={props?.isComment}
          renderRight={renderRight}
          isParentMessageMine={isParentMessageMine}
          isAiEventSuggestion={isAiEventSuggestion}
        >
          <SenderNameText
            isMyMessage={isMyMessage}
            avatarHidden={isAiEventSuggestion || avatarHidden}
            isComment={props?.isComment}
            renderRight={renderRight}
            isHighlighted={isHighlighted}
          >
            {senderName}
          </SenderNameText>
          <FlexContainer
            isMyMessage={isMyMessage}
            isComment={props?.isComment}
            isParentMessageMine={isParentMessageMine}
            renderRight={renderRight}
            isHighlighted={isHighlighted}
            failed={message?.failed}
          >
            <HoverContainer />
            {getMessageBodyJsx()}
            {formattedReactions.length === 0 &&
              isHovered &&
              !message?.isOptimistic &&
              !isAiEventSuggestion && (
                <>
                  <EmojiPopover
                    isCustomEmojiVisible
                    referenceElementCb={(ref, getReferenceProps) => (
                      <MessageButtonContainer renderRight={renderRight}>
                        {formattedReactions.length === 0 && (
                          <GrayCircle
                            ref={ref}
                            // onClick={toggleEmojiPicker}
                            isComment={props?.isComment}
                            onMouseEnter={() => {
                              hoverInlineButtonRef.current = true;
                              hoverStart();
                            }}
                            onMouseLeave={() => {
                              hoverInlineButtonRef.current = false;
                            }}
                            {...getReferenceProps()}
                          >
                            <AddReactionIcon />
                          </GrayCircle>
                        )}
                        <GrayCircle renderRight={renderRight}>
                          <AddCommentIcon onClick={onMessageBodyClick} />
                        </GrayCircle>
                      </MessageButtonContainer>
                    )}
                    onEmojiClick={onEmojiClick}
                    onClose={() => {
                      setIsHovered(false);
                    }}
                    onOpenStateChanged={(open) => setEmojiPickerIsOpen(open)}
                  />
                </>
              )}
            {formattedReactions.length !== 0 &&
              isHovered &&
              !message?.isOptimistic &&
              !isAiEventSuggestion && (
                <MessageButtonContainer renderRight={renderRight}>
                  <GrayCircle renderRight={renderRight}>
                    <AddCommentIcon onClick={onMessageBodyClick} />
                  </GrayCircle>
                </MessageButtonContainer>
              )}
          </FlexContainer>
          <ReactionRow
            isMyMessage={isMyMessage}
            isComment={props?.isComment}
            isParentMessageMine={isParentMessageMine}
            renderRight={renderRight}
            isHighlighted={isHighlighted}
          >
            {formattedReactions.map(([emoji, count], idx) => (
              <ReactionPill
                key={emoji}
                emoji={emoji}
                count={count}
                shake={pillShakingAnimation?.[emoji]}
                clickFunction={clickReactionPillFactory(emoji)}
                message={message}
                isComment={props?.isComment}
                renderRight={renderRight}
                emojiPillRefs={emojiPillRefs}
                clearReactionsFunction={clearReactions}
                emojiCountPerPerson={emojiCountPerPerson?.[emoji]}
                filledColor={
                  typeof myReactions[emoji] !== "undefined" ? groupColor : null
                }
                groupColor={groupColor}
                spamReactView={spamReactView}
              />
            ))}
            {formattedReactions.length > 0 && (
              <>
                <EmojiPopover
                  isCustomEmojiVisible
                  onEmojiClick={onEmojiClick}
                  referenceElementCb={(ref, getReferenceProps) => (
                    <AddEmojiInline
                      fill={"#AEAEB2"}
                      ref={ref}
                      {...getReferenceProps()}
                    />
                  )}
                />
              </>
            )}
          </ReactionRow>
          {message?.user?.uid === myself?.uid ? (
            <ChatMessageIndicator
              messageId={message?.uid}
              isComment={props?.isComment || false}
            />
          ) : null}
        </RightOfAvatarContainer>
      </ChatMessageContainer>

      {message?.newChat ? (
        <NewChatBlock isMyMessage={isMyMessage}>
          <ChatMessageNewChatContainer channelId={message?.newChat ?? ""} />
        </NewChatBlock>
      ) : null}
    </MarginTopAutoContainer>
  );
};

export default ChatMessage;

const ReactionDetailsModal = (props) => {
  const users = useSelector((state) => state?.data?.users);
  const myself = useSelector((state) => state?.data?.myself);

  //emojiCountPerPerson is an array of objects of the form {count, time, userUid}
  const hasMe = props?.emojiCountPerPerson?.some(
    (e) => e?.userUid === myself?.uid
  );

  const hoverStart = () => {
    props.hoverRef.current = true;
  };

  const hoverEnd = () => {
    props.hoverRef.current = false;
    if (!props.hoverRef.current) {
      props?.setShowModal(false);
    }
  };

  return (
    <ReactionDetailsModalContainer
      hasMe={hasMe}
      renderRight={props?.renderRight}
      onMouseEnter={hoverStart}
      onMouseLeave={hoverEnd}
    >
      {props?.emojiCountPerPerson &&
        props?.emojiCountPerPerson.map((entry) => (
          <ReactionDetailsModalLine
            isMe={entry?.userUid === myself?.uid}
            onClick={() => {
              if (entry?.userUid === myself?.uid) {
                props?.clearReactionsFunction(props?.emoji);
                props?.setShowModal(false);
              }
            }}
          >
            <Squircle
              userUid={entry?.userUid}
              width={21}
              key={entry?.userUid + "avatar"}
            ></Squircle>
            <CalloutRegular
              color={
                entry?.userUid === myself?.uid ? props?.groupColor : "#8e8e93"
              }
            >
              {users?.[entry?.userUid]?.name}
            </CalloutRegular>
            {entry?.userUid === myself?.uid && (
              <RemoveReactsButton filledColor={props?.groupColor} />
            )}
            <FootnoteRegularGray
              hasMe={hasMe}
              color={
                entry?.userUid === myself?.uid ? props?.groupColor : "#8e8e93"
              }
            >
              {entry?.count}
            </FootnoteRegularGray>
          </ReactionDetailsModalLine>
        ))}
    </ReactionDetailsModalContainer>
  );
};

const ReactionPill = ({ spamReactView, ...props }) => {
  const isHovering = useRef(false);
  const [showModal, setShowModal] = useState(false);

  const hoverStart = () => {
    isHovering.current = true;
    setTimeout(() => {
      if (isHovering.current) {
        setShowModal(true);
      }
    }, 500);
  };
  const hoverEnd = () => {
    isHovering.current = false;
    setTimeout(() => {
      if (!isHovering.current) {
        setShowModal(false);
      }
    }, 200);
  };

  const isVerboseReactView = spamReactView === VERBOSE_REACT_VIEW;

  const getSingleCounts = () => {
    if (!props.emojiCountPerPerson || !props.emojiCountPerPerson.length) {
      return 0;
    }

    if (isVerboseReactView) {
      return props.count;
    } else {
      return props.emojiCountPerPerson.length;
    }
  };

  const reactionElement = replaceSpecialEmoji(props?.emoji, 16);

  const numChars = ("" + getSingleCounts()).length;

  return (
    <>
      <ReactionPillContainer>
        {showModal && (
          <ReactionDetailsModal
            {...props}
            hoverRef={isHovering}
            setShowModal={setShowModal}
          />
        )}
        <NoSelectWrapper
          filledColor={props?.filledColor}
          initial={{ x: 20 }}
          animate={{ x: props?.shake ? [-6, 6, -3, 0] : 0 }}
          exit={{ x: -20 }}
          transition={{ type: "spring", duration: 0.3, bounce: 0.25 }}
          numChars={isVerboseReactView ? numChars + 2 : numChars}
          onClick={props?.clickFunction}
          ref={(element) =>
            (props.emojiPillRefs.current[props.emoji] = element)
          }
          onMouseEnter={hoverStart}
          onMouseLeave={hoverEnd}
        >
          <AnimatePresence>
            <ReactionPillEmojiText>
              {isVerboseReactView ? (
                <>
                  {reactionElement}
                  <VerboseReactXMark
                    $color={props?.filledColor ? "#FFF" : "#000"}
                  >
                    ×
                  </VerboseReactXMark>
                </>
              ) : (
                reactionElement
              )}
            </ReactionPillEmojiText>
            <ReactionPillText
              filledColor={props?.filledColor}
              initial={{ y: 20, opacity: 0.4 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: -20, opacity: 0.4 }}
              transition={{ type: "spring", duration: 0.3, bounce: 0.25 }}
              key={getSingleCounts()}
            >
              {getSingleCounts()}
            </ReactionPillText>
          </AnimatePresence>
        </NoSelectWrapper>
      </ReactionPillContainer>
    </>
  );
};

const VerboseReactXMark = styled.div`
  margin: 0 4px 1px 4px;
  color: ${({ $color }) => $color};
`;

const NewChatBlock = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: ${({ isMyMessage }) =>
    isMyMessage ? "flex-end" : "flex-start"};
  margin: ${({ isMyMessage }) =>
    isMyMessage ? "5px 78px 5px 5px" : "5px 5px 5px 78px"};
`;

const GrayCircle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 6px;

  width: 31px;
  height: 31px;

  /* UI Tones/Light/Gray 6 */

  background: #f2f2f7;
  border-radius: 100px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin-left: 4px;
  margin-right: 4px;

  cursor: pointer;
  ${(props) => props?.renderRight && `order: -1;`}
`;

const MessageButtonContainer = styled.div`
  position: absolute;
  top: calc(100% / 2 - 18px);
  right: -24px;
  ${(props) => props?.renderRight && `order: -1; left: -24px;`}
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  /* flex reverse */
  // flex-direction: row-reverse;
  ${(props) => props?.renderRight && `flex-direction: row-reverse;`}
  // background: red;
  // height: 20px;
  width: 20px;
  z-index: 1;
`;

const ExpandedImageContainer = styled.div`
  position: fixed;
  // width: 50%;
  // height: calc(100% - 260px);
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  padding-top: 130px;
  padding-bottom: 130px;
  padding-left: 130px;
  padding-right: 130px;

  z-index: 11;

  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  position: auto;
`;

const ExpandedImage = styled.img`
  border-radius: 17px 17px 17px 17px;
  align-self: auto;
  z-index: 11;
  max-height: 100%;
  height: auto;
  width: auto;
  max-width: 95vw;
`;

const DownloadIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 36.9px;
  height: 36.9px;
  right: 4px;
  bottom: 4px;

  background: rgba(0, 0, 0, 0.25);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 100px;
  cursor: pointer;
  z-index: 2;
`;

const DownloadIcon = styled(DownloadIconSvg)`
  width: 24.3px;
  height: 24.3px;
  fill: white;
`;

const BottomCommentLine = styled(BottomCommentLineSvg)`
  width: 24px;
  height: 48px;
  position: absolute;
  top: -1px;
  ${(props) => (props?.renderRight ? "right: 45px;" : "left: 55px;")}
  ${(props) => (props?.renderRight ? "transform: scaleX(-1);" : "")}
  fill: #E5E5EA;
`;

const TopCommentLine = styled.div`
  width: 5px;
  // height: 100%;
  height: calc(100% - 80px);
  ${(props) =>
    ((props?.isMyMessage && props?.renderRight && !props?.isComment) ||
      props?.avatarHidden) &&
    `height: calc(100% - 64px);`}
  position: absolute;
  bottom: 0px;
  ${(props) => (props?.renderRight ? "right: 45.5px;" : "left: 55.5px;")}
  background: #E5E5EA;
  border-radius: 100px 100px 0 0;
`;

const MiddleCommentLine = styled.div`
  width: 5px;
  height: 100%;
  position: absolute;
  top: 0px;
  ${(props) => (props?.renderRight ? "right: 45.5px;" : "left: 55.5px;")}
  background: #E5E5EA;
  border-radius: 100px 100px 0 0;
`;

const RemoveReactsButton = styled(xIconSvg)`
  width: 16px;
  height: 16px;
  fill: ${(props) => props?.filledColor};
  position: absolute;
  right: 16px;
  // top: 0px;
`;

const CloseExpandedImage = styled(xIconSvg)`
  width: 60px;
  height: 60px;
  // fill: ${(props) => props?.filledColor};
  fill: white;
  z-index: 13;
  cursor: pointer;
`;

const ReactionDetailsModalLine = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0px;
  gap: 8px;
  /* no line break */
  white-space: nowrap;
  min-width: 160px;
  ${(props) => props?.isMe && `cursor: pointer; order: 1;`}
`;

const FootnoteRegularGray = styled(FootnoteRegular)`
  // width: 100%;
  // align-self: flex-end;
  text-align: right;
  /* put at end of line */
  position: absolute;
  right: 16px;
  ${(props) => props?.hasMe && `right: 38px`}
`;

const CancelEmoji = styled;

const ReactionDetailsModalContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 12px;
  padding-right: 36px;
  ${(props) => props?.hasMe && `padding-right: 62px;`}

  position: absolute;
  ${(props) => (props?.renderRight ? `right: 0px;` : `left: 0px;`)}
  bottom: 50px;

  /* UI Tones/Light/White */

  background: #ffffff;
  border: 0.5px solid rgba(0, 0, 0, 0.1);
  /* Shadows/400 */

  box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.1);
  border-radius: 16px;
  z-index: 5;
  cursor: default;
`;

const EmojiPickerOpenButton = styled(EmojiPickerSvg)`
  width: 24px;
  height: 24px;
  fill: #8e8e93;
  // fill: black;
  cursor: pointer;
  position: absolute;
  top: 9px;
  top: calc(100% / 2 - 12px);
  right: -32px;
  ${(props) => props?.renderRight && `order: -1; left: -32px;`}
  z-index: 3;
`;

const AddReactionIcon = styled(AddReactionSvg)`
  width: 15.2px;
  height: 15.2px;
  fill: #606769;
`;

const AddCommentIcon = styled(AddCommentSvg)`
  width: 18px;
  height: 17.1px;
  fill: #606769;
`;

const LinkInMessage = styled.a`
  color: ${(props) => (props?.isMyMessage ? "#fff" : "#000")};
  cursor: pointer;
  text-decoration: underline;
  -webkit-text-decoration-color: ${(props) =>
    props?.isMyMessage ? "#ffffff80" : "#00000080"};
  text-decoration-color: ${(props) =>
    props?.isMyMessage ? "#ffffff80" : "#00000080"};
  &:hover {
    -webkit-text-decoration-color: ${(props) =>
      props?.isMyMessage ? "#fff" : "#000"};
    text-decoration-color: ${(props) => (props?.isMyMessage ? "#fff" : "#000")};
  }
  word-break: break-all !important;
  white-space: normal !important;
`;

const MentionMessage = styled.span`
  font-weight: bold;
  color: ${({ color }) => color || "inherit"};

  &:hover {
    cursor: pointer;
  }
`;

const NoSelectWrapper = styled(motion.div)`
  display: inherit;
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;

  display: flex;
  align-items: center;
  justify-content: center;

  margin-right: 5px;
  margin-bottom: 5px;
  padding: 5px 6px;
  gap: 5px;

  width: ${(props) => {
    const rawPx = props?.numChars * 8 + 38;
    return getUserBrowser() === "Safari" ? rawPx + 6 : rawPx;
  }}px;
  height: 28px;

  background: #ff2d55;
  background: ${(props) => props.filledColor ?? "#fff"};
  box-shadow: 0px 2px 4px -2px rgba(24, 39, 75, 0.2);
  border-radius: 1000px;

  position: relative;
  overflow: hidden !important;
`;

const EmojiExplosionParticle = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 64px;
  height: 64px;
  font-size: 48px;
  z-index: 8;

  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
  pointer-events: none;
`;

const StyledPicker = styled(Picker)``;

const MarginTopAutoContainer = styled(motion.div)`
  // height: 100%;
  height: auto;
  // flex-grow: 0;
  width: 100%;
  display: block;
`;

const TimestampContainer = styled.div`
  width: 100%;
  height: 16px;

  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  letter-spacing: 0.02em;

  margin-top: 16px;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  justify-content: center;

  /* UI Tones/Light/Gray 1 */

  color: #8e8e93;
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
  pointer-events: none;

  span {
    font-weight: bold;
    margin: 0 4px 0 0;
  }
`;

const AvatarContainer = styled.div`
  margin-top: 14px;

  ${(props) =>
    (props?.isMyMessage && props?.renderRight && !props?.isComment) ||
    props?.avatarHidden
      ? `margin-top: 0px;`
      : ""}
  margin-left: 38px;
  ${(props) => !props?.isComment && `margin-right: 5px;`}
  ${(props) => props?.isComment && `margin-left: 85px; margin-top: 16px;`}

  ${(props) => props.renderRight && `margin-right: 26px; margin-left: 5px;`}
  ${(props) =>
    props.isComment &&
    props?.isParentMessageMine &&
    `margin-right: 73px; margin-left: 5px;`}
  visibility: ${(props) => (props.avatarHidden ? "hidden" : "visible")};
  // ${(props) => props?.avatarHidden && `display: none;`}
  ${(props) => props?.avatarHidden && `height: 0px;`}
  width: 40px;
  flex-shrink: 0;
  ${(props) => props?.isHighlighted && `z-index: 3;`}
`;

const AddEmojiInline = styled(AddEmojiInlineSvg)`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-right: 5px;
  margin-bottom: 5px;
  padding-left 6px;
  padding-right 5px;
  padding-top 5px;
    padding-bottom 5px;
  gap: 5px;

  width: 18px;
  height: 18px;

  background: #fff;

  box-shadow: 0px 2px 4px -2px rgba(24, 39, 75, 0.2);
  border-radius: 1000px;

  cursor: pointer;
  z-index: 2 !important;
`;

const ReactionRow = styled.div`
  display: flex;
  justify-content: ${(props) =>
    props.renderRight ? "flex-end" : "flex-start"};

  align-items: center;
  margin-left: 18px;
  margin-right: 8px;
  // margin-top: -4px;
  top: -4px;
  //   background: blue;
  flex-wrap: wrap;
  position: relative;
  ${(props) => props?.isHighlighted && `z-index: 3;`}
`;

const ReactionPillEmojiText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: 600;
  font-size: 17px;
  line-height: 18px;
  /* identical to box height, or 138% */

  letter-spacing: 0.02em;
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
  pointer-events: none;

  position: absolute;
  left: 8px;
`;

const ReactionPillContainer = styled.div`
  cursor: pointer;
  position: relative;
`;

const ReactionPillText = styled(motion.div)`
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  /* identical to box height, or 138% */

  letter-spacing: 0.02em;

  /* UI Tones/Light/White */

  color: ${(props) => (props?.filledColor ? "#fff" : "#000")};
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
  pointer-events: none;

  position: absolute;
  right: 8px;
`;

const SenderNameText = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 138% */

  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 1px;

  letter-spacing: 0.02em;

  /* UI Tones/Light/Gray 1 */

  color: #8e8e93;
  ${(props) =>
    (props?.isMyMessage && props?.renderRight && !props?.isComment) ||
    props?.avatarHidden
      ? `display: none;`
      : ""}
  ${(props) => props?.isHighlighted && `z-index: 3;`}
`;

const RightOfAvatarContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: ${(props) => (props.renderRight ? "flex-end" : "flex-start")};

  ${({ isAiEventSuggestion }) =>
    isAiEventSuggestion ? `margin: 12px 0 0 0;` : "max-width: 70%;"}
`;

const ChatMessageContainer = styled(motion.div)`
  display: flex;
  flex-direction: ${(props) => (props.renderRight ? "row-reverse" : "row")};
  width: 100%;
  // min-height: 64px;
  height: auto;
  // height: 100%;
  // flex-shrink: 0;

  //spacing for normal messages should be 12
  //if the name is gone, it should be -10

  //for comments that should be smaller

  ${(props) => !props?.avatarHidden && `padding-top: 12px;`}
  ${(props) => props?.avatarHidden && `padding-top: -12px;`}

  ${(props) =>
    props?.previousMessageHasEmojis &&
    props?.avatarHidden &&
    `padding-top: -18;`}

    ${(props) =>
    props?.previousMessageHasEmojis &&
    !props?.avatarHidden &&
    `padding-top: 4px;`}

  ${(props) => props?.failed && `opacity: 0.5;`}
  position: relative;
  // overflow: hidden;
`;

const FlexContainer = styled.div`
  display: flex;
  ${(props) => (props?.renderRight ? "margin-left: 18px;" : "")}
  justify-content: ${(props) =>
    props.renderRight ? "flex-end" : "flex-start"};

  ${(props) => props?.isHighlighted && `z-index: 3;`}
  position: relative;
  align-items: center;
  ${(props) =>
    props?.failed &&
    `border: 6px solid #FF0000; border-radius: 24px 24px 24px 24px;`}
`;

const HoverContainer = styled.div`
  // background: red;
  // position: absolute;
  // top: 0;
  // width: calc(100% + 40px);
  // height: 100%;
  // z-index: -1;
`;

const ChatImageBody = styled.img`
  cursor: pointer;
  display: inline-block;
  border-radius: 17px 17px 17px 17px;
  align-self: auto;
  max-width: 100%;
  max-height: 225px;
  width: auto;
  margin: 0 0 2px 0;

  ${({ $isError }) =>
    $isError ? `border: 2.25px solid rgba(255, 12, 24, 0.25);` : ""}
`;

const ChatVideoBody = styled.video`
  cursor: pointer.
  display: inline-block;
  border-radius: 17px 17px 17px 17px;
  align-self: auto;
  max-width: 100%;
  max-height: 225px;
  width: auto;
`;

const ChatBubbleBody = styled.div`
  //   display: flex;
  display: inline-block;
  padding: 9.5px 13px;

  /* UI Tones/Light/Gray 6 */

  border-radius: 17px 17px 17px 17px;

  /* Inside auto layout */

  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.01em;

  ${(props) =>
    props?.isComment &&
    `
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.02em;
  padding: 7px 12px;
  `}

  /* or 129% */

  

  /* UI Tones/Dark/Black */

  color: ${(props) => (props.isMyMessage ? "#fff" : "#000")};

  /* Inside auto layout */

  align-self: stretch;
  white-space: pre-wrap;

  background: ${(props) =>
    !props.isMyMessage
      ? "#f2f2f7;"
      : "linear-gradient(180deg, rgba(255, 255, 255, 0.25) 0%, rgba(255, 255, 255, 0) 100%), " +
        props?.groupColor +
        ";"};

  ${(props) =>
    props?.isDeleted && props.isMyMessage && `color: rgba(255,255,255,0.6);`}
  ${(props) =>
    props?.isDeleted && !props.isMyMessage && `color: rgba(0,0,0,0.6);`}

    margin-bottom: 2px;

  ${(props) =>
    props?.note &&
    `
    background: transparent;
    color: #8E8E93;
    border: 2px solid #E5E5EA;
  `}

  ${({ $isError }) =>
    $isError
      ? `background: transparent; color: #FF0C18; border: 2.25px solid rgba(255, 12, 24, 0.25);`
      : ""}
`;

const OpacityOverlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: calc(100%);
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  // opacity: 0.5;
  z-index: 9;
  mouse-events: none;
`;

const LinkPreviewBodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${({ isMyMessage }) =>
    isMyMessage ? "flex-end" : "flex-start"};
`;
