import React, { FC, Fragment, MouseEvent, RefObject, useCallback } from "react";
import { AnimatePresence, HTMLMotionProps } from "framer-motion";

import { logDebug } from "dive/util/log.util";

import { UiMenuOption } from "./UiMenu.type";
import {
  UiMenuBlock,
  UiMenuOptionBlock,
  UiMenuOptionIcon,
  UiMenuOptionText,
  UiMenuSeparator,
} from "./UiMenu.style";

type UiMenuProps = HTMLMotionProps<"div"> & {
  isVisible: boolean;
  isDark?: boolean;
  options: UiMenuOption[];
  menuElementRef?: RefObject<HTMLDivElement>;
  topOffsetPx?: number;
  rightOffsetPx?: number;
  bottomOffsetPx?: number;
  leftOffsetPx?: number;
  onOptionClick: (optionId: string) => void;
};

export const UiMenu: FC<UiMenuProps> = ({
  isVisible,
  isDark,
  options,
  menuElementRef,
  topOffsetPx,
  rightOffsetPx,
  bottomOffsetPx,
  leftOffsetPx,
  onOptionClick,
  ...motionDivProps
}) => {
  const onMenuClick = useCallback(
    (event: MouseEvent<HTMLUListElement>) => {
      if (!event.target) {
        logDebug(`[UiMenu][onMenuClick] Cannot get target element`);

        return;
      }

      // @ts-expect-error
      const optionId = event.target.getAttribute("data-id");

      if (!optionId) {
        logDebug(`[UiMenu][onMenuClick] Cannot get option ID`);

        return;
      }

      onOptionClick(optionId);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onOptionClick]
  );

  return (
    <AnimatePresence>
      {isVisible ? (
        // @ts-ignore
        <UiMenuBlock
          ref={menuElementRef}
          transition={{ type: "spring", duration: 0.4, bounce: 0.2 }}
          $top={topOffsetPx ? `${topOffsetPx}px` : undefined}
          $right={rightOffsetPx ? `${rightOffsetPx}px` : undefined}
          $bottom={bottomOffsetPx ? `${bottomOffsetPx}px` : undefined}
          $left={leftOffsetPx ? `${leftOffsetPx}px` : undefined}
          $isDark={isDark}
          onClick={onMenuClick}
          {...motionDivProps}
        >
          {options.map(({ id, title, icon }, index) => (
            <Fragment key={id}>
              <UiMenuOptionBlock data-id={id} $isDark={isDark}>
                <UiMenuOptionText $isDark={isDark}>{title}</UiMenuOptionText>
                <UiMenuOptionIcon>{icon}</UiMenuOptionIcon>
              </UiMenuOptionBlock>
              {index !== options.length - 1 ? (
                <UiMenuSeparator $isDark={isDark} />
              ) : null}
            </Fragment>
          ))}
        </UiMenuBlock>
      ) : null}
    </AnimatePresence>
  );
};
