import React, { useEffect, useRef, useState } from "react";
import styled, { css, keyframes } from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import Squircle from "./Squircle";
import { createSelector } from "@reduxjs/toolkit";
import fb from "../firebase/firebaseCalls.js";
import { motion, AnimatePresence, useAnimation } from "framer-motion";
import {
  Title2Regular,
  Title2Bold,
  CalloutRegular,
} from "../constants/DesignSystem";
// import { addPollListener } from "../store/data";
// import { ReactComponent as AddEmojiInlineSvg } from "../assets/addReaction.svg";

const LoadingListItem = (props) => {
  return (
    <ListItemContainer>
      <Squircle width={52} loading={true}></Squircle>
      <TextContainer>
        <SpaceApart>
          <Title />
          <Timestamp />
        </SpaceApart>

        <TextLong />
        <TextShort />
      </TextContainer>
    </ListItemContainer>
  );
};

export default LoadingListItem;

const keyframesShimmer = keyframes`
  0% {
    background-position: -80vw 0;
  }
  100% {
    background-position: 80vw 0;
  }
`;

const shimmerAnimation2 = css`
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.3) 4%,
    rgba(0, 0, 0, 0.5) 25%,
    rgba(0, 0, 0, 1) 36%
  );
  background-size: 80vw 100%;
  animation: ${keyframesShimmer} 3s infinite linear;
`;

const ListItemContainer = styled.div`
  height: 86px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 16px;
  padding-left: 22px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 16px;
  position: relative;
  flex-shrink: 0;
  // background: red;
`;

const Title = styled.div`
  width: 154px;
  height: 12px;

  background: #000000;
  opacity: 0.1;
  border-radius: 100px;
  margin-bottom: 13px;
  ${shimmerAnimation2}
`;

const TextLong = styled.div`
  width: 251px;
  height: 8px;

  background: #000000;
  opacity: 0.05;
  border-radius: 100px;
  margin-bottom: 8px;
  ${shimmerAnimation2}
`;

const SpaceApart = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const Timestamp = styled.div`
  width: 49px;
  height: 12px;

  background: #000000;
  opacity: 0.05;
  border-radius: 100px;
  ${shimmerAnimation2}
`;

const TextShort = styled(TextLong)`
  width: 118px;
`;

const TextContainer = styled.div`
  width: 100%;
  height: 100%;
  margin-left: 14px;
  margin-top: 12px;
`;
