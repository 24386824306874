// import { Mixpanel } from 'mixpanel-react-native';

// let envCheck = process.env.NODE_ENV === 'production';
// if (envCheck) {
//   //prod (Dive Prod on mixpanel)
//   analytics = new Mixpanel('d009188bf36563fb21ec872f26799f41');
// } else {
//   //dev (Dive V2 on mixpanel)
//   analytics = new Mixpanel('5a7fe00e4936dc768fc62751e47d449b');
// }
// // analytics = new ExpoMixpanelAnalytics('5a7fe00e4936dc768fc62751e47d449b');
// analytics.init();
// export default analytics;

var analytics = require("mixpanel-browser");

analytics.init("d009188bf36563fb21ec872f26799f41");
export default analytics;
