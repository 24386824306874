// Link Preview Providers (Supported by the app)
export const INSTAGRAM_PROVIDER = "instagram";
export const TIKTOK_PROVIDER = "tiktok";
export const TWITTER_PROVIDER = "twitter";
export const YOUTUBE_PROVIDER = "youtube";
export const SPOTIFY_PROVIDER = "spotify";
export const APPLE_MUSIC_PROVIDER = "apple_music";
export const DIVE_PROVIDER = "dive";
export const DIVE_GROUP_PROVIDER = "dive_group";
export const UNIVERSAL_PROVIDER = "universal";

// Link Preview Types (Supported by the app)
export const PROFILE_LINK_TYPE = "profile";
export const ARTICLE_LINK_TYPE = "article";
export const WEBSITE_LINK_TYPE = "website";
export const INST_PHOTO_LINK_TYPE = "instapp:photo";
export const VIDEO_OTHER_LINK_TYPE = "video.other";
export const MUSIC_SONG_LINK_TYPE = "music.song";
export const MUSIC_ALBUM_LINK_TYPE = "music.album";
export const MUSIC_PLAYLIST_LINK_TYPE = "music.playlist";
