import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import {
  FormInitialState,
  PageOrModalId,
  SetFormActionPayload,
} from "./form.type";

export const formReducerName = "form";

export const formInitialState: FormInitialState = {
  data: {},
};

const formSlice = createSlice({
  name: formReducerName,
  initialState: formInitialState,
  reducers: {
    setForm: (state, { payload }: PayloadAction<SetFormActionPayload>) => {
      const { id, formData } = payload;

      // @ts-expect-error
      state.data[id] = formData;
    },
    resetForm: (state, { payload }: PayloadAction<PageOrModalId>) => {
      const { [payload]: formToExclude, ...formData } = state.data;

      state.data = formData;
    },
  },
});

export const formReducer = formSlice.reducer;

export const { setForm, resetForm } = formSlice.actions;
