import { createAsyncThunk } from "@reduxjs/toolkit";

import { getDraftMap } from "../../../firebase/draft";

export const retrieveDraftMap = createAsyncThunk(
  "data/getDraftMap",
  (userId: string) => {
    return getDraftMap(userId);
  }
);
