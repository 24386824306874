import { createAsyncThunk } from "@reduxjs/toolkit";

import { getProfileSharedData } from "../../../firebase/user";

export const retrieveProfileSharedData = createAsyncThunk(
  "data/RETRIEVE_PROFILE_SHARED_DATA",
  (userId: string) => {
    return getProfileSharedData(userId);
  }
);
