import React, { FC, useCallback, useEffect, useRef, useState } from "react";

import { useModal } from "dive/hook/useModal.hook";
import { logDebug } from "dive/util/log.util";
import {
  NEW_DM_MODAL_ID,
  NEW_EVENT_MODAL_ID,
  NEW_GROUP_MODAL_ID,
  UiModalId,
} from "dive/store/ui";
import { UiMenu } from "dive/components/UiMenu";
import analytics from "dive/util/analytics";
// @ts-expect-error
import { ReactComponent as PeopleSvg } from "dive/assets/people.svg";
// @ts-expect-error
import { ReactComponent as CalendarSvg } from "dive/assets/ios-calendar.svg";
// @ts-expect-error
import { ReactComponent as DmSvg } from "dive/assets/dm.svg";

import { CreateButtonBlock, CreateButtonIcon } from "./CreateButton.style";

type CreateButtonProps = {};

export const CreateButton: FC<CreateButtonProps> = ({}) => {
  const plusButtonRef = useRef<HTMLButtonElement>(null);
  const menuBlockRef = useRef<HTMLDivElement>(null);

  const [isMenuVisible, setIsMenuVisible] = useState(false);

  useEffect(() => {
    const onClickOutside: EventListener = (event) => {
      if (
        menuBlockRef.current &&
        // @ts-expect-error
        !menuBlockRef.current?.contains(event.target) &&
        // @ts-expect-error
        !plusButtonRef.current?.contains(event.target)
      ) {
        setIsMenuVisible(false);
      }
    };

    document.addEventListener("mousedown", onClickOutside);

    return () => {
      document.removeEventListener("mousedown", onClickOutside);
    };
  }, [isMenuVisible]);

  const onPlusButtonClick = useCallback(() => {
    if (!isMenuVisible) {
      setIsMenuVisible(true);

      analytics.track("web_press_plus");
    }
  }, [isMenuVisible]);

  const { showModal } = useModal();

  const menuOptions = [
    {
      id: NEW_GROUP_MODAL_ID,
      title: "New Group",
      icon: <PeopleSvg width="26" height="17" />,
    },
    {
      id: NEW_EVENT_MODAL_ID,
      title: "New Event",
      icon: <CalendarSvg width="26" height="20" />,
    },
  ];

  const isDmCreationSupported = false;

  if (isDmCreationSupported) {
    menuOptions.push({
      id: NEW_DM_MODAL_ID,
      title: "New DM",
      icon: <DmSvg width="26" height="22" />,
    });
  }

  const onOptionClick = useCallback(
    (optionId: string) => {
      const modalId = optionId as UiModalId;

      if (!modalId) {
        logDebug(`[CreateButton][onOptionClick] Cannot get modal ID`);

        return;
      }

      setIsMenuVisible(false);

      showModal(modalId);

      if (modalId === NEW_EVENT_MODAL_ID) {
        analytics.track("web_press_create_event");
      }
    },
    [showModal]
  );

  const menuButtonRect = plusButtonRef.current?.getBoundingClientRect();

  return (
    <>
      <CreateButtonBlock ref={plusButtonRef} onClick={onPlusButtonClick}>
        <CreateButtonIcon width="32" height="32" />
      </CreateButtonBlock>
      <UiMenu
        isVisible={isMenuVisible}
        options={menuOptions}
        menuElementRef={menuBlockRef}
        initial={{ opacity: 0, scale: 0, x: 75, y: -75 }}
        animate={{ opacity: 1, scale: 1, x: 0, y: 0 }}
        exit={{ opacity: 0, scale: 0, x: 75, y: -75 }}
        topOffsetPx={
          (menuButtonRect?.top || 0) + (menuButtonRect?.height || 0) + 12
        }
        leftOffsetPx={
          (menuButtonRect?.left || 0) + (menuButtonRect?.width || 0) - 200
        }
        onOptionClick={onOptionClick}
      />
    </>
  );
};
