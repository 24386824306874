import { getDatabase, ref, get } from "firebase/database";
import { getAuth } from "firebase/auth";

import { logDebug } from "../../util/log.util";
import { DraftMap } from "../../type/draft.type";

export function getDraftMap(userId: string) {
  if (!userId) {
    logDebug(`[getDraftMap] User ID is not defined`);

    return Promise.reject();
  }

  const realtimeDb = getDatabase();
  const authSession = getAuth();

  return new Promise<DraftMap>((resolve, reject) => {
    get(ref(realtimeDb, `drafts/${userId}`))
      .then((snapshot) => {
        if (!snapshot.exists()) {
          logDebug(`[getDraftMap] Snapshot doesn't exist`);

          return resolve({} as DraftMap);
        }

        const userDrafts = snapshot.val() as DraftMap;

        resolve(userDrafts);
      })
      .catch((error) => {
        logDebug(`[getDraftMap] Operation failed`, error);

        reject(error);
      });
  });
}
