import React, { FC, useCallback, useState } from "react";

import { useDispatch, useSelector } from "../../store";
import {
  applicationIsUpdateAlertDismissibleSelector,
  setIsUpdateAlertDismissible,
  setIsUpdateAlertVisible,
} from "../../store/application";
import { logWarning } from "../../util/log.util";
import analytics from "../../util/analytics";

import { UpdateAlert } from "./UpdateAlert";

type UpdateAlertContainerProps = {
  onUpdateNativeClient: () => Promise<void>;
};

export const UpdateAlertContainer: FC<UpdateAlertContainerProps> = ({
  onUpdateNativeClient,
}) => {
  const dispatch = useDispatch();

  const [isUpdating, setIsUpdating] = useState(false);

  const isUpdateAlertDismissible = useSelector(
    applicationIsUpdateAlertDismissibleSelector
  );

  const onUpdate = useCallback(
    () => {
      setIsUpdating(true);

      analytics.track("desktop_update_modal", {
        action: "install",
        forcedUpdate: !isUpdateAlertDismissible,
      });

      onUpdateNativeClient()
        .then(() => {
          // Technically this code won't be executed as the app
          //   should quit and start installation process.
          dispatch(setIsUpdateAlertDismissible(true));
          dispatch(setIsUpdateAlertVisible(false));

          setIsUpdating(false);
        })
        .catch((ex) => {
          logWarning(
            `[UpdateAlertContainer] Cannot update native desktop client`,
            ex
          );
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isUpdateAlertDismissible]
  );

  const onDismiss = useCallback(
    () => {
      analytics.track("desktop_update_modal", { action: "dismiss" });

      dispatch(setIsUpdateAlertDismissible(false));
      dispatch(setIsUpdateAlertVisible(false));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <UpdateAlert
      isDismissible={isUpdateAlertDismissible}
      isUpdating={isUpdating}
      onDismiss={onDismiss}
      onUpdate={onUpdate}
    />
  );
};
