import { getDatabase, ref, push } from "firebase/database";
import { getAuth } from "firebase/auth";

import { logDebug } from "../../util/log.util";
import { getRandomInt } from "../../util/number.util";
import { basePalette } from "../../constants/groupColors";
import { HOUR_MS } from "../../constants/time.constant";
import { CanvasBackgroundImage } from "../../type/canvas.type";

import { createEvent } from "./createEvent";
import { generateAiEventPoster } from "./generateAiEventPoster";

type CreateAiEventPayload = {
  title: string;
  emoji: string;
  date: number;
  bgImage?: CanvasBackgroundImage;
};

export async function createAiEvent(payload: CreateAiEventPayload) {
  const { title, emoji, date, bgImage } = payload;

  const realtimeDb = getDatabase();
  const authSession = getAuth();

  const eventId = push(ref(realtimeDb, "events")).key;
  const userId = authSession.currentUser?.uid;

  if (!eventId || !userId) {
    logDebug(`[createAiEvent] Cannot get "eventId" or "userId" value`, {
      eventId,
      userId,
    });

    return;
  }

  const colorList = Object.values(basePalette);
  const randomColorIndex = getRandomInt(0, colorList.length);
  const randomColor = colorList[randomColorIndex];

  const eventData = {
    title: title.trim(),
    emoji: emoji,
    date: date,
    startTime: date,
    endTime: date + HOUR_MS * 2,
    isAllDay: false,
    location: "",
    description: "",
    color: randomColor,
    approvalRequired: false,
    creator: userId,
    responses: {},
    responseTimes: {},
    uid: eventId,
    views: 0,
    shares: 0,
    createdAt: Date.now(),
  };

  const canvasWidth = 430;
  const screenHeight = 932;
  const canvasHeight = 780;
  const pixelRatio = 3;

  const canvasData = {
    stickers: {},
    texts: {},
    drawings: [],
    background: bgImage ? bgImage : { image: randomColor },
  };

  const eventCanvasExtra = {
    drawingCanvasWidthPx: canvasWidth * pixelRatio,
    drawingCanvasHeightPx: canvasHeight * pixelRatio,
    screenWidth: canvasWidth,
    screenHeight,
    pixelRatio,
    canvasHeight,
  };

  const eventCreatePayload = {
    ...eventData,
    eventCanvas: { ...canvasData, ...eventCanvasExtra },
    simpleEvent: true,
    timestamp: Date.now(),
    supportsDraggableEmoji: true,
  };

  await createEvent({ data: eventCreatePayload });

  await generateAiEventPoster({
    eventId,
    saveBackgroundUri: false,
  });

  return eventCreatePayload;
}
