import React, { FC, useCallback, useState } from "react";

import { store, useDispatch, useSelector } from "dive/store";
import {
  dataChannelsByIdSelector,
  dataMyselfIdSelector,
  selectSidebar,
  setChannel,
  setIsNestedSidebarOpen,
  setSelectedChannel,
} from "dive/store/data";
import { getCompoundKey } from "dive/util/uid.util";
import { logDebug } from "dive/util/log.util";
import { createDmChannelTemplate } from "dive/util/template.util";
import analytics from "dive/util/analytics";

import { ProfileCard, ProfileCardProps } from "./ProfileCard";

type ProfileCardContainerProps = Omit<
  Omit<Omit<ProfileCardProps, "onSendDm">, "isSendDmLoading">,
  "isSendDmVisible"
> & {
  hideProfileCard: () => void;
};

export const ProfileCardContainer: FC<ProfileCardContainerProps> = ({
  hideProfileCard,
  ...profileCardProps
}) => {
  const dispatch = useDispatch();

  const [isSendDmLoading, setIsSendDmLoading] = useState(false);

  // @ts-expect-error
  const myId = useSelector(dataMyselfIdSelector);

  const channelId = getCompoundKey(myId, profileCardProps.data.userId);

  const channelData = useSelector((st) =>
    // @ts-expect-error
    dataChannelsByIdSelector(st, channelId)
  );

  const isSendDmVisible = myId !== profileCardProps.data.userId;

  const onSendDm = useCallback(
    () => {
      analytics.track("press_send_dm_profile_card");

      const storeSnapshot = store.getState();

      // @ts-expect-error
      const dmChannel = dataChannelsByIdSelector(storeSnapshot, channelId);

      if (!dmChannel) {
        logDebug(`[ProfileCardContainer][onSendDm] Creating new DM`, {
          compoundKey: channelId,
        });

        const channelTemplate = createDmChannelTemplate({
          name: "",
          channelId,
          myId,
          otherId: profileCardProps.data.userId,
        });

        // @ts-expect-error
        channelTemplate.localOnly = true;

        dispatch(setChannel(channelTemplate));
      }

      dispatch(setIsNestedSidebarOpen(false));
      dispatch(setSelectedChannel(channelId));
      dispatch(selectSidebar(channelId));

      hideProfileCard();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [profileCardProps.data.userId, channelId]
  );

  return (
    <ProfileCard
      {...profileCardProps}
      isSendDmVisible={isSendDmVisible}
      isSendDmLoading={isSendDmLoading}
      onSendDm={onSendDm}
    />
  );
};
