import { createAsyncThunk } from "@reduxjs/toolkit";

import * as applicationFb from "../../../firebase/application";

import { setLatestDesktopAppVersion } from "../application.slice";

export const addDesktopVersionListener = createAsyncThunk(
  "application/ADD_DESKTOP_VERSION_LISTENER",
  (payload, { getState, dispatch }) => {
    const stateSnapshot = getState();

    // @ts-expect-error
    if (stateSnapshot?.data?.listenerMap?.["__desktopVersionString"]) {
      return Promise.reject("The listener already exists");
    }

    const removeDesktopVersionListener =
      applicationFb.addDesktopVersionListener({
        onDesktopVersionChange: (snapshot) => {
          const latestDesktopApplicationVersion = snapshot.val();

          if (latestDesktopApplicationVersion) {
            dispatch(
              setLatestDesktopAppVersion(latestDesktopApplicationVersion)
            );
          }
        },
      });

    // TODO: Save the "removeDesktopVersionListener" to cache/Redux so
    //   we can access this function later to remove group listener.

    return Promise.resolve();
  }
);
