import React, {
  ChangeEvent,
  FC,
  PropsWithChildren,
  TextareaHTMLAttributes,
  memo,
  useCallback,
  useState,
} from "react";

type TextAreaProps = PropsWithChildren<
  TextareaHTMLAttributes<HTMLTextAreaElement>
>;

const TextAreaComponent: FC<TextAreaProps> = ({ ...textAreaProps }) => {
  const [value, setValue] = useState(textAreaProps.defaultValue);

  // Need to implement a custom change handler to avoid input lagging
  //   when entering the data too fast.
  // See more:
  //   https://dev.to/kevinkh89/how-to-solve-input-delay-lagging-in-react-j2o
  const onChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      setValue(event.target.value);

      textAreaProps.onChange?.(event);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [textAreaProps.onChange]
  );

  return <textarea {...textAreaProps} value={value} onChange={onChange} />;
};

export const TextArea = memo(TextAreaComponent);
