import styled from "styled-components";

export const SUGGESTION_LIST_MAX_WIDTH = 250;

export const SuggestionListContainer = styled.div`
  position: absolute;
  z-index: 999999;
  top: ${({ y }) => `${y}px` || "-0px"};
  left: ${({ x }) => `${x}px` || "0px"};
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  background-color: white;
  padding: 10px 0;
  max-height: 200px;
  max-width: ${SUGGESTION_LIST_MAX_WIDTH}px;
  overflow: scroll;
  opacity: ${({ isReady }) => (isReady ? "1" : "0")};
`;

export const SuggestionListLabel = styled.div`
  margin: 10px 30px;
  color: #8e8e93;
  font-size: 12px;
`;

export const SuggestionListDataContainer = styled.ul`
  margin: 5px;
`;

export const SuggestionListItemContainer = styled.li`
  margin: 5px 5px;
  padding: 10px 15px;
  border: 1px solid
    ${({ isSelected }) => (isSelected ? "rgba(0, 0, 0, 0.1)" : "transparent")};
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${({ isSelected }) => (isSelected ? "white" : "inherit")};
  background-color: ${({ isSelected, color }) =>
    isSelected ? color : "transparent"};

  &:hover {
    cursor: pointer;
  }
`;
