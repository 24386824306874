import { useSelector } from "react-redux";

import { getGroupColor } from "../constants/groupColors";
import {
  dataSelectedChannelIdSelector,
  dataGroupsSelectedGroupSelector,
} from "../store/data";
import { colorPalette } from "../constants/Theme";

export function useChatColor() {
  const channelId = useSelector(dataSelectedChannelIdSelector) || "";
  const selectedGroup = useSelector(dataGroupsSelectedGroupSelector);

  const themeColor = getGroupColor(selectedGroup) || "";

  const groupColor = channelId?.length > 30 ? colorPalette.blue : themeColor;

  return groupColor;
}
