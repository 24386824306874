import React, { FC, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ChannelRecord } from "../../type/channel.type";
import { getChannelInfo, joinChannel } from "../../firebase/channel";
import { logDebug } from "../../util/log.util";
import {
  dataChannelsByIdSelector,
  dataMyselfIdSelector,
  dataSelectedGroupSelector,
  selectSidebar,
  setChannel,
  setSelectedChannel,
} from "../../store/data";
import { useChatColor } from "../../hook/useChatColor";

import { ChatMessageNewChat } from "./ChatMessageNewChat";

type ChatMessageNewChatContainerProps = {
  channelId: string;
};

export const ChatMessageNewChatContainer: FC<
  ChatMessageNewChatContainerProps
> = ({ channelId }) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const chatColor = useChatColor();
  const myId = useSelector(dataMyselfIdSelector);
  const groupId = useSelector(dataSelectedGroupSelector);
  const channelData = useSelector((st) =>
    // @ts-expect-error
    dataChannelsByIdSelector(st, channelId)
  ) as ChannelRecord;

  const hasJoined = !!channelData?.members?.[myId];

  useEffect(
    () => {
      getChannelInfo({ channelId })
        .then((channelData) => {
          dispatch(setChannel(channelData));
        })
        .catch((error) => {
          logDebug(
            "[ChatMessageNewChatContainer] Cannot retrieve channel info",
            {
              channelId,
              error,
            }
          );
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [channelId]
  );

  const onJoin = useCallback(
    () => {
      if (hasJoined) {
        dispatch(setSelectedChannel(channelId));
        dispatch(selectSidebar(channelId));

        return;
      }

      setIsLoading(true);

      joinChannel({ channelId, groupId }).then(() => {
        setIsLoading(false);

        dispatch(setSelectedChannel(channelId));
        dispatch(selectSidebar(channelId));

        // TODO: Add channel listeners
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [channelId, hasJoined]
  );

  if (!channelData) {
    return;
  }

  return (
    <ChatMessageNewChat
      name={channelData.name}
      emoji={channelData.emoji}
      chatColor={chatColor}
      memberCount={Object.keys(channelData.members || {}).length || 0}
      hasJoined={hasJoined}
      isLoading={isLoading}
      onJoin={onJoin}
    />
  );
};
