import React, { FC } from "react";

// @ts-expect-error
import { ReactComponent as ArrowDownSvg } from "dive/assets/arrow-down.svg";
// @ts-expect-error
import { ReactComponent as XSvg } from "dive/assets/x.svg";

import {
  DesktopSidebarTileBlock,
  DesktopSidebarTileClose,
  DesktopSidebarTileContent,
  DesktopSidebarTileContentDescription,
  DesktopSidebarTileContentTitle,
  DesktopSidebarTileIconBadge,
  DesktopSidebarTileIconImg,
  DesktopSidebarTileIconSection,
} from "./DesktopSidebarTile.style";

type DesktopSidebarTileProps = {
  isVisible: boolean;
  onContainerClick: () => void;
  onClose: () => void;
};

export const DesktopSidebarTile: FC<DesktopSidebarTileProps> = ({
  isVisible,
  onContainerClick,
  onClose,
}) => {
  if (!isVisible) {
    return null;
  }

  const platform =
    // @ts-expect-error
    window.navigator?.userAgentData?.platform ||
    window.navigator?.platform ||
    "";

  const isMac = platform.toUpperCase().indexOf("MAC") >= 0;

  return (
    <DesktopSidebarTileBlock
      href="https://dive.chat/download"
      target="_blank"
      onClick={onContainerClick}
    >
      <DesktopSidebarTileIconSection>
        <DesktopSidebarTileIconImg src={require("dive/assets/logo.png")} />
        <DesktopSidebarTileIconBadge>
          <ArrowDownSvg />
        </DesktopSidebarTileIconBadge>
      </DesktopSidebarTileIconSection>
      <DesktopSidebarTileContent>
        <DesktopSidebarTileContentTitle>
          Download for {isMac ? "Mac" : "Windows"}
        </DesktopSidebarTileContentTitle>
        <DesktopSidebarTileContentDescription>
          Get it on your dock and with notifications built-in.
        </DesktopSidebarTileContentDescription>
      </DesktopSidebarTileContent>
      <DesktopSidebarTileClose
        onClick={(event) => {
          event.preventDefault();

          onClose();
        }}
      >
        <XSvg width="16" height="16" />
      </DesktopSidebarTileClose>
    </DesktopSidebarTileBlock>
  );
};
