import axios from "axios";

import { logDebug } from "./log.util";

type IncrementBgDownloadCountPayload = {
  photoId: string;
};

export function incrementBgDownloadCount({
  photoId,
}: IncrementBgDownloadCountPayload) {
  // Need to call this api to comply with terms of service.
  // See more: https://help.unsplash.com/en/articles/2511258-guideline-triggering-a-download

  const incrementDownload = `https://www.dive.chat/api/photos/download?item_id=${photoId}`;

  return axios.get(incrementDownload).catch((ex) => {
    logDebug("[incrementBgDownloadCount] Cannot increment download count", ex);
  });
}
