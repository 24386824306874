import React, { FC, useCallback, useEffect, useState } from "react";

import { useModal } from "dive/hook/useModal.hook";
import analytics from "dive/util/analytics";
import { useInitialPayload } from "dive/hook/useInitialPayload.hook";
import { useDispatch, useSelector } from "dive/store";
import { logDebug } from "dive/util/log.util";
import {
  dataGroupByIdSelector,
  dataMyselfSharedDataSelector,
  selectGroup,
  selectGroupSidebar,
  selectSidebar,
  setIsNestedSidebarOpen,
  setSelectedChannel,
} from "dive/store/data";
import { sleep } from "dive/util/sleep.util";
import { useCustomGroupFormData } from "./useCustomGroupFormData";
import { useCreateDive } from "dive/hook/useCreateDive.hook";
import { PINK_COLOR_ID } from "dive/constants/palette.constant";
import { newColorPalette } from "dive/constants/Theme";

import { CustomGroupModal } from "./CustomGroupModal";

type CustomGroupModalContainerProps = {};

export const CustomGroupModalContainer: FC<
  CustomGroupModalContainerProps
> = ({}) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [navigationPayload, setNavigationPayload] = useState<{
    groupId: string;
    mainChannelId: string;
  } | null>(null);

  const { hideModal } = useModal();

  // @ts-expect-error
  const userSharedData = useSelector(dataMyselfSharedDataSelector);
  const newGroupData = useSelector((st) =>
    // @ts-expect-error
    dataGroupByIdSelector(st, navigationPayload?.groupId)
  );

  const {
    data,
    setData,
    resetData,
    meta: { initialData },
  } = useCustomGroupFormData();

  const { hasChanged } = useInitialPayload(data);

  const { createDive } = useCreateDive();

  const onNameChange = useCallback(
    (name: string) => {
      setData({ name });
    },
    [setData]
  );

  // Wait for newly created group data to appear in Redux store
  useEffect(
    () => {
      if (
        !newGroupData ||
        !navigationPayload ||
        !navigationPayload.groupId ||
        !navigationPayload.mainChannelId
      ) {
        return;
      }

      dispatch(selectGroup(navigationPayload.groupId));
      dispatch(setSelectedChannel(navigationPayload.mainChannelId));
      dispatch(selectGroupSidebar(navigationPayload.groupId));
      dispatch(selectSidebar(navigationPayload.mainChannelId));

      dispatch(setIsNestedSidebarOpen(true));

      hideModal();

      resetData();

      // No need to reseat loading state, as at this point the modal
      //   should be closed.
      // setIsLoading(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [newGroupData]
  );

  const onSubmit = useCallback(
    () => {
      // @ts-expect-error
      const selectedColor = newColorPalette[data?.colorId || PINK_COLOR_ID];

      const channels = [
        {
          name: "Main Subchat",
          emoji: "👋",
          isPublic: true,
          isDefault: true,
          isAdminPost: false,
        },
        {
          name: "Random Discussion",
          emoji: "🎉",
          isPublic: true,
          isDefault: true,
          isAdminPost: false,
        },
      ];

      if (!selectedColor) {
        logDebug(`[CustomGroupModalContainer][onSubmit] Cannot get color`, {
          selectedColor,
          data,
        });

        return;
      }

      setIsLoading(true);

      createDive({
        name: data?.name,
        color: selectedColor,
        emoji: data?.emoji,
        image: data?.image,
        channels,
      })
        .then((groupData) => {
          logDebug(
            `[CustomGroupModalContainer][onSubmit] New group created`,
            groupData
          );

          setNavigationPayload({
            groupId: groupData.uid,
            mainChannelId: groupData.main,
          });
        })
        .catch((ex) => {
          logDebug(
            `[CustomGroupModalContainer][onSubmit] Cannot create new group`,
            ex
          );

          setIsLoading(false);
          setNavigationPayload(null);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );

  const onClose = useCallback(
    () => {
      hideModal();

      sleep(1000).then(() => {
        resetData();
      });

      // analytics.track("web_abort_create_event");
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hideModal]
  );

  const userName = userSharedData?.name?.split(" ")?.[0]?.trim() ?? "User";

  return (
    <CustomGroupModal
      hasChanged={hasChanged}
      isLoading={isLoading}
      isFormValid={data?.name?.length > 2}
      userName={userName}
      onNameChange={onNameChange}
      onSubmit={onSubmit}
      onClose={onClose}
    />
  );
};
