import { useCallback, useRef } from "react";

import { store, useDispatch, useSelector } from "dive/store";
import {
  PageOrModalFormData,
  PageOrModalId,
  formDataByIdSelector,
  resetForm,
  setForm,
} from "dive/store/form";

export function useFormData<T extends PageOrModalFormData>(
  id: PageOrModalId,
  initialData: T
) {
  const dispatch = useDispatch();

  const formData = useSelector(
    (st) => (formDataByIdSelector(st, id) || {}) as T
  );

  const initialDataRef = useRef(initialData);

  const setData = useCallback(
    (dataChunk: Partial<T>) => {
      const storeDataSnapshot = store.getState();

      const currentData = formDataByIdSelector(storeDataSnapshot, id) as T;

      const dataSnapshot: T = {
        ...initialDataRef.current,
        ...currentData,
        ...dataChunk,
      };

      dispatch(setForm({ id, formData: dataSnapshot }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id]
  );

  const resetData = useCallback(
    () => {
      dispatch(resetForm(id));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id]
  );

  return {
    data: formData,
    setData,
    resetData,
    meta: {
      initialData: initialDataRef.current,
    },
  };
}
