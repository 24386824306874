import { useState } from "react";

import {
  useFloating,
  autoUpdate,
  offset,
  autoPlacement,
  shift,
  useClick,
  useDismiss,
  useRole,
  useInteractions,
} from "@floating-ui/react";

export default function usePopover() {
  const [open, setOpen] = useState(false);

  const { x, y, reference, floating, strategy, context } = useFloating({
    placement: "top",
    open,
    onOpenChange: setOpen,
    middleware: [
      offset(10),
      autoPlacement(),
      shift(),
    ],
    whileElementsMounted: autoUpdate,
  });

  const click = useClick(context);
  const dismiss = useDismiss(context);
  const role = useRole(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
    role,
  ]);

  return {
    x,
    y,
    reference,
    floating,
    strategy,
    context,
    getReferenceProps,
    getFloatingProps,
    open,
    setOpen,
  };
}
