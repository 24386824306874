import { motion } from "framer-motion";
import styled from "styled-components";

import { flexCenter } from "dive/constants/DesignSystem";
import { devices } from "dive/constants/device.constant";

// @ts-expect-error
export const NewGroupModalBlock = styled(motion.div)`
  ${flexCenter}
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
`;

export const NewGroupModalCard = styled.div`
  position: relative;
  width: 40%;
  max-height: 80%;
  border-radius: 24px;
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(50px);
  box-shadow: 0px 6px 14px -6px rgba(24, 39, 75, 0.1);
  padding: 18px 18px 18px 18px;

  @media ${devices.laptop} {
    width: 35%;
  }

  @media ${devices.laptopL} {
    width: 32%;
  }

  @media ${devices.desktopS} {
    width: 30%;
  }

  @media ${devices.desktop} {
    width: 28%;
  }
`;

export const NewGroupModalClose = styled.button`
  ${flexCenter}
  position: absolute;
  width: 44px;
  height: 44px;
  top: -14px;
  right: -10px;
  border-radius: 50%;
  border: 3px solid #1c1c1d;
  background: transparent;
  overflow: hidden;
  z-index: 999;

  &:hover {
    cursor: pointer;
  }

  &:active {
    transform: scale(0.98);
  }
`;

export const NewGroupModalIconBlock = styled.div`
  svg {
    margin: 2px 0 0 0;
  }
`;

export const NewGroupModalTitle = styled.div`
  color: #fff;
  text-align: center;
  font-family: "Clash Display";
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 18px 18px 18px 18px;
`;

export const NewGroupModalDescription = styled.div`
  color: var(--UI-Tones-Light-Gray-3, #c7c7cc);
  text-align: center;
  font-family: "SF Pro Rounded";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.32px;
  margin: 0 0 27px 0;
`;

export const NewGroupModalTemplateGrid = styled.div`
  margin: 0 0 18px 0;
  display: grid;
  gap: 18px;
  grid-template-columns: auto auto;
  user-select: none;
`;

type NewGroupModalTemplateItemProps = {
  $isDisabled: boolean;
};

export const NewGroupModalTemplateItem = styled.button<NewGroupModalTemplateItemProps>`
  min-height: 56px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  border: 0.5px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  box-shadow: 0px 2px 4px -2px rgba(24, 39, 75, 0.1);
  transition: 0.1s;

  svg {
    pointer-events: none;
  }

  img {
    pointer-events: none;
    width: 20px;
  }

  ${({ $isDisabled }) =>
    !$isDisabled
      ? `
  &:hover {
    cursor: pointer;
  }
  &:active {
    transform: scale(0.98);
    opacity: 0.9;
  }
  `
      : "opacity: 0.8;"}
`;

export const NewGroupModalTemplateItemName = styled.div`
  pointer-events: none;
  color: #101b1e;
  text-align: center;
  font-family: "SF Pro Rounded";
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.34px;
  margin: 0 0 0 9px;
`;
