import { getDatabase, ref, get } from "firebase/database";
import { getAuth } from "firebase/auth";

import { logDebug } from "../../util/log.util";
import { ProfileScore } from "../../type/user.type";

export function getProfileScore(userId: string) {
  if (!userId) {
    logDebug(`[getProfileScore] User ID is not defined`);

    return Promise.reject();
  }

  const realtimeDb = getDatabase();
  const authSession = getAuth();

  return new Promise<ProfileScore>((resolve, reject) => {
    get(ref(realtimeDb, `scores/${userId}`))
      .then((snapshot) => {
        const profileScore: ProfileScore = {
          reacts: 0,
          events: 0,
          comments: 0,
        };

        if (!snapshot.exists()) {
          logDebug(`[getProfileScore] Snapshot doesn't exist`);

          return resolve(profileScore);
        }

        const channelData = snapshot.val() as ProfileScore;

        resolve(channelData);
      })
      .catch((error) => {
        logDebug(`[getProfileScore] Operation failed`, error);

        reject(error);
      });
  });
}
