import { getDatabase, update, ref, push } from "firebase/database";
import { getAuth } from "firebase/auth";
import { getLinkPreview } from "link-preview-js";
import Google from "../assets/LinkPreviews/Google.png";
import TikTok from "../assets/LinkPreviews/TikTok.png";
import Twitter from "../assets/LinkPreviews/Twitter.png";
import YouTube from "../assets/LinkPreviews/Youtube.png";
import storage from "./storage";
import analytics from "../util/analytics";
import { IMAGE_MAX_WIDTH } from "../constants/image";
import { logDebug, logWarning } from "../util/log.util";
import has from "lodash/has";

const auth = getAuth();
const db = getDatabase();

//sets the reactions for a message
async function setEmojiCount(
  userUid,
  channelUid,
  messageUid,
  emoji,
  count,
  isComment,
  parentUid
) {
  if (count > 69) {
    analytics.track("web_maxed_reactions");
    return;
  }
  const updates = {};
  const now = Date.now();
  if (isComment) {
    if (count == 0) {
      updates[
        `comments/${channelUid}/${parentUid}/${messageUid}/reacts/${userUid}/${emoji}`
      ] = null;
    } else {
      updates[
        `comments/${channelUid}/${parentUid}/${messageUid}/reacts/${userUid}/${emoji}/count`
      ] = count;
      if (count === 1) {
        updates[
          `comments/${channelUid}/${parentUid}/${messageUid}/reacts/${userUid}/${emoji}/time`
        ] = now;
      }
    }
    updates[`comments/${channelUid}/${parentUid}/${messageUid}/timestamp`] =
      now;
    updates[`messages/${channelUid}/${parentUid}/commentMap/${messageUid}`] =
      now;
    updates[`messages/${channelUid}/${parentUid}/commentTimestamp`] = now;
    updates[`messages/${channelUid}/${parentUid}/timestamp`] = now;
  } else {
    if (count === 0) {
      updates[
        `messages/${channelUid}/${messageUid}/reacts/${userUid}/${emoji}`
      ] = null;
    } else {
      updates[
        `messages/${channelUid}/${messageUid}/reacts/${userUid}/${emoji}/count`
      ] = count;
      if (count === 1) {
        updates[
          `messages/${channelUid}/${messageUid}/reacts/${userUid}/${emoji}/time`
        ] = now;
      }
    }
    updates[`messages/${channelUid}/${messageUid}/timestamp`] = now;
  }
  update(ref(db), updates);
  analytics.track("web_reaction", {
    channelUid: channelUid,
    emojiType: emoji,
    numReactionsWithEmoji: count,
    isComment: isComment,
    messageUid: messageUid,
  });
}

function createMessageObj(timestamp = undefined) {
  const postListRef = ref(db, `messages`);
  const newPostKey = push(postListRef).key;
  const myUid = auth.currentUser?.uid;
  if (!timestamp) {
    timestamp = Date.now();
  }
  const message = {
    createdAt: timestamp,
    user: { uid: myUid, _id: myUid },
    timestamp: timestamp,
    uid: newPostKey,
  };
  return message;
}

// The "people" should be an array of user UIDs, for example: ["uid1", "uid2"]
function sendTextMessage(
  channelUid,
  text,
  parentMessageUid,
  timestamp,
  people = null,
  setLocalState
) {
  if (text.trim().length === 0) {
    return Promise.reject("The text message is empty");
  }

  let messageObj = createMessageObj(timestamp);

  if (people && people.length) {
    messageObj.people = people;
  }

  messageObj.text = text.trim();

  const isOnline = window.navigator.onLine;

  setLocalState({
    ...messageObj,
    ...(isOnline ? {} : { showNotSent: true }),
  });

  if (!isOnline) {
    return Promise.reject("User is offline");
  }

  return firebaseSetMessage(messageObj, channelUid, parentMessageUid).then(
    () => {
      return {
        channelId: channelUid,
        messageData: messageObj,
      };
    }
  );
}

/**
 * @param {{ channelId: string, eventId: string, [parentMessageId]: string}} payload
 */
function sendEventMessage({ channelId, eventId, parentMessageId }) {
  const messageData = createMessageObj();

  messageData.event = eventId;

  return firebaseSetMessage(messageData, channelId, parentMessageId);
}

async function firebaseSetMessage(messageObj, channelUid, parentMessageUid) {
  if (
    has(messageObj, "localMessage") ||
    has(messageObj, "showNotSent") ||
    has(messageObj, "isOptimistic") ||
    has(messageObj, "_mediaFile")
  ) {
    logWarning(`[firebaseSetMessage] Attempting to submit local-only fields!`);
  }

  const myUid = auth.currentUser?.uid;
  analytics.track("web_message_sent", {
    userUid: myUid,
    channelUid: channelUid,
    isComment: parentMessageUid ? true : false,
    hasImage: messageObj.image ? true : false,
  });
  const updates = {};
  //sending a message marks the channel as viewed
  updates["lastViewed/" + myUid + "/channels/" + channelUid] =
    messageObj?.createdAt;
  //send a message clears any unread messages for this channel
  updates["users/" + myUid + "/unreadChats/" + channelUid] = null;
  //if the message is a comment, update the parent message
  if (parentMessageUid) {
    //update the commentTimestamp on the parent message which keeps track of the last time one of its comments was updated
    updates[
      "messages/" + channelUid + "/" + parentMessageUid + "/commentTimestamp"
    ] = messageObj.createdAt;
    //update the commentMap on the parent message which keeps track of all the comments on it
    updates[
      "messages/" +
        channelUid +
        "/" +
        parentMessageUid +
        "/commentMap/" +
        messageObj.uid
    ] = messageObj.createdAt;
    //update the timestamp on the parent message which keeps track of the last time it was updated
    updates["messages/" + channelUid + "/" + parentMessageUid + "/timestamp"] =
      messageObj.createdAt;
    //set the comment message itself to the messageObj
    updates[
      "comments/" + channelUid + "/" + parentMessageUid + "/" + messageObj.uid
    ] = messageObj;
  } else {
    //in the case of a regular message that's not a comment, just set the message to the messageObj
    updates["messages/" + channelUid + "/" + messageObj.uid] = messageObj;
  }

  return update(ref(db), updates);
}

function setLastViewedChannels(channelUid) {
  console.log("==========================");
  const updates = {};
  const myUid = auth.currentUser?.uid;
  updates["lastViewed/" + myUid + "/channels/" + channelUid] = Date.now();
  updates["users/" + myUid + "/unreadChats/" + channelUid] = null;
  update(ref(db), updates);
}

async function optimisticSendImageMessage(
  channelUid,
  imageObj,
  parentMessageUid,
  setLocalState,
  timestamp
) {
  const isOnline = window.navigator.onLine;

  let messageObj = createMessageObj(timestamp);
  let optimisticMessageObj = { ...messageObj, isOptimistic: timestamp };

  optimisticMessageObj.image = imageObj?.imagePreviewUrl;
  optimisticMessageObj._mediaFile = imageObj?.file;

  setLocalState({
    ...optimisticMessageObj,
    localMessage: true,
    ...(isOnline ? {} : { showNotSent: true }),
  });

  if (!isOnline) {
    return Promise.reject("User is offline");
  }

  const compressionMaxWidth = IMAGE_MAX_WIDTH;

  try {
    const externalFileUrl = await storage.uploadFileToStorage(
      imageObj?.file,
      compressionMaxWidth
    );

    messageObj.image = externalFileUrl;

    await firebaseSetMessage(messageObj, channelUid, parentMessageUid);
  } catch (error) {
    logDebug("[optimisticSendImageMessage] Cannot send image", error);

    setLocalState({ ...optimisticMessageObj, showNotSent: true });
  }

  return optimisticMessageObj;
}

export default {
  setEmojiCount,
  sendTextMessage,
  sendEventMessage,
  setLastViewedChannels,
  optimisticSendImageMessage,
  firebaseSetMessage,
};
