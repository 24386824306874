import { PINK_COLOR_ID } from "dive/constants/palette.constant";
import { useFormData } from "dive/hook/useFormData.hook";
import { CustomGroupFormData } from "dive/store/form";
import { CUSTOM_GROUP_MODAL_ID } from "dive/store/ui";

export function useCustomGroupFormData() {
  return useFormData<CustomGroupFormData>(CUSTOM_GROUP_MODAL_ID, {
    name: "",
    emoji: "🎉",
    colorId: PINK_COLOR_ID,
  });
}
