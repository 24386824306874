import { getDatabase, ref, get } from "firebase/database";

import { logDebug } from "../../util/log.util";

export function getUserId(phoneNumber: string) {
  if (!phoneNumber) {
    logDebug(`[getUserId] Phone number value is not defined`);

    return Promise.reject();
  }

  const realtimeDb = getDatabase();

  return new Promise<string | null>((resolve, reject) => {
    get(ref(realtimeDb, `phoneMap/${phoneNumber}`))
      .then((snapshot) => {
        if (!snapshot.exists()) {
          logDebug(`[getUserId] Snapshot doesn't exist`);

          return resolve(null);
        }

        const userId = snapshot.val() as string;

        resolve(userId);
      })
      .catch((error) => {
        logDebug(`[getUserId] Operation failed`, error);

        reject(error);
      });
  });
}
