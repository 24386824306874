import React from "react";
import ReactDOM from "react-dom/client";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";

import "react-datepicker/dist/react-datepicker.css";

import "./index.css";
import App from "./components/App";
import { reportWebVitals } from "./util/reportWebVitals";
import { store, storePersistor } from "./store";
import { initErrorLogger } from "./util/crashlytic.util";

initErrorLogger();

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={storePersistor}>
      <App />
    </PersistGate>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
