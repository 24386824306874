import styled from "styled-components";

export const DesktopSidebarTileBlock = styled.a`
  width: calc(100% - 16px);
  min-height: 78px;
  margin: 12px 8px 0 8px;
  border-radius: 11.747px;
  border: 0.5px solid rgba(0, 0, 0, 0.1);
  background: rgba(250, 250, 250, 0.8);
  box-shadow: 0px 2px 12px -2px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  user-select: none;
  transition: 0.1s;

  &:hover {
    transform: scale(1.02);
  }
`;

export const DesktopSidebarTileIconSection = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 12px 0 12px;
`;

export const DesktopSidebarTileIconBadge = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #fff;
  background: #000;
  box-shadow: 0px 2px 4px -2px rgba(19, 40, 78, 0.1);
`;

export const DesktopSidebarTileIconImg = styled.img`
  width: 58px;
  height: 58px;
`;

export const DesktopSidebarTileContent = styled.div`
  flex: 1;
`;

export const DesktopSidebarTileContentTitle = styled.div`
  color: var(--UI-Tones-Dark-Black, #000);
  font-family: "SF Pro Rounded";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 18.355px;
  letter-spacing: 0.147px;
  margin: 0 0 3px 0;
`;

export const DesktopSidebarTileContentDescription = styled.div`
  color: rgba(0, 0, 0, 0.4);
  font-family: "SF Pro Rounded";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16.152px;
  letter-spacing: 0.25px;
`;

export const DesktopSidebarTileClose = styled.div`
  margin: 0 20px 0 12px;

  &:hover {
    cursor: pointer;
  }
`;
