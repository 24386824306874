import React, { useEffect } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import SidebarListItem from "./SidebarListItem";
import { addGroupListener } from "../store/data/thunk/addGroupListener";
import { createSelector } from "@reduxjs/toolkit";

import { getGroupColor } from "../constants/groupColors";
import { formatTime, mostRecentMessage, getPreviewText } from "../util";
import { useGroupUnreadPipStatus } from "../hook/useGroupUnreadPipStatus.hook";
import { dataListenerMapSelector } from "../store/data";

const GroupListItem = (props) => {
  const dispatch = useDispatch();
  const createdAt = useSelector(
    (state) => state?.data?.groups?.[props.groupUid]?.createdAt
  );
  const selectHeader = (state) => state?.data?.groups?.[props.groupUid]?.name;

  const header = useSelector(selectHeader);
  const color = useSelector((state) =>
    getGroupColor(state?.data?.groups?.[props.groupUid])
  );
  const groupObj = useSelector(
    (state) => state?.data?.groups?.[props.groupUid]
  );

  const dataListenerMap = useSelector(dataListenerMapSelector);

  const selectChannelKeyValue = (state) =>
    state?.data?.groups?.[props.groupUid]?.channels;
  const channelMuteValues = (state) => state?.data?.myself?.channels;

  const isGroupMuted = useSelector((state) =>
    Boolean(state?.data?.myself?.groups?.[props.groupUid])
  );

  const selectNonMutedChannels = createSelector(
    selectChannelKeyValue,
    channelMuteValues,
    (channelKeyValue, mutedKeyValues) => {
      if (!channelKeyValue) return [];
      if (!mutedKeyValues) return [];
      return Object.keys(channelKeyValue)
        .map((channelUid) => {
          return !Boolean(mutedKeyValues[channelUid]) ? channelUid : null;
        })
        .filter((channelUid) => channelUid);
    }
  );
  const nonMutedChannels = useSelector(selectNonMutedChannels);
  const selectPreviewMessages = (state) => state?.data?.previewMessages;
  const selectLastViewed = (state) => state?.data?.lastViewed;

  const selectIsNew = createSelector(
    selectChannelKeyValue,
    selectLastViewed,
    (channelKeyValue, lastViewed) => {
      if (!channelKeyValue) return false;
      if (!lastViewed) return false;
      return !Object.keys(channelKeyValue).some((channelUid) => {
        return typeof lastViewed[channelUid] !== "undefined";
      });
    }
  );

  const isNew = useSelector(selectIsNew);

  const selectIsUnread = createSelector(
    selectNonMutedChannels,
    selectPreviewMessages,
    selectLastViewed,
    (channelUids, previewMessages, lastViewed) => {
      if (!channelUids) return null;
      const channelMessages = channelUids.map((channelUid) => {
        return previewMessages?.[channelUid];
      });
      // console.log(channelMessages);
      const recentChannelMessage = channelMessages
        .map((messages) => {
          if (!messages) return null;
          return mostRecentMessage(Object.values(messages));
        })
        .filter((message) => message !== null);
      const unreadList = recentChannelMessage.map((message) => {
        return message?.createdAt > lastViewed?.[message?.channelUid];
      });
      return unreadList.some((unread) => unread);

      // return mostRecentMessage(recentChannelMessage);
    }
  );

  const selectMessageObjectForPreview = createSelector(
    selectChannelKeyValue,
    selectPreviewMessages,
    (channelKeyValue, previewMessages) => {
      if (!channelKeyValue) return null;
      const channelMessages = Object.keys(channelKeyValue).map((channelUid) => {
        return previewMessages?.[channelUid];
      });
      const recentChannelMessage = channelMessages
        .map((messages) => {
          if (!messages) return null;
          return mostRecentMessage(Object.values(messages));
        })
        .filter((message) => message !== null);
      return mostRecentMessage(recentChannelMessage);
    }
  );

  const messageObjForPreview = useSelector(selectMessageObjectForPreview);

  const selectUsers = (state) => state?.data?.users;

  const selectPreviewMessageSenderName = createSelector(
    selectMessageObjectForPreview,
    selectUsers,
    (message, users) => {
      if (!message) return "";
      return users?.[message?.user?.uid]?.name ?? "";
    }
  );

  const previewMessageSenderName = useSelector(selectPreviewMessageSenderName);

  const isPinned = useSelector(
    (state) => state?.data?.myself?.pinnedGroups?.[props.groupUid]
  );
  const mostRecentNonMutedMessage = useSelector(selectIsUnread);

  // const isUnread = useSelector(selectIsUnread);
  const isUnread = useGroupUnreadPipStatus(props.groupUid);

  const selectGroupSearchQuery = (state) => state?.data?.groupSearchQuery;

  const selectIsFilteredFromSearch = createSelector(
    selectGroupSearchQuery,
    selectHeader,
    (searchQuery, header) => {
      if (searchQuery.length === 0) return false;
      if (!header) return false;
      return !header.toLowerCase().includes(searchQuery.toLowerCase());
    }
  );

  const isFilteredFromSearch = useSelector(selectIsFilteredFromSearch);

  useEffect(() => {
    if (!dataListenerMap[props?.groupUid]) {
      dispatch(addGroupListener(props?.groupUid));
    }
  }, []);

  if (isFilteredFromSearch) {
    return null;
  }

  return (
    <SidebarListItem
      isUnread={isUnread}
      isNew={isNew}
      isPinned={isPinned}
      isMuted={nonMutedChannels.length === 0 || isGroupMuted}
      isPublic
      header={header}
      preview={getPreviewText(messageObjForPreview, previewMessageSenderName)}
      time={formatTime(messageObjForPreview?.createdAt ?? 0)}
      groupObj={groupObj}
      color={color}
      timestamp={messageObjForPreview?.createdAt ?? 0}
      createdAt={createdAt}
    />
  );
};

export default GroupListItem;
