import { getDatabase, ref, update } from "firebase/database";
import { auth } from "../client";

const typingCache: Record<string, boolean> = {};

export function addTypingIndicator(channelUid: string, timestamp?: number) {
  const myselfUid = auth.currentUser?.uid;
  if (!myselfUid) {
    return;
  }

  const updates: Record<string, number | null> = {};

  if (!!timestamp) {
    updates[`typingIndicator/${channelUid}/${myselfUid}`] = timestamp;
    typingCache[`typingIndicator/${channelUid}/${myselfUid}`] = true;
  } else {
    updates[`typingIndicator/${channelUid}/${myselfUid}`] = null;
    delete typingCache[`typingIndicator/${channelUid}/${myselfUid}`];
  }

  return update(ref(getDatabase()), updates);
}

export function clearTypingIndicatorCacheOnDestroy() {
  const updates: Record<string, null> = {};
  Object.keys(typingCache).forEach((typingCachePath) => {
    updates[typingCachePath] = null;
  });
  // Cleanup to ensure correctness
  Object.keys(typingCache).forEach((typingCachePath) => {
    delete typingCache[typingCachePath];
  });
  
  return update(ref(getDatabase()), updates);
}
