import React, { ButtonHTMLAttributes, FC, useMemo, useState } from "react";
import { AnimatePresence } from "framer-motion";

import {
  ReportBugButtonBlock,
  ReportBugButtonIcon,
  ReportBugButtonTooltip,
} from "./ReportBugButton.style";

type ReportBugButtonProps = {
  // ...
};

export const ReportBugButton: FC<ReportBugButtonProps> = ({}) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const bugButtonProps = useMemo(() => {
    const elementProps: ButtonHTMLAttributes<HTMLButtonElement> = {
      onMouseEnter: () => setIsTooltipVisible(true),
      onMouseLeave: () => setIsTooltipVisible(false),
      onClick: () => {
        window.open("https://forms.gle/XY6kNJMqqkdbqmkM9", "_blank");
      },
    };

    return elementProps;
  }, []);

  return (
    <>
      <ReportBugButtonBlock {...bugButtonProps}>
        <ReportBugButtonIcon />
      </ReportBugButtonBlock>
      <AnimatePresence>
        {isTooltipVisible ? (
          <ReportBugButtonTooltip
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ type: "spring", duration: 0.4, bounce: 0.1 }}
          >
            Leave Feedback!
          </ReportBugButtonTooltip>
        ) : null}
      </AnimatePresence>
    </>
  );
};
