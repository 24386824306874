import { useSelector, useDispatch } from "react-redux";
import React, { useEffect, useMemo } from "react";
import { addEventListener } from "../store/data";

export function useEventsLazily(uids: string[], excludePastEvents = true) {
  const dispatch = useDispatch();

  // const events = useSelector((s) => s.social.events);
  const events = useSelector((state) => state?.data?.events);

  useEffect(() => {
    uids.forEach((uid) => {
      if (uid) {
        dispatch(addEventListener(uid));
      }
    });
  }, [uids]);

  return useMemo(() => {
    const currentTimeMs = Date.now();

    return uids
      .filter((uid) => events[uid])
      .map((uid) => events[uid])
      .filter((event) => {
        return !excludePastEvents || currentTimeMs - event.endTime < HOUR_MS;
      });
  }, [events, excludePastEvents, uids]);
}
