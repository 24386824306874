import React, {
  ChangeEvent,
  FC,
  MouseEvent,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { EmojiClickData } from "emoji-picker-react";

// @ts-expect-error
import { ReactComponent as PhotoSvg } from "dive/assets/photo.svg";
// @ts-expect-error
import { ReactComponent as SmileSvg } from "dive/assets/smile.svg";
// @ts-expect-error
import { ReactComponent as PhotoLibrarySvg } from "dive/assets/photo-library.svg";
import { ColorPaletteRing } from "dive/components/ColorPaletteRing";
import {
  DARK_GRAY_COLOR_ID,
  LIGHT_GRAY_COLOR_ID,
  paletteGlassFills,
} from "dive/constants/palette.constant";
import { ColorId } from "dive/type/theme.type";
import { CustomGroupFormData } from "dive/store/form";
import { logDebug, logWarning } from "dive/util/log.util";
import Squircle from "dive/components/Squircle";
import { UiMenu } from "dive/components/UiMenu";
import { UiMenuOption } from "dive/components/UiMenu/UiMenu.type";
import { EmojiPicker } from "dive/components/EmojiPicker";

import {
  CustomGroupModalEmojiPosterPicker,
  CustomGroupModalPosterBadge,
  CustomGroupModalPosterBlock,
  CustomGroupModalPosterGrid,
  CustomGroupModalPosterImg,
  CustomGroupModalPosterView,
} from "./CustomGroupModalPoster.style";
import { getImageDimension } from "dive/util/image.util";

type CustomGroupModalPosterProps = {
  imageRawContent: string | undefined;
  emoji: string;
  colorId: string;
  mediaPickerPostfix: ReactNode;
  onImageRawContentChange: (
    imageRawContent: CustomGroupFormData["image"]
  ) => void;
  onEmojiChange: (emoji: string) => void;
  onColorIdChange: (colorId: ColorId) => void;
};

export const CustomGroupModalPoster: FC<CustomGroupModalPosterProps> = ({
  imageRawContent,
  emoji,
  colorId,
  mediaPickerPostfix,
  onImageRawContentChange,
  onEmojiChange,
  onColorIdChange,
}) => {
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [isEmojiPickerVisible, setIsEmojiPickerVisible] = useState(false);

  const menuRef = useRef<HTMLDivElement>(null);
  const emojiPickerRef = useRef<HTMLDivElement>(null);
  const editPosterButtonRef = useRef<HTMLButtonElement>(null);
  const imageSelectInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const onClickOutside: EventListener = (event) => {
      if (
        // @ts-expect-error
        !editPosterButtonRef.current?.contains(event.target) &&
        // @ts-expect-error
        !menuRef.current?.contains(event.target) &&
        // @ts-expect-error
        !emojiPickerRef.current?.contains(event.target)
      ) {
        setIsMenuVisible(false);
        setIsEmojiPickerVisible(false);
      }
    };

    document.addEventListener("mousedown", onClickOutside);

    return () => {
      document.removeEventListener("mousedown", onClickOutside);
    };
  }, []);

  const onPaletteGridClick = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      if (!event.target) {
        logDebug(
          `[CustomGroupModalPoster][onPaletteGridClick] Target is not defined`,
          event
        );

        return;
      }

      // @ts-expect-error
      const colorId = event.target.getAttribute("data-id");

      if (!colorId) {
        logDebug(
          `[CustomGroupModalPoster][onPaletteGridClick] Cannot get color iD`,
          colorId
        );

        return;
      }

      onColorIdChange(colorId);
    },
    [onColorIdChange]
  );

  const onFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    if (!event.target?.files) {
      logDebug(`[CustomGroupModalPoster][onFileChange] No files selected`);

      return;
    }

    if (event.target.files.length > 1) {
      logWarning(
        `[CustomGroupModalPoster][onFileChange] More than 1 file received`,
        event.target.files
      );
    }

    const imageFile = event.target.files[0];

    let fileReader = new FileReader();

    fileReader.readAsDataURL(imageFile);

    fileReader.onloadend = () => {
      if (!fileReader.result) {
        logDebug(
          `[CustomGroupModalPoster][onFileChange] Cannot get raw image content`
        );

        return;
      }

      const imageRawContent = fileReader.result.toString();

      getImageDimension(imageRawContent)
        .then((imageLayout) => {
          onImageRawContentChange({
            rawContent: imageRawContent,
            type: imageFile.type,
            width: imageLayout.width,
            height: imageLayout.height,
          });
        })
        .catch((ex) => {
          logDebug(
            `[CustomGroupModalPoster][onFileChange] Cannot get image size`,
            ex
          );
        });

      fileReader.abort();

      // Reset input state. See more:
      //   https://stackoverflow.com/a/16222877
      if (imageSelectInputRef.current) {
        imageSelectInputRef.current.type = "text";
        imageSelectInputRef.current.type = "file";
        imageSelectInputRef.current.value = "";
      }
    };

    fileReader.onerror = () => {
      logDebug(
        `[CustomGroupModalPoster][onFileChange][onerror] Cannot get raw image content`,
        fileReader.error
      );
    };
  };

  const onEmojiClick = (emojiData: EmojiClickData) => {
    // @ts-expect-error
    onEmojiChange(emojiData.id || emojiData.emoji);
    onImageRawContentChange(undefined);

    setIsEmojiPickerVisible(false);
  };

  const onOptionClick = (optionId: string) => {
    if (optionId === EMOJI_OPTION_ID) {
      setIsEmojiPickerVisible(true);
    } else if (optionId === IMAGE_OPTION_ID) {
      imageSelectInputRef.current?.click();
    }

    setIsMenuVisible(false);
  };

  const EMOJI_OPTION_ID = "emoji";
  const IMAGE_OPTION_ID = "image";

  const options: UiMenuOption[] = [
    {
      id: IMAGE_OPTION_ID,
      title: "Upload Photo",
      icon: <PhotoLibrarySvg width="22" />,
    },
    {
      id: EMOJI_OPTION_ID,
      title: "Choose Emoji",
      icon: <SmileSvg width="22" height="22" />,
    },
  ];

  const groupGlassFills = paletteGlassFills.filter(
    (item) => item.id !== DARK_GRAY_COLOR_ID && item.id !== LIGHT_GRAY_COLOR_ID
  );

  const selectedColor = paletteGlassFills.find(
    ({ id }) => id === colorId
  )?.cssBackgroundValue;

  if (!selectedColor) {
    logDebug("[CustomGroupModalPoster] Couldn't find selected color", {
      colorId,
      paletteGlassFills,
    });

    return null;
  }

  return (
    <>
      <CustomGroupModalPosterBlock>
        <CustomGroupModalPosterView
          $backgroundValue={selectedColor}
          ref={editPosterButtonRef}
          onClick={() => setIsMenuVisible(true)}
        >
          {imageRawContent ? (
            <CustomGroupModalPosterImg src={imageRawContent} />
          ) : (
            <Squircle
              channelEmoji={emoji}
              channelEmojiBg="transparent"
              channelEmojiSize={64}
              channelEmojiFontSize="52px"
              channelEmojiLineHeight="64px"
            />
          )}
          <CustomGroupModalPosterBadge>
            <PhotoSvg height="19" width="19" />
          </CustomGroupModalPosterBadge>
        </CustomGroupModalPosterView>
        {mediaPickerPostfix}
        <CustomGroupModalPosterGrid onClick={onPaletteGridClick}>
          {groupGlassFills.map(({ id, cssBackgroundValue }) => (
            <ColorPaletteRing
              key={id}
              id={id}
              isSelected={id === colorId}
              cssBgValue={cssBackgroundValue}
            />
          ))}
        </CustomGroupModalPosterGrid>
        <UiMenu
          isVisible={isMenuVisible}
          menuElementRef={menuRef}
          options={options}
          initial={{ opacity: 0, scale: 0, y: -45 }}
          animate={{ opacity: 1, scale: 1, y: 0 }}
          exit={{ opacity: 0, scale: 0, y: -45 }}
          topOffsetPx={124}
          onOptionClick={onOptionClick}
        />
        {isEmojiPickerVisible ? (
          <CustomGroupModalEmojiPosterPicker ref={emojiPickerRef}>
            <EmojiPicker
              isCustomEmojiVisible
              autoFocusSearch
              lazyLoadEmojis
              width={280}
              height={320}
              previewConfig={{ showPreview: false }}
              onEmojiClick={onEmojiClick}
            />
          </CustomGroupModalEmojiPosterPicker>
        ) : null}
      </CustomGroupModalPosterBlock>
      <input
        ref={imageSelectInputRef}
        hidden
        multiple={false}
        type="file"
        accept="image/*,"
        onChange={onFileChange}
      />
    </>
  );
};
