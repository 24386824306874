import { createSelector } from "@reduxjs/toolkit";
import get from "lodash/fp/get";
import values from "lodash/values";
import keys from "lodash/keys";
import pick from "lodash/pick";
import {
  NONE_LAST_ACTIVE_PERMISSION,
  CONTACTS_LAST_ACTIVE_PERMISSION,
} from "../../constants/lastActive.constant";
import { DraftMap } from "../../type/draft.type";
import { MyselfRecord, ProfileScore, User } from "../../type/user.type";
import { Group } from "../../type/group.type";
import { ChannelRecord } from "../../type/channel.type";
import { CustomEmoji } from "dive/type/custom-emoji.type";
import { CONCISE_REACT_VIEW } from "dive/constants/user.constant";

export const dataSelector = get("data");

export const dataSelectedEventIdSelector = createSelector(
  dataSelector,
  get("selectedEvent")
);

// Drafts
export const dataDraftMapSelector = createSelector(
  dataSelector,
  get("drafts") as unknown as () => DraftMap
);

export const dataDraftByChannelIdSelector = createSelector(
  [dataDraftMapSelector, (st, channelId) => channelId],
  (draftMap, channelId) => get(channelId)(draftMap)
);

// Listener Map
export const dataListenerMapSelector = createSelector(
  dataSelector,
  get("listenerMap")
);

// Channels
export const dataChannelsSelector = createSelector(
  dataSelector,
  get("channels") as unknown as () => Record<string, ChannelRecord>
);

export const dataChannelsByIdSelector = createSelector(
  [dataChannelsSelector, (_, channelId: string) => channelId],
  (channels, channelId) => get(channelId)(channels) as unknown as ChannelRecord
);

export const dataChannelsByIdMembersSelector = createSelector(
  dataChannelsByIdSelector,
  get("members")
);

export const dataChannelsByIdEmojiSelector = createSelector(
  dataChannelsByIdSelector,
  get("emoji")
);

export const dataSelectedChannelIdSelector = createSelector(
  dataSelector,
  get("selectedChannel")
);

// Sidebar
export const dataIsEventSidebarOpenSelector = createSelector(
  dataSelector,
  get("isEventsSidebarOpen")
);

export const dataIsNestedSidebarOpenSelector = createSelector(
  dataSelector,
  get("isNestedSidebarOpen")
);

// Groups
export const dataGroupsSelector = createSelector(
  dataSelector,
  get("groups") as unknown as () => Record<string, Group>
);

export const dataSelectedGroupSelector = createSelector(
  dataSelector,
  get("selectedGroup")
);

export const dataSelectedGroupSidebarSelector = createSelector(
  dataSelector,
  get("selectedGroupSidebar")
);

export const dataGroupsSelectedGroupSelector = createSelector(
  dataGroupsSelector,
  dataSelectedGroupSelector,
  (groups, selectedGroupId) => get(selectedGroupId)(groups) as Group
);

export const dataGroupByIdSelector = createSelector(
  [dataGroupsSelector, (st, groupId: string) => groupId],
  (groups, groupId) => get(groupId)(groups) as Group
);

export const dataSelectedChannelSelector = createSelector(
  dataSelector,
  get("selectedChannel")
);

// Recents

export const dataRecentsSelector = createSelector(
  dataSelector,
  (data) => get("recents")(data) || {}
);

// Last Viewed
export const dataLastViewedMapSelector = createSelector(
  dataSelector,
  get("lastViewed") as unknown as () => Record<
    string,
    number | null | undefined
  >
);

export const dataLastViewedByChannelIdSelector = createSelector(
  dataLastViewedMapSelector,
  (channelId) =>
    (get("lastViewed")(channelId) || {}) as number | null | undefined
);

// Comments
export const dataCommentMapSelector = createSelector(
  dataSelector,
  get("comments")
);

export const dataCommentByIdSelector = createSelector(
  [dataCommentMapSelector, (_, { commentId }) => commentId],
  (commentMap, commentId) => get(commentId)(commentMap)
);

// Messages
export const dataMessagesSelector = createSelector(
  dataSelector,
  get("messages")
);

export const dataMessageMapByChannelSelector = createSelector(
  [dataMessagesSelector, (_, channelId) => channelId],
  (messages, channelId) => get(channelId)(messages)
);

export const dataMessagesByChannelSelector = createSelector(
  [dataMessagesSelector, (_, channelId) => channelId],
  (messages, channelId) => values(get(channelId)(messages))
);

export const dataMessagesByIdlSelector = createSelector(
  [
    dataMessagesSelector,
    (_, { messageId, channelId }) => ({ messageId, channelId }),
  ],
  (messageMap, { channelId, messageId }) => messageMap?.[channelId]?.[messageId]
);

export const dataMessagesByChannelMentionedIdsSelector = createSelector(
  dataMessagesByChannelSelector,
  (messages) => {
    const peopleIdMap = messages.reduce(
      (acc, record) =>
        record?.people
          ? {
              ...acc,
              ...record.people.reduce(
                (currentMap, userId) => ({ ...currentMap, [userId]: null }),
                {}
              ),
            }
          : acc,
      {}
    );

    return keys(peopleIdMap);
  }
);

// Returns sorted by date list of UIDs of users that sent messages to channel
export const dataMessagesByChannelPublishersSelector = createSelector(
  dataMessagesByChannelSelector,
  (messages) => {
    const messageList = messages.sort(
      (el1, el2) => el2.createdAt - el1.createdAt
    );

    const publishers = messageList.reduce((acc, message) => {
      const publisherId = message?.user?.uid;

      if (!publisherId || acc.includes(publisherId)) {
        return acc;
      } else {
        return [...acc, publisherId];
      }
    }, []);

    return publishers;
  }
);

// Returns sorted by date list of UIDs of users that sent messages to channel most recently concatenated with list of UIDs of users that are members of channel
// This is used in the @mention implementation
// There are no duplicates in the list
export const dataMessagesByChannelPublishersAndMembersSelector = createSelector(
  dataMessagesByChannelPublishersSelector,
  dataChannelsByIdMembersSelector,
  (publishers, members) => {
    const publishersSet = new Set(publishers);
    const membersSet = new Set(Object.keys(members));

    const publishersAndMembers = [...publishersSet, ...membersSet];
    const noDuplicates = Array.from(new Set(publishersAndMembers));

    return noDuplicates;
  }
);

// Users
export const dataUsersSelector = createSelector(
  dataSelector,
  get("users") as unknown as () => Record<string, User>
);

export const dataChannelsByIdMembersProfilesSelector = createSelector(
  [dataChannelsByIdMembersSelector, dataUsersSelector],
  (memberMap, userMap) => {
    const memberIdList = Object.keys(memberMap || {});

    return memberIdList
      .map((memberId) => {
        return userMap[memberId];
      })
      .filter((profile) => !!profile);
  }
);

export const dataUsersByIdSelector = createSelector(
  [dataUsersSelector, (_, userId: string) => userId],
  (userMap, userId) => get(userId)(userMap) as User
);

export const dataUsersByIdsSelector = createSelector(
  [dataUsersSelector, (_, userIds) => userIds],
  (userMap, userIds) => pick(userMap, userIds)
);

// Myself
export const dataMyselfSelector = createSelector(
  dataSelector,
  get("myself") as unknown as () => MyselfRecord
);

export const dataMyselfIdSelector = createSelector(
  dataMyselfSelector,
  get("uid")
);

export const dataMyselfSpamReactViewSelector = createSelector(
  dataMyselfSelector,
  (profile) => profile.spamReactionView || CONCISE_REACT_VIEW
);

export const dataMyselfSharedDataSelector = createSelector(
  dataMyselfSelector,
  get("sharedData")
);

export const dataMyselfUnreadChatsSelector = createSelector(
  dataMyselfSelector,
  get("unreadChats")
);

// lastActiveMap
export const dataLastActiveMapSelector = createSelector(
  dataSelector,
  get("lastActiveMap")
);

export const dataLastActiveByUserIdSelector = createSelector(
  [dataLastActiveMapSelector, (st, userId) => userId],
  (lastActiveMap, userId) => get(userId)(lastActiveMap)
);

export const dataLastActiveByUserIdAndPermissionSelector = createSelector(
  [
    dataUsersSelector,
    dataLastActiveByUserIdSelector,
    dataMyselfSelector,
    (st, userId) => userId,
  ],
  (userMap, userLastActive, myselfData, userId): boolean | number => {
    if (userId === myselfData?.uid) {
      return true;
    }

    const userLastActivePermission =
      userMap?.[userId]?.sharingActiveTimestampTo;

    const myselfLastActivePermissions =
      myselfData?.sharedData?.sharingActiveTimestampTo;

    // TODO: Handle contacts on Web
    const isContact = false;

    if (
      !userLastActive ||
      userLastActivePermission === NONE_LAST_ACTIVE_PERMISSION ||
      myselfLastActivePermissions === NONE_LAST_ACTIVE_PERMISSION ||
      (myselfLastActivePermissions === CONTACTS_LAST_ACTIVE_PERMISSION &&
        !isContact) ||
      (userLastActivePermission === CONTACTS_LAST_ACTIVE_PERMISSION &&
        !isContact)
    ) {
      return null;
    }

    return userLastActive;
  }
);

export const dataProfileScoreSelector = createSelector(
  dataSelector,
  get("profileScores")
);

export const dataProfileScoreByUserIdSelector = createSelector(
  [dataProfileScoreSelector, (_, userId: string) => userId],
  (profileScoreMap, userId) => profileScoreMap?.[userId] as ProfileScore
);

export const dataCustomEmojiMapSelector = createSelector(
  dataSelector,
  (data) =>
    data.customEmojiMap as {
      [groupId: string]: {
        [emojiId: string]: CustomEmoji;
      };
    }
);

export const dataCustomEmojiByIdSelector = createSelector(
  [dataCustomEmojiMapSelector, (st, emojiId) => emojiId],
  (customEmojiMap, emojiId) => {
    return Object.keys(customEmojiMap || {})
      .flatMap((groupId) => Object.values(customEmojiMap[groupId] || {}))
      .find((customEmoji) => customEmoji?.emojiUid === emojiId);
  }
);
