// Copied from:
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/random#getting_a_random_integer_between_two_values
export function getRandomInt(min: number, max: number) {
  min = Math.ceil(min);
  max = Math.floor(max);

  // The maximum is exclusive and the minimum is inclusive
  return Math.floor(Math.random() * (max - min) + min);
}

// https://stackoverflow.com/a/40724354
export function formatNumber(num: number) {
  var SI_SYMBOL = ["", "K", "M", "G", "T", "P", "E"];

  // what tier? (determines SI symbol)
  var tier = (Math.log10(Math.abs(num)) / 3) | 0;

  // if zero, we don't need a suffix
  if (tier == 0) return `${num}`;

  // get suffix and determine scale
  var suffix = SI_SYMBOL[tier];
  var scale = Math.pow(10, tier * 3);

  // scale the number
  var scaled = Math.trunc(10 * (num / scale)) / 10;

  // format number and add suffix
  return scaled + suffix;
}

/**
 *
 * @param int An integer
 * @returns string
 * Converts all numbers from 1-100 to english.
 * Example: 11 => Eleven
 */
export function integerToEnglish(int: number) {
  if (!Number.isInteger(int) || int < 0) {
    return int.toString();
  }
  const single_digit = [
    "",
    "One",
    "Two",
    "Three",
    "Four",
    "Five",
    "Six",
    "Seven",
    "Eight",
    "Nine",
  ];
  const double_digit = [
    "Ten",
    "Eleven",
    "Twelve",
    "Thirteen",
    "Fourteen",
    "Fifteen",
    "Sixteen",
    "Seventeen",
    "Eighteen",
    "Nineteen",
  ];
  const below_hundred = [
    "Twenty",
    "Thirty",
    "Forty",
    "Fifty",
    "Sixty",
    "Seventy",
    "Eighty",
    "Ninety",
  ];
  const translate = (integer: number) => {
    let word = "";
    if (integer < 10) {
      word = single_digit[integer] + " ";
    } else if (integer < 20) {
      word = double_digit[integer - 10] + " ";
    } else if (integer < 100) {
      const rem = translate(integer % 10);
      word = below_hundred[(integer - (integer % 10)) / 10 - 2] + " " + rem;
    } else {
      word = integer.toString();
    }
    return word;
  };
  return translate(int).trim();
}
