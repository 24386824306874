import React, {
  ForwardRefRenderFunction,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

import { getRandomInt } from "../../util/number.util";
import { logDebug } from "../../util/log.util";

import { AnimatedCounterBlock } from "./AnimatedCounterBlock";

export type AnimatedCounterProps = {
  onShow?: VoidFunction;
  onHide?: VoidFunction;
};

export type AnimatedCounterHandle = {
  show: (count: number, options?: ShowCounterOptions) => void;
};

type CounterBlock = {
  id: number;
  count: number;
  xPosPercent: number;
  yPosPercent: number;
  rotateDeg: number;
  textColor: string;
  onHide: VoidFunction;
};

type ShowCounterOptions = {
  textColor: string;
};

const AnimatedCounterComponent: ForwardRefRenderFunction<
  AnimatedCounterHandle,
  AnimatedCounterProps
> = ({ onShow, onHide }, elementRef) => {
  const [counterBlocks, setCounterBlocks] = useState<CounterBlock[]>([]);

  const prevCounterBlocksCountRef = useRef<number>(0);

  const onBlockAnimationFinish = (blockId: number) => {
    setCounterBlocks((currentCounterBlocks) => {
      logDebug("[AnimatedCounter] Hiding block with ID: ", blockId);

      return currentCounterBlocks.filter(({ id }) => id !== blockId);
    });
  };

  useEffect(() => {
    logDebug("[AnimatedCounter] Counter blocks change: ", counterBlocks);

    if (prevCounterBlocksCountRef.current > 0 && counterBlocks.length === 0) {
      logDebug(`[AnimatedCounter] Calling 'onHide' handler`);

      if (onHide) {
        onHide();
      }
    }

    if (prevCounterBlocksCountRef.current === 0 && counterBlocks.length > 0) {
      logDebug(`[AnimatedCounter] Calling 'onShow' handler`);

      if (onShow) {
        onShow();
      }
    }

    prevCounterBlocksCountRef.current = counterBlocks.length;
  }, [counterBlocks]);

  useImperativeHandle(elementRef, () => ({
    show: (count, options) => {
      setCounterBlocks((currentCounterBlocks) => {
        //
        // NOTE: The spam react multiplier is temporarily removed from the application
        //
        // See more:
        //   https://www.notion.so/divechat/Spam-React-Experimental-Toggle-83df7b72bbfe4e35ab9a86d3b70276e7?pvs=4#3aa25f5dc2344f6888d9abd9c030cbca
        //
        const isVisible = false;

        if (!isVisible) {
          return [];
        }

        if (!count) {
          return [];
        }

        const blockId = Date.now();

        const randomXPosPercent = getRandomInt(10, 80);
        const randomYPosPercent = getRandomInt(5, 40);
        const randomRotateDeg = getRandomInt(-15, 15);

        const newBlock: CounterBlock = {
          id: blockId,
          count: count,
          xPosPercent: randomXPosPercent,
          yPosPercent: randomYPosPercent,
          rotateDeg: randomRotateDeg,
          textColor: options?.textColor || "#000",
          onHide: () => onBlockAnimationFinish(blockId),
        };

        if (currentCounterBlocks.length < 10) {
          return [...currentCounterBlocks, newBlock];
        } else {
          const [firstCounterBlock, ...relevantCurrentBlocks] =
            currentCounterBlocks;

          return [newBlock, ...relevantCurrentBlocks];
        }
      });
    },
  }));

  const counterBlocksCount = counterBlocks.length;

  return (
    <>
      {counterBlocks.map(
        (
          { id, count, xPosPercent, yPosPercent, rotateDeg, textColor, onHide },
          index
        ) => {
          return (
            <AnimatedCounterBlock
              key={`${id}`}
              isLast={index === counterBlocksCount - 1}
              count={count}
              xPosPercent={xPosPercent}
              yPosPercent={yPosPercent}
              rotateDeg={rotateDeg}
              textColor={textColor}
              onHide={onHide}
            />
          );
        }
      )}
    </>
  );
};

export const AnimatedCounter = forwardRef(AnimatedCounterComponent);
