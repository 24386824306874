import { gt } from "semver";

import { logWarning } from "./log.util";

export function getIsUpdateAvailableState(
  currentAppVersion: string,
  latestAppVersion: string
) {
  if (!currentAppVersion || !latestAppVersion) {
    logWarning("[getIsUpdateAvailableState] The arguments aren't provided", {
      currentAppVersion,
      latestAppVersion,
    });

    return false;
  }

  if (currentAppVersion === latestAppVersion) {
    return false;
  }

  return gt(latestAppVersion, currentAppVersion);
}
