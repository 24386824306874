import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import styled from "styled-components";

import GlobalFonts from "../assets/fonts/fonts";
import backgroundNoise from "../assets/noise.svg";
import analytics from "../util/analytics";
import { auth } from "../firebase/client";
import { ProfileCardProvider } from "../provider/ProfileCardProvider";
import { useDispatch } from "../store";
import { setIsBrowser } from "../store/application";
import { DesktopNotificationProvider } from "../provider/DesktopNotificationProvider";
import { ApplicationProvider } from "../provider/ApplicationProvider";
import { UpdateAvailableProvider } from "../provider/UpdateAvailableProvider";
import { ModalProvider } from "dive/provider/ModalProvider";

import Login from "./Login";
import { Mainpage } from "./Mainpage";

function getMobileOperatingSystem() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }

  return "unknown";
}

function App() {
  const [user, loading, error] = useAuthState(auth);
  const [isMobile, setIsMobile] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (user) {
      analytics.identify(user?.uid);
      analytics.register({ isWeb: true });
      analytics.track("web_page_load");
    }
  }, [user]);

  useEffect(() => {
    if (navigator?.userAgent?.toLowerCase()?.includes("electron")) {
      dispatch(setIsBrowser(false));
    }
  }, []);

  useEffect(() => {
    /* if user is mobile, redirect */
    if (
      window.innerWidth < 600 &&
      !navigator?.userAgent?.toLowerCase()?.includes("electron")
    ) {
      window.location.replace("https://dive.chat");
      if (getMobileOperatingSystem() === "Android") {
        window.location.assign(
          "https://play.google.com/store/apps/details?id=com.Dive.Dive&hl=en_US&gl=US"
        );
        setIsMobile(true);
        // mixpanel.track("website_view", {
        //   page: "download-redirect",
        //   os: "android",
        // });
      } else {
        window.location.assign(
          "https://apps.apple.com/us/app/dive-chat/id1482213146"
        );
        setIsMobile(true);
        // mixpanel.track("website_view", { page: "download-redirect", os: "iOS" });
      }
    }
  }, []);

  return loading ? (
    <></>
  ) : isMobile ? (
    <GradientContainer>
      <MobileHeaderText>Dive Chat</MobileHeaderText>
      <MobileDescriptionText>The web app is desktop only</MobileDescriptionText>
      <MobileDescriptionText>
        Download the app for mobile use
      </MobileDescriptionText>
    </GradientContainer>
  ) : (
    <ApplicationProvider>
      <UpdateAvailableProvider>
        {user ? (
          <DesktopNotificationProvider>
            <ModalProvider>
              <MainContainer>
                <GlobalFonts />
                <ProfileCardProvider>
                  <Mainpage />
                </ProfileCardProvider>
              </MainContainer>
            </ModalProvider>
          </DesktopNotificationProvider>
        ) : (
          <MainContainer>
            <GlobalFonts />
            <Login />
          </MainContainer>
        )}
      </UpdateAvailableProvider>
    </ApplicationProvider>
  );
}

export default App;

const MainContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const MobileHeaderText = styled.div`
  font-weight: 600;
  font-size: 36px;
  line-height: 34px;
  padding-bottom: 20px;
`;

const MobileDescriptionText = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  align-items: center;
`;

const GradientContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // z-index: -1;
  width: 100%;
  height: 100%;
  color: white;
  font-family: "SF Pro Rounded";
  font-style: normal;

  background: radial-gradient(
        35.97% 25.18% at 114.25% 16.57%,
        #ff7a00 0%,
        #ff7a00 16.15%,
        rgba(255, 122, 0, 0) 100%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    radial-gradient(
        51.9% 36.33% at 100% 38.2%,
        #ff7a00 0%,
        #ff7a00 16.15%,
        rgba(255, 122, 0, 0) 100%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    radial-gradient(
        29.9% 40.7% at 7.9% 37.8%,
        #ff3156 0%,
        rgba(255, 49, 86, 0) 100%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    radial-gradient(
        75.84% 65.74% at 11.42% 89.35%,
        #ff3156 0%,
        rgba(255, 49, 86, 0) 100%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    #ff007a;
  // &:before {
  //   content: "";
  //   background-image: url(${backgroundNoise});
  //   background-size: tile;
  //   width: 100%;
  //   height: 100%;
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   opacity: 0.075;
  // }
`;
