import { useCallback } from "react";

import { SubchatType } from "dive/type/subchat.type";
import { generateChannelId } from "dive/firebase/channel/generateChannelId";
import { store, useSelector } from "dive/store";
import { dataGroupByIdSelector, dataMyselfIdSelector } from "dive/store/data";
import {
  ANNOUNCEMENT_SUBCHAT_TYPE,
  PRIVATE_SUBCHAT_TYPE,
  PUBLIC_SUBCHAT_TYPE,
} from "dive/constants/subchat.contant";
import * as channel from "dive/firebase/channel";
import { logDebug } from "dive/util/log.util";

type CreateChannelPayload = {
  groupId: string;
  name: string;
  emoji: string;
  isAutoAdd: boolean;
  type: SubchatType;
};

export function useCreateChannel() {
  // @ts-expect-error
  const myId = useSelector(dataMyselfIdSelector);

  const createChannel = useCallback(
    async (payload: CreateChannelPayload) => {
      const { groupId, name, emoji, isAutoAdd, type } = payload;

      const newChannelId = generateChannelId();

      if (!newChannelId) {
        logDebug(`[useCreateChannel] Cannot generate new channel ID`, {
          newChannelId,
        });

        return Promise.reject(new Error("Cannot generate new channel ID"));
      }

      let isPublic = true,
        isAdminPost = false;

      if (type === PUBLIC_SUBCHAT_TYPE) {
        isPublic = true;
        isAdminPost = false;
      } else if (type === PRIVATE_SUBCHAT_TYPE) {
        isPublic = false;
        isAdminPost = false;
      } else if (type === ANNOUNCEMENT_SUBCHAT_TYPE) {
        isPublic = true;
        isAdminPost = true;
      }

      const channelData = {
        isPublic,
        isAdminPost,
        isDefault: isAutoAdd,
        isAdminChannel: false,
        isDM: false,
        uid: newChannelId,
        groupUid: groupId,
        createdBy: myId,
        name,
        emoji,
        typing: {},
        recent: -1,
      };

      await channel.createChannel({ data: channelData });

      await channel.sendChannelInitialMessage({
        channelId: newChannelId,
        showCuratedEmojiSuggestion: false,
        isAdminPost,
        isPublic,
        isMain: false,
      });

      const storeSnapshot = store.getState();

      // @ts-expect-error
      const groupData = dataGroupByIdSelector(storeSnapshot, groupId);

      const memberIds = Object.keys(groupData.members || {});

      const taskList = memberIds.map((memberId) =>
        channel.joinChannel({
          channelId: newChannelId,
          groupId: groupId,
          userId: memberId,
        })
      );

      await Promise.all(taskList);

      return channelData;
    },
    [myId]
  );

  return {
    createChannel,
    meta: {
      myId,
    },
  };
}
