import React, { FC, useCallback } from "react";

import { useDispatch, useSelector } from "dive/store";
import {
  applicationIsDesktopAppPromoVisibleSelector,
  setIsDesktopAppPromoVisible,
} from "dive/store/application";
import { useIsBrowser } from "dive/hook/useIsBrowser";
import analytics from "dive/util/analytics";

import { DesktopSidebarTile } from "./DesktopSidebarTile";

type DesktopSidebarTileContainerProps = {};

export const DesktopSidebarTileContainer: FC<
  DesktopSidebarTileContainerProps
> = ({}) => {
  const dispatch = useDispatch();

  const isBrowser = useIsBrowser();

  const isDesktopAppPromoVisible = useSelector(
    applicationIsDesktopAppPromoVisibleSelector
  );

  const onContainerClick = useCallback(() => {
    analytics.track("web_press_desktop_ad");
  }, []);

  const onClose = useCallback(
    () => {
      dispatch(setIsDesktopAppPromoVisible(false));

      analytics.track("web_dismiss_desktop_ad");
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <DesktopSidebarTile
      isVisible={!!(isBrowser && isDesktopAppPromoVisible)}
      onContainerClick={onContainerClick}
      onClose={onClose}
    />
  );
};
