import React, { FC, ReactNode, useMemo } from "react";

import { CustomGroupFormData } from "dive/store/form";
import { ColorId } from "dive/type/theme.type";

import { useCustomGroupFormData } from "../useCustomGroupFormData";

import { CustomGroupModalPoster } from "./CustomGroupModalPoster";

type CustomGroupModalPosterContainerProps = {
  mediaPickerPostfix: ReactNode;
};

export const CustomGroupModalPosterContainer: FC<
  CustomGroupModalPosterContainerProps
> = ({ mediaPickerPostfix }) => {
  const {
    data,
    setData,
    meta: { initialData },
  } = useCustomGroupFormData();

  const { onImageRawContentChange, onEmojiChange, onColorIdChange } =
    useMemo(() => {
      return {
        onImageRawContentChange: (image: CustomGroupFormData["image"]) => {
          setData({ image });
        },
        onEmojiChange: (emoji: string) => {
          setData({ emoji });
        },
        onColorIdChange: (colorId: ColorId) => {
          setData({ colorId });
        },
      };
    }, [setData]);

  return (
    <CustomGroupModalPoster
      imageRawContent={data?.image?.rawContent}
      emoji={data?.emoji || initialData.emoji}
      colorId={data?.colorId || initialData.colorId}
      mediaPickerPostfix={mediaPickerPostfix}
      onImageRawContentChange={onImageRawContentChange}
      onEmojiChange={onEmojiChange}
      onColorIdChange={onColorIdChange}
    />
  );
};
